<template>
  <b-container
    fluid
    class="mt-2 px-1 px-sm-3 pb-3 accounting-page new-page"
  >
    <b-row>
      <b-col cols="" class="">
        <b-row>
          <b-col cols="">
            <div class="label mb-3 text-left">
              <router-link to="/">dashboard / </router-link>
              <router-link to="/customers">customers</router-link>
              / <span> customer</span>
            </div>
            <div class="expiry-data text-left mb-4">
              <h2>
                Customer //
                <span class="font-weight-normal">
                  {{ currentCustomer.name }}
                </span>
              </h2>
            </div>
            <div class="data-table tabs-box bg-white">
              <v-tabs class="team-tabs mb-0 bg-white">
                <v-tab @click="tabsValue = 0"
                  ><img
                    :src="
                      tabsValue === 0
                        ? require('@/assets/general-checked.svg')
                        : require('@/assets/general.svg')
                    "
                    class="mr-6"
                  />General Info</v-tab
                >
                <v-tab @click="tabsValue = 1"
                  ><img
                    :src="
                      tabsValue === 0
                        ? require('@/assets/customer-contact.svg')
                        : require('@/assets/customer-contact-checked.svg')
                    "
                    class="mr-6"
                  />
                  Contacts
                </v-tab>
              </v-tabs>
            </div>
            <CustomerInfo
              v-if="tabsValue === 0"
              :currentCustomer="currentCustomer"
            />
            <CustomerContacts v-if="tabsValue === 1" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CustomerInfo from "@/components/CustomerInfo.vue";
import CustomerContacts from "@/components/CustomerContacts.vue";

export default {
  name: "CustomerDetails",
  components: {
    CustomerInfo,
    CustomerContacts,
  },
  data() {
    return {
      tabsValue: 0,
      customerId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters(["currentCustomer"]),
  },
  methods: {
    ...mapActions(["setNotification", "getCurrentCustomer"]),
  },
  async mounted() {
    try {
      await Promise.all([this.getCurrentCustomer(this.customerId)]);
    } catch (error) {
      console.log(error);
      await this.setNotification({
        msg: error,
        type: "error",
        color: "red",
      });
      this.$router.push("/");
    }
  },
};
</script>

<style>
.accounting-page .card-header {
  margin-bottom: 12px;
  padding: 0px;
  background-color: #fff !important;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 5px !important;
  height: 45px;
}

.mr-6 {
  margin-right: 6px;
}

.accounting-page .nav-tabs .nav-item .nav-link {
  background-color: transparent !important;
  color: #797979 !important;
  padding: 0;
  margin: 0 45px;
  height: 45px;
}

.accounting-page .nav-tabs .nav-item .nav-link:first-child {
  margin-left: 25px;
}

.accounting-page .nav-tabs .nav-item .nav-link.active {
  color: var(--yellow) !important;
}

.accounting-page .tab-content {
  border-radius: 5px;
  padding: 0;
  box-shadow: unset;
  background-color: transparent;
}

.active_img {
  filter: invert(40%) sepia(67%) saturate(1912%) hue-rotate(215deg)
    brightness(95%) contrast(87%);
}

.accounting-page .tab-content .card-body {
  padding: 0;
}

.accounting-page .tab-content .card-body .card-text {
  padding: 25px;
  box-shadow: 0px 4px 50px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 5px;
}

.accounting-page .data-table {
  margin-top: 20px;
  padding: 25px;
}

.accounting-page .add {
  background-color: var(--btn-bg-color);
}

.accounting-page .cancel {
  background-color: #dddddd !important;
  color: #000;
}

.accounting-page .btn.cancel,
.accounting-page .btn.save {
  width: 100px;
}

.accounting-page hr {
  opacity: 0.1;
  margin: 20px 0;
}

.accounting-page label {
  color: #000;
}
</style>
