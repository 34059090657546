<template>
  <b-container fluid class="mt-2 px-1 px-sm-3 pb-3 team-page">
    <b-row>
      <b-col cols="" class="">
        <b-row>
          <b-col cols="">
            <div class="label mb-3 text-left">
              <router-link to="/">dashboard</router-link> /
              <span> customers</span>
            </div>
            <div
              class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3"
            >
              <h2>Customers</h2>
              <div
                class="d-flex justify-content-between justify-content-sm-center flex-wrap"
              >
                <b-button
                  class="export d-flex justify-content-center align-items-center mx-1 my-1"
                  @click="downloadExcel"
                >
                  <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
                  <p class="mb-0 ml-3 text-white" variant="primary">Export to CSV</p>
                </b-button>
                <b-button
                  @click="openModal"
                  class="export equipment d-flex justify-content-center align-items-center mx-1 my-1"
                >
                  <b-icon icon="plus" aria-hidden="true"></b-icon>
                  <p class="mb-0 ml-2 text-white">Add Customer</p>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="data-table team-data-table bg-white px-4 rounded pt-4">
              <div class="table_row team_table_row">
                <div class="text-left">
                  <h4>Filter Data</h4>
                </div>

                <form class="equipment-border d-flex mt-4">
                  <b-form-group label="Name" class="text-left label">
                    <b-form-input
                      type="text"
                      class="mb-4 input"
                      required
                      v-model="searchName"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Country"
                    class="text-left select_arrow label"
                  >
                    <b-form-select
                      v-model="searchCountry"
                      :options="countries"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Billing Email"
                    class="text-left select_arrow label"
                  >
                    <b-form-select
                      type="text"
                      class="mb-4 input"
                      required
                      v-model="searchBillingEmail"
                      :options="billingOptions"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label="Status"
                    class="text-left select_arrow label"
                  >
                    <b-form-select
                      v-model="searchStatus"
                      :options="statusOptions"
                    />
                  </b-form-group>
                </form>

                <b-table
                  responsive
                  :fields="fields"
                  :items="searchItems"
                  striped
                  hover
                  :per-page="perPage"
                  :current-page="currentPage"
                >
                  <template #cell(name)="data">
                    <router-link
                      class="text_primary"
                      :to="{
                        name: 'CustomerDetails',
                        params: { id: data.item.id },
                      }"
                      >{{ data.value }}</router-link
                    >
                  </template>
                  <template #cell(billing_email)="data">
                    <b-img
                      :src="
                        data.value === 'Active'
                          ? require('@/assets/Check.svg')
                          : require('@/assets/Close.svg')
                      "
                    ></b-img>
                  </template>
                  <template #cell(status)="data">
                    <b-button
                      class="export table_btn d-flex justify-content-center align-items-center my-1"
                      :class="
                        data.item.status === 'Active'
                          ? 'equipment'
                          : 'inactive-equipment'
                      "
                    >
                      <p class="mb-0">{{ data.value }}</p>
                    </b-button>
                  </template>
                </b-table>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="entries-pagination mt-0">
          <p class="text-left" style="opacity: 0.6">
            *click on name to open item page, and edit update information there
          </p>
          <div
            class="d-flex align-items-center justify-content-between flex-wrap"
          >
            <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
              <b-form-select v-model="perPage" :options="pageOptions" />
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg> -->
            </div>
            <div class="pagination">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
          <p class="mt-2">{{ positionText }}</p>
        </div>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal id="create-customer" centered hide-footer title="Add Customer">
      <div class="d-block text-center">
        <b-row>
          <b-col lg="12" md="12" sm="12">
            <b-form>
              <b-form-group label="Name" class="text-left font-weight-normal">
                <b-form-input
                  type="text"
                  placeholder="Enter Name"
                  class="unit"
                  required
                  v-model="customer.name"
                ></b-form-input>
                <p v-if="errors.name" class="field-error">
                  This field is required.
                </p>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col lg="12" md="12" sm="12">
            <b-form>
              <b-form-group
                label="Address"
                class="text-left label font-weight-normal"
              >
                <b-form-input
                  type="text"
                  placeholder="Enter Address"
                  class="unit"
                  required
                  v-model="customer.address"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col lg="6" md="6" sm="12" class="my-3 select_arrow text-left">
            <b-form>
              <b-form-group
                label="City"
                class="text-left label font-weight-normal"
              >
                <b-form-input
                  type="text"
                  placeholder="Enter City"
                  class="unit"
                  required
                  v-model="customer.city"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col lg="6" md="6" sm="12" class="my-3 select_arrow text-left">
            <label>State</label>
            <v-select
              class="bg_white w-100"
              v-model="customer.state"
              :options="states"
              :reduce="(option) => option.id"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" class="my-3 select_arrow text-left">
            <b-form>
              <b-form-group
                label="Postal Code"
                class="text-left label font-weight-normal"
              >
                <b-form-input
                  type="text"
                  placeholder="Enter Postal Code"
                  class="unit"
                  required
                  v-model="customer.postal_code"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col lg="6" md="6" sm="12" class="my-3 text-left">
            <label>Country</label>
            <v-select
              class="bg_white w-100"
              @option:selected="getStates"
              v-model="customer.country"
              :options="customerCountries"
              :reduce="(option) => option.id"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" class="my-3 select_arrow text-left">
            <b-form>
              <b-form-group
                label="Phone"
                class="text-left label font-weight-normal"
              >
                <vue-tel-input
                  v-bind="bindProps"
                  class="w-100 f-14"
                  v-model="customer.phone"
                  default-country="US"
                ></vue-tel-input>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col lg="6" md="6" sm="12" class="my-3 select_arrow text-left">
            <b-form>
              <b-form-group
                label="Billing Email"
                class="text-left label font-weight-normal"
              >
                <b-form-input
                  type="text"
                  placeholder="Enter Billing Email"
                  class="unit"
                  required
                  v-model="customer.billing_email"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button
          class="mt-3 mr-3 btn cancel"
          block
          @click="$bvModal.hide('create-customer')"
          >Cancel</b-button
        >
        <b-button class="mt-3 btn save" @click="createCustomer">Save</b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "Team-list",
  components: {
    vSelect,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      isPhoneValid: false,
      bindProps: {
        mode: "international",
        autoFormat: true,
        preferredCountries: ["US", "CA", "MX"],
        inputOptions: {
          placeholder: "Enter a phone no.",
          maxlength: 30,
        },
        dropdownOptions: {
          showSearchBox: true,
          showDialCodeInList: true,
          showFlags: true,
          showDialCodeInSelection: true,
          width: "300px",
        },
      },
      errors: {
        name: false,
      },
      countries: [],
      customerCountries: [],
      states: [],
      customer: {
        name: null,
        address: null,
        city: null,
        state: null,
        postal_code: null,
        country: null,
        phone: null,
        billing_email: null,
        company: null,
      },
      searchName: null,
      searchCountry: null,
      searchBillingEmail: null,
      searchStatus: null,
      statusOptions: [
        {
          value: null,
          text: "",
        },
        {
          value: "Active",
          text: "Active",
        },
        {
          value: "Inactive",
          text: "Inactive",
        },
      ],
      billingOptions: [
        {
          value: null,
          text: "",
        },
        {
          value: "Active",
          text: "Active",
        },
        {
          value: "Inactive",
          text: "Inactive",
        },
      ],
      fields: [
        {
          label: "Name",
          key: "name",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Address",
          key: "address",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "City",
          key: "city",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "State",
          key: "state",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Postal Code",
          key: "postal_code",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Country",
          key: "country",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Billing Email",
          key: "billing_email",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Status",
          key: "status",
          sortable: true,
          tdClass: "align-middle",
        },
      ],
      selected: null,
      items: [],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["allCountries", "allCustomers", "user", "company"]),
    rows() {
      return this.searchItems.length;
    },
    positionText() {
      let endIndex = this.currentPage * this.perPage,
        startIndex =
          this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.rows ? this.rows : endIndex) +
        " of " +
        this.rows +
        " entries"
      );
    },
    searchItems() {
      const searchKeysWithFields = {
        name: this.searchName,
        country: this.searchCountry,
        billing_email: this.searchBillingEmail,
        status: this.searchStatus,
      };
      let filteredItems = this.items;
      for (let [field, searchQuery] of Object.entries(searchKeysWithFields)) {
        console.log("------", field, searchQuery);
        if (searchQuery !== null && searchQuery !== "") {
          filteredItems = this.searchByKeys(searchQuery, field, filteredItems);
        }
      }
      return filteredItems;
    },
  },
  watch: {
    allCustomers() {
      this.items = [];
      this.allCustomers.map((customer) => {
        this.items.push({
          name: customer.name,
          address: customer.address,
          city: customer.city,
          state: customer.state?.name,
          postal_code: customer.postal_code,
          country: customer.state
            ? this.getCountryName(customer.state.country)
            : "",
          phone: customer.phone,
          billing_email: customer.billing_email ? "Active" : "Inactive",
          status: customer.status ? "Active" : "Inactive",
        });
      });
    },
  },
  methods: {
    ...mapActions([
      "setNotification",
      "postExcelData",
      "getAllCountries",
      "getAllCustomers",
      "addCustomer",
      "getCompany",
    ]),
    getCountryName(id) {
      return this.allCountries.filter((country) => country.id === id)[0].name;
    },
    checkErrors() {
      if (!this.customer.name) {
        this.errors.name = !this.customer.name;
        return true;
      } else {
        this.errors.name = false;
        return false;
      }
    },
    async createCustomer() {
      let errors = this.checkErrors();
      if (errors) {
        return;
      }
      try {
        await this.addCustomer(this.customer);
        await this.setNotification({
          msg: "Customer Added Successfully",
          type: "success",
          color: "green",
        });
        this.$bvModal.hide("create-customer");
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    async getCountries() {
      await this.getAllCountries();
      console.log(this.allCountries);
      this.countries.push({
        value: "",
        text: "",
      });
      this.allCountries.map((item) =>
        this.countries.push({
          value: item.name,
          text: item.name,
        })
      );
      this.customerCountries = this.allCountries.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      this.getStates(this.customerCountries[0]);
    },
    getStates(selectedOption) {
      this.states = this.allCountries
        .filter((country) => country.id === selectedOption.id)[0]
        .state.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      if (this.states.length === 0) {
        this.customer.state = null;
      } else {
        this.customer.state = this.states.filter(
          (state) => state.label === "-"
        )[0].id;
      }
    },
    searchByKeys(searchQuery, field, filteredItems) {
      console.log("----------", searchQuery, field, filteredItems);
      if (["status", "role", "billing_email"].includes(field)) {
        return filteredItems.filter(
          (x) => x[field] && x[field] === searchQuery
        );
      }
      return filteredItems.filter(
        (x) =>
          x[field] &&
          x[field]
            .toString()
            .toLowerCase()
            .includes(searchQuery.toString().toLowerCase())
      );
    },
    openModal() {
      this.customer = {
        name: null,
        address: null,
        city: null,
        state: 54,
        postal_code: null,
        country: 1,
        phone: null,
        billing_email: null,
      };
      this.errors = {
        name: false,
      };
      this.$bvModal.show("create-customer");
    },
    async downloadExcel() {
      let excelData = JSON.parse(JSON.stringify(this.searchItems));
      excelData.forEach((item) => {
        delete item.id,
          delete item.quick_pay,
          delete item.factoring_loads,
          delete item.company;
      });

      const link = await this.postExcelData(excelData);
      const fileLink = URL.createObjectURL(new Blob([link]));
      this.downloadURI(fileLink);
    },
    downloadURI(uri) {
      let link = document.createElement("a");
      link.setAttribute("href", uri);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    checkNullValues(payload) {
      let nullKeys = [];
      for (let [key, value] of Object.entries(payload)) {
        if (!value) {
          nullKeys.push(key);
        }
      }
      return nullKeys;
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.getCountries(),
        this.getAllCustomers(),
        this.getCompany(),
      ]);
      this.customer.company = this.company[0].id;
    } catch (error) {
      console.log(error);
      if (error.status === 403) {
        await this.setNotification({
          msg: "You are not allowed to see team members.",
          type: "error",
          color: "red",
        });
      }
    }
  },
};
</script>

<style>
.modal .col-form-label {
  font-size: var(--small) !important;
}

.team-page .select_arrow {
  position: relative;
}

.team-page .select_arrow svg {
  position: absolute;
  right: 10px;
  top: 40px;
}

.font-grey {
  font-size: 12px;
  color: #000000 !important;
}

/* @media (min-width: 992px) and (max-width: 1166px){
  .team-data-table{
    overflow-x: auto;
  }
  .team_table_row{
    width: 1050px;
  }
} */
</style>
