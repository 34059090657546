<template>
    <div>
        <b-modal id="modal-driver-public-share" no-close-on-esc centered no-close-on-backdrop hide-footer>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    {{ title }}
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="text-left">
                <b-form-group :label="title">
                    <b-form-select class="input" v-model="share" :options="[
                        {
                            value: null,
                            text: '',
                        },
                        {
                            value: true,
                            text: 'Yes'
                        },
                        {
                            value: false,
                            text: 'No'
                        },
                    ]">

                    </b-form-select>
                    <p v-if="error && error.share" class="field-error">
                        {{ error.share[0] }}
                    </p>
                </b-form-group>
            </div>
            <div class="text-right modal-footer border-0 p-0 mt-3">
                <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetPopup()" variant="secondary">Cancel</b-button>
                <b-button class="mx-0 mb-0 h-40px w-100px mt-0" variant="primary" @click="bulkUpdateDocs">Save</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: "DriverAndPublicShare",
    data() {
        return {
            error: {},
            share: null
        }
    },
    props: ["title", "docIds"],
    methods: {
        ...mapActions(["updateBulkDocument", "setNotification", "setLoader"]),
        resetPopup() {
            this.error = {}
            this.share = null
            this.$bvModal.hide('modal-driver-public-share')
            this.$emit('close')
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (value === null) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async bulkUpdateDocs() {
            try {
                const nullKeys = this.checkNullValues({
                    share: this.share
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    await this.updateBulkDocument({
                            "ids": this.docIds,
                            ...this.title === 'Public Share' ? { "public": this.share } : { "driver": this.share }
                    })
                    this.setLoader(false)
                    await this.setNotification({
                        msg: `Documents Updated Successfully`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.$emit("updateDocs")
                    this.resetPopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                this.setLoader(false)
                if (typeof error === "object") {
                    this.error = error;
                }
            }
        }
    },
}
</script>