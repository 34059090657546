<template>
    <div class="integration-box">
        <div class="d-flex align-items-center flex-wrap">
            <img :src="require('@/assets/geotab.svg')" />
            <button v-if="status" type="button"
                class="h-100 btn btn-primary export equipment ml-0 ml-sm-4 mt-2 mt-sm-0 h-40px w-100px">
                Active
            </button>
            <button v-else type="button" class="h-40px btn btn-secondary close-btn ml-4 mt-2 mt-sm-0">
                Inactive
            </button>
        </div>
        <p class="mt-3 text-tertiary">
            Geotab integration will integrate your drivers, vehicles and trailers to our platform.
        </p>
        <div v-if="status">
            <div class="d-flex align-items-center flex-wrap mb-3" v-for="(item, index) in buttonsData" :key="index">
                <div>
                    <p class="mb-0">{{ item.title }}</p>
                    <button @click="setSyncData(item.link)" type="button" :disabled="item.status === true ? false : true"
                        class="h-40px btn btn-primary export equipment mt-2 w-100px text-white">
                        Sync Data
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-between buttons-section flex-wrap">
            <div>
                <b-button type="button" v-if="status" class="w-125px h-40px" variant="danger" @click="removeToken()">
                    Deactivate
                </b-button>
                <b-button v-else class="border-0 assigned-equipment w-100px" variant="success"
                    @click="$bvModal.show('modal-geo-tab-activate')">Activate</b-button>
            </div>
            <button type="button" class="btn btn-secondary close-btn w-100px h-40px" @click="$emit('close')">
                Close
            </button>
        </div>
        <b-modal id="modal-geo-tab-activate" :header-bg-variant="'white'" header-class="border-none" no-close-on-backdrop
            :footer-class="'d-block px-3 pt-0 pb-3 m-0'" no-close-on-esc>
            <template #modal-header="{ close }">
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="text-center">
                <img :src="require('@/assets/geotab.svg')" />
            </div>
            <div class="mt-3">
                <b-form-group label="Database ID" class="text-left">
                    <b-form-input type="text" class="input" v-model="form.geotab_database"></b-form-input>
                    <p v-if="errors && errors.geotab_database" class="field-error">
                        {{ errors.geotab_database[0] }}
                    </p>
                </b-form-group>
                <b-form-group label="Username" class="text-left">
                    <b-form-input type="text" class="input" v-model="form.geotab_username"></b-form-input>
                    <p v-if="errors && errors.geotab_username" class="field-error">
                        {{ errors.geotab_username[0] }}
                    </p>
                </b-form-group>
                <b-form-group label="Password" class="text-left password show-password-section">
                    <b-form-input  :type="showPassword ? 'text' : 'password'" class="input" v-model="form.geotab_password"></b-form-input>
                    <b-icon
                    :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"
                    @click="showPassword = !showPassword"
                  ></b-icon>
                    <p v-if="errors && errors.geotab_password" class="field-error">
                        {{ errors.geotab_password[0] }}
                    </p>
                </b-form-group>
            </div>
            <template #modal-footer>
                <div class="text-right modal-footer border-0 p-0">
                    <b-button class="mr-3 btn cancel m-0" block @click="closePopup">Cancel</b-button>
                    <b-button class="btn save mx-0 m-0" variant="primary" @click="saveGeoTabData">Save</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "Geo-Tab-Integration",
    props: ["status"],
    data() {
        return {
            form: {
                geotab_database: "",
                geotab_username: "",
                geotab_password: ""
            },
            errors: {},
            showPassword:false,
            buttonsData: [
                {
                    title: "Vehicles",
                    link: "get_geotab_vehicle_data",
                    status: true,
                    data: "Vehicle",
                },
                {
                    title: "Trailers",
                    link: "get_geotab_trailer_data",
                    status: true,
                    data: "Trailer",
                },
                {
                    title: "Drivers",
                    link: "get_geotab_driver_data",
                    status: true,
                    data: "Driver",
                },
            ],
        };
    },
    methods: {
        ...mapActions([
            "setLoader",
            "setNotification",
            "setAssetsData",
            "getAssetsData",
            "updateCompany"
        ]),
        closePopup() {
            this.$bvModal.hide('modal-geo-tab-activate')
            this.errors = {}
            this.showPassword = false
            this.form = {
                geotab_database: "",
                geotab_username: "",
                geotab_password: ""
            }
        },
        async setSyncData(link) {
            try {
                this.setLoader(true);
                await this.setAssetsData(link);
                await this.setNotification({
                    msg: "Sync done successfully !!",
                    type: "success",
                    color: "green",
                });
                this.setLoader(false);
            } catch (error) {
                this.setLoader(false);
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        async removeToken() {
            try {
                delete this.company[0].logo
                await this.updateCompany({
                    id: this.userCompany,
                    payload: { ...this.company[0], geotab_database:null, geotab_username:null, geotab_password: null, state: this.company[0].state.id },
                });
                await this.setNotification({
                    msg: "Geo Tab Integration removed successfully !!",
                    type: "success",
                    color: "green",
                });
            }
            catch (e) {
                console.log(e)
            }
        },
        async saveGeoTabData() {
            try {
                this.errors = {}
                const nullKeys = this.checkNullValues({
                    geotab_database: this.form.geotab_database,
                    geotab_password: this.form.geotab_password,
                    geotab_username: this.form.geotab_username,
                });
                if (nullKeys.length === 0) {
                    delete this.company[0].logo
                    await this.updateCompany({
                        id: this.userCompany,
                        payload: { ...this.company[0], geotab_database: this.form.geotab_database, geotab_username: this.form.geotab_username, geotab_password: this.form.geotab_password, state: this.company[0].state.id },
                    });
                    await this.setNotification({
                        msg: "Geo Tab Integration done successfully !!",
                        type: "success",
                        color: "green",
                    });
                    this.closePopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
    },
    computed: {
        ...mapGetters(["assetsData", "company", "userCompany"]),
    },
    mounted() { },
};
</script>
<style>
.integration-box {
    min-height: 430px;
    position: relative;
}
.integration-box .buttons-section {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

@media (max-width: 575px) {
    .integration-box {
        min-height: 500px;
    }
}
</style>
  