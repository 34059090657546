<template>
  <div>
    <CustomerInfoFields
      v-if="currentCustomer"
      :currentCustomer="currentCustomer"
      :errors="errors"
      @updateNumState="changeNumState"
    />
    <CustomerDocuments />
    <div
      class="table_btn ml-2 d-flex justify-content-end align-items-center bg-white padding-customer-info"
    >
      <b-button class="mt-3 mr-3 btn" to="/customers">Close</b-button>
      <b-button class="mt-3 btn save" @click="updateCustomerDetail">
        Save
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CustomerInfoFields from "@/components/CustomerInfo/CustomerInfoFields.vue";
import CustomerDocuments from "@/components/CustomerInfo/CustomerDocuments.vue";

export default {
  name: "CustomerInfo",
  props: ["currentCustomer"],
  data() {
    return {
      errors: {
        name: false,
        phone: false,
      },
      isPhoneValid: false,
    };
  },
  components: {
    CustomerInfoFields,
    CustomerDocuments,
  },

  methods: {
    ...mapActions(["updateCurrentCustomer", "setNotification"]),

    changeNumState(numState) {
      this.isPhoneValid = numState;
    },
    checkErrors() {
      console.log(this.isPhoneValid);
      if (!this.currentCustomer.name) {
        this.errors = {
          ...this.errors,
          name: !this.currentCustomer.name,
          phone: this.currentCustomer.phone ? !this.isPhoneValid : null,
        };
        return true;
      } else {
        this.errors.name = false;
        if (!this.isPhoneValid) {
          this.errors = {
            ...this.errors,
            phone: this.currentCustomer.phone ? !this.isPhoneValid : null,
          };
          return true;
        }
        return false;
      }
    },
    async updateCustomerDetail() {
      let errors = this.checkErrors();
      if (errors) {
        return;
      }
      try {
        await this.updateCurrentCustomer({
          ...this.currentCustomer,
          state: this.currentCustomer.state.id,
        });
        this.errors = {};
        await this.setNotification({
          msg: "Customer Updated Successfully",
          type: "success",
          color: "green",
        });
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
  },
};
</script>

<style>
.padding-customer-info {
  padding: 0px 25px 25px;
}
</style>
