<template>
    <div>
        <b-modal id="modal-smart-drop-plus-preview" centered size="lg" no-close-on-backdrop :body-class="'p-4'"
            no-close-on-esc :footer-class="'d-block border-0 px-20px pt-0 pb-3 m-0'" scrollable>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Preview
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="d-block text-center">
                <b-row>
                    <b-col md="6" sm="12" class="text-left mb-3">
                        <label class="state">Name:</label>
                        <v-select class="bg_white w-100 f-12" v-model="documentData.name" :options="nameOptions"
                            item-value="id" item-text="label" :reduce="(option) => option.id" @search="searchAssets">
                            <span slot="no-options">
                                Search Unit No
                            </span>
                        </v-select>
                        <p v-if="errors && errors.name" class="field-error">
                                {{ errors.name[0] }}
                            </p>
                    </b-col>
                    <b-col md="6" sm="12">
                        <div class="text-left mb-3">
                            <label for="modal-smart-drop-plus-datepicker" class="state">Date:</label>
                            <b-form-datepicker id="modal-smart-drop-plus-datepicker" v-model="documentData.date"
                                reset-button></b-form-datepicker>
                            
                        </div>
                    </b-col>
                    <b-col md="6" sm="12">
                        <div class="text-left mb-3">
                            <label for="modal-smart-drop-plus-expiry-date" class="state">Expiry Date:</label>
                            <b-form-datepicker id="modal-smart-drop-plus-expiry-date" v-model="documentData.expiry_date"
                                reset-button></b-form-datepicker>
                        </div>
                    </b-col>
                    <b-col md="6" sm="12">
                        <div class="text-left mb-3">
                            <label for="modal-smart-drop-plus-expiry-date" class="state">Description</label>
                            <b-form-input type="text" placeholder="Description" v-model="documentData.description"
                                class="unit f-12"></b-form-input>
                        </div>
                    </b-col>
                    <b-col md="6" sm="12">
                        <div class="text-left mb-3">
                            <label class="state">Driver</label>
                            <b-form-checkbox v-model="documentData.driver" name="check-button" switch size="lg"
                                class="ml-2" />
                        </div>
                    </b-col>
                    <b-col md="6" sm="12">
                        <div class="text-left mb-3">
                            <label class="state">Public</label>
                            <b-form-checkbox v-model="documentData.public" name="check-button" switch size="lg"
                                class="ml-2" />
                        </div>
                    </b-col>
                    <b-col md="12" class="mt-3">
                        <vue-pdf-embed v-if="mediaType === 'pdf'" :source="media" />
                        <img :src="media" v-else class="w-100" />
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <div class="d-flex modal-footer border-0 p-0 justify-content-between mt-3">
                    <div class="d-flex m-0">
                        <b-button class="cancel text-white mr-3" variant="danger">Delete</b-button>
                        <b-button class="btn mx-0 w-125px" variant="success"
                            @click="downloadFile(media)">Download</b-button>
                    </div>
                    <div class="d-flex m-0">
                        <b-button class="btn cancel mr-3" block @click="resetPopup">Close</b-button>
                        <b-button class="btn save mx-0" variant="primary" @click="updateDocument">Approve</b-button>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as moment from "moment-timezone";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
    components: { vSelect, VuePdfEmbed },
    props: ['documentItem'],
    data() {
        return {
            nameOptions: [],
            documentData: {
                name: "",
                date: "",
                expiry_date: "",
                description: "",
                driver: false,
                public: false
            },
            media: "",
            mediaID: "",
            mediaType: "",
            errors: {}
        }
    },
    name: "SmartDropPreview",
    watch: {
        documentItem() {
            if (this.documentItem) {
                this.documentData.description = this.documentItem.description
                this.documentData.date = this.documentItem.date ? moment(this.documentItem.date).format("YYYY-MM-DD") : ''
                this.documentData.expiry_date = this.documentItem.expiry
                this.documentData.driver = this.documentItem.driver
                this.documentData.public = this.documentItem.public
                console.log(this.documentItem.name)
                if (this.documentItem.name !== '-') {
                    this.documentData.name = this.documentItem.userID
                    this.nameOptions.push({
                        id: this.documentItem.userID,
                        label: this.documentItem.name,
                    })
                }
                this.media = this.documentItem.link
                this.mediaID = this.documentItem.pk
                if (this.documentItem.link.includes('.pdf')) this.mediaType = 'pdf'
                else this.mediaType = 'image'
            }
        }
    },
    methods: {
        ...mapActions(["getAllEquipments", "setLoader", "setNotification","updateTeamMemberDocument"]),
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        downloadFile(url) {
            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = url.substring(url.lastIndexOf('/') + 1)
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove()
                })
                .catch(error => console.error('There has been a problem with your fetch operation:', error));
        },
        resetPopup() {
            this.nameOptions = [],
                this.documentData = {
                    name: "",
                    date: "",
                    expiry_date: "",
                    description: "",
                    driver: false,
                    public: false
                },
                this.errors = {}
            this.media = ""
            this.mediaID = ""
            this.nameOptions = []
            this.mediaType = ""
            this.$bvModal.hide('modal-smart-drop-plus-preview')
            this.$emit("close")
        },
        async searchAssets(searchText) {
                if(searchText) {
                let options = []
                let url = `unit_no=${searchText}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
                const equipmentRecords = await this.getAllEquipments(url);
                equipmentRecords.records.map((item) => {
                    options.push({
                        id: `${item.id}`,
                        label: item.unit_no,
                        type: "equipment",
                    });
                });
                this.nameOptions = options    
            }        
        },
        async updateDocument() {
            try {
                const nullKeys = this.checkNullValues({
                    name: this.documentData.name,
                });
                if (nullKeys.length === 0) {
                this.setLoader(true)
                delete this.documentData.document;
                let formData = new FormData();
                formData.append("object_id", this.documentData.name);
                formData.append(
                    "date",
                    moment(this.documentData.date).format("YYYY-MM-DD")
                );
                formData.append("description", this.documentData.description);
                formData.append("type", this.documentItem.type);
                    formData.append("expiry_date", this.documentData.expiry_date ? this.documentData.expiry_date : '');
                formData.append("driver", this.documentData.driver);
                formData.append("public", this.documentData.public);
                formData.append(
                    "model_type", "equipment"
                )
                formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
                formData.append("data_extraction_successful", true);
                await this.updateTeamMemberDocument({
                    id: this.documentItem.pk,
                    payload: formData,
                });
                await this.setNotification({
                    msg: "Document Successfully updated",
                    type: "success",
                    color: "green",
                });
                this.resetPopup()
                this.setLoader(false)
                this.$emit('docsUpdated')
            }
            else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                }
                console.log(this.errors)
            }
        }
    },
}
</script>