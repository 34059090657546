<template>
  <b-row>
    <b-col cols="12">
      <div>
        <div class="num d-flex align-items-center justify-content-start mb-3">
          <p class="mb-0 font-weight-bold">Pay Info</p>
        </div>
        <b-row class="switch-row">
          <b-col lg="2" md="3" sm="4" class="text-left mb-2">
            <label>Same As Driver</label>
            <b-form-checkbox
              name="check-button"
              class="switch-drivers"
              v-model="singleDriverPayInfo.same_as_driver"
              switch
              size="lg"
            ></b-form-checkbox>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col lg="6" md="8" sm="12">
            <b-form class="mb-2">
              <b-form-group label="Name" class="text-left">
                <b-form-input
                  type="text"
                  v-model="driverPayInfo.name"
                  placeholder="Enter Name"
                  :readonly="singleDriverPayInfo.same_as_driver"
                ></b-form-input>
                <p v-if="errors.name" class="field-error">
                  This field is required.
                </p>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col lg="6" md="8" sm="12">
            <b-form class="mb-2">
              <b-form-group label="Tax ID" class="text-left">
                <b-form-input
                  type="text"
                  v-model="driverPayInfo.tax_id"
                  placeholder="Enter Tax ID"
                  :readonly="singleDriverPayInfo.same_as_driver"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>

          <b-col lg="3" md="4" sm="12">
            <b-form class="mb-2">
              <b-form-group label="Address" class="text-left select_arrow">
                <b-form-input
                  type="text"
                  v-model="driverPayInfo.address"
                  placeholder="Enter Address"
                  :readonly="singleDriverPayInfo.same_as_driver"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col lg="2" md="4" sm="12" class="text-left mb-2">
            <b-form class="mb-2">
              <b-form-group label="City" class="text-left">
                <b-form-input
                  type="text"
                  v-model="driverPayInfo.city"
                  placeholder="Enter City"
                  :readonly="singleDriverPayInfo.same_as_driver"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col lg="3" md="4" sm="12" class="text-left mb-2">
            <b-form class="mb-2">
              <b-form-group label="State" class="text-left">
                <v-select
                  class="bg_white"
                  v-model="driverPayInfo.state"
                  :options="driverStates"
                  :reduce="(option) => option.id"
                  :disabled="singleDriverPayInfo.same_as_driver"
                />
              </b-form-group>
            </b-form>
          </b-col>
          <b-col lg="2" md="4" sm="12" class="text-left mb-2">
            <b-form class="mb-2">
              <b-form-group label="Postal Code" class="text-left">
                <b-form-input
                  type="text"
                  v-model="driverPayInfo.postal_code"
                  placeholder="Enter Postal Code"
                  :readonly="singleDriverPayInfo.same_as_driver"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>

          <b-col lg="2" md="4" sm="12" class="text-left mb-2">
            <b-form class="mb-2">
              <b-form-group label="Country" class="text-left">
                <v-select
                  class="bg_white mb-3"
                  v-model="driverPayInfo.country"
                  :options="countries"
                  :reduce="(option) => option.id"
                  :disabled="singleDriverPayInfo.same_as_driver"
                />
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
export default {
  name: "DriverPayInfoFields",
  components: {
    vSelect,
  },
  computed: {
    ...mapGetters(["allCountries"]),
  },
  props: [
    "currentTeamMember",
    "countries",
    "states",
    "driverPayInfo",
    "singleDriverPayInfo",
    "errors",
  ],
  data() {
    return {
      // sameAsDriver: true,
      driverStates: this.states,
    };
  },
  methods: {
    getStates(selectedOption, item) {
      this.driverStates = this.allCountries
        .filter((country) => country.id === selectedOption.id)[0]
        .state.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      console.log("driverStates.length", this.driverStates);
      if (this.driverStates.length === 0) {
        console.log("in null");
        // this.driverPayInfo.state = null;
        this.driverPayInfo.state = this.driverStates.filter(
          (state) => state.label === "-"
        )[0].id;
      } else {
        if (this.driverPayInfo.state && item) {
          console.log("item", item);
          this.driverPayInfo.state = item;
        } else if (this.driverPayInfo.state) {
          this.driverPayInfo.state = this.driverStates.filter(
            (state) => state.label === "-"
          )[0].id;
        }
      }
    },
  },

  watch: {
    "singleDriverPayInfo.same_as_driver"(val) {
      this.$emit("sameAsDriverChange", val);
    },
    "driverPayInfo.country"() {
      this.getStates({
        id: this.driverPayInfo.country,
      });
    },
  },
  mounted() {
    console.log(this.driverPayInfo);
    this.driverStates = this.states;
  },
};
</script>

<style>
.switch-drivers {
  padding-left: 1.5rem !important;
}
</style>
