<template>
  <b-container fluid class="mt-2 px-1 px-sm-3 pb-3 equipment-list-page">
    <b-row>
      <b-col cols="">
        <b-row>
          <b-col cols="">
            <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
              <h2 class="text-left">Equipment</h2>
              <div class="d-flex justify-content-between justify-content-sm-center flex-wrap"
                v-if="$store.state.user.user.team_member.role === 'Admin' ||
                  $store.state.user.user.team_member.role === 'Operations'">
                <b-button @click="allowAddEquipment = true"
                  class="export equipment d-flex justify-content-center align-items-center mx-1 my-1 w-fit-content" variant="primary">
                  <b-icon icon="plus" aria-hidden="true"></b-icon>
                  <p class="mb-0 ml-2 text-white">Add Equipment</p>
                </b-button>
                <b-dropdown id="dropdown-divider" text="text" class="load-dropdown">
                  <template slot="button-content">
                    <b-icon class="dots" icon="three-dots"></b-icon>
                  </template>
                  <b-dropdown-item-button @click="downloadExcel"
                    :class="$store.state.user.user.team_member.role !== 'Maintenance Manager' ? 'mb-2' : ''">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Export CSV</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button :class="selectedRows.length ? 'mb-2' : 'mb-0'" @click="$bvModal.show('modal-bulk-upload')"
                    v-if="$store.state.user.user.team_member.role !== 'Maintenance Manager'">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Import CSV</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button v-if="($store.state.user.user.team_member.role === 'Admin' && selectedRows.length)"
                    @click="$bvModal.show('modal-add-bulk-tags')">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Add Tags</p>
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="data-table team-data-table rounded table-custom-border">
              <div class="bg-white rounded">
                <v-tabs class="team-tabs mb-0">
                  <v-tab @click="setEquipmentType('')">All</v-tab>
                  <v-tab @click="setEquipmentType('Vehicle')">Vehicles</v-tab>
                  <v-tab @click="setEquipmentType('Trailer')">Trailers</v-tab>
                </v-tabs>
              </div>
              <div class="bg-white pt-3 pt-sm-4">
                <div class="table_row">
                  <div class="equipment-border filter-box">
                    <div class="d-flex mb-4 px-3 px-sm-4">
                      <b-form-group class="text-left label font-weight-normal search-field mb-0">
                        <b-input-group class="username-input-field">
                          <b-form-input type="text" placeholder="Search" v-model="filterFields.search_field"
                            class="unit f-12" @keyup.enter="getEquipmentData()"></b-form-input>
                          <b-input-group-append>
                            <b-button variant="primary" @click="getEquipmentData()">
                              <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                  stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>

                      <div class="ml-auto">
                        <b-dropdown id="dropdown-divider" ref="filterDropdown"
                          class="filter-section table-columns-dropdown mt-0"
                          :class="checkFilterStatus ? 'active-dropdown' : ''">
                          <template slot="button-content">
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M8.74045 19C8.55379 19 8.37477 18.9256 8.24278 18.7933C8.11079 18.6609 8.03663 18.4813 8.03663 18.2941V11.5071L1.51223 4.31059C1.2554 4.02674 1.08624 3.67422 1.02528 3.29583C0.964316 2.91743 1.01418 2.52942 1.16881 2.17889C1.32344 1.82836 1.57619 1.53038 1.89639 1.32112C2.21659 1.11185 2.59047 1.0003 2.97266 1H18.0273C18.4095 1.0003 18.7834 1.11185 19.1036 1.32112C19.4238 1.53038 19.6766 1.82836 19.8312 2.17889C19.9858 2.52942 20.0357 2.91743 19.9747 3.29583C19.9138 3.67422 19.7446 4.02674 19.4878 4.31059L12.9634 11.5071V15.4706C12.9633 15.5763 12.9395 15.6807 12.8939 15.776C12.8482 15.8713 12.7817 15.9551 12.6994 16.0212L9.18034 18.8447C9.05557 18.9451 8.90042 18.9999 8.74045 19ZM2.97266 2.41176C2.86314 2.41204 2.75604 2.44414 2.66434 2.50419C2.57263 2.56424 2.50024 2.64966 2.45594 2.75011C2.41163 2.85056 2.3973 2.96174 2.41468 3.07019C2.43207 3.17864 2.48042 3.27971 2.55389 3.36118L9.26128 10.7729C9.3762 10.8997 9.44123 11.064 9.44427 11.2353V16.8259L11.5557 15.1176V11.2353C11.5553 11.0591 11.6206 10.8892 11.7387 10.7588L18.4461 3.34706C18.5152 3.26523 18.5598 3.16547 18.5748 3.0593C18.5897 2.95312 18.5744 2.84488 18.5306 2.74706C18.4868 2.64924 18.4163 2.56585 18.3273 2.50652C18.2382 2.44719 18.1342 2.41434 18.0273 2.41176H2.97266Z"
                                stroke-width="0.2" />
                            </svg>

                          </template>
                          <div class="filter-section-items">
                            <p class="font-weight-bold mb-2 mt-2 heading">Filter</p>
                            <b-form-group label="Ownership" v-if="checkTableField('ownership')"
                              class="text-left select_arrow label mb-3">
                              <b-form-select type="text" class="input f-12" :options="[
                                {
                                  value: '',
                                  text: '',
                                },
                                {
                                  value: 'Owned',
                                  text: 'Owned',
                                },
                                {
                                  value: 'Leased',
                                  text: 'Leased',
                                },
                                {
                                  value: 'Owner Operator',
                                  text: 'Owner Operator',
                                }
                              ]" v-model="filterFields.ownership" @change="getEquipmentData()"></b-form-select>
                            </b-form-group>
                            <b-form-group label="Docs" v-if="checkTableField('num_docs')"
                              class="text-left select_arrow label mb-3">
                              <b-form-select type="text" class="input f-12" :options="[
                                {
                                  value: '',
                                  text: '',
                                },
                                {
                                  value: 'True',
                                  text: 'Yes',
                                },
                                {
                                  value: 'False',
                                  text: 'No',
                                },
                              ]" v-model="filterFields.doc" @change="getEquipmentData()"></b-form-select>
                            </b-form-group>
                            <b-form-group label="Tags" class="text-left w-equipment-box mb-3">
                              <v-select multiple class="f-12 bg-grey" :options="allTags" :reduce="(option) => option.id"
                                v-model="filterFields.tags" @input="getEquipmentData()" />
                            </b-form-group>

                            <b-form-group label="Status" class="text-left select_arrow label w-equipment-box mb-3">
                              <b-form-select type="text" class="input f-12" :options="statusOptions"
                                v-model="filterFields.status" @change="getEquipmentData()"></b-form-select>
                            </b-form-group>
                          </div>
                          <div class="d-flex align-items-center justify-content-between buttons-section">
                            <b-button variant="primary" @click="resetFilterFields()">Reset</b-button>
                            <b-button class="text-dark btn-secondary-2"
                              @click="$refs.filterDropdown.hide()">Close</b-button>
                          </div>
                        </b-dropdown>
                        <b-dropdown id="dropdown-divider" ref="tableDropdown" class="table-columns-dropdown ml-2">
                          <template slot="button-content">
                            <svg width="18" height="20" class="dots" viewBox="0 0 18 20" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <mask id="path-1-inside-1_4223_1514" fill="white">
                                <rect width="4" height="20" rx="1" />
                              </mask>
                              <rect width="4" height="20" rx="1" stroke="#2D69F6" stroke-width="3"
                                mask="url(#path-1-inside-1_4223_1514)" />
                              <mask id="path-2-inside-2_4223_1514" fill="white">
                                <rect x="7" width="4" height="20" rx="1" />
                              </mask>
                              <rect x="7" width="4" height="20" rx="1" stroke="#2D69F6" stroke-width="3"
                                mask="url(#path-2-inside-2_4223_1514)" />
                              <mask id="path-3-inside-3_4223_1514" fill="white">
                                <rect x="14" width="4" height="20" rx="1" />
                              </mask>
                              <rect x="14" width="4" height="20" rx="1" stroke="#2D69F6" stroke-width="3"
                                mask="url(#path-3-inside-3_4223_1514)" />
                            </svg>

                          </template>
                          <div class="checkbox-section">
                            <p class="font-weight-bold mb-2 mt-2 heading">Columns</p>
                            <draggable v-model="fields" :element="'div'" ghost-class="ghost">
                              <div class="d-flex align-items-center justify-content-between mb-3 cursor-pointer"
                                v-for="(field, index) in fields" :key="index">
                                <b-form-checkbox :id="'checkbox-' + index" v-model="field.visible"
                                  :disabled="field.disabled" @change="updateFields()" :name="'checkbox-' + index"
                                  @click.native.capture.stop>
                                  {{ field.label }}
                                </b-form-checkbox>
                                <svg width="19" height="7" viewBox="0 0 19 7" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <rect width="19" height="2" rx="1" fill="black"></rect>
                                  <rect y="5" width="19" height="2" rx="1" fill="black"></rect>
                                </svg>

                              </div>
                            </draggable>
                          </div>
                          <div class="d-flex align-items-center justify-content-between buttons-section">
                            <b-button variant="primary" @click="resetTableFields()">Reset</b-button>
                            <b-button class="text-dark btn-secondary-2"
                              @click="$refs.tableDropdown.hide()">Close</b-button>
                          </div>
                        </b-dropdown>
                      </div>
                    </div>
                  </div>

                  <b-table ref="tableRef" class="mt-2" responsive :fields="visibleFields" :items="items" hover
                    :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true"
                    :busy.sync="isBusy" :show-empty="emptyTable"
                    selectable select-mode="multi" no-select-on-click
                      @row-selected="onRowSelected"
                    >
                    <template #head(selected)="" v-if="$store.state.user.user.team_member.role === 'Admin' ||
                        $store.state.user.user.team_member.role === 'Operations'">
                        <b-form-checkbox class="pl-0 w-fit-content" @change="selectRows($event)"
                          v-model="allRowsSelected">
                        </b-form-checkbox>
                      </template>
                      <template #cell(selected)="data" v-if="$store.state.user.user.team_member.role === 'Admin' ||
                        $store.state.user.user.team_member.role === 'Operations'">
                        <b-form-checkbox class="pl-0 w-fit-content" @change="checked(data.index, data.rowSelected)"
                          v-model="data.rowSelected">
                        </b-form-checkbox>
                      </template>
                    <template #cell(unit_no)="data">
                      <p class="text_primary mb-0 cursor-pointer" @click="setEquipmentData(data.item.id)">
                        {{ data.value }}
                      </p>
                    </template>
                    <template #cell(tags)="data">
                    <div v-if="data.value.length" class="d-flex align-items-center">
                      <div class="table-tags mr-2">{{ data.value[0]}}</div>
                      <v-app>
                    <v-tooltip bottom v-if="data.value.length > 1">
                        <template #activator="{ on, attrs }">
                          <div class="table-tags"  v-on="on" v-bind="attrs">+{{ data.value.length - 1}}</div>
                        </template> <span v-html="data.value.slice(1).join('<br>')"></span>
                    </v-tooltip>
                </v-app>
                </div>
                <p class="mb-0" v-else>-</p>
                  </template>
                    <template #cell(num_docs)="data">
                      <span :style='data.item.color' class="docs-circle">{{ data.value }}</span>
                    </template>
                    <template #cell(status)="data">
                      <b-button
                        class="w-100px btn export pe-none d-flex justify-content-center align-items-center"
                        :class="data.item.status === 'Active'
                          ? 'equipment'
                          : data.item.status === 'Assigned'
                            ? 'assigned-equipment'
                            : 'inactive-equipment'
                          "
                        :variant="data.item.status === 'Active' ? 'primary' : data.item.status === 'Assigned' ? 'success' : 'secondary'">
                        <p class="mb-0">{{ data.value }}</p>
                      </b-button>
                    </template>
                  </b-table>
                  <div class="entries-pagination px-3 px-sm-4">
                    <div class="d-flex align-items-center flex-wrap">
                      <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                        <b-form-select v-model="perPage" :options="pageOptions" />
                      </div>
                      <div class="pagination">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                          aria-controls="my-table"></b-pagination>
                      </div>
                      <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Import CSV Modal  -->
    <b-modal id="modal-bulk-upload" centered hide-footer @shown="resetField" no-close-on-backdrop :body-class="'p-4'"
    no-close-on-esc
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Import CSV
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="d-block text-center">
        <b-row class="import-excel-modal mx-0">
          <b-col cols="12" class="px-0">
            <div class="upload-btn-wrapper mt-0">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button class="foo" v-if="!fileName">
                Drop your document here, or browse
              </button>
              <button v-else class="foo">{{ fileName }}</button>
              <input ref="docFile" type="file" name="docFile" @change="handleFileUpload" accept=".csv" />
              <p v-if="errors && errors.document" class="field-error">
                {{ errors.document }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" class="text-left cursor-pointer mt-2 pt-1 pl-1">
            <a class="download-sample-file" href="/files/EquipmentSample.csv" download="sample.csv">
              <b-img :src="require('@/assets/sample-file.svg')"></b-img>
              Download Sample File
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button class="mx-0 mr-3 h-40px w-100px" @click="$bvModal.hide('modal-bulk-upload')"
                            variant="secondary">Cancel</b-button>
        <b-button class="mx-0 mb-0 h-40px w-100px" variant="primary"
                                @click="bulkUploadEquipment">Save</b-button>
      </div>
    </b-modal>
    <AddEquipment :allowAddEquipment="allowAddEquipment" :states="states" :options="options"
      @close="allowAddEquipment = false" @getNewData="getEquipmentNewData($event)" />
    <EditEquipment :equipmentID="equipmentID" :states="states" :options="options" @updateEquipment="resetEquipmentList"
      @updateData="getEquipmentData()" @resetEquipment="equipmentID = ''" />
      <AddTags :ids="selectedRows.map((data) => data.id)" v-if="selectedRows.length" @close="$refs.tableRef.clearSelected(); selectedRows=[]"
      @updateTags="getEquipmentData()" :type="'equipment'"/>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "../../router";
import vSelect from "vue-select";
import draggable from 'vuedraggable'
import "vue-select/dist/vue-select.css";
import EditEquipment from "@/components/Equipment/EditEquipment.vue";
import AddEquipment from "@/components/Equipment/AddEquipment.vue";
import AddTags from "@/components/Shared/AddTags.vue";
export default {
  name: "equipment-list",
  components: {
    vSelect,
    draggable,
    EditEquipment,
    AddEquipment,
    AddTags
  },
  data() {
    return {
      states: [],
      countries: [],
      allRowsSelected: false,
      document: null,
      allowAddEquipment: false,
      rows: 1,
      emptyTable: false,
      positionText: "",
      equipmentType: "",
      equipmentID: null,
      sortBy: undefined,
      sortDesc: undefined,
      isBusy: false,
      fileName: null,
      perPage: 10,
      currentPage: 1,
      widthBox: 100,
      items: [],
      errors: {
        document: null,
      },
      filterFields: {
        search_field: '',
        type: '',
        ownership: '',
        doc: '',
        tags: [],
        status: 'Active,Assigned'
      },
      equipmentDispatchGroups: [],
      allTags: [],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      newEquipment: {
        unit_no: null,
        type: null,
        subtype: null,
        year: null,
        make: null,
        vin_no: null,
        plate_no: null,
        state: {
          id: 54,
          country: 1,
        },
      },
      selectedRows: [],
      defaultFields: [
      {
          key: "selected",
          label: "",
          sortable: false,
          heading: "Select",
          visible: true,
          disabled: true,
          thStyle: { minWidth: "20px", width: "20px" },
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "unit_no",
          label: "Unit #",
          sortable: true,
          visible: true,
          disabled: true,
          field: "text",
          stickyColumn: true,
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Type",
          key: "type",
          sortable: true,
          visible: true,
          disabled: true,
          field: "Dropdown",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "subtype",
          label: "Subtype",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "vin_no",
          label: "Vin #",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "serial_number",
          label: "Serial #",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "year",
          label: "Year",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "make",
          label: "Make",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "model",
          label: "Model",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "plate_no",
          label: "Plate",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "plate_state",
          label: "State",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Tags",
          key: "tags",
          sortable: true,
          tdClass: "align-middle",
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "ownership",
          label: "Ownership",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "value",
          label: "Value",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "driver",
          label: "Driver",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "num_docs",
          label: "Docs",
          sortable: true,
          visible: true,
          disabled: false,
          field: "dropdown",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          visible: true,
          disabled: true,
          field: "dropdown",
          thClass: 'font-table-weight-bold'
          // Variant applies to the whole column, including the header and footer
        },
      ],
      fields: [],
      selected: null,
      options: [
        {
          value: "",
          text: "",
        },
        {
          value: "Vehicle",
          text: "Vehicle",
        },
        {
          value: "Trailer",
          text: "Trailer",
        },
      ],
      typeOptions: [
        {
          value: "",
          text: "",
        },
        {
          value: "Vehicle",
          text: "Vehicle",
        },
        {
          value: "Trailer",
          text: "Trailer",
        },
      ],
      statusOptions: [
        {
          value: "",
          text: "",
        },
        {
          value: "Active",
          text: "Active",
        },
        {
          value: "Assigned",
          text: "Assigned",
        },
        {
          value: "Active,Assigned",
          text: "Active + Assigned",
        },
        {
          value: "Inactive",
          text: "Inactive",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "allEquipments",
      "dispatchGroups",
      "licenseInventory",
      "company",
      "allCountries",
      "tags",
      "userCompany",
    ]),
    checkFilterStatus() {
      return !Object.values(this.filterFields).every(x => x === null || x === '' || (Array.isArray(x) && x.length === 0));
    },
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
    subTypeOptions() {
      let options = [];
      if (this.newEquipment.type === "Vehicle") {
        options = [
          {
            value: null,
            text: "",
          },
          {
            value: "Truck",
            text: "Truck",
          },
          {
            value: "Semi-Truck",
            text: "Semi-Truck",
          },
          {
            value: "Day-Cab",
            text: "Day-Cab",
          },
          {
            value: "Straight-Truck",
            text: "Straight-Truck",
          },
          {
            value: "Box-Truck",
            text: "Box-Truck",
          },
          {
            value: "Flatbed-Truck",
            text: "Flatbed-Truck",
          },
          {
            value: "Dump-Truck",
            text: "Dump-Truck",
          },
          {
            value: "Refrigerated-Truck",
            text: "Refrigerated-Truck",
          },
          {
            value: "Tow-Truck",
            text: "Tow-Truck",
          },
          {
            value: "Cargo-Van",
            text: "Cargo-Van",
          },
          {
            value: "Van",
            text: "Van",
          },
          {
            value: "Auto",
            text: "Auto",
          },
          {
            value: "SUV",
            text: "SUV",
          },
        ];
      } else if (this.newEquipment.type === "Trailer") {
        options = [
          {
            value: null,
            text: "",
          },
          {
            value: "Dry-Van",
            text: "Dry-Van",
          },
          {
            value: "Dry-Van-Air-Ride",
            text: "Dry-Van-Air-Ride",
          },
          {
            value: "Reefer",
            text: "Reefer",
          },
          {
            value: "Flatbed",
            text: "Flatbed",
          },
          {
            value: "Stepdeck",
            text: "Stepdeck",
          },
          {
            value: "Conestoga",
            text: "Conestoga",
          },
          {
            value: "Conestoga-Drop",
            text: "Conestoga-Drop",
          },
          {
            value: "Double-Drop",
            text: "Double-Drop",
          },
          {
            value: "Gooseneck",
            text: "Gooseneck",
          },
          {
            value: "Tanker",
            text: "Tanker",
          },
          {
            value: "Dump",
            text: "Dump",
          },
          {
            value: "Lowboy",
            text: "Lowboy",
          },
          {
            value: "Auto-Hauler",
            text: "Auto-Hauler",
          },
          {
            value: "Chassis",
            text: "Chassis",
          },
          {
            value: "Container",
            text: "Container",
          },
          {
            value: "Livestock",
            text: "Livestock",
          },
        ];
      }
      return options;
    },
  },
  methods: {
    ...mapActions([
      "getAllEquipments",
      "setNotification",
      "postExcelData",
      "getDispatchGroups",
      "addBulkData",
      "getAllCountries",
      "getAllTags",
      "setLoader",
      "getEquipmentsExportData"
    ]),
    checked(index, checked) {
      let tableRef = this.$refs.tableRef
      if (checked === true) tableRef.selectRow(index)
      else tableRef.unselectRow(index)
    },
    onRowSelected(items) {
      this.selectedRows = items
    },
    selectRows(checked) {
      if (checked) {
        this.$refs.tableRef.selectAllRows()
      }
      else {
        this.$refs.tableRef.clearSelected()
      }
    },
    async setEquipmentType(type = "") {
      this.equipmentType = type
      await this.getEquipmentData()
    },
    async getEquipmentNewData(id=null) {
      this.allowAddEquipment = false
      await this.getEquipmentData()
      if(id) this.equipmentID = id
    },
    async resetFilterFields() {
      this.filterFields = {
        search_field: '',
        ownership: '',
        doc: '',
        tags: [],
        status: 'Active,Assigned'
      }
      await this.getEquipmentData();
    },
    resetEquipmentList(equip) {
      console.log(equip)
      this.items = this.items.map((equipment) => {
        if (equipment.id === equip.id) {
          let color;
          if (equip.docs.length === 0) {
            color = "background:#C4C4C4";
          }
          else if (equip.docs.filter((item) => item.expiry_date && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) <= 0).length) {
            color = "background:#FF0000";
          }
          else if (equip.docs.filter((item) => item.expiry_date && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) <= 31 && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) >= 1).length) {
            color = "background:#FFC800";
          }
          else {
            color = "background:#2CCE89";
          }

          const data = {
            id: equip.id,
            unit_no: equip.unit_no,
            Expiry: equip.inactivate_date,
            type: equip.type,
            subtype: equip.subtype,
            num_docs: equip.num_docs,
            vin_no: equip.vin_no,
            year: equip.year,
            make: equip.make,
            model: equip.model,
            plate_no: equip.plate_no,
            plate_state: equip.state?.name,
            driver: equip.driver
              ? equip.driver["first name"] + " " + equip.driver["last name"]
              : "-",
            status: equip.status,
            ownership: equip.ownership ? equip.ownership : '-',
            value: equip.value ? equip.value : '-',
            dispatch_group1: equip.dispatch_group1,
            dispatch_group2: equip.dispatch_group2,
            serial_number: equip.serial_number ? equip.serial_number : '-',
            Tags: equip.tags,
            color
          }
          return data
        }
        else return equipment
      })
      this.equipmentID = ""
    },
    setEquipmentData(id) {
      this.equipmentID = id
    },
    async updateFields() {
      this.filterFields = {
        search_field: '',
        ownership: '',
        doc: '',
        tags: [],
        status: 'Active,Assigned'
      }
      localStorage.setItem("equipmentTableFields", JSON.stringify(this.fields))
      await this.getEquipmentData();
    },
    resetTableFields() {
      localStorage.setItem("equipmentTableFields", JSON.stringify(this.defaultFields))
      this.fields = JSON.parse(JSON.stringify(this.defaultFields));
    },
    async bulkUploadEquipment() {
      if (this.document) {
        this.setLoader(true)
        this.errors = {
          document: null,
        };
        try {
          let formData = new FormData();
          formData.append("file", this.document);
          formData.append("target_table", "Equipment");
          formData.append("company_id", this.userCompany)
          const newEquipments = await this.addBulkData(formData);
          if (newEquipments) {
            await this.getEquipmentData();
            await this.setNotification({
              msg: `Successfully Imported Equipments`,
              type: "success",
              color: "green",
            });
            this.$bvModal.hide("modal-bulk-upload");
            this.setLoader(false)
          }
        } catch (e) {
          this.setLoader(false)
          await this.setNotification({
            msg: "The Uploaded CSV has Wrong/Missing Fields.",
            type: "error",
            color: "red",
          });
        }
      } else {
        this.errors = {
          document: "This field is required.",
        };
      }
    },
    resetField() {
      this.$refs.docFile.value = null;
      this.fileName = null;
      this.document = null;
      this.errors = {
        document: null,
      };
    },
    handleFileUpload() {
      this.document = this.$refs.docFile.files[0];
      this.fileName = this.document.name;
    },
    async downloadExcel() {
      try {
        this.setLoader(true)
        let url = ""
        Object.keys(this.filterFields).map((key) => {
          if (this.filterFields[key] !== '' && key != 'tags') {
            if (key !== 'search_field') url += `${key}=${this.filterFields[key]}&`
            else {
              let searchList = ""
              let tableFields = localStorage.getItem("equipmentTableFields") ? JSON.parse(localStorage.getItem("equipmentTableFields")) : JSON.parse(JSON.stringify(this.defaultFields));
              tableFields.filter((item) => item.visible && item.field === 'text').map(({ key }, index) => index === 0 ? searchList = key : searchList += ',' + key)
              url += `${key}=${this.filterFields[key]}&search_list=${searchList}&`
            }
          }
          else if (key === "tags" && this.filterFields[key].length > 0) url += `${key}=${this.filterFields[key].map((item, index) => index === 0 ? item : "," + item)}&`
        })
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}`
        }
        else {
          url += `sort_field=unit_no&sort_order=asc`
        }
        const { excel_file } = await this.getEquipmentsExportData(url)
        window.open(process.env.VUE_APP_API_URL + excel_file, "_blank");
        await this.setNotification({
          msg: `File Exported Successfully`,
          type: "success",
          color: "green",
        });
        this.setLoader(false)
      }
      catch (e) {
        console.log(e)
        this.setLoader(false)
      }
    },
    checkTableField(key) {
      return this.fields.filter((item) => item.key === key)[0]?.visible
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async getEquipmentData() {
      try {
        this.isBusy = true
        let url = ""
        let tags = ""
        Object.keys(this.filterFields).map((key) => {
          if (this.filterFields[key] !== '' && key != 'tags') {
            if (key !== 'search_field') url += `${key}=${this.filterFields[key]}&`
            else {
              let searchList = ""
              let tableFields = localStorage.getItem("equipmentTableFields") ? JSON.parse(localStorage.getItem("equipmentTableFields")) : JSON.parse(JSON.stringify(this.defaultFields));
              tableFields.filter((item) => item.visible && item.field === 'text').map(({ key }, index) => index === 0 ? searchList = key : searchList += ',' + key)
              url += `${key}=${this.filterFields[key]}&search_list=${searchList}&`
            }
          }
          else if (key === "tags" && this.filterFields[key].length > 0) {
            this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
            url += `${key}=${tags}&`
          }
        })
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
        }
        if (this.equipmentType) {
          url += `type=${this.equipmentType}&`
        }
        else {
          url += `sort_field=unit_no&sort_order=asc&`
        }
        url += `page=${this.currentPage}&page_size=${this.perPage}`
        const data = await this.getAllEquipments(url);
        if (data.total_entries) {
          this.rows = data.total_entries
          this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
        }
        else {
          this.rows = 0
          this.positionText = `0 to 0 of 0`
        }
        this.items = [];
        this.allEquipments.forEach((equip) => {
          let color;
          if (equip.docs.length === 0) {
            color = "background:#C4C4C4";
          }
          else if (equip.docs.filter((item) => item.expiry_date && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) <= 0).length) {
            color = "background:#FF0000";
          }
          else if (equip.docs.filter((item) => item.expiry_date && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) <= 31 && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) >= 1).length) {
            color = "background:#FFC800";
          }
          else {
            color = "background:#2CCE89";
          }


          this.items.push({
            id: equip.id,
            unit_no: equip.unit_no,
            Expiry: equip.inactivate_date,
            type: equip.type,
            subtype: equip.subtype ? equip.subtype : '-',
            num_docs: equip.num_docs,
            vin_no: equip.vin_no ? equip.vin_no : '-',
            year: equip.year,
            make: equip.make,
            model: equip.model,
            plate_no: equip.plate_no,
            plate_state: equip.state?.name,
            driver: equip.driver
              ? equip.driver["first name"] + " " + equip.driver["last name"]
              : "-",
            status: equip.status,
            ownership: equip.ownership ? equip.ownership : '-',
            value: equip.value ? equip.value : '-',
            dispatch_group1: equip.dispatch_group1,
            dispatch_group2: equip.dispatch_group2,
            serial_number: equip.serial_number ? equip.serial_number : '-',
            Tags: equip.tags,
            color,
            tags: equip.tags.map((tag)=>tag.add_tag)
          });
        });
        if (this.items.length === 0) this.emptyTable = true
        else this.emptyTable = false
        this.isBusy = false
      }
      catch (e) {
        this.isBusy = false
        console.log(e)
      }
    },
    async getCountries(item) {
      await this.getAllCountries();
      this.countries = this.allCountries.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      this.getStates(this.countries[0], item);
    },
    getStates(selectedOption, item) {
      this.states = this.allCountries
        .filter((country) => country.id === selectedOption.id)[0]
        .state.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      if (this.states.length === 0) {
        this.newEquipment.state.id = null;
      } else {
        if (this.newEquipment.state && item) {
          this.newEquipment.state.id = item;
        } else if (this.newEquipment.state) {
          this.newEquipment.state.id = this.states.filter(
            (state) => state.label === "-"
          )[0].id;
        }
      }
    },
    getCountryName(id) {
      return this.allCountries.filter((item) => item.id === id)[0].name;
    },
  },
  watch: {
    perPage: {
      async handler() {
        await this.getEquipmentData();
      },
      immediate: true,
    },
    currentPage: {
      async handler() {
        await this.getEquipmentData();
      },
    },
    sortBy: {
      async handler() {
        await this.getEquipmentData();
      },
    },
    sortDesc: {
      async handler() {
        await this.getEquipmentData();
      },
    },
    filterFields: {
      handler() {
        this.currentPage = 1
      },
      deep: true
    },
    company: {
      async handler() {
        await this.getDispatchGroups(this.company[0].id);
        await this.getAllTags(this.company[0].id);
        this.dispatchGroups.map((item) => {
          this.equipmentDispatchGroups.push({
            id: item.id,
            label: item.name,
          });
        });
        this.tags.map((item) => {
          this.allTags.push({
            id: item.add_tag,
            label: item.add_tag,
          });
        });
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    try {
      this.fields = localStorage.getItem("equipmentTableFields") ? JSON.parse(localStorage.getItem("equipmentTableFields")) : JSON.parse(JSON.stringify(this.defaultFields));
      await this.getCountries(this.newEquipment.state.id);
    } catch (error) {
      await this.setNotification({
        msg: error,
        type: "error",
        color: "red",
      });
      await router.push("/");
    }
  },
};
</script>

<style>
/* .equipment-border{
        border-bottom: 1px solid rgba(0 0 0 / 10%);
    } */
.equipment {
  background-color: var(--btn-bg-color) !important;
}

.inactive-equipment {
  background-color: #F4F4F4 !important;
  color: black !important;
  border: 1px solid #EAEAEA;
}

.assigned-equipment {
  background-color: var(--bg-green) !important;
  color: white !important;
}

.modal-header {
  background-color: #fff;
  color: black;
  padding: 1rem !important;
}

#modal-geo-tab-activate .modal-md .modal-content .modal-header,
#modal-isaac-activate .modal-md .modal-content .modal-header {
  padding: 0px !important;
}

.modal-header h5 {
  color: black !important
}

.modal-title {
  font-size: 18px;
}

.close {
  background: none;
  color: #fff;
  font-size: 16px;
}

.modal .col-form-label {
  color: var(--black);
  opacity: 1;
  text-transform: capitalize;
}

.modal-body label {
  font-size: var(--small);
  color: var(--black);
}

.unit.form-control,
.v-select.vs--searchable,
.unit.form-control:focus {
  background: #fafafa !important;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  font-size: 14px;
}

.v-select .vs__dropdown-toggle {
  border: none !important;
}

.modal-footer .cancel {
  background-color: #F4F4F4;
  color: var(--black);
  font-size: 16px;
  width: 100px !important;
  height: 40px;
}

.modal-footer .cancel.w-150px {
  width: 150px !important;
}

.modal-footer .cancel:hover,
.modal-footer .cancel:focus,
.modal-footer .cancel:active {
  background-color: #dddddd;
  color: var(--black);
}

.modal-footer .save {
  background-color: var(--btn-bg-color) !important;
  color: #fff;
  font-size: 16px;
  width: 100px !important;
  height: 40px;
}

.modal-footer .w-150px .save.w-100 {
  width: 100% !important;
}

.modal-footer .save.w-fit-content {
  width: fit-content !important;
}

.modal-footer .save.danger-btn {
  background-color: var(--danger-color) !important;
  color: white !important
}

.modal-footer .save:hover,
.modal-footer .save:focus,
.modal-footer .save:active {
  background-color: var(--btn-bg-color);
}

.custom-select {
  background: #ffffff;
  border: 1px solid #dee2e6;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: -webkit-fill-available;
  height: 40px;
  font-size: 14px;
  padding-left: 10px;
}

.custom-select:focus-visible,
.custom-select:active {
  box-shadow: unset !important;
}

.equipment-list-page .select_arrow {
  position: relative;
}

.equipment-list-page .select_arrow svg {
  position: absolute;
  top: 40px;
  right: 10px;
}

.w-equipment-box>div>div>div {
  min-height: 40px !important;
  height: auto !important;
}

.table-columns-dropdown .btn.dropdown-toggle {
  height: 40px !important;
}

.table-columns-dropdown {
  margin-top: 0rem !important;
}

.table-columns-dropdown ul {
  min-width: 354px;
  padding: 0.5rem 0 0 0 !important;
}

.table-columns-dropdown .dropdown-menu.show {
  transform: translate3d(-293px, 40px, 0px) !important;
  border-radius: 5px;
  border: 1px solid #EAEAEA;
}

.filter-section ul {
  width: 240px;
  min-width: 100% !important
}

.filter-section-items {
  width: 190px;
  margin: 0 auto;
}

.filter-section .dropdown-menu.show {
  transform: translate3d(-185px, 40px, 0px) !important
}


.table-columns-dropdown .checkbox-section>div>div,
.table-columns-dropdown .checkbox-section .heading {
  padding: 0px 25px;
}

.table-columns-dropdown .checkbox-section>div>div .custom-control {
  padding-left: 0rem !important;
}

.table-columns-dropdown .checkbox-section {
  height: 380px;
  overflow-y: auto;
}

.table-columns-dropdown .buttons-section {
  border-radius: 0px 0px 5px 5px;
  border-top: 1px solid #EAEAEA;
  background: #FFF;
  padding: 23px 27px;
}

.ghost {
  opacity: 0.5;
  background: #e9e9e9 !important;
  height: 30px;
}

.docs-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
}

.filter-box>div .search-field {
  width: 335px !important;
}

.equipment-table-section {
  border-radius: 10px;
  border: 1px solid #EAEAEA;
  padding: 16px 25px;
}

.equipment-table-section>table>thead>tr>th {
  border: none !important
}

.ml-1 {
  margin-left: 0.1rem !important;
}

.w-150px {
  width: 150px !important;
}

@media (max-width: 575px) {
  .filter-box>div .search-field {
    width: 200px !important;
  }
}
</style>
