<template>
  <div>
    <div class="data-table">
      <b-row>
        <b-col cols="12">
          <div class="bg-white px-4 rounded pt-4">
            <DriverPayInfoFields
              :currentTeamMember="currentTeamMember"
              :countries="countries"
              :states="states"
              :driverPayInfo="driverPayInfo"
              :singleDriverPayInfo="singleDriverPayInfo[0]"
              @sameAsDriverChange="sameAsDriverChange($event)"
              v-if="showFields"
              :errors="errors"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <DriverPaySettings
      :currentTeamMember="currentTeamMember"
      :driverPayInfo="singleDriverPayInfo[0]"
    />
    <DriverPay
      :currentTeamMember="currentTeamMember"
      :singleDriverPayInfo="singleDriverPayInfo[0]"
    />
    <DriverPayTable :currentTeamMember="currentTeamMember" />
    <div class="app_user_detail bg-white rounded p-4">
      <div class="table_btn ml-2 d-flex justify-content-end align-items-center">
        <b-button class="mt-3 mr-3 btn" href="/team">Close</b-button>
        <b-button @click="save()" variant="primary" class="mt-3 btn save">Save</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import DriverPayInfoFields from "@/components/driver/DriverPayInfoFields.vue";
import DriverPaySettings from "@/components/driver/DriverPaySettings.vue";
import DriverPay from "@/components/driver/DriverPay.vue";
import DriverPayTable from "@/components/driver/DriverPayTable.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DriverPayInfo",
  props: ["currentTeamMember", "countries", "states"],
  data() {
    return {
      errors: {
        name: false,
      },
      showFields: false,
      driverPayInfo: {
        name: "",
        tax_id: "",
        address: "",
        city: "",
        state: "",
        postal_code: "",
        country: 0,

        sameAsDriver: true,

        driver: 0,
      },
      payInfoId: null,
    };
  },
  components: {
    DriverPayInfoFields,
    DriverPaySettings,
    DriverPay,
    DriverPayTable,
  },
  computed: {
    ...mapGetters(["singleDriverPayInfo"]),
  },
  methods: {
    ...mapActions([
      "getSingleDriverPayInfo",
      "updateDriverPayInfo",
      "setNotification",
    ]),
    sameAsDriverChange(e) {
      console.log("sameAsDriverChange", e);
      if (e) {
        // this.driverStates = this.states;
        this.driverPayInfo = JSON.parse(
          JSON.stringify({
            ...this.driverPayInfo,
            name: this.currentTeamMember.name,
            tax_id: this.currentTeamMember.tax_id,
            address: this.currentTeamMember.address,
            city: this.currentTeamMember.city,
            state: this.currentTeamMember.state.id,
            postal_code: this.currentTeamMember.zipcode,
            country: this.currentTeamMember.state.country,
          })
        );
      } else {
        // this.driverStates = this.states;
        this.driverPayInfo = JSON.parse(
          JSON.stringify({
            ...this.driverPayInfo,
            name: this.singleDriverPayInfo[0].name,
            address: this.singleDriverPayInfo[0].address,
            city: this.singleDriverPayInfo[0].city,
            tax_id: this.singleDriverPayInfo[0].tax_id,
            state: this.singleDriverPayInfo[0].state
              ? this.singleDriverPayInfo[0].state.id
              : 54,
            postal_code: this.singleDriverPayInfo[0].postal_code,
            country: this.singleDriverPayInfo[0].state
              ? this.singleDriverPayInfo[0].state.country
              : 1,
          })
        );
      }
      console.log(this.driverPayInfo);
    },
    checkErrors() {
      if (!this.driverPayInfo.name) {
        this.errors.name = !this.driverPayInfo.name;
        return true;
      } else {
        this.errors.name = false;
        return false;
      }
    },
    async save() {
      let errors = this.checkErrors();
      if (errors) {
        return;
      }
      let driverData = {
        ...this.singleDriverPayInfo[0],
        driver: this.currentTeamMember.id,
        name: this.driverPayInfo.name,
        address: this.driverPayInfo.address,
        city: this.driverPayInfo.city,
        postal_code: this.driverPayInfo.postal_code,
        state: this.driverPayInfo.state,
        tax_id: this.driverPayInfo.tax_id,
        country: this.driverPayInfo.country,
      };

      try {
        await this.updateDriverPayInfo({
          id: this.payInfoId,
          payload: driverData,
        });
        await this.setNotification({
          msg: "Pay Info Updated Successfully",
          type: "success",
          color: "green",
        });
      } catch (error) {
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
    },
  },

  async mounted() {
    try {
      await this.getSingleDriverPayInfo({
        id: this.currentTeamMember.id,
      });
      this.payInfoId = this.singleDriverPayInfo[0].id;
      this.showFields = true;
    } catch (error) {
      console.log(error);
    }
    console.log(this.currentTeamMember);
    this.sameAsDriverChange(this.singleDriverPayInfo[0].same_as_driver);
  },
};
</script>

<style></style>
