import { getReq, postReq, patchReq, deleteReq } from "../../../services/api";

const state = {
  allLocations: [],
};

const getters = {
  allLocations: (state) => state.allLocations,
};

const actions = {
  async getAllLocations({ commit }) {
    try {
      const allLocations = await getReq(`tms_customer/locations/`);
      commit("setAllLocations", allLocations);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },

  async createLocation({ commit }, payload) {
    try {
      const location = await postReq(`tms_customer/locations/`, payload);
      commit("addLocation", location);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  async editLocation({ commit }, payload) {
    let id = payload.id;
    delete payload.id;
    try {
      const location = await patchReq(`tms_customer/locations`, id, payload);
      commit("editLocation", location);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  async deleteLocation({ commit }, payload) {
    try {
      await deleteReq(`tms_customer/locations`, payload.id);
      commit("deleteLocation", payload.id);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
};

const mutations = {
  setAllLocations: (state, allLocations) => (state.allLocations = allLocations),
  addLocation: (state, location) => state.allLocations.push(location),
  editLocation: (state, location) => {
    state.allLocations = state.allLocations.map((item) =>
      item.id === location.id ? location : item
    );
  },

  deleteLocation: (state, id) => {
    const index = state.allLocations.findIndex((x) => x.id === id);
    state.allLocations.splice(index, 1);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
