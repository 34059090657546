<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" backdrop shadow :visible="!!accidentID" :right="true"
            no-header-close no-close-on-backdrop no-close-on-esc>
            <template #header="{ }">
                <div class="d-flex align-items-center w-100 justify-content-between">
                    <div class="d-flex align-items-center w-100 mr-2">
                        <div class="text-nowrap" v-if="currentAccidentData">
                            Accident // {{ currentAccidentData.record_no }}
                        </div>
                        <b-button class="ml-5 pe-none d-flex justify-content-center align-items-center w-100px h-40px"
                            :class="accidentPayload.status === 'New'
                                ? 'new-record'
                                : accidentPayload.status === 'Urgent'
                                    ? 'urgent-record text-white'
                                    : accidentPayload.status === 'Open' ? 'open-record' : 'inactive-equipment text-black'"
                            :variant="accidentPayload.status === 'New' ? 'warning' : accidentPayload.status === 'Urgent' ? 'danger' : accidentPayload.status === 'Open' ? 'success' : 'light'">
                            <p class="mb-0">{{ accidentPayload.status }}</p>
                        </b-button>
                    </div>
                    <b-dropdown id="dropdown-divider" text="text" class="load-dropdown mt-0">
                        <template slot="button-content">
                            <b-icon class="dots" icon="three-dots"></b-icon>
                        </template>
                        <b-dropdown-item-button @click="showDeleteAccidentModal = true">
                            <p class="text_secondary cursor-pointer mb-0 f-14">Delete</p>
                        </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </template>
            <div class="body" v-if="currentAccidentData">
                <div class="sidebar-tabs">
                    <div class="bg-white rounded">
                        <v-tabs class="team-tabs mb-0" v-model="activeTab">
                            <v-tab key='info'>Info</v-tab>
                            <v-tab key='contacts'>Contacts</v-tab>
                            <v-tab key='documents'>Documents</v-tab>
                        </v-tabs>
                    </div>
                </div>
                <v-tabs-items v-model="activeTab">
                    <v-tab-item key='info'>
                        <hr class="mt-0" />
                        <div class="d-block text-center mt-4rem">
                            <b-row class="align-items-center px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Record # :" class="text-left mb-0">
                                        <b-form-input v-model="currentAccidentData.record_no" disabled type="text"
                                            placeholder="Enter Record" class="unit" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left select_arrow">
                                        <label class="state">Status:</label>
                                        <b-form-select v-model="accidentPayload.status" class="f-12" :options="[{
                                            value: 'New',
                                            text: 'New'
                                        },
                                        {
                                            value: 'Open',
                                            text: 'Open'
                                        },
                                        {
                                            value: 'Closed',
                                            text: 'Closed'
                                        },
                                        {
                                            value: 'Urgent',
                                            text: 'Urgent'
                                        }
                                        ]" />
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label for="accident-add-datepicker" class="state">Date:</label>
                                    <b-form-datepicker id="accident-add-datepicker" v-model="accidentPayload.date"
                                        reset-button class="f-12"></b-form-datepicker>
                                    <p v-if="errors && errors.date" class="field-error">
                                        {{ errors.date[0] }}
                                    </p>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">Time:</label>
                                    <b-form-input type="time" class="f-12" v-model="accidentPayload.time"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left select_arrow">
                                        <label class="state">Driver:</label>
                                        <v-select class="bg_white w-100 f-12" v-model="accidentPayload.driver"
                                            :options="driverOptions" item-value="id" item-text="label"
                                            :reduce="(option) => option.id" @search="searchDriver">
                                            <span slot="no-options">
                                                Search Driver ...
                                            </span>
                                        </v-select>
                                        <p v-if="errors && errors.driver" class="field-error">
                                            {{ errors.driver[0] }}
                                        </p>
                                    </div>
                                </b-col>
                                <b-col md="3" sm="12" class="mt-3">
                                    <div class="text-left select_arrow">
                                        <label class="state">Vehicle:</label>
                                        <v-select class="bg_white w-100 f-12" v-model="accidentPayload.vehicle"
                                            :options="vehicleOptions" item-value="id" item-text="label"
                                            :reduce="(option) => option.id" @search="searchVehicle">
                                            <span slot="no-options">
                                                Search Vehicle ...
                                            </span>
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col md="3" sm="12" class="mt-3">
                                    <div class="text-left select_arrow">
                                        <label class="state">Trailer:</label>
                                        <v-select class="bg_white w-100 f-12" v-model="accidentPayload.trailer"
                                            :options="trailerOptions" item-value="id" item-text="label"
                                            :reduce="(option) => option.id" @search="searchTrailer">
                                            <span slot="no-options">
                                                Search Trailer ...
                                            </span>
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left select_arrow">
                                        <label class="state">DOT:</label>
                                        <b-form-select v-model="accidentPayload.dot_status" class="f-12" :options="[{
                                            value: '',
                                            text: ''
                                        },
                                        {
                                            value: true,
                                            text: 'Yes'
                                        },
                                        {
                                            value: false,
                                            text: 'No'
                                        }
                                        ]" />
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left select_arrow">
                                        <label class="state">Fault:</label>
                                        <b-form-select v-model="accidentPayload.fault_status" class="f-12" :options="[{
                                            value: '',
                                            text: ''
                                        },
                                        {
                                            value: true,
                                            text: 'Yes'
                                        },
                                        {
                                            value: false,
                                            text: 'No'
                                        }
                                        ]" />
                                    </div>
                                </b-col>
                                <b-col md="12" class="text-left mt-3">
                                    <label class="state text-left">Notes:</label>
                                    <b-form-textarea id="textarea" placeholder="Enter Notes" rows="5"
                                        v-model="accidentPayload.notes" class="f-12"></b-form-textarea>
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Claims</h3>
                                <b-col md="12" sm="12" class="text-left mt-3">
                                    <label class="state">Claim #’s:</label>
                                    <v-select class="bg_white w-100 f-12" multiple v-model="claimNumbers"
                                        :options="claimOptions" item-value="id" item-text="label"
                                        :reduce="(option) => option.id" @search="searchClaims">
                                        <span slot="no-options">
                                            Search Claim # ...
                                        </span>
                                    </v-select>
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4 mb-7rem">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Details</h3>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Address:</label>
                                    <b-form-input v-model="accidentPayload.address" class="f-12"
                                        placeholder="Enter Address"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">City:</label>
                                    <b-form-input v-model="accidentPayload.city" class="f-12"
                                        placeholder="Enter City"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12">
                                    <div class="text-left select_arrow mt-3">
                                        <label class="state">State:</label>
                                        <v-select class="bg_white w-100 f-12" v-model="accidentPayload.state"
                                            :options="states" item-value="id" item-text="label"
                                            :reduce="(option) => option.id">
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Postal Code:</label>
                                    <b-form-input v-model="accidentPayload.postal_code" class="f-12"
                                        placeholder="Enter Postal Code"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Surface:</label>
                                    <b-form-input v-model="accidentPayload.surface" class="f-12"
                                        placeholder="Enter Surface"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Weather:</label>
                                    <b-form-input v-model="accidentPayload.weather" class="f-12"
                                        placeholder="Enter Weather"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state"># of Vehicles:</label>
                                    <b-form-input v-model="accidentPayload.number_of_vehicles" class="f-12"
                                        placeholder="Enter # of Vehicles"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Hazmat or Gas Spill:</label>
                                    <b-form-input v-model="accidentPayload.hazmat_or_gas_spill" class="f-12"
                                        placeholder="Enter Hazmat or Gas Spill"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Report #:</label>
                                    <b-form-input v-model="accidentPayload.report_number" class="f-12"
                                        placeholder="Enter Report #"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12">
                                    <div class="text-left select_arrow mt-3">
                                        <label class="state">Report State:</label>
                                        <v-select class="bg_white w-100 f-12" v-model="accidentPayload.report_state"
                                            :options="states" item-value="id" item-text="label"
                                            :reduce="(option) => option.id">
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Officer:</label>
                                    <b-form-input v-model="accidentPayload.officer" class="f-12"
                                        placeholder="Enter Officer:"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Citation #:</label>
                                    <b-form-input v-model="accidentPayload.citation_number" class="f-12"
                                        placeholder="Enter Citation #"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state"># of Fatality’s:</label>
                                    <b-form-input v-model="accidentPayload.number_of_fatality" class="f-12"
                                        placeholder="Enter # of Fatality's"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state"># of Injury’s:</label>
                                    <b-form-input v-model="accidentPayload.number_of_injury" class="f-12"
                                        placeholder="Enter # of Injury's"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label for="drug-test-add-datepicker" class="state">Drug Test:</label>
                                    <b-form-datepicker id="drug-test-add-datepicker" class="f-12"
                                        v-model="accidentPayload.drug_test_date" reset-button></b-form-datepicker>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label for="alc-add-datepicker" class="state">Alc. Test:</label>
                                    <b-form-datepicker id="alc-add-datepicker" class="f-12"
                                        v-model="accidentPayload.alc_test_date" reset-button></b-form-datepicker>
                                </b-col>
                                <b-col lg="6" md="12" sm="12" class="text-left mt-3">
                                    <label class="state">Customer:</label>
                                    <b-form-input v-model="accidentPayload.customer" class="f-12"
                                        placeholder="Enter Customer"></b-form-input>
                                </b-col>
                                <b-col lg="6" md="12" sm="12" class="text-left mt-3">
                                    <label class="state">Load #:</label>
                                    <b-form-input v-model="accidentPayload.load_number" class="f-12"
                                        placeholder="Enter Load #"></b-form-input>
                                </b-col>
                            </b-row>
                        </div>
                    </v-tab-item>
                    <v-tab-item key='contacts'>
                        <div class="d-block body">
                            <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
                                <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Contacts</h3>
                                <div class="d-flex align-items-center justify-content-end cursor-pointer"
                                    @click='$bvModal.show("modal-contact")'>
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z"
                                            fill="#367BF6" />
                                        <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6"
                                            stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <p class="text_primary ml-2 mb-0 fw-600">Add Contact</p>
                                </div>
                            </div>
                        </div>
                        <b-table show-empty responsive :fields="contactFields" :items="contactItems" hover
                            :current-page="currentContactPage">
                            <template #cell(role)="data">
                                <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
                            </template>
                            <template #cell(phone)="data">
                                <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
                            </template>
                            <template #cell(email)="data">
                                <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
                            </template>
                            <template #cell(action)="data">
                                <b-icon style="fill: limegreen" class="mr-4" icon="pencil"
                                    @click="setContactData(data.item)" />
                                <b-icon style="fill:#FF0000" icon="trash-fill"
                                    @click="setDeleteContactData(data.item.id)" />
                            </template>
                        </b-table>
                    </v-tab-item>
                    <v-tab-item key='documents'>
                        <div class="d-block body">
                            <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
                                <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Documents</h3>
                                <div class="d-flex align-items-center justify-content-end cursor-pointer"
                                    @click="$bvModal.show('modal-document')">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z"
                                            fill="#367BF6" />
                                        <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6"
                                            stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <p class="text_primary ml-2 mb-0 fw-600">Add Document</p>
                                </div>
                            </div>
                            <b-table show-empty responsive :fields="documentFields" :items="documentItems" hover
                                :current-page="currentDocumentPage">
                                <template #cell(description)="data">
                                    <v-app v-if="data.value">
                                        <v-tooltip top>
                                            <template #activator="{ on, attrs }">
                                                <span class="w-fit-content item-description text-left" v-on="on"
                                                    v-bind="attrs">{{ data.value }}</span>
                                            </template>{{ formatTooltipText(data.value) }}
                                        </v-tooltip>
                                    </v-app>
                                    <p v-else>-</p>
                                </template>
                                <template #cell(action)="data">
                                    <b-icon v-if="data.item.document" style="fill: #2D69F6" class="mr-4" icon="eye-fill"
                                        @click="openDoc(data.item)" />
                                    <b-icon style="fill: limegreen" class="mr-4" icon="pencil"
                                        @click="setDocumentData(data.item)" />
                                    <b-icon style="fill:#FF0000" icon="trash-fill"
                                        @click="setDeleteDocumentData(data.item.id)" />
                                </template>
                            </b-table>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
                <div class="sidebar-footer position-fixed">
                    <div class="d-flex align-items-center justify-content-end">
                        <div class="text-right modal-footer border-0 p-0">
                            <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="resetPopup()"
                                variant="secondary">Close</b-button>
                            <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary"
                                @click="onUpdateAccident()">Save</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-sidebar>
        <Contacts @contactUpdated="getContactData" :id="accidentID" :contactData="contactData"
            @popupClose="contactData = null" :addContact="addAccidentContact" :updateContact="updateAccidentContact" />
        <DeleteModal v-if="showDeleteContactPopup" @confirm="deleteContactItem($event)" />
        <DeleteModal v-if="showDeleteDocumentPopup" @confirm="deleteDocumentItem($event)" />
        <DeleteModal v-if="showDeleteAccidentModal" @confirm="deleteAccidentItem($event)" />
        <Document :id="accidentID" @documentsUpdated="getDocumentData" :documentItem="documentData"
            @popupClose="documentData = null" :addDoc="addAccidentDoc" :updateDoc="updateAccidentDoc" :allowVideo="true" />
        <MediaPopup :media="media" :mediaID="mediaID" :mediaType="mediaType" :deleteDocItem="deleteDocumentItem" />
    </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
import DeleteModal from "@/components/DeleteModal.vue";
import Contacts from "./Contacts";
import Document from './Document.vue';
import MediaPopup from "@/components/Shared/MediaPopup.vue"
export default {
    components: { vSelect, Contacts, DeleteModal, Document, MediaPopup },
    name: "EditAccident",
    props: ['accidentID'],
    computed: {
        ...mapGetters([
            "userCompany",
            "allEquipments",
            "allDrivers",
            "currentAccidentData",
            "allCountries",
        ]),
    },
    data() {
        return {
            errors: {},
            activeTab: "info",
            claimOptions: [],
            claimNumbers: [],
            media: null,
            mediaType: null,
            mediaID: null,
            contactData: null,
            documentData: null,
            states: [],
            currentDocumentPage: 1,
            showDeleteContactPopup: false,
            showDeleteDocumentPopup: false,
            showDeleteAccidentModal: false,
            currentContactPage: 1,
            contactFields: [
                {
                    key: "name",
                    label: "Name",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "role",
                    label: "Role",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "phone",
                    label: "Phone",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "email",
                    label: "Email",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
            accidentPayload: {
                record_no: null,
                address: "",
                alc_test_date: null,
                city: "",
                claim_number: "",
                customer: "",
                drug_test_date: null,
                hazmat_or_gas_spill: "",
                number_of_fatality: null,
                number_of_injury: null,
                number_of_vehicles: null,
                load_number: "",
                officer: "",
                postal_code: "",
                report_number: "",
                report_state: null,
                state: null,
                surface: "",
                driver: null,
                weather: "",
                status: "Closed",
                vehicle: null,
                trailer: null,
                date: null,
                time: null,
                citation_number: "",
                dot_status: null,
                fault_status: null,
                notes: ""
            },
            driverOptions: [],
            vehicleOptions: [],
            trailerOptions: [],
            contactItems: [],
            documentItems: [],
            documentFields: [
                {
                    label: "Description",
                    key: "description",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
        }
    },
    methods: {
        ...mapActions([
            "getAllEquipments",
            "getAllDrivers",
            "setLoader",
            "getAccidentData",
            "setNotification",
            "getAllCountries",
            "updateAccident",
            "getAccidentContacts",
            "deleteAccidentContact",
            "getAccidentDocs",
            "deleteAccidentDocument",
            "addAccidentDoc",
            "updateAccidentDoc",
            "deleteAccident",
            "addAccidentContact",
            "updateAccidentContact",
            "getAllClaims"
        ]),
        async openLink(link) {
            window.open(link, "_blank");
        },
        async openDoc(data) {
            this.media = data.document
            this.mediaID = data.id
            if (data.document.includes('.pdf')) this.mediaType = 'pdf'
            else if (data.document.includes('.mp4') || data.document.includes('.mkv') || data.document.includes('.mov') || data.document.includes('.avi')) this.mediaType = 'video'
            else this.mediaType = 'image'
            this.$bvModal.show("doc-media-popup");
        },
        setDeleteDocumentData(id) {
            this.showDeleteDocumentPopup = true
            this.documentID = id
        },
        setDocumentData(data) {
            this.documentData = data
            this.$bvModal.show("modal-document");
        },
        formatTooltipText(text) {
            const maxLineLength = window.screen.width <= 767 ? 50 : 80;
            let formattedText = "";
            let lineLength = 0;

            text.split(' ').forEach(word => {
                if (lineLength + word.length + 1 > maxLineLength) {
                    formattedText += "\n";
                    lineLength = 0;
                }
                formattedText += (lineLength === 0 ? "" : " ") + word;
                lineLength += word.length + 1;
            });

            return formattedText;
        },
        async getDocumentData() {
            this.documentItems = []
            const data = await this.getAccidentDocs({ id: this.accidentID })
            console.log(data)
            data.map((item) => {
                this.documentItems.push({
                    id: item.id,
                    date: item.date_created,
                    description: item.description,
                    document: item.document
                })
            })
        },
        async deleteDocumentItem(status, id = null) {
            if (status) {
                try {
                    this.setLoader(true)
                    await this.deleteAccidentDocument({
                        accidentID: this.accidentID,
                        documentID: `${id ? id : this.documentID}/?company_id=${this.userCompany}`
                    })
                    await this.setNotification({
                        msg: `Document Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.getDocumentData()
                    this.documentID = null
                    this.showDeleteDocumentPopup = false
                    this.setLoader(false)
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            } else {
                this.documentID = null
                this.showDeleteDocumentPopup = false
            }
        },

        setDeleteContactData(id) {
            this.showDeleteContactPopup = true
            this.contactID = id
        },
        async deleteAccidentItem(status) {
            if (status) {
                try {
                    this.setLoader(true)
                    await this.deleteAccident({
                        accidentID: `${this.accidentID}/?company_id=${this.userCompany}`
                    })
                    await this.setNotification({
                        msg: `Accident Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.showDeleteAccidentModal = false
                    this.$emit("accidentUpdated", true)
                    this.setLoader(false)
                    this.resetPopup()
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            } else {
                this.showDeleteAccidentModal = false
            }
        },
        async deleteContactItem(status) {
            if (status) {
                try {
                    this.setLoader(true)
                    await this.deleteAccidentContact({
                        accidentID: this.accidentID,
                        contactID: `${this.contactID}/?company_id=${this.userCompany}`
                    })
                    await this.setNotification({
                        msg: `Contact Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.getContactData()
                    this.contactID = null
                    this.showDeleteContactPopup = false
                    this.setLoader(false)
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            } else {
                this.contactID = null
                this.showDeleteContactPopup = false
            }
        },
        setContactData(data) {
            this.contactData = data
            this.$bvModal.show("modal-contact");
        },
        async onUpdateAccident() {
            try {
                const nullKeys = this.checkNullValues({
                    driver: this.accidentPayload.driver,
                    date: this.accidentPayload.date,
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    await this.updateAccident({ id: this.accidentID, payload: { ...this.accidentPayload, claim_number: this.claimNumbers.length ? this.claimNumbers.toString() : '' } })
                    await this.setNotification({
                        msg: `Accident Successfully Updated`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.$emit("accidentUpdated", true)
                    this.resetPopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
                console.log(error)
                this.setLoader(false);
            }
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async getCountries() {
            await this.getAllCountries();
            const countries = this.allCountries.map((item) => ({
                id: item.id,
                label: item.name,
            }));
            this.getStates(countries[0]);
        },
        getStates(selectedOption) {
            this.states = this.allCountries
                .filter((country) => country.id === selectedOption.id)[0]
                .state.map((item) => ({
                    id: item.id,
                    label: item.name,
                }));
            this.accidentPayload.state = this.states.filter(
                (state) => state.label === "-"
            )[0].id;
            this.accidentPayload.report_state = this.states.filter(
                (state) => state.label === "-"
            )[0].id;
        },
        async getContactData() {
            this.contactItems = []
            const data = await this.getAccidentContacts({ id: this.accidentID })
            console.log(data)
            data.map((item) => {
                this.contactItems.push({
                    id: item.id,
                    name: item.name,
                    role: item.role,
                    phone: item.phone,
                    email: item.email
                })
            })
        },
        resetPopup() {
            this.accidentPayload = {
                record_no: null,
                address: "",
                alc_test_date: null,
                city: "",
                claim_number: "",
                customer: "",
                citation_number: "",
                drug_test_date: null,
                hazmat_or_gas_spill: "",
                number_of_fatality: null,
                number_of_injury: null,
                number_of_vehicles: null,
                officer: "",
                postal_code: "",
                report_number: "",
                report_state: null,
                state: null,
                load_number: "",
                surface: "",
                driver: null,
                weather: "",
                status: "Closed",
                vehicle: null,
                trailer: null,
                date: null,
                time: null,
                dot_status: null,
                fault_status: null,
                notes: ""
            },
                this.errors = {}
            this.driverOptions = [],
                this.vehicleOptions = [],
                this.trailerOptions = [],
                this.claimOptions = []
            this.claimNumbers = []
            this.$emit("resetEditAccident", true)
        },
        async searchClaims(searchText) {
            let options = []
            const url = `sort_field=record_no&search_field=${searchText}&sort_order=desc&page=1&page_size=10`
            const data = await this.getAllClaims(url);
            data.records.forEach((x) => {
                options.push({
                    id: x.record_no.toString(),
                    label: x.record_no.toString(),
                });
            });
            this.claimOptions = this.claimOptions.concat(options)
            this.claimOptions = [...new Map(this.claimOptions.map(item => [item['id'], item])).values()]
        },
        async searchDriver(searchText) {
            let options = []
            const url = `name=${searchText}&page=1&page_size=10`
            await this.getAllDrivers(url);
            this.allDrivers.forEach((x) => {
                options.push({
                    id: x.id,
                    label: `${x.user.first_name} ${x.user.last_name}`,
                });
            });
            this.driverOptions = this.driverOptions.concat(options)
            this.driverOptions = [...new Map(this.driverOptions.map(item => [item['id'], item])).values()]
        },
        async searchVehicle(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Vehicle&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.vehicleOptions = this.vehicleOptions.concat(options)
            this.vehicleOptions = [...new Map(this.vehicleOptions.map(item => [item['id'], item])).values()]
        },
        async searchTrailer(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Trailer&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.trailerOptions = this.trailerOptions.concat(options)
            this.trailerOptions = [...new Map(this.trailerOptions.map(item => [item['id'], item])).values()]
        },
    },
    watch: {
        async accidentID() {
            if (this.accidentID) {
                this.activeTab = "info"
                try {
                    this.setLoader(true)
                    this.$bvModal.show("modal-edit-accident");
                    await this.getCountries();
                    await this.getAccidentData(this.accidentID)
                    this.accidentPayload = {
                        record_no: this.currentAccidentData.record_no,
                        address: this.currentAccidentData.address,
                        alc_test_date: this.currentAccidentData.alc_test_date,
                        city: this.currentAccidentData.city,
                        claim_number: this.currentAccidentData.claim_number,
                        customer: this.currentAccidentData.customer,
                        drug_test_date: this.currentAccidentData.drug_test_date,
                        hazmat_or_gas_spill: this.currentAccidentData.hazmat_or_gas_spill,
                        number_of_fatality: this.currentAccidentData.number_of_fatality,
                        number_of_injury: this.currentAccidentData.number_of_injury,
                        number_of_vehicles: this.currentAccidentData.number_of_vehicles,
                        officer: this.currentAccidentData.officer,
                        postal_code: this.currentAccidentData.postal_code,
                        report_number: this.currentAccidentData.report_number,
                        report_state: this.currentAccidentData.report_state,
                        state: this.currentAccidentData.state,
                        surface: this.currentAccidentData.surface,
                        load_number: this.currentAccidentData.load_number,
                        driver: this.currentAccidentData.driver,
                        weather: this.currentAccidentData.weather,
                        status: this.currentAccidentData.status,
                        vehicle: this.currentAccidentData.vehicle,
                        trailer: this.currentAccidentData.trailer,
                        date: this.currentAccidentData.date,
                        time: this.currentAccidentData.time,
                        dot_status: this.currentAccidentData.dot_status,
                        fault_status: this.currentAccidentData.fault_status,
                        citation_number: this.currentAccidentData.citation_number,
                        notes: this.currentAccidentData.notes
                    }
                    if (this.currentAccidentData.driver) {
                        this.driverOptions.push({
                            id: this.currentAccidentData.driver,
                            label: this.currentAccidentData.driver_name,
                        })
                    }
                    if (this.currentAccidentData.vehicle) {
                        this.vehicleOptions.push({
                            id: this.currentAccidentData.vehicle,
                            label: this.currentAccidentData.vehicle_name,
                        })
                    }
                    if (this.currentAccidentData.trailer) {
                        this.trailerOptions.push({
                            id: this.currentAccidentData.trailer,
                            label: this.currentAccidentData.trailer_name,
                        })
                    }
                    if (this.currentAccidentData.claim_number) {
                        this.claimNumbers = this.accidentPayload.claim_number ? this.accidentPayload.claim_number.split(",") : []
                        this.claimNumbers.map((opt) => {
                            this.claimOptions.push({
                                id: opt,
                                label: opt,
                            })
                        })

                    }
                    this.getContactData()
                    this.getDocumentData()
                    this.setLoader(false)
                }
                catch (e) {
                    console.log(e)
                    this.setLoader(false)
                }
            }
        }
    }
}
</script>