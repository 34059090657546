<template>
  <div class="table mb-0 pt-3 bg-white rounded">
    <div class="expiry-data d-flex justify-content-between flex-wrap px-3 align-items-center mb-3">
      <h3 class="font-weight-bold f-20 text-left mb-0 text-black">Users</h3>
      <b-button @click="openAddUserModal"
        class="export equipment d-flex justify-content-center align-items-center w-fit-content" variant="primary">
        <b-icon icon="plus" class="p-0" aria-hidden="true"></b-icon>
        <p class="mb-0 ml-2 text-white px-0">Add User</p>
      </b-button>
    </div>
    <!-- <form class="equipment-border mt-4 d-flex flex-wrap flex-sm-nowrap">
      <b-form-group label="Name" class="text-left label w-100 mr-3">
        <b-form-input type="text" class="input" required v-model="filterFields.name" @keydown.enter="getTeamMemberData()"
          @blur="getTeamMemberData()"></b-form-input>
      </b-form-group>

      <b-form-group label="Role" class="text-left select_arrow label w-100 mr-3">
        <b-form-select v-model="filterFields.role_choice" :options="options" @change="getTeamMemberData()" />
      </b-form-group>

      <b-form-group label="Email" class="text-left label w-100 mr-3">
        <b-form-input type="text" class="input" required v-model="filterFields.email" @keydown.enter="getTeamMemberData()"
          @blur="getTeamMemberData()"></b-form-input>
      </b-form-group>
      <b-form-group label="Status" class="text-left select_arrow label w-100 mr-3 mr-sm-0">
        <b-form-select class="input" required v-model="filterFields.app_user" :options="appUserOptions"
          @change="getTeamMemberData()"></b-form-select>
      </b-form-group>
    </form> -->
    <b-table responsive :fields="fields" :items="items" hover :current-page="currentPage" :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy" :show-empty="emptyTable">
      <template #cell(name)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <div>
          <p class="text_primary mb-0 cursor-pointer" v-if="company[0].owner.email !== data.item.email &&
            $store.state.user.user.team_member.user.username.split(
              `.${company[0].company_ID}`
            )[0] !== data.item.username
            " @click="updateUserData(data.item)">{{ data.value }}</p>
          <p class="mb-0" v-else>{{ data.value }}</p>
        </div>
      </template>
      <template #cell(app_user)="data">
        <b-button class="export table_btn d-flex justify-content-center align-items-center" :class="data.item.app_user === 'Active' ? 'equipment' : 'inactive-equipment'
          " :variant="data.item.app_user === 'Active' ? 'primary' : 'secondary'">
          <p class="mb-0">{{ data.value }}</p>
        </b-button>
      </template>
    </b-table>
    <div class="entries-pagination px-3 px-sm-4">
      <div class="d-flex align-items-center flex-wrap">
        <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
          <b-form-select v-model="perPage" :options="pageOptions" />
        </div>
        <div class="pagination">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
            aria-controls="my-table"></b-pagination>
        </div>
        <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-3 mt-sm-0">{{ positionText }}</p>
      </div>
    </div>
    <!-- Modal -->
    <b-modal id="modal-teammember" centered hide-footer no-close-on-backdrop
    no-close-on-esc
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          {{ editUserFlag ? "Edit User" : "Add User" }}
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="d-block text-center">
        <b-row>
          <b-col lg="12" md="12" sm="12">
            <b-form-group label="Email:" class="text-left label font-weight-normal">
              <b-form-input type="email" placeholder="Enter Email" class="unit" required
                :disabled="editUserFlag"
                v-model="newTeamMember.email"></b-form-input>
              <p v-if="errors && errors.email" class="field-error mb-lg-3">
                {{ errors.email[0] }}
              </p>
            </b-form-group>
          </b-col>
        </b-row>
        <div v-if="editUserFlag || userAddingStepper > 0">
          <b-row v-if="!editUserFlag ||
            (editUserFlag && company[0].owner.email !== newTeamMember.email)
            ">
            <b-col sm="12" class="mb-3 select_arrow text-left">
              <label>Role * :</label>
              <b-form-select class="bg_white f-12" v-model="newTeamMember.role" :options="options"></b-form-select>
              <p v-if="errors && errors.role" class="field-error mb-lg-3">
                {{ errors.role[0] }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group label="First Name * :" class="text-left label font-weight-normal">
                <b-form-input type="text" placeholder="Enter First Name" class="unit" required
                  v-model="newTeamMember.first_name"></b-form-input>
                <p v-if="errors && errors.first_name" class="field-error mb-lg-3">
                  {{ errors.first_name[0] }}
                </p>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Last Name * :" class="text-left label font-weight-normal">
                <b-form-input type="text" placeholder="Enter Last Name" class="unit" required
                  v-model="newTeamMember.last_name"></b-form-input>
                <p v-if="errors && errors.last_name" class="field-error mb-lg-3">
                  {{ errors.last_name[0] }}
                </p>
              </b-form-group>
            </b-col>

            <b-col lg="12" md="12" sm="12">
              <b-form-group label="Phone:" class="text-left position-relative mb-2">
                <vue-tel-input v-bind="bindProps" v-model="newTeamMember.phone" class="w-100 f-12" @input="checkNum" />
                <p v-if="errors && errors.phone" class="field-error">
                  {{ errors.phone[0] }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center" :class="editUserFlag && company[0].owner.email !== newTeamMember.email
        ? 'justify-content-between'
        : 'justify-content-end'
        ">
        <b-button class="mt-3 mr-3" variant="danger" v-if="editUserFlag && company[0].owner.email !== newTeamMember.email"
          @click="showMemberModal = true">Remove</b-button>
        <div class="modal-footer border-0 p-0">
          <b-button class="mt-3 mr-3 btn cancel m-0" block
            @click="$bvModal.hide('modal-teammember'); userAddingStepper = 0">Cancel</b-button>
          <b-button class="mt-3 btn save m-0" variant="primary" v-if="editUserFlag"
            @click="updateTeamMemberData">Save</b-button>
          <b-button class="mt-3 btn save m-0" variant="primary" v-else @click="onAddNewTeamMember">Invite</b-button>
        </div>
      </div>
    </b-modal>
    <DeleteModal v-if="showMemberModal" @confirm="deleteMemberItem($event)"
      :message="'Do you want to remove this User?'" :removeMessage="'Remove'" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import DeleteModal from "@/components/DeleteModal.vue";
import { checkEmail } from "../../services/helper";
export default {
  name: "Users",
  components: {
    DeleteModal,
  },
  data() {
    return {
      states: [],
      countries: [],
      document: null,
      editUserFlag: false,
      emptyTable:false,
      checkEmail,
      fileName: null,
      showMemberModal: false,
      perPage: 50,
      currentPage: 1,
      userAddingStepper: 0,
      rows: 1,
      positionText: "",
      items: [],
      errors: {},
      teamDispatchGroups: [],
      sortBy: undefined,
      sortDesc: undefined,
      isBusy: false,
      bindProps: {
        mode: "international",
        autoFormat: true,
        preferredCountries: ["US", "CA", "MX"],
        inputOptions: {
          placeholder: "Enter a phone no.",
          maxlength: 30,
        },
        dropdownOptions: {
          showSearchBox: true,
          showDialCodeInList: true,
          showFlags: true,
          showDialCodeInSelection: true,
          width: "300px",
        },
      },
      statusOptions: [
        {
          value: "",
          text: "",
        },
        {
          value: "True",
          text: "Active",
        },
        {
          value: "False",
          text: "Inactive",
        },
      ],
      appUserOptions: [
        {
          value: "",
          text: "",
        },
        {
          value: "True",
          text: "Active",
        },
        {
          value: "False",
          text: "Inactive",
        },
      ],
      fields: [
        {
          label: "Name",
          key: "name",
          sortable: true,
          tdClass: "align-middle",
          stickyColumn: true,
          thClass:'font-table-weight-bold',
        },
        {
          key: "role",
          label: "Role",
          sortable: true,
          tdClass: "align-middle",
          thClass:'font-table-weight-bold'
        },
        {
          label: "Email",
          key: "email",
          sortable: true,
          tdClass: "align-middle",
          thClass:'font-table-weight-bold'
        },
        {
          key: "phone",
          label: "Phone",
          sortable: true,
          tdClass: "align-middle",
          thClass:'font-table-weight-bold'
        },
        {
          key: "app_user",
          label: "Status",
          sortable: true,
          tdClass: "align-middle",
          thClass:'font-table-weight-bold'
        },
      ],
      selected: null,
      options: [
        {
          value: null,
          text: "",
        },
        {
          value: "Admin",
          text: "Admin",
        },
        {
          value: "Operations",
          text: "Operations",
        },
        {
          value: "Maintenance",
          text: "Maintenance",
        },
        {
          value:"Maintenance Manager",
          text:"Maintenance Manager"
        },
        {
          value: "Dispatcher",
          text: "Dispatcher",
        },
      ],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      isPhoneValid: false,
      newTeamMember: {
        first_name: "",
        last_name: "",
        role: null,
        email: "",
        username: "",
        phone: "",
        password: "",
        inviteNewMember: false,
        is_active: false,
      },
    };
  },

  computed: {
    ...mapGetters([
      "allTeamMembers",
      "dispatchGroups",
      "licenseInventory",
      "company",
      "currentTeamMember",
      "userCompany",
    ]),
  },
  methods: {
    ...mapActions([
      "getAllTeamMembers",
      "addTeamMember",
      "sendInvite",
      "setNotification",
      "getDispatchGroups",
      "deleteTeamMember",
      "getTeamMember",
      "updateTeamMember",
      "setPassword",
      "checkUserExists",
      "setLoader"
    ]),
    async updateTeamMemberData() {
      const payload = {
        ...this.currentTeamMember,
        user: {
          first_name: this.newTeamMember.first_name,
          last_name: this.newTeamMember.last_name,
          email: this.newTeamMember.email,
          is_active: this.newTeamMember.is_active,
          username: this.newTeamMember.username + "." + this.company[0].company_ID,
        },
        phone: this.newTeamMember.phone,
        role: this.newTeamMember.role,
        state: this.currentTeamMember.state.id
          ? this.currentTeamMember.state.id
          : 54,
        license_state: this.currentTeamMember.license_state.id
          ? this.currentTeamMember.license_state.id
          : 54,
      };
      try {
        if (this.newTeamMember.password) {
          await this.setPassword({
            user: this.currentTeamMember.id,
            password: this.newTeamMember.password,
          });
        }
        if (this.newTeamMember.phone) {
          if (!this.isPhoneValid) {
            throw {
              phone: ["Wrong format"],
            };
          }
        }
        if (this.newTeamMember.email) {
          if (!this.checkEmail(this.newTeamMember.email)) {
            throw {
              email: ["Wrong format"],
            };
          }
        }
        if (this.newTeamMember.username) {
          if (this.newTeamMember.username.includes("@")) {
            throw {
              username: ["Username shouldn't contain @"],
            };
          }
        }
        const nullKeys = this.checkNullValues({
          first_name: this.newTeamMember.first_name,
          last_name: this.newTeamMember.last_name,
          role: this.newTeamMember.role,
          email: this.newTeamMember.email
        });
        if (nullKeys.length === 0) {
          try {
            this.setLoader(true)
            await this.updateTeamMember({
              id: this.currentTeamMember.id,
              payload: payload,
            });
            this.userAddingStepper = 0
            await this.setNotification({
              msg: `Successfully updated ${this.newTeamMember.first_name} ${this.newTeamMember.last_name}`,
              type: "success",
              color: "green",
            });
            await this.getTeamMemberData();
            this.setLoader(false)
            this.$bvModal.hide("modal-teammember");
          } catch (error) {
            this.setLoader(false)
            console.log(error);
            if (typeof error === "object") {
              this.errors = error;
              // eslint-disable-next-line no-ex-assign
            }
          }
        } else {
          let error = {};
          nullKeys.forEach((x) => {
            error[x] = ["This field is required."];
          });
          throw error;
        }
      } catch (error) {
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
    },
    deleteMemberItem(item) {
      if (item) {
        this.deleteMember(this.itemId);
      }
      this.showMemberModal = false;
    },
    async deleteMember() {
      try {
        await this.deleteTeamMember(`${this.currentTeamMember.id}/?company_id=${this.userCompany}`);
        await this.setNotification({
          msg: "Member successfully deleted!!",
          type: "success",
          color: "green",
        });
        this.$bvModal.hide("modal-teammember");
        await this.getTeamMemberData();
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    checkNum(data, phoneObject) {
      if (phoneObject.valid !== undefined) {
        console.log(phoneObject);
        this.isPhoneValid = phoneObject.valid;
      }
    },
    resetField() {
      this.$refs.docFile.value = null;
      this.fileName = null;
      this.document = null;
      this.errors = {
        document: null,
      };
    },
    async updateUserData(data) {
      this.$bvModal.show("modal-teammember");
      this.errors = {};
      this.editUserFlag = true;
      await this.getTeamMember(data.id);
      this.newTeamMember.email = data.email;
      this.newTeamMember.role = data.role;
      this.newTeamMember.first_name = data.first_name;
      this.newTeamMember.username = data.username.split(
        `.${this.company[0].company_ID}`
      )[0]
      this.newTeamMember.last_name = data.last_name;
      this.newTeamMember.phone = this.currentTeamMember.phone
        ? this.currentTeamMember.phone !== "-"
          ? this.currentTeamMember.phone
          : ""
        : "";
      this.newTeamMember.is_active = this.currentTeamMember.user.is_active;
    },
    openAddUserModal() {
      this.editUserFlag = false;
      this.errors = {};
      (this.newTeamMember = {
        first_name: "",
        last_name: "",
        password: "",
        username: "",
        role: "",
        email: "",
        phone: "",
        inviteNewMember: false,
        is_active: false,
      }),
        this.$bvModal.show("modal-teammember");
    },
    checkNullValues(payload) {
      let nullKeys = [];
      for (let [key, value] of Object.entries(payload)) {
        if (!value) {
          nullKeys.push(key);
        }
      }
      return nullKeys;
    },
    async sendInviteTeam(id, type) {
      try {
        await this.sendInvite({
          user: id,
          invite_type: type
        });
      } catch (error) {
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
          error = "Couldnot send email";
        }
        console.log(error);
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    async onAddNewTeamMember() {
      if (this.userAddingStepper === 0) {
        try {
          if (this.newTeamMember.email) {
            if (!this.checkEmail(this.newTeamMember.email)) {
              throw {
                email: ["Wrong format"],
              };
            }
            const { status } = await this.checkUserExists({ email: this.newTeamMember.email })
            if (status === 'User with this email already exists.' || status === 'Driver with this email already exists') {
              this.setLoader(true)
              const newMember = await this.addTeamMember({
                company_id: this.userCompany,
                user: {
                  email: this.newTeamMember.email
                }
              });
              await this.getTeamMemberData();
              await this.sendInviteTeam(newMember.id, "company-invite");
              await this.setNotification({
                msg: `Successfully added ${newMember.user.first_name} ${newMember.user.last_name}.`,
                type: "success",
                color: "green",
              });
              this.userAddingStepper = 0
              this.setLoader(false)
              this.$bvModal.hide("modal-teammember");
              this.errors = {}
            }
            else {
              this.userAddingStepper++;
              this.errors = {}
            }
          }
          else {
            throw {
              email: ["Email is Required"],
            };
          }

        }
        catch (error) {
          this.setLoader(false)
          if(Array.isArray(error)){
              await this.setNotification({
              msg: error[0],
              type: "error",
              color: "red",
            });
            }     
          else if (typeof error === "object" && error.error) {
                    await this.setNotification({
                        msg: error.error,
                        type: "error",
                        color: "red",
                    });
                }
                else if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
                else {
                    await this.setNotification({
                        msg: error,
                        type: "error",
                        color: "red",
                    })
                }
        }
      }
      else {
        delete this.newTeamMember.is_active;
        const user = {
          first_name: this.newTeamMember.first_name,
          last_name: this.newTeamMember.last_name,
          email:
            this.newTeamMember.email
        };
        let postData = {
          user,
          role: this.newTeamMember.role,
          phone: this.newTeamMember.phone,
          company_id: this.userCompany
        };
        try {
          if (this.newTeamMember.phone) {
            if (!this.isPhoneValid) {
              throw {
                phone: ["Wrong format"],
              };
            }
          }
          if (this.newTeamMember.email) {
            if (!this.checkEmail(this.newTeamMember.email)) {
              throw {
                email: ["Wrong format"],
              };
            }
          }
          const nullKeys = this.checkNullValues({
            first_name: this.newTeamMember.first_name,
            last_name: this.newTeamMember.last_name,
            role: this.newTeamMember.role,
            email: this.newTeamMember.email
          });
          if (nullKeys.length === 0) {
            try {
              this.setLoader(true)
              const newMember = await this.addTeamMember(postData);
              if (newMember) {
                await this.getTeamMemberData();
                await this.sendInviteTeam(newMember.id, "user-invite");
                this.userAddingStepper = 0
                await this.setNotification({
                  msg: `Successfully added ${newMember.user.first_name} ${newMember.user.last_name}`,
                  type: "success",
                  color: "green",
                });
              }
              this.setLoader(false)
              this.$bvModal.hide("modal-teammember");
            } catch (e) {
              this.setLoader(false)
              await this.setNotification({
                msg: `${Object.keys(e?.user)[0]}: ${e?.user[Object.keys(e?.user)[0]]
                  }`,
                type: "error",
                color: "red",
              });
            }
          } else {
            let error = {};
            nullKeys.forEach((x) => {
              error[x] = ["This field is required."];
            });
            throw error;
          }
        } catch (error) {
          if (typeof error === "object") {
            this.errors = error;
            // eslint-disable-next-line no-ex-assign
          }
        }
      }
    },
    async getTeamMemberData() {
      try {
        this.isBusy = true
        let url = ""
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
        }
        else {
          url += `sort_field=name&sort_order=asc&`
        }
        url += `page=${this.currentPage}&page_size=${this.perPage}`
        const data = await this.getAllTeamMembers(url);
        if (data.total_entries) {
          this.rows = data.total_entries
          this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1)+data.queryset_count} of ${data.total_entries}`
        }
        else {
          this.rows = 0
          this.positionText = `0 to 0 of 0`
        }
        const teamMemberData = [];
        this.allTeamMembers.map((member) => {
          const data = {
            name: `${member.user.first_name} ${member.user.last_name}`,
            username: member.user.username,
            first_name: member.user.first_name,
            last_name: member.user.last_name,
            role: member.role,
            email: member.user.email ? member.user.email : "",
            phone: member.phone ? member.phone : "-",
            app_user: member.user.is_active ? "Active" : "Inactive",
            id: member.id,
            Image: member.profile_picture,
            dispatch_group1: member.dispatch_group1,
            dispatch_group2: member.dispatch_group2,
          };
          teamMemberData.push(data);
        });
        this.items = teamMemberData;
        if(this.items.length === 0) this.emptyTable = true
        else this.emptyTable = false
        this.isBusy = false
      }
      catch (e) {
        this.isBusy = false
        console.log(e)
      }
    },
    getCountryName(id) {
      return this.allCountries.filter((item) => item.id === id)[0].name;
    },
  },
  watch: {
    perPage: {
      async handler() {
        await this.getTeamMemberData();
      },
      immediate: true,
    },
    currentPage: {
      async handler() {
        await this.getTeamMemberData();
      },
    },
    sortBy: {
      async handler() {
        await this.getTeamMemberData();
      },
    },
    sortDesc: {
      async handler() {
        await this.getTeamMemberData();
      },
    },
  },
  async mounted() {
    try {
      await this.getTeamMemberData();
      await this.getDispatchGroups(this.userCompany);
      this.dispatchGroups.map((item) => {
        this.teamDispatchGroups.push({
          id: item.id,
          label: item.name,
        });
      });
    } catch (error) {
      console.log(error);
      if (error.status === 403) {
        await this.setNotification({
          msg: "You are not allowed to see team members.",
          type: "error",
          color: "red",
        });
      }
    }
  },
};
</script>
