<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" :title="'Add Accident'" backdrop shadow
            :visible="allowAddAccident" :right="true" no-header-close no-close-on-backdrop no-close-on-esc>
            <div class="d-block text-center mt-3">
                <b-row class="align-items-center px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Record # :" class="text-left mb-0" v-if="showRecordField">
                            <b-form-input v-model="accidentPayload.record_no" type="text" placeholder="Enter Record"
                                class="unit" required></b-form-input>
                            <p v-if="errors && errors.record_no" class="field-error">
                                {{ errors.record_no[0] }}
                            </p>
                        </b-form-group>
                        <b-form-group label="Record # :" class="text-left mb-0" v-else>
                            <b-form-input v-model="lastRecord" disabled type="text" placeholder="Enter Record" class="unit"
                                required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <div class="text-left select_arrow">
                            <label class="state">Status:</label>
                            <b-form-select v-model="accidentPayload.status" class="f-12" :options="[{
                                value: 'New',
                                text: 'New'
                            },
                            {
                                value: 'Open',
                                text: 'Open'
                            },
                            {
                                value: 'Closed',
                                text: 'Closed'
                            },
                            {
                                value: 'Urgent',
                                text: 'Urgent'
                            }
                            ]" />
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <label for="accident-add-datepicker" class="state">Date:</label>
                        <b-form-datepicker id="accident-add-datepicker" v-model="accidentPayload.date" reset-button
                            class="f-12"></b-form-datepicker>
                        <p v-if="errors && errors.date" class="field-error">
                            {{ errors.date[0] }}
                        </p>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <label class="state">Time:</label>
                        <b-form-input type="time" class="f-12" v-model="accidentPayload.time"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <div class="text-left select_arrow">
                            <label class="state">Driver:</label>
                            <v-select class="bg_white w-100 f-12" v-model="accidentPayload.driver" :options="driverOptions"
                                item-value="id" item-text="label" :reduce="(option) => option.id" @search="searchDriver">
                                <span slot="no-options">
                                    Search Driver ...
                                </span>
                            </v-select>
                            <p v-if="errors && errors.driver" class="field-error">
                                {{ errors.driver[0] }}
                            </p>
                        </div>
                    </b-col>
                    <b-col md="3" sm="12" class="mt-3">
                        <div class="text-left select_arrow">
                            <label class="state">Vehicle:</label>
                            <v-select class="bg_white w-100 f-12" v-model="accidentPayload.vehicle"
                                :options="vehicleOptions" item-value="id" item-text="label" :reduce="(option) => option.id"
                                @search="searchVehicle">
                                <span slot="no-options">
                                    Search Vehicle ...
                                </span>
                            </v-select>
                        </div>
                    </b-col>
                    <b-col md="3" sm="12" class="mt-3">
                        <div class="text-left select_arrow">
                            <label class="state">Trailer:</label>
                            <v-select class="bg_white w-100 f-12" v-model="accidentPayload.trailer"
                                :options="trailerOptions" item-value="id" item-text="label" :reduce="(option) => option.id"
                                @search="searchTrailer">
                                <span slot="no-options">
                                    Search Trailer ...
                                </span>
                            </v-select>
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <div class="text-left select_arrow">
                            <label class="state">DOT:</label>
                            <b-form-select v-model="accidentPayload.dot_status" class="f-12" :options="[{
                                value: '',
                                text: ''
                            },
                            {
                                value: true,
                                text: 'Yes'
                            },
                            {
                                value: false,
                                text: 'No'
                            }
                            ]" />
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <div class="text-left select_arrow">
                            <label class="state">Fault:</label>
                            <b-form-select v-model="accidentPayload.fault_status" class="f-12" :options="[{
                                value: '',
                                text: ''
                            },
                            {
                                value: true,
                                text: 'Yes'
                            },
                            {
                                value: false,
                                text: 'No'
                            }
                            ]" />
                        </div>
                    </b-col>
                    <b-col md="12" class="text-left mt-3">
                        <label class="state text-left">Notes:</label>
                        <b-form-textarea id="textarea" placeholder="Enter Notes" rows="5" v-model="accidentPayload.notes"
                            class="f-12"></b-form-textarea>
                    </b-col>
                </b-row>
                <hr class="m-4" />
                <b-row class="px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Claims</h3>
                    <b-col md="12" sm="12" class="text-left mt-3">
                        <label class="state">Claim #’s:</label>
                        <v-select class="bg_white w-100 f-12" multiple v-model="claimNumbers" :options="claimOptions"
                            item-value="id" item-text="label" :reduce="(option) => option.id" @search="searchClaims">
                            <span slot="no-options">
                                Search Claim # ...
                            </span>
                        </v-select>
                    </b-col>
                </b-row>
                <hr class="m-4" />
                <b-row class="px-4 mb-7rem">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Details</h3>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Address:</label>
                        <b-form-input v-model="accidentPayload.address" class="f-12"
                            placeholder="Enter Address"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">City:</label>
                        <b-form-input v-model="accidentPayload.city" class="f-12" placeholder="Enter City"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12">
                        <div class="text-left select_arrow mt-3">
                            <label class="state">State:</label>
                            <v-select class="bg_white w-100 f-12" v-model="accidentPayload.state" :options="states"
                                item-value="id" item-text="label" :reduce="(option) => option.id">
                            </v-select>
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Postal Code:</label>
                        <b-form-input v-model="accidentPayload.postal_code" class="f-12"
                            placeholder="Enter Postal Code"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Surface:</label>
                        <b-form-input v-model="accidentPayload.surface" class="f-12"
                            placeholder="Enter Surface"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Weather:</label>
                        <b-form-input v-model="accidentPayload.weather" class="f-12"
                            placeholder="Enter Weather"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state"># of Vehicles:</label>
                        <b-form-input v-model="accidentPayload.number_of_vehicles" class="f-12"
                            placeholder="Enter # of Vehicles"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Hazmat or Gas Spill:</label>
                        <b-form-input v-model="accidentPayload.hazmat_or_gas_spill" class="f-12"
                            placeholder="Enter Hazmat or Gas Spill"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Report #:</label>
                        <b-form-input v-model="accidentPayload.report_number" class="f-12"
                            placeholder="Enter Report #"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12">
                        <div class="text-left select_arrow mt-3">
                            <label class="state">Report State:</label>
                            <v-select class="bg_white w-100 f-12" v-model="accidentPayload.report_state" :options="states"
                                item-value="id" item-text="label" :reduce="(option) => option.id">
                            </v-select>
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Officer:</label>
                        <b-form-input v-model="accidentPayload.officer" class="f-12"
                            placeholder="Enter Officer:"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Citation #:</label>
                        <b-form-input v-model="accidentPayload.citation_number" class="f-12"
                            placeholder="Enter Citation #"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state"># of Fatality’s:</label>
                        <b-form-input v-model="accidentPayload.number_of_fatality" class="f-12"
                            placeholder="Enter # of Fatality's"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state"># of Injury’s:</label>
                        <b-form-input v-model="accidentPayload.number_of_injury" class="f-12"
                            placeholder="Enter # of Injury's"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label for="drug-test-add-datepicker" class="state">Drug Test:</label>
                        <b-form-datepicker id="drug-test-add-datepicker" class="f-12"
                            v-model="accidentPayload.drug_test_date" reset-button></b-form-datepicker>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label for="alc-add-datepicker" class="state">Alc. Test:</label>
                        <b-form-datepicker id="alc-add-datepicker" class="f-12" v-model="accidentPayload.alc_test_date"
                            reset-button></b-form-datepicker>
                    </b-col>
                    <b-col lg="6" md="12" sm="12" class="text-left mt-3">
                        <label class="state">Customer:</label>
                        <b-form-input v-model="accidentPayload.customer" class="f-12"
                            placeholder="Enter Customer"></b-form-input>
                    </b-col>
                    <b-col lg="6" md="12" sm="12" class="text-left mt-3">
                        <label class="state">Load #:</label>
                        <b-form-input v-model="accidentPayload.load_number" class="f-12"
                            placeholder="Enter Load #"></b-form-input>
                    </b-col>
                </b-row>
                <div class="sidebar-footer position-fixed">
                    <div class="text-right modal-footer border-0 p-0">
                        <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetPopup()"
                            variant="secondary">Cancel</b-button>
                        <b-button class="mx-0 h-40px w-125px px-2 mr-3" @click="onAddNewAccident(true)"
                            variant="outline-primary">Save & Open</b-button>
                        <b-button class="mx-0 h-40px w-100px" @click="onAddNewAccident()" variant="primary">Save</b-button>
                    </div>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
export default {
    components: { vSelect },
    name: "AddAccident",
    props: {
        allowAddAccident: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapGetters([
            "userCompany",
            "allEquipments",
            "allDrivers",
            "allCountries"
        ]),
    },
    data() {
        return {
            errors: {},
            states: [],
            lastRecord: null,
            claimNumbers: [],
            accidentID: null,
            accidentPayload: {
                address: "",
                alc_test_date: null,
                city: "",
                claim_number: "",
                customer: "",
                drug_test_date: null,
                hazmat_or_gas_spill: "",
                number_of_fatality: null,
                number_of_injury: null,
                number_of_vehicles: null,
                load_number: "",
                officer: "",
                postal_code: "",
                report_number: "",
                report_state: null,
                state: null,
                surface: "",
                driver: null,
                record_no: "",
                weather: "",
                status: "New",
                vehicle: null,
                trailer: null,
                date: null,
                time: null,
                citation_number: "",
                dot_status: null,
                fault_status: null,
                notes: ""
            },
            driverOptions: [],
            vehicleOptions: [],
            trailerOptions: [],
            claimOptions: []
        }
    },
    methods: {
        ...mapActions([
            "getAllEquipments",
            "getAllDrivers",
            "setLoader",
            "addAccident",
            "setNotification",
            "getAllClaims",
            "getAllCountries",
            "getLastRecord"
        ]),
        async searchClaims(searchText) {
            let options = []
            const url = `sort_field=record_no&search_field=${searchText}&sort_order=desc&page=1&page_size=10`
            const data = await this.getAllClaims(url);
            data.records.forEach((x) => {
                options.push({
                    id: x.record_no.toString(),
                    label: x.record_no.toString(),
                });
            });
            this.claimOptions = this.claimOptions.concat(options)
            this.claimOptions = [...new Map(this.claimOptions.map(item => [item['id'], item])).values()]
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async onAddNewAccident(openAccident = false) {
            try {
                const nullKeys = this.checkNullValues({
                    driver: this.accidentPayload.driver,
                    date: this.accidentPayload.date,
                    ...this.showRecordField ? { record_no: this.accidentPayload.record_no } : {},
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true);
                    if (!this.showRecordField) delete this.accidentPayload.record_no
                    if (!this.accidentPayload.dot_status) delete this.accidentPayload.dot_status
                    if (!this.accidentPayload.fault_status) delete this.accidentPayload.fault_status
                    const record = await this.addAccident({ ...this.accidentPayload, company: this.userCompany, claim_number: this.claimNumbers.length ? this.claimNumbers.toString() : '' })
                    await this.setNotification({
                        msg: `Accident Successfully Added`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false);
                    if (openAccident) this.$emit("accidentAdded", record)
                    else this.$emit("accidentAdded", null)
                    this.resetPopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                console.log(error)
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
                this.setLoader(false);
            }

        },
        resetPopup() {
            this.accidentPayload = {
                address: "",
                alc_test_date: null,
                city: "",
                claim_number: "",
                customer: "",
                drug_test_date: null,
                hazmat_or_gas_spill: "",
                number_of_fatality: null,
                number_of_injury: null,
                number_of_vehicles: null,
                load_number: "",
                officer: "",
                postal_code: "",
                report_number: "",
                report_state: null,
                state: null,
                surface: "",
                driver: null,
                record_no: "",
                weather: "",
                status: "New",
                vehicle: null,
                trailer: null,
                date: null,
                time: null,
                citation_number: "",
                dot_status: null,
                fault_status: null,
                notes: ""
            },
                this.errors = {}
            this.driverOptions = [],
                this.vehicleOptions = [],
                this.trailerOptions = [],
                this.claimOptions = []
            this.claimNumbers = []
            this.$emit('close');
        },
        async searchDriver(searchText) {
            let options = []
            const url = `name=${searchText}&page=1&page_size=10`
            await this.getAllDrivers(url);
            this.allDrivers.forEach((x) => {
                options.push({
                    id: x.id,
                    label: `${x.user.first_name} ${x.user.last_name}`,
                });
            });
            this.driverOptions = this.driverOptions.concat(options)
            this.driverOptions = [...new Map(this.driverOptions.map(item => [item['id'], item])).values()]
        },
        async searchVehicle(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Vehicle&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.vehicleOptions = this.vehicleOptions.concat(options)
            this.vehicleOptions = [...new Map(this.vehicleOptions.map(item => [item['id'], item])).values()]
        },
        async searchTrailer(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Trailer&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.trailerOptions = this.trailerOptions.concat(options)
            this.trailerOptions = [...new Map(this.trailerOptions.map(item => [item['id'], item])).values()]
        },
        async getCountries() {
            await this.getAllCountries();
            const countries = this.allCountries.map((item) => ({
                id: item.id,
                label: item.name,
            }));
            this.getStates(countries[0]);
        },
        getStates(selectedOption) {
            this.states = this.allCountries
                .filter((country) => country.id === selectedOption.id)[0]
                .state.map((item) => ({
                    id: item.id,
                    label: item.name,
                }));
            this.accidentPayload.state = this.states.filter(
                (state) => state.label === "-"
            )[0].id;
            this.accidentPayload.report_state = this.states.filter(
                (state) => state.label === "-"
            )[0].id;
        }
    },
    watch: {
        async allowAddAccident() {
            if (this.allowAddAccident) {
                this.setLoader(true)
                const lastItemRecord = await this.getLastRecord({
                    company_id: this.userCompany,
                    query: `model_name=safetyaccident`
                })
                if (lastItemRecord) this.lastRecord = lastItemRecord + 1;
                else this.showRecordField = true
                this.setLoader(false)
            }
        }
    },
    async mounted() {
        await this.getCountries();
    },
}
</script>