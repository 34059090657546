<template>
    <div>
        <b-modal id="modal-violation" class="maintenance_modal" centered :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
            scrollable>
            <template #modal-header="{ close }">
                <h5 class="modal-title"> {{ violationData ? 'Edit' : 'Add' }} Violation
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="d-block text-center">
                <b-row>
                    <b-col sm="6" class="text-left">
                        <b-form-group>
                            <label class="state">Code:</label>
                            <b-form-input class="f-12" type="text" v-model="violationPayload.code" placeholder="Enter Code"></b-form-input>
                        </b-form-group>
                        <b-form-group>
                            <label class="state">Description:</label>
                            <b-form-textarea class="f-12" type="text" v-model="violationPayload.description" rows="6" placeholder="Enter Description"></b-form-textarea>
                            <p v-if="errors && errors.description" class="field-error">
                                {{ errors.description[0] }}
                            </p>
                        </b-form-group>
                        </b-col>
                        <b-col sm="6" class="text-left">
                        <b-form-group>
                            <label class="state">Unit:</label>
                            <b-form-select type="text" class="f-12" v-model="violationPayload.unit" :options="[
                                {
                                    value: '',
                                    text: '',
                                },
                                {
                                    value: 'Driver',
                                    text: 'Driver',
                                },
                                {
                                    value: 'Vehicle',
                                    text: 'Vehicle',
                                },
                                {
                                    value: 'Trailer',
                                    text: 'Trailer',
                                }
                            ]
                                "></b-form-select>
                            <p v-if="errors && errors.unit" class="field-error">
                                {{ errors.unit[0] }}
                            </p>
                        </b-form-group>
                        <b-form-group>
                            <label class="state">Weight:</label>
                            <b-form-select type="text" class="f-12" v-model="violationPayload.weight" :options="[
                                {
                                    value: null,
                                    text: '',
                                },
                                {
                                    value: 1,
                                    text: '1',
                                },
                                {
                                    value: 2,
                                    text: '2',
                                },
                                {
                                    value: 3,
                                    text: '3',
                                }
                            ]"></b-form-select>
                        </b-form-group>
                        <b-form-group>
                            <label class="state">Points:</label>
                            <b-form-input type="text" class="f-12" v-model="violationPayload.points" placeholder="Enter Points"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col class="text-left">
                    <b-form-group>
                            <label class="state">OOS:</label>
                            <b-form-checkbox v-model="violationPayload.oos_status" name="check-button" switch size="lg"
                                class="ml-2" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <div class="text-right modal-footer border-0 p-0">
                    <b-button class="mt-3 mr-3 btn cancel m-0" block @click="resetPopup()">Close</b-button>
                    <div class="d-flex align-items mt-3 m-0">
                        <b-button class="btn save mx-0 w-100" variant="primary" @click="onAddViolation">Save</b-button>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: "Violation",
    props: ["inspectionID", "violationData"],
    data() {
        return {
            errors: {},
            violationPayload: {
                code: "",
                description: "",
                unit: "",
                weight: null,
                points: null,
                oos_status: false
            }
        }
    },
    methods: {
        ...mapActions(['addViolation', 'setNotification', 'setLoader', 'updateViolation']),
        resetPopup() {
            this.errors = {}
            this.violationPayload = {
                code: null,
                description: "",
                unit: "",
                weight: null,
                points: null,
                oos_status: false
            }
            this.$bvModal.hide("modal-violation");
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async onAddViolation() {
            try {
                const nullKeys = this.checkNullValues({
                    description: this.violationPayload.description,
                    unit: this.violationPayload.unit
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    let method = 'Added'
                    if (this.violationData) {
                        await this.updateViolation({ id: this.inspectionID, payload: { ...this.violationPayload, id: this.violationData.id } })
                        method = 'Updated'
                    }
                    else await this.addViolation({ id: this.inspectionID, payload: this.violationPayload })
                    await this.setNotification({
                        msg: `Violation Successfully ${method}`,
                        type: "success",
                        color: "green",
                    });
                    this.$emit("violationUpdated", true)
                    this.resetPopup()
                    this.setLoader(false)
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
                this.setLoader(false);
                console.log(error)
            }
        }
    },
    watch: {
        violationData() {
            if (this.violationData) this.violationPayload = this.violationData
        }
    }
}
</script>