import {
    getReq,
} from "../../../services/api";
const actions = {
    async getActiveDrivers(_, url) {
        try {
            const data = await getReq(`app/active-drivers-report/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return { records: data.data, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page }
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async getActiveEquipments(_, url) {
        try {
            const data = await getReq(`app/active-equipment-report/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return { records: data.data, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page }
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async exportActiveDrivers(_, url) {
        try {
            const data = await getReq(`app/active-drivers-report/export_to_csv/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async exportActiveEquipments(_, url) {
        try {
            const data = await getReq(`app/active-equipment-report/export_to_csv/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    }
}
export default {
    actions
  };