<template>
  <div>
    <b-container fluid class="mt-2 px-1 px-sm-3 pb-3 team-page">
      <b-row>
        <b-col cols="" class="">
          <b-row>
            <b-col cols="">
              <div class="documents-alert-section f-14 text-left" v-if="!!showDocumentProgess">
                Smart Drop{{ showDocumentProgess.requestor === 2 ? ' +' : '' }} is processing your zip file. Please check
                back
                soon!
              </div>
              <div class="documents-progess-section f-14 text-left d-flex align-items-center"
                v-if="!!showDocumentUploadStatus">
                <div class="w-75">
                  Smart Drop + has processed your file. Results are as follows :
                  | {{ showDocumentUploadStatus.desc_json.total_completed }} Completed Files | {{
                    showDocumentUploadStatus.desc_json.total_incomplete }} Incomplete Files | {{
    showDocumentUploadStatus.desc_json.total_failed_files }}
                  Failed to read |
                  {{ showDocumentUploadStatus.desc_json.total_incomplete +
                    showDocumentUploadStatus.desc_json.data_processing_errors.length }}
                  Total files require review | {{
                    showDocumentUploadStatus.desc_json.unsupported_file_formats.length }} Unsupported files are ignored |
                </div>
                <p class="mb-0 text-primary cursor-pointer f-14 ml-auto" @click="dimissUploadStatus">Dismiss</p>
              </div>
              <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
                <h2 class="text-left">Documents</h2>
                <div class="d-flex justify-content-between justify-content-sm-center flex-wrap"
                  v-if="$store.state.user.user.team_member.role === 'Admin' ||
                    $store.state.user.user.team_member.role === 'Operations' || $store.state.user.user.team_member.role === 'Maintenance Manager'">
                  <b-button @click="addDocument" v-if="!reviewDocs"
                    class="export equipment d-flex justify-content-center align-items-center mx-1 my-1 w-fit-content"
                    variant="primary">
                    <b-icon icon="plus" aria-hidden="true"></b-icon>
                    <p class="mb-0 ml-2 text-white">Add Document</p>
                  </b-button>
                  <b-button v-else-if="selectedRows.length && reviewDocs"
                    class="export equipment d-flex justify-content-center align-items-center mx-1 my-1 w-125px"
                    variant="primary" @click="approveDocs">Approve
                  </b-button>
                  <b-dropdown id="dropdown-divider" text="text" class="load-dropdown"
                    v-if="((($store.state.user.user.team_member.role === 'Admin' ||
                      $store.state.user.user.team_member.role === 'Operations') && !showDocumentProgess) || selectedRows.length)">
                    <template slot="button-content">
                      <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button @click="checkUploadStatus('smartDrop')" class="mb-2"
                      v-if="!showDocumentProgess">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Smart Drop</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="checkUploadStatus('smartDrop+')"
                      :class="selectedRows.length ? 'mb-2' : 'mb-0'" v-if="!showDocumentProgess">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Smart Drop +</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="selectedRows.length" class="mb-2"
                      @click="sharePopupTitle = 'Driver Share'; $bvModal.show('modal-driver-public-share')">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Driver Share</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="selectedRows.length" class="mb-2"
                      @click="sharePopupTitle = 'Public Share'; $bvModal.show('modal-driver-public-share')">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Public Share</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="selectedRows.length" @click="deleteMultipleDocumentModal = true">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Delete {{ selectedRows.length }} Rows</p>
                    </b-dropdown-item-button>
                  </b-dropdown>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="data-table team-data-table table-custom-border rounded-top">
                <div class="bg-white rounded" v-if="$store.state.user.user.team_member.role === 'Admin' ||
                  $store.state.user.user.team_member.role === 'Operations'">
                  <v-tabs class="team-tabs mb-0">
                    <v-tab @click="setDocumentType('')">All</v-tab>
                    <v-tab @click="setDocumentType('Review')">Review <span class="f-12 ml-1"> ({{ reviewDocsCount ?
                      reviewDocsCount : 0 }})</span></v-tab>
                  </v-tabs>
                </div>
                <div class="bg-white pt-3 pt-sm-4">
                  <div class="table_row team_table_row">
                    <div class="equipment-border filter-box">
                      <div class="d-flex mb-4 px-3 px-sm-4">
                        <b-form-group class="text-left label font-weight-normal search-field mb-0">
                          <b-input-group class="username-input-field">
                            <b-form-input type="text" placeholder="Search" v-model="filterFields.search_field"
                              class="unit f-12" @keyup.enter="getAllDocs()"></b-form-input>
                            <b-input-group-append>
                              <b-button variant="primary" @click="getAllDocs()">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                </svg>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                        <div class="ml-auto">
                          <b-dropdown id="dropdown-divider" ref="filterDropdown"
                            class="filter-section table-columns-dropdown mt-0"
                            :class="checkFilterStatus ? 'active-dropdown' : ''">
                            <template slot="button-content">
                              <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M8.74045 19C8.55379 19 8.37477 18.9256 8.24278 18.7933C8.11079 18.6609 8.03663 18.4813 8.03663 18.2941V11.5071L1.51223 4.31059C1.2554 4.02674 1.08624 3.67422 1.02528 3.29583C0.964316 2.91743 1.01418 2.52942 1.16881 2.17889C1.32344 1.82836 1.57619 1.53038 1.89639 1.32112C2.21659 1.11185 2.59047 1.0003 2.97266 1H18.0273C18.4095 1.0003 18.7834 1.11185 19.1036 1.32112C19.4238 1.53038 19.6766 1.82836 19.8312 2.17889C19.9858 2.52942 20.0357 2.91743 19.9747 3.29583C19.9138 3.67422 19.7446 4.02674 19.4878 4.31059L12.9634 11.5071V15.4706C12.9633 15.5763 12.9395 15.6807 12.8939 15.776C12.8482 15.8713 12.7817 15.9551 12.6994 16.0212L9.18034 18.8447C9.05557 18.9451 8.90042 18.9999 8.74045 19ZM2.97266 2.41176C2.86314 2.41204 2.75604 2.44414 2.66434 2.50419C2.57263 2.56424 2.50024 2.64966 2.45594 2.75011C2.41163 2.85056 2.3973 2.96174 2.41468 3.07019C2.43207 3.17864 2.48042 3.27971 2.55389 3.36118L9.26128 10.7729C9.3762 10.8997 9.44123 11.064 9.44427 11.2353V16.8259L11.5557 15.1176V11.2353C11.5553 11.0591 11.6206 10.8892 11.7387 10.7588L18.4461 3.34706C18.5152 3.26523 18.5598 3.16547 18.5748 3.0593C18.5897 2.95312 18.5744 2.84488 18.5306 2.74706C18.4868 2.64924 18.4163 2.56585 18.3273 2.50652C18.2382 2.44719 18.1342 2.41434 18.0273 2.41176H2.97266Z"
                                  stroke-width="0.2" />
                              </svg>

                            </template>
                            <div class="filter-section-items">
                              <p class="font-weight-bold mb-2 mt-2 heading">Filter</p>
                              <b-form-group label="Type" class="text-left select_arrow label w-equipment-box mb-3">
                                <b-form-select v-model="filterFields.type" class="f-12 bg-white" @change="getAllDocs()"
                                  :options="typeOptions" />
                              </b-form-group>
                              <b-form-group label="Days" class="text-left select_arrow label w-equipment-box bg-white">
                                <b-form-select v-model="filterFields.expiry_days" class="f-12 bg-white"
                                  @change="getAllDocs()" :options="[
                                    {
                                      value: '',
                                      text: '',
                                    },
                                    {
                                      value: '0-15',
                                      text: '0-15',
                                    },
                                    {
                                      value: '0-31',
                                      text: '0-31',
                                    },
                                  ]" />
                              </b-form-group>
                            </div>
                            <div class="d-flex align-items-center justify-content-between buttons-section">
                              <b-button variant="primary" @click="resetFilterFields()">Reset</b-button>
                              <b-button class="text-dark btn-secondary-2"
                                @click="$refs.filterDropdown.hide()">Close</b-button>
                            </div>
                          </b-dropdown>
                          <b-dropdown id="dropdown-divider" ref="tableDropdown" class="table-columns-dropdown ml-2">
                            <template slot="button-content">
                              <svg width="18" height="20" class="dots" viewBox="0 0 18 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <mask id="path-1-inside-1_4223_1514" fill="white">
                                  <rect width="4" height="20" rx="1" />
                                </mask>
                                <rect width="4" height="20" rx="1" stroke="#2D69F6" stroke-width="3"
                                  mask="url(#path-1-inside-1_4223_1514)" />
                                <mask id="path-2-inside-2_4223_1514" fill="white">
                                  <rect x="7" width="4" height="20" rx="1" />
                                </mask>
                                <rect x="7" width="4" height="20" rx="1" stroke="#2D69F6" stroke-width="3"
                                  mask="url(#path-2-inside-2_4223_1514)" />
                                <mask id="path-3-inside-3_4223_1514" fill="white">
                                  <rect x="14" width="4" height="20" rx="1" />
                                </mask>
                                <rect x="14" width="4" height="20" rx="1" stroke="#2D69F6" stroke-width="3"
                                  mask="url(#path-3-inside-3_4223_1514)" />
                              </svg>

                            </template>
                            <div class="checkbox-section">
                              <p class="font-weight-bold mb-2 mt-2 heading">Columns</p>
                              <draggable v-model="fields" :element="'div'" ghost-class="ghost">
                                <div class="d-flex align-items-center justify-content-between mb-3 cursor-pointer"
                                  v-for="(field, index) in fields" :key="index">
                                  <b-form-checkbox :id="'checkbox-' + index" v-model="field.visible"
                                    :disabled="field.disabled" @change="updateFields()" :name="'checkbox-' + index"
                                    @click.native.capture.stop>
                                    {{ field.heading ? field.heading : field.label }}
                                  </b-form-checkbox>
                                  <svg width="19" height="7" viewBox="0 0 19 7" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect width="19" height="2" rx="1" fill="black"></rect>
                                    <rect y="5" width="19" height="2" rx="1" fill="black"></rect>
                                  </svg>

                                </div>
                              </draggable>
                            </div>
                            <div class="d-flex align-items-center justify-content-between buttons-section">
                              <b-button variant="primary" @click="resetTableFields()">Reset</b-button>
                              <b-button class="text-dark btn-secondary-2"
                                @click="$refs.tableDropdown.hide()">Close</b-button>
                            </div>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>

                    <b-table ref="tableRef" class="mt-2" responsive :fields="visibleFields" :items="items" hover
                      :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                      :no-local-sorting="true" :busy.sync="isBusy" selectable select-mode="multi" no-select-on-click
                      @row-selected="onRowSelected" :show-empty="emptyTable">
                      <template #head(selected)="" v-if="$store.state.user.user.team_member.role === 'Admin' ||
                        $store.state.user.user.team_member.role === 'Operations'">
                        <b-form-checkbox class="pl-0 w-fit-content" @change="selectRows($event)"
                          v-model="allRowsSelected">
                        </b-form-checkbox>
                      </template>
                      <template #cell(name)="data">
                        <div class="d-flex align-items-center">
                          <a>{{ data.value }}</a>
                          <svg class="ml-2" v-if="data.item.data_extraction_requested" width="14" height="14"
                            viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_8879_165)">
                              <path d="M2.03955 13.4484V10.9683H4.5197" stroke="#2D69F6" stroke-linecap="round"
                                stroke-linejoin="round" />
                              <path
                                d="M13.3389 5.81494C13.956 9.09662 11.9478 12.35 8.66898 13.2286C6.15951 13.901 3.59901 12.9965 2.03955 11.1225"
                                stroke="#2D69F6" stroke-linecap="round" stroke-linejoin="round" />
                              <path
                                d="M0.661145 8.18502C0.0440415 4.90334 2.05229 1.64992 5.33108 0.771373C7.84038 0.0990079 10.4007 1.00334 11.9602 2.87708"
                                stroke="#2D69F6" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.9601 0.551758V3.03191H9.47998" stroke="#2D69F6" stroke-linecap="round"
                                stroke-linejoin="round" />
                              <path
                                d="M4.08961 7.33361C3.73868 7.27256 3.73868 6.76878 4.08961 6.70773C5.36097 6.48655 6.37216 5.51818 6.64811 4.25758L6.66926 4.16095C6.74519 3.81412 7.23903 3.81196 7.31798 4.15811L7.34367 4.27072C7.62983 5.52538 8.64129 6.48556 9.90912 6.70613C10.2618 6.7675 10.2618 7.27384 9.90912 7.3352C8.64129 7.55577 7.62983 8.51596 7.34367 9.77061L7.31798 9.88322C7.23903 10.2294 6.74519 10.2272 6.66926 9.88039L6.64811 9.78376C6.37216 8.52315 5.36097 7.55479 4.08961 7.33361Z"
                                stroke="#2D69F6" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_8879_165">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </template>
                      <template #cell(selected)="data" v-if="$store.state.user.user.team_member.role === 'Admin' ||
                        $store.state.user.user.team_member.role === 'Operations'">
                        <b-form-checkbox class="pl-0 w-fit-content" @change="checked(data.index, data.rowSelected)"
                          v-model="data.rowSelected">
                        </b-form-checkbox>
                      </template>
                      <template #cell(description)="data">
                        <v-app v-if="data.value && data.value !== 'null'">
                          <v-tooltip top>
                            <template #activator="{ on, attrs }">
                              <span class="w-fit-content item-description text-left" v-on="on" v-bind="attrs">{{
                                data.value
                              }}</span>
                            </template>{{ data.value }}
                          </v-tooltip>
                        </v-app>
                        <p v-else>-</p>
                      </template>

                      <template #cell(public)="data">
                        <b-form-checkbox :disabled="$store.state.user.user.team_member.role === 'Maintenance'" v-if="data.item.type === 'Trailer' ||
                          data.item.type === 'Vehicle'
                          " v-model="data.value" name="check-button" switch @change="
    updateDocumentOnToggle(
      data.item,
      'public',
      data.value
    )
    " size="lg" class="ml-2" />
                      </template>
                      <template #cell(expiry_date)="data">
                        <span v-html="data.value"></span>
                      </template>
                      <template #cell(expiry_days)="data">
                        <span v-if="data.item.expiry_date !== '-'" :style="data.item.color">{{ data.value }} Days</span>
                        <span v-else :style="data.item.color">-</span>
                      </template>
                      <template #cell(driver)="data">
                        <b-form-checkbox v-model="data.value"
                          :disabled="$store.state.user.user.team_member.role === 'Maintenance'" name="check-button" switch
                          @change="
                            updateDocumentOnToggle(
                              data.item,
                              'driver',
                              data.value
                            )
                            " size="lg" class="ml-2" />
                      </template>
                      <template #cell(action)="data">
                        <b-icon v-if="data.item.link" style="fill: #2D69F6" class="mr-4" icon="eye-fill"
                          @click="openDoc(data.item)" />
                        <b-icon
                          v-if="($store.state.user.user.team_member.role === 'Admin' ||
                            $store.state.user.user.team_member.role === 'Operations' || ((data.item.type === 'Vehicle' || data.item.type === 'Trailer') && $store.state.user.user.team_member.role === 'Maintenance Manager')) && !reviewDocs"
                          style="fill: limegreen" class="mr-4" icon="pencil" @click="editDocument(data.item.id)" />
                        <b-icon
                          v-if="$store.state.user.user.team_member.role === 'Admin' ||
                            $store.state.user.user.team_member.role === 'Operations' || ((data.item.type === 'Vehicle' || data.item.type === 'Trailer') && $store.state.user.user.team_member.role === 'Maintenance Manager')"
                          style="fill:#FF0000" icon="trash-fill" @click="showPopup(data.item.pk)" />
                      </template>
                    </b-table>
                    <div class="entries-pagination px-3 px-sm-4">
                      <div class="d-flex align-items-center justify-content-between flex-wrap">
                        <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                          <b-form-select v-model="perPage" :options="pageOptions" />
                        </div>

                        <div class="pagination">
                          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="my-table"></b-pagination>
                        </div>
                        <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">
                          {{ positionText }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <DeleteModal v-if="showDocumentModal" @confirm="deleteDocumentItem($event)" />
    <DeleteModal v-if="deleteMultipleDocumentModal" @confirm="deleteDocumentItems($event)"
      :message="`Do you want to delete ${selectedRows.length} Items`" />
    <b-modal id="modal-add-document" centered hide-footer no-close-on-backdrop :body-class="'p-4'" no-close-on-esc>
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          {{ editDocumentFlag ? "Edit Document" : "Add Document" }}
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="d-block text-center">
        <b-row>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label class="state">Name:</label>
              <v-select class="bg_white w-100 f-12" v-model="documentData.name" :options="nameOptions" item-value="id"
                item-text="label" :reduce="(option) => option.id" @option:selected="getNameItem($event)"
                @search="searchAssets">
                <span slot="no-options">
                  Search Unit No or Name...
                </span>
              </v-select>
              <p v-if="errors && errors.name" class="field-error">
                {{ errors.name[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Date:</label>
              <b-form-datepicker id="modal-datepicker" class="mb-2" v-model="documentData.date"
                reset-button></b-form-datepicker>
              <p v-if="errors && errors.date" class="field-error">
                {{ errors.date[0] }}
              </p>
            </div>

            <div class="text-left mb-3">
              <label for="modal-expiry-date" class="state">Expiry Date:</label>
              <b-form-datepicker id="modal-expiry-date" class="mb-2" v-model="documentData.expiry_date"
                reset-button></b-form-datepicker>
            </div>
            <div class="text-left mb-3">
              <label class="state">Description:</label>
              <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                v-model="documentData.description"></b-form-textarea>
              <p v-if="errors && errors.description" class="field-error">
                {{ errors.description[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label class="state">Driver:</label>
              <b-form-checkbox class="ml-2" v-model="documentData.driver" name="driver-button" switch size="lg" />
            </div>
            <div class="text-left" v-if="showPublicQR">
              <label class="state">Public (QR):</label>
              <b-form-checkbox class="ml-2" v-model="documentData.public" name="public-button" switch size="lg" />
            </div>
          </b-col>
          <b-col lg="6" md="8" sm="12">
            <div class="upload-btn-wrapper">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button v-if="!fileName" class="foo">
                Drop your document here, or browse
              </button>
              <button v-else class="foo">{{ fileName }}</button>
              <p v-if="fileName" class="mb-0 field-error cursor-pointer font-12 position-relative error-button"
                @click="removeFile()">
                Delete
              </p>
              <input ref="file" type="file" name="myfile" v-on:change="handleFileUpload"
                accept=".pdf, .png, .jpg, .jpeg" />
              <p v-if="errors && errors.document" class="field-error">
                {{ errors.document[0] }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button class="mt-3 mr-3 btn cancel" block @click="$bvModal.hide('modal-add-document')">Cancel</b-button>
        <b-button class="mt-3 btn save mx-0" variant="primary" @click="sendDocumentData">Save</b-button>
      </div>
    </b-modal>
    <b-modal id="media-popup" no-close-on-backdrop size="lg" scrollable centered
      :footer-class="'d-block border-0 px-20px pt-0 pb-3 m-0'" no-close-on-esc>
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Preview
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <vue-pdf-embed v-if="mediaType === 'pdf'" :source="media" />
      <img :src="media" v-else class="w-100" />
      <template #modal-footer>
        <div class="d-flex modal-footer border-0 p-0 mt-3"
          :class="($store.state.user.user.team_member.role !== 'Admin' ||
            $store.state.user.user.team_member.role !== 'Operations') ? 'justify-content-between' : 'justify-flex-end'">
          <div class="d-flex m-0">
            <b-button class="cancel text-white m-0" v-if="$store.state.user.user.team_member.role === 'Admin' ||
              $store.state.user.user.team_member.role === 'Operations'" :class="mediaType === 'pdf' ? 'mr-3' : ''"
              variant="danger" @click="showPopup(mediaID)">Delete</b-button>
            <b-button class="btn w-125px mx-0 m-0 px-2" v-if="mediaType === 'pdf'" @click="downloadFile(media)"
              variant="success">Download</b-button>
          </div>
          <b-button class="btn cancel m-0" block @click="$bvModal.hide('media-popup')">Close</b-button>
        </div>
      </template>
    </b-modal>
    <!-- Import CSV Modal  -->
    <b-modal id="modal-bulk-upload" centered hide-footer @shown="resetField" no-close-on-backdrop no-close-on-esc>
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Smart Drop
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="d-block text-center">
        <b-row class="import-excel-modal mx-0">
          <b-col cols="12" class="px-0">
            <div class="upload-btn-wrapper mt-0">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button class="foo w-100" v-if="!bulkFileUploadFileName">
                Drop your document here, or browse
                <br />
                <br />
                <span>Only Zip File of equipment documents is allowed.</span>
              </button>
              <button v-else class="foo">{{ bulkFileUploadFileName }}</button>
              <input ref="bulkFile" type="file" name="bulkFile" @change="handleBulkFileUpload"
                accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" />
              <p v-if="bulkFileUploadErrors && bulkFileUploadErrors.document" class="field-error">
                {{ bulkFileUploadErrors.document[0] }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button class="mx-0 mr-3 h-40px w-100px" @click="$bvModal.hide('modal-bulk-upload')"
          variant="secondary">Cancel</b-button>
        <b-button class="mx-0 mb-0 h-40px w-100px mt-0" variant="primary" @click="bulkUploadEquipment">Save</b-button>
      </div>
    </b-modal>
    <SmartDropPlus />
    <SmartDropPreview :documentItem="selectedReviewDoc" @docsUpdated="getAllDocs()" @close="selectedReviewDoc = null" />
    <DriverAndPublicShare :title="sharePopupTitle" @updateDocs="getAllDocs()" v-if="selectedRows.length"
      :docIds="selectedRows.map((data) => data.pk)" 
      @close="$refs.tableRef.clearSelected(); selectedRows=[]"
      />
  </div>
</template>
<script>
import * as moment from "moment-timezone";
import DeleteModal from "@/components/DeleteModal.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import draggable from 'vuedraggable'
import { mapActions, mapGetters } from "vuex";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import axios from "axios";
import SmartDropPlus from "@/components/Documents/SmartDropPlus.vue";
import SmartDropPreview from "@/components/Documents/SmartDropPreview.vue";
import DriverAndPublicShare from "@/components/Documents/DriverAndPublicShare.vue";
export default {
  name: "Documents",
  components: { DeleteModal, vSelect, VuePdfEmbed, draggable, SmartDropPlus, SmartDropPreview, DriverAndPublicShare },
  data() {
    return {
      searchName: null,
      searchType: null,
      currentPage: 1,
      selectedReviewDoc: null,
      sortBy: undefined,
      showDocumentProgess: null,
      sortDesc: undefined,
      showDocumentUploadStatus: null,
      emptyTable: false,
      reviewDocs: false,
      isBusy: false,
      rows: 1,
      media: null,
      mediaType: null,
      mediaID: null,
      positionText: "",
      sharePopupTitle: "Driver Share",
      allRowsSelected: false,
      searchDescription: null,
      showDocumentModal: false,
      deleteMultipleDocumentModal: false,
      itemId: null,
      searchDays: null,
      fileName: null,
      bulkFileUploadFileName: '',
      editDocumentFlag: false,
      items: [],
      showPublicQR: null,
      reviewDocsCount: 0,
      perPage: 25,
      selectedRows: [],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      defaultFields: [
        {
          key: "selected",
          label: "",
          sortable: false,
          heading: "Select",
          visible: true,
          disabled: true,
          thStyle: { minWidth: "20px", width: "20px" },
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
          visible: true,
          disabled: true,
          field: "text",
          stickyColumn: true,
          thClass: 'font-table-weight-bold'
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "date",
          label: "Date",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "expiry_date",
          label: "Expiry Date",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "expiry_days",
          label: "Exp Days",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "driver",
          label: "Driver",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "public",
          label: "Public (QR)",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "Action",
          label: "Action",
          sortable: true,
          visible: true,
          disabled: true,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
      ],
      fields: [],
      filterFields: {
        search_field: '',
        type: '',
        expiry_days: '',
      },
      documentData: {
        name: null,
        object_id: null,
        date: new Date(),
        type: null,
        document: null,
        description: null,
        expiry_date: "",
        driver: true,
        public: false,
      },
      bulkUploadDocumentData: {
        document: null,
      },
      errors: {
        date: null,
        name: null,
        document: null,
        description: null,
      },
      bulkFileUploadErrors: {
        document: null
      },
      nameOptions: [],
    };
  },
  computed: {
    ...mapGetters(["allCompanyDocs", "company", "allDrivers", "allEquipments", "userCompany"]),
    checkFilterStatus() {
      return !Object.values(this.filterFields).every(x => x === null || x === '');
    },
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
    typeOptions() {
      const options = [
        {
          value: "",
          text: "",
        },
        {
          value: "Vehicle",
          text: "Vehicle",
        },
        {
          value: "Trailer",
          text: "Trailer",
        },
      ];

      const userRole = this.$store.state.user.user.team_member.role;

      if (userRole !== 'Maintenance' && userRole !== 'Maintenance Manager') {
        options.push({
          value: "Driver",
          text: "Driver",
        });
        options.push({
          value: "Company",
          text: "Company",
        });
      }

      return options;
    }
  },
  methods: {
    ...mapActions([
      "getCompanyDocs",
      "setLoader",
      "retrieveDocument",
      "deleteCompanyDocument",
      "setNotification",
      "updateTeamMemberDocument",
      "getAllDrivers",
      "getAllEquipments",
      "sendCompanyDocuments",
      "sendBulkCompanyDocuments",
      "checkBulkCompanyDocumentsUploadStatus",
      "updateBulkCompanyDocumentsUploadStatus",
      "checkSmartDropStatus"
    ]),
    async checkUploadStatus(model) {
      try {
        console.log(model)
        const status = await this.checkSmartDropStatus()
        if (!status) {
          if (model === 'smartDrop') this.$bvModal.show('modal-bulk-upload')
          else this.$bvModal.show('modal-smart-drop-plus')
        }
        else {
          await this.setNotification({
            msg: `${model === 'smartDrop' ? 'Smart Drop' : 'Smart Drop +'} is in use, please try later!`,
            type: "error",
            color: "red",
          });
        }
      }
      catch (e) {
        console.log(e)
      }
    },
    async dimissUploadStatus() {
      try {
        this.setLoader(true)
        await this.updateBulkCompanyDocumentsUploadStatus({
          id: this.showDocumentUploadStatus.id,
          payload: { dismissed: true }
        })
        await this.setNotification({
          msg: "Dimissed Successfully",
          type: "success",
          color: "green",
        });
        this.showDocumentUploadStatus = null
        this.setLoader(false)
      }
      catch (e) {
        this.setLoader(false)
        console.log(e)

      }
    },
    resetTableFields() {
      localStorage.setItem("documentTableFields", JSON.stringify(this.defaultFields))
      this.fields = JSON.parse(JSON.stringify(this.defaultFields));
    },
    async resetFilterFields() {
      this.filterFields = {
        search_field: '',
        type: '',
        expiry_days: '',
      }
      await this.getAllDocs();
    },
    async updateFields() {
      this.filterFields = {
        search_field: '',
        type: '',
        expiry_days: '',
      }
      localStorage.setItem("documentTableFields", JSON.stringify(this.fields))
      await this.getAllDocs();
    },
    onRowSelected(items) {
      this.selectedRows = items
    },
    selectRows(checked) {
      if (checked) {
        this.$refs.tableRef.selectAllRows()
      }
      else {
        this.$refs.tableRef.clearSelected()
      }
    },
    checked(index, checked) {
      let tableRef = this.$refs.tableRef
      if (checked === true) tableRef.selectRow(index)
      else tableRef.unselectRow(index)
    },
    resetField() {
      this.$refs.bulkFile.value = null;
      this.bulkFileUploadFileName = null;
      this.document = null;
      this.bulkFileUploadErrors = {
        document: null,
      };
    },
    downloadFile(uri) {
      axios({
        method: 'get',
        url: uri,
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const parts = uri.split('/');
        const lastPart = parts[parts.length - 1];
        const fileName = lastPart.split('?')[0];
        a.download = fileName
        document.body.appendChild(a);
        a.click();
        a.remove()
        window.URL.revokeObjectURL(url);
      });
    },
    handleFileUpload() {
      this.documentData.document = this.$refs.file.files[0];
      this.fileName = this.documentData.document.name;
    },
    handleBulkFileUpload() {
      this.bulkUploadDocumentData.document = this.$refs.bulkFile.files[0];
      this.bulkFileUploadFileName = this.bulkUploadDocumentData.document.name;
    },
    async openDoc(data) {
      if (this.reviewDocs) {
        this.selectedReviewDoc = data
        console.log(data)
        this.$bvModal.show("modal-smart-drop-plus-preview");
      } else {
        this.media = data.link
        this.mediaID = data.pk
        if (data.link.includes('.pdf')) this.mediaType = 'pdf'
        else this.mediaType = 'image'
        this.$bvModal.show("media-popup");
      }
    },
    getNameItem(item) {
      this.showPublicQR = item.type === "equipment";
    },
    async searchAssets(searchText) {
      let options = []
      let url = `unit_no=${searchText}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
      const equipmentRecords = await this.getAllEquipments(url);
      if (equipmentRecords.records.length === 0 && this.$store.state.user.user.team_member.role !== 'Maintenance Manager') {
        url = `name=${searchText}&sort_field=name&sort_order=asc&&page=1&page_size=10`
        const driverRecords = await this.getAllDrivers(url);
        if (driverRecords.records?.length) {
          driverRecords.records?.map((item) => {
            options.push({
              id: `Driver-${item.id}`,
              label: item.name,
              type: "teammember",
            });
          });
        }
      }
      else {
        equipmentRecords.records.map((item) => {
          options.push({
            id: `${item.type}-${item.id}`,
            label: item.unit_no,
            type: "equipment",
          });
        });
      }
      if (this.$store.state.user.user.team_member.role !== 'Maintenance Manager') {
        this.nameOptions = this.nameOptions.concat(options).concat([{
          id: `Company-${this.userCompany}`,
          label: "Company",
          type: "company",
        }])
      }
      else this.nameOptions = options
      this.nameOptions = [...new Map(this.nameOptions.map(item => [item['id'], item])).values()]
    },
    async bulkUploadEquipment() {
      if (this.bulkUploadDocumentData.document) {
        try {
          this.setLoader(true);
          let formData = new FormData();
          formData.append("zip_file", this.bulkUploadDocumentData.document)
          formData.append("company_id", this.userCompany)
          const status = await this.sendBulkCompanyDocuments(formData);
          if (status === 'Another task is already in progress.') {
            await this.setNotification({
              msg: status,
              type: "error",
              color: "red",
            });
          }
          else {
            if (status === 'In Progress') this.showDocumentProgess = true
            this.getAllDocs();
            await this.setNotification({
              msg: "File Successfully Added. Documents will be live shortly!!",
              type: "success",
              color: "green",
            });
          }
          this.$bvModal.hide('modal-bulk-upload')
          this.setLoader(false);
          this.bulkUploadDocumentData = {
            document: null
          }
        }
        catch (error) {
          this.setLoader(false);
          if (error?.data?.error) {
            await this.setNotification({
              msg: error?.data?.error,
              type: "error",
              color: "red",
            });
          }
          if (typeof error === "object") {
            this.bulkFileUploadErrors = error;
            // eslint-disable-next-line no-ex-assign

          }
        }
      }
      else {
        this.bulkFileUploadErrors.document = ["This field is required."]
      }
    },
    async sendDocumentData() {
      if (this.documentData.date && this.documentData.name) {
        try {
          this.setLoader(true);
          let formData = new FormData();
          if (this.$refs.file.files.length === 0) {
            this.documentData.document = null;
          }
          formData.append("object_id", this.documentData.name.split("-")[1]);
          formData.append(
            "date",
            moment(this.documentData.date).format("YYYY-MM-DD")
          );
          formData.append("description", this.documentData.description);
          formData.append("type", this.documentData.type);
            formData.append("expiry_date", this.documentData.expiry_date ? this.documentData.expiry_date : '');
          if (this.documentData.document)
            formData.append(
              "document",
              new File(
                [this.documentData.document],
                this.documentData.document["name"].split(".")[0] +
                "_" + this.userCompany + "_" +
                new Date().getTime() +
                "." +
                this.documentData.document["type"].split("/").pop(),
                { type: this.documentData.document["type"] }
              )
            );
          formData.append("driver", this.documentData.driver);
          formData.append("public", this.documentData.public);
          formData.append(
            "model_type",
            this.nameOptions.filter(
              (item) => item.id === this.documentData.name
            )[0].type
          );
          formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
          if (this.editDocumentFlag) {
            this.documentData = await this.updateTeamMemberDocument({
              id: this.documentData.id,
              payload: formData,
            });
            await this.setNotification({
              msg: "Document Successfully updated!!",
              type: "success",
              color: "green",
            });
            this.$bvModal.hide("modal-add-document");
            let color;
            let expiryColor;
            let daysDiff = "-";
            if (this.documentData.expiry_date) {
              const currentDate = new Date();
              const expiryDate = new Date(this.documentData.expiry_date);
              const diffDays = parseInt(
                (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                10
              );
              if (diffDays > 31) {
                color = "color:black";
              } else if (diffDays <= 31 && diffDays >= 1) {
                color = "color:#FFC800";
              } else color = "color:#FF0000";
              daysDiff = parseInt(
                (new Date(this.documentData.expiry_date) - new Date()) /
                (1000 * 60 * 60 * 24),
                10
              );
              if (daysDiff <= 0) {
                expiryColor = "color:red";
              } else if (daysDiff <= 30 && daysDiff >= 1) {
                expiryColor = "color:#FFC800";
              } else {
                expiryColor = "color:green";
              }
              this.allCompanyDocs[this.documentIndex] = this.documentData;
              this.items[this.documentIndex].Date = moment(
                this.documentData.date
              ).format("MMM DD, YYYY");
              this.items[this.documentIndex].type = this.documentData.type;
              this.items[this.documentIndex].Description =
                this.documentData.description;
              this.items[this.documentIndex].expiry_date = this.documentData
                .expiry_date
                ? `<span style=${color}>${moment(
                  this.documentData.expiry_date
                ).format("MMM DD, YYYY")}</span`
                : "-";
              this.items[this.documentIndex].Driver = this.documentData.driver;
              this.items[this.documentIndex].public = this.documentData.public;
              this.items[this.documentIndex].Days = daysDiff;
              this.items[this.documentIndex].color = expiryColor;
              this.editDocumentFlag = false;
            }
          } else {
            await this.sendCompanyDocuments(formData);
            this.$bvModal.hide("modal-add-document");
            await this.setNotification({
              msg: "Document Successfully added!!",
              type: "success",
              color: "green",
            });
          }
          this.getAllDocs();
          this.documentData = {
            object_id: null,
            date: null,
            name: null,
            type: null,
            document: null,
            description: null,
            expiry_date: "",
            driver: true,
            public: false,
          };
          this.fileName = null;
          this.nameOptions = []
          this.setLoader(false);
        } catch (error) {
          console.log(error)
          this.setLoader(false);
          if (typeof error === "object") {
            this.errors = error;
            // eslint-disable-next-line no-ex-assign
          }
        }
      } else {
        this.errors.date = this.documentData.date
          ? null
          : ["This field is required."];
        this.errors.name = this.documentData.name
          ? null
          : ["This field is required."];
      }
    },
    removeFile() {
      this.fileName = null;
      this.$refs.file.value = "";
    },
    addDocument() {
      this.$bvModal.show("modal-add-document");
      this.nameOptions = []
      this.fileName = null;
      this.showPublicQR = null;
      this.editDocumentFlag = false;
      this.documentData = {
        name: null,
        object_id: null,
        date: new Date(),
        type: null,
        document: null,
        description: null,
        expiry_date: "",
        driver: true,
        public: false,
      };
      this.errors = {
        date: null,
        name: null,
        document: null,
        description: null,
      };
    },
    async openLink(id) {
      const doc = await this.retrieveDocument(id);
      window.open(doc.document, "_blank");
    },
    async deleteDocumentItem(item) {
      if (item) {
        try {
          this.setLoader(true)
          await this.deleteDocument(this.itemId);
          this.setLoader(false)
        }
        catch (e) {
          this.setLoader(false)
        }
      }
      this.showDocumentModal = false;
    },
    async approveDocs() {
      let acceptedDocs = 0
      this.selectedRows.map(async (item) => {
        if (item.name !== '-') acceptedDocs++
      })
      if (acceptedDocs !== this.selectRows.length) {
        await this.setNotification({
          msg: "All documents require name.",
          type: "error",
          color: "red",
        });
        return
      }
      this.setLoader(true)
      await Promise.all(
        this.selectedRows.map(async (data) => {
          await this.updateTeamMemberDocument({
            id: data.pk,
            payload: { ...data, data_extraction_successful: true, date: moment(data.date).format("YYYY-MM-DD"), expiry_date: data.expiry ? moment(data.expiry).format("YYYY-MM-DD") : "" },
          });
        })
      ).then(async () => {
        this.setLoader(false)
        await this.setNotification({
          msg: "Document successfully updated!!",
          type: "success",
          color: "green",
        });
        await this.getAllDocs();
        this.$refs.tableRef.clearSelected()
        this.allRowsSelected = false
      })
    },
    async deleteDocumentItems(item) {
      if (item) {
        this.setLoader(true)
        await Promise.all(
          this.selectedRows.map(async (item) => {
            await this.deleteDocument(item.pk, false);
          })
        ).then(async () => {
          this.setLoader(false)
          await this.getAllDocs();
          this.$refs.tableRef.clearSelected()
          this.allRowsSelected = false
          this.deleteMultipleDocumentModal = false;
        })
      }
      else {
        this.deleteMultipleDocumentModal = false
       this.$refs.tableRef.clearSelected(); 
       this.selectedRows=[]
      }
    },
    showPopup(id) {
      this.showDocumentModal = true;
      this.itemId = id;
    },
    async deleteDocument(id, refreshDocs = true) {
      try {
        await this.deleteCompanyDocument(`${id}/?company_id=${this.userCompany}`);
        if (refreshDocs) this.getAllDocs();
        await this.setNotification({
          msg: "Document successfully deleted!!",
          type: "success",
          color: "green",
        });
        this.$bvModal.hide("media-popup");
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    editDocument(id) {
      const data = this.items.filter(
        (item) => item.pk === this.allCompanyDocs[id].id
      )[0];
      console.log(data)
      this.nameOptions.push({
        id: `${data.type}-${data.type === "Company" ? this.userCompany : data.userID}`,
        label: data.name,
        type: `${data.type === "Company" ? 'company' : data.type === "Vehicle" || data.type === "Trailer" ? 'equipment' : 'teammember'}`,
      })
      this.nameOptions = [...new Map(this.nameOptions.map(item => [item['id'], item])).values()]
      this.showPublicQR = data.type === "Vehicle" || data.type === "Trailer";
      this.editDocumentFlag = true;
      this.documentIndex = id;
      this.documentData = this.allCompanyDocs[id];
      this.documentData.name = `${data.type}-${data.type === "Company" ? this.userCompany : data.userID}`;
      if (this.documentData.document) {
        let file_name = "";
        if (typeof this.documentData.document === "string") {
          file_name = this.documentData.document;
        } else {
          file_name = this.documentData.document.name;
        }
        this.fileName = file_name.split("/").pop().split("#")[0].split("?")[0];
      } else {
        this.documentData.document = null;
        this.fileName = "";
        this.errors = {
          date: null,
          type: null,
          document: null,
          description: null,
        };
      }
      this.$bvModal.show("modal-add-document");
    },
    async updateDocumentOnToggle(data, key, value) {
      try {
        this.setLoader(true)
        data[key] = value;
        delete data.document;
        await this.updateTeamMemberDocument({
          id: data.pk,
          payload: { ...data, date: moment(data.date).format("YYYY-MM-DD"), expiry_date: data.expiry ? moment(data.expiry).format("YYYY-MM-DD") : "" },
        });
        await this.setNotification({
          msg: "Document Successfully updated",
          type: "success",
          color: "green",
        });
        this.setLoader(false)
      } catch (error) {
        console.log(error);
        this.setLoader(false)
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    async setDocumentType(review) {
      if (review) this.reviewDocs = true
      else this.reviewDocs = false
      this.currentPage = 1
      this.resetFilterFields()
    },
    async getAllDocs() {
      try {
        this.isBusy = true
        let url = ""
        Object.keys(this.filterFields).map((key) => {
          if (this.filterFields[key] !== '') {
            if (key !== 'search_field') url += `${key}=${this.filterFields[key]}&`
            else {
              let searchList = ""
              let tableFields = localStorage.getItem("documentTableFields") ? JSON.parse(localStorage.getItem("documentTableFields")) : JSON.parse(JSON.stringify(this.defaultFields));
              tableFields.filter((item) => item.visible && item.field === 'text').map(({ key }, index) => index === 0 ? searchList = key : searchList += ',' + key)
              url += `${key}=${this.filterFields[key]}&search_list=${searchList}&`
            }
          }
        })
        localStorage.setItem(
          "docs_filters",
          JSON.stringify({
            filterFields: this.filterFields
          })
        );
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
        }
        else {
          url += `sort_field=expiry_date&sort_order=asc&`
        }
        if (this.reviewDocs) url += `to_be_reviewed=True&`

        url += `page=${this.currentPage}&page_size=${this.perPage}`
        const data = await this.getCompanyDocs({ url, company_id: this.userCompany });
        this.reviewDocsCount = data.review_count
        if (data.total_entries) {
          this.rows = data.total_entries
          this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
        }
        else {
          this.rows = 0
          this.positionText = `0 to 0 of 0`
        }
        this.updateDocs();
        this.isBusy = false
      }
      catch (e) {
        this.isBusy = false
        console.log(e)
      }
    },
    async updateDocs() {
      this.items = [];
      this.allCompanyDocs.forEach((doc, id) => {
        if (
          !doc.document_of.role ||
          (doc.document_of.role &&
            (doc.document_of.role === "Driver" ||
              doc.document_of.role === "Company"))
        ) {
          let color;
          let expiryColor;
          let daysDiff = "-";
          if (doc.expiry_date) {
            const currentDate = new Date();
            const expiryDate = new Date(doc.expiry_date);
            const diffDays = parseInt(
              (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
              10
            );
            if (diffDays > 31) {
              color = "color:black";
            } else if (diffDays <= 31 && diffDays >= 1) {
              color = "color:#FFC800";
            } else color = "color:#FF0000";
            daysDiff = parseInt(
              (new Date(doc.expiry_date) - new Date()) / (1000 * 60 * 60 * 24),
              10
            );
            if (daysDiff <= 0) {
              expiryColor = "color:red";
            } else if (daysDiff <= 31 && daysDiff >= 1) {
              expiryColor = "color:#FFC800";
            } else {
              expiryColor = "color:green";
            }
          }
          this.items.push({
            name: doc.document_of.unit_no
              ? doc.document_of.unit_no
              : doc.document_of.name,
            type: doc.document_of.role
              ? doc.document_of.role
              : doc.document_of.type,
            description: doc.description,
            data_extraction_requested: doc.data_extraction_requested,
            date: moment(doc.date).format("MMM DD, YYYY"),
            expiry: doc.expiry_date,
            expiry_date: doc.expiry_date
              ? `<span style=${color}>${moment(doc.expiry_date).format(
                "MMM DD, YYYY"
              )}</span>`
              : "-",
            id: id,
            pk: doc.id,
            link: doc.document,
            color: expiryColor,
            public: doc.public,
            driver: doc.driver,
            expiry_days: daysDiff,
            userID: doc.document_of.id,
          });

        }
        else {
          console.log(doc)
        }
      });
      if (this.items.length === 0) this.emptyTable = true
      else this.emptyTable = false
    },
  },
  watch: {
    perPage: {
      async handler() {
        await this.getAllDocs();
      },
      immediate: true,
    },
    currentPage: {
      async handler() {
        await this.getAllDocs();
      },
    },
    sortBy: {
      async handler() {
        await this.getAllDocs();
      },
    },
    sortDesc: {
      async handler() {
        await this.getAllDocs();
      },
    },
    filterFields: {
      handler() {
        this.currentPage = 1
      },
      deep: true
    }
  },
  async mounted() {
    this.fields = localStorage.getItem("documentTableFields") ? JSON.parse(localStorage.getItem("documentTableFields")) : JSON.parse(JSON.stringify(this.defaultFields));
    const data = await this.checkBulkCompanyDocumentsUploadStatus()
    if (data.status === 'In Progress') this.showDocumentProgess = data
    if ((data.status === 'Completed' || data.status === 'Failed') && !data.dismissed && data.requestor === 2) this.showDocumentUploadStatus = data
  }
};
</script>
<style>
.documents-alert-section {
  background-color: white;
  color: black;
  padding: 11px 18px;
  border-radius: 5px;
  margin-bottom: 26px;
  font-weight: 500;
  border-left: 10px solid #FF0000;
}

.documents-progess-section {
  background-color: white;
  border-left: 10px solid #FFC800;
  color: black;
  padding: 11px 18px;
  border-radius: 5px;
  margin-bottom: 26px;
  font-weight: 500;
}

.error-button {
  z-index: 1;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px !important;
    width: 800px !important;
  }
}
</style>
