<template>
    <div>
        <b-modal id="modal-add-bulk-tags" no-close-on-esc centered no-close-on-backdrop hide-footer>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Add Tags
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="text-left">
                <b-form-group label="Add Tags">
                    <v-select class="f-12" :options="allTags" multiple :reduce="(option) => option.id" v-model="tagsData" />
                    <p v-if="error && error.tags" class="field-error">
                        {{ error.tags[0] }}
                    </p>
                </b-form-group>
            </div>
            <div class="text-right modal-footer border-0 p-0 mt-3">
                <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetPopup()" variant="secondary">Cancel</b-button>
                <b-button class="mx-0 mb-0 h-40px w-100px mt-0" variant="primary" @click="addTags">Save</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            tagsData: [],
            error: {}
        }
    },
    props: ["ids",'type'],
    name: "AddBulkTags",
    components: { vSelect },
    computed: {
        ...mapGetters([
            "userCompany",
            "tags"
        ]),
        allTags() {
            return this.tags.map((item) => {
                return {
                    id: item.id,
                    label: item.add_tag,
                }
            })
        }
    },
    watch: {
        async ids() {
            if (this.ids) {
                await this.getAllTags(this.userCompany);
            }
        }
    },
    methods: {
        ...mapActions(["getAllTags", "addDriverBulkTags","addEquipmentBulkTags", "setLoader", "setNotification"]),
        resetPopup() {
            this.error = {}
            this.tagsData = []
            this.$bvModal.hide('modal-add-bulk-tags')
            this.$emit('close')
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (value.length === 0) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async addTags() {
            try {
                const nullKeys = this.checkNullValues({
                    tags: this.tagsData
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    if(this.type === 'driver')
                    await this.addDriverBulkTags({
                        drivers_ids: this.ids,
                        tags_ids: this.tagsData
                    })
                    else await this.addEquipmentBulkTags({
                        equips_ids: this.ids,
                        tags_ids: this.tagsData
                    })
                    this.setLoader(false)
                    await this.setNotification({
                        msg: `Tags Added Successfully`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.$emit("updateTags")
                    this.resetPopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                this.setLoader(false)
                if (typeof error === "object") {
                    this.error = error;
                }
            }
        }
    },
}
</script>