<template>
    <b-modal id="send-invite-modal" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
        :body-class="'equipment-edit-modal'" no-close-on-esc>
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                {{ title }}
            </h5>
            <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                Close Modal
            </b-button>
        </template>
        <div class="body">
            <b-row>
                <b-col cols="12">
                    <div class="text-left">
                        <label class="state mt-4">Set Password</label>
                        <b-form-checkbox class="pl-3-5" name="check-button" switch v-model="setPassword"
                            size="lg"></b-form-checkbox>
                    </div>
                </b-col>
                <b-col cols="12" v-if="setPassword" class="mt-3">
                    <b-form-group label="Enter Password" class="text-left mb-0 password show-password-section">
                        <b-form-input v-model="password" placeholder="Enter Password" class="unit"
                        :type="showPassword ? 'text' : 'password'"
                            required></b-form-input>

                  <b-icon
                    :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"
                    @click="showPassword = !showPassword"
                  ></b-icon>
                        <p v-if="errors && errors.password" class="field-error mb-lg-3">
                            {{ errors.password[0] }}
                        </p>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <template #modal-footer>
            <div class="text-right modal-footer border-0 p-0 mx-2">
                <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="resetForm()"
                            variant="secondary">Cancel</b-button>
                <div class="d-flex align-items m-0">
                    <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary"
                                @click="sendInvite()">Invite</b-button>
                </div>
            </div>
        </template>
    </b-modal>
</template>
<script>
export default {
    name: "SendInvite",
    props: {
        title: {
            type: String,
            default: "Send Invite"
        }
    },
    data() {
        return {
            setPassword: false,
            showPassword:false,
            password: "",
            errors: {

            }
        }
    },
    methods: {
        resetForm() {
            this.setPassword = false,
                this.password = "",
                this.errors = {
                }
            this.$bvModal.hide('send-invite-modal')
        },
        sendInvite() {
            if (this.setPassword && !this.password) {
                this.errors['password'] = 'THIS FIELD IS REQUIRED.'
            }
            else this.$emit("sendInvite", this.password)
        }
    },
}
</script>