<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" :title="'Add Record'" backdrop shadow
            :visible="!!recordID" :right="true" no-header-close no-close-on-backdrop no-close-on-esc>
            <template #header="{ }" v-if="currentMaintenance">
                <div class="d-flex align-items-center w-100 justify-content-between">
                    <div class="d-flex align-items-center w-100 mr-2">
                        <div class="text-nowrap" v-if="currentMaintenance.record_no">
                            Record // {{ currentMaintenance.record_no }}
                        </div>
                        <b-button
                            class="ml-5 export pe-none d-flex justify-content-center align-items-center w-100px h-40px"
                            :class="recordPayload.status === 'New'
                                ? 'new-record'
                                : recordPayload.status === 'Urgent'
                                    ? 'urgent-record text-white'
                                    : recordPayload.status === 'Open' ? 'open-record' : 'inactive-equipment text-black'"
                            :variant="recordPayload.status === 'New' ? 'warning' : recordPayload.status === 'Urgent' ? 'danger' : recordPayload.status === 'Open' ? 'success' : 'light'">
                            <p class="mb-0">{{ recordPayload.status }}</p>
                        </b-button>
                    </div>
                    <!-- <b-dropdown id="dropdown-divider" text="text" class="load-dropdown mt-0">
                        <template slot="button-content">
                            <b-icon class="dots" icon="three-dots"></b-icon>
                        </template>
                        <b-dropdown-item-button>
                            <p class="text_secondary cursor-pointer mb-0 f-14">Delete</p>
                        </b-dropdown-item-button>
                    </b-dropdown> -->
                </div>
            </template>
            <div class="body" v-if="currentMaintenance">
                <div class="sidebar-tabs">
                    <div class="bg-white rounded">
                        <v-tabs class="team-tabs mb-0" v-model="activeTab">
                            <v-tab key='info'>Info</v-tab>
                            <v-tab key='documents'>Documents</v-tab>
                        </v-tabs>
                    </div>
                </div>
                <v-tabs-items v-model="activeTab">
                    <v-tab-item key='info'>
                        <hr class="mt-0" />
                        <div class="d-block text-center mt-4rem">
                            <b-row class="align-items-center px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Record # :" class="text-left mb-0">
                                        <b-form-input v-model="currentMaintenance.record_no" disabled type="text"
                                            placeholder="Enter Record" class="unit" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left select_arrow ">
                                        <label class="state">Status</label>
                                        <b-form-select v-model="recordPayload.status" :options="[{
                                            value: 'New',
                                            text: 'New'
                                        },
                                        {
                                            value: 'Open',
                                            text: 'Open'
                                        },
                                        {
                                            value: 'Closed',
                                            text: 'Closed'
                                        },
                                        {
                                            value: 'Urgent',
                                            text: 'Urgent'
                                        }
                                        ]" />
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left ">
                                        <label for="modal-date-datepicker" class="state">Date *</label>
                                        <b-form-datepicker id="modal-date-datepicker" class="" v-model="recordPayload.date"
                                            reset-button></b-form-datepicker>
                                        <p v-if="errors && errors.date" class="field-error">
                                            {{ errors.date[0] }}
                                        </p>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left select_arrow ">
                                        <label class="state">Unit #</label>
                                        <v-select class="bg_white w-100" v-model="recordPayload.unit_no"
                                            :options="nameOptions" item-value="id" item-text="label"
                                            :reduce="(option) => option.id" @search="searchAssets">
                                            <span slot="no-options">
                                                Search Unit # ...
                                            </span>
                                        </v-select>
                                        <p v-if="errors && errors.unit_no" class="field-error">
                                            {{ errors.unit_no[0] }}
                                        </p>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left ">
                                        <b-form-group label="Odometer" class="text-left">
                                            <b-form-input type="text" v-mask="numberMask" placeholder="Enter Odometer"
                                                required v-model="recordPayload.odometer"></b-form-input>
                                            <p v-if="errors && errors.odometer" class="field-error">
                                                {{ errors.odometer[0] }}
                                            </p>
                                        </b-form-group>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left ">
                                        <b-form-group label="Amount" class="text-left">
                                            <b-form-input type="text" v-mask="amountMask" placeholder="Enter Amount" required
                                                v-model="recordPayload.amount"></b-form-input>
                                            <p v-if="errors && errors.amount" class="field-error">
                                                {{ errors.amount[0] }}
                                            </p>
                                        </b-form-group>
                                    </div>
                                </b-col>
                                <b-col md="12">
                                    <div class="text-left">
                                        <label class="state">Description</label>
                                        <b-form-textarea id="textarea" class="f-14" placeholder="Enter Description" rows="5"
                                            v-model="recordPayload.description"></b-form-textarea>
                                        <p v-if="errors && errors.description" class="field-error">
                                            {{ errors.description[0] }}
                                        </p>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </v-tab-item>
                    <v-tab-item key='documents'>
                        <div class="d-block body">
                            <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
                                <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Documents</h3>
                                <div class="d-flex align-items-center justify-content-end cursor-pointer"
                                    @click="addDocument">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z"
                                            fill="#367BF6" />
                                        <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6"
                                            stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <p class="text_primary ml-2 mb-0 fw-600">Add Document</p>
                                </div>
                            </div>
                            <b-table show-empty responsive :fields="fields" :items="items" hover
                                :current-page="currentPage">
                                <template #cell(name)="data">
                                    <a :href="`#${data.value
                                        .replace(/[^a-z]+/i, '-')
                                        .toLowerCase()}`">{{ data.value }}</a>
                                </template>
                                <template #cell(description)="data">
                                    <span class="item-description" v-if="data.value && data.value !== 'null'">{{ data.value
                                    }}</span>
                                    <span v-else>-</span>
                                </template>
                                <template #cell(expiry_date)="data">
                                    <span v-html="data.value"></span>
                                </template>
                                <template #cell(driver)="data">
                                    <b-form-checkbox :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                        $store.state.user.user.team_member.role !== 'Operations'" v-model="data.value"
                                        name="check-button" switch @change="
                                            updateDocumentOnToggle(data.item.id, 'driver', data.value)
                                            " size="lg" class="ml-2" />
                                </template>
                                <template #cell(action)="data">
                                    <b-icon v-if="data.item.link" style="fill: #2D69F6" class="mr-4" icon="eye-fill"
                                        @click="openDoc(data.item.pk)" />
                                    <b-icon v-if="$store.state.user.user.team_member.role === 'Admin' ||
                                        $store.state.user.user.team_member.role === 'Operations'"
                                        style="fill: limegreen" class="mr-4" icon="pencil"
                                        @click="editDocument(data.item.id)" />
                                    <b-icon style="fill:#FF0000" icon="trash-fill" v-if="$store.state.user.user.team_member.role === 'Admin' ||
                                        $store.state.user.user.team_member.role === 'Operations'"
                                        @click="showPopup(data.item.pk)" />
                                </template>
                            </b-table>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
                <div class="sidebar-footer position-fixed">
                    <div class="d-flex align-items-center justify-content-end">
                        <div class="text-right modal-footer border-0 p-0">
                            <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="resetForm"
                                variant="secondary">Close</b-button>
                            <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary"
                                @click="updateMaintenance()">Save</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-sidebar>
        <b-modal id="modal-document" centered hide-footer @shown="resetField" no-close-on-backdrop :body-class="'p-4'">
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    {{ editDocumentFlag ? "Edit Document" : "Add Document" }}
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="d-block text-center">
                <b-row>
                    <b-col lg="6" md="8" sm="12">
                        <div class="text-left mb-3">
                            <label for="modal-datepicker" class="state">Date</label>
                            <b-form-datepicker id="modal-datepicker" class="" v-model="documentData.date"
                                reset-button></b-form-datepicker>
                            <p v-if="errors && errors.date" class="field-error">
                                {{ errors.date[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Description</label>
                            <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                                v-model="documentData.description"></b-form-textarea>
                            <p v-if="errors && errors.description" class="field-error">
                                {{ errors.description[0] }}
                            </p>
                        </div>
                    </b-col>
                    <b-col lg="6" md="8" sm="12">
                        <div class="upload-btn-wrapper">
                            <b-img :src="require('@/assets/drop.svg')"></b-img>
                            <button v-if="!fileName" class="foo">
                                Drop your document here, or browse
                            </button>
                            <button v-else class="foo">{{ fileName }}</button>
                            <p v-if="fileName"
                                class="mb-0 field-error cursor-pointer font-12 position-relative error-button"
                                @click="removeFile()">
                                Delete
                            </p>
                            <input ref="docFile" type="file" name="docFile" v-on:change="handleFileUpload"
                                accept=".pdf, .png, .jpg, .jpeg" />
                            <p v-if="errors && errors.document" class="field-error">
                                {{ errors.document[0] }}
                            </p>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="text-right modal-footer border-0 p-0">
                <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="$bvModal.hide('modal-document')"
                    variant="secondary">Cancel</b-button>
                <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="sendDocumentData">Save</b-button>
            </div>
        </b-modal>
        <DeleteModal v-if="showDocumentModal" @confirm="deleteDocumentItem($event)" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import DeleteModal from "@/components/DeleteModal.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import * as moment from "moment-timezone";
const numberMask = createNumberMask({
    prefix: "",
    allowDecimal: true,
    includeThousandsSeparator: true,
});
const amountMask = createNumberMask({
    prefix: "",
    suffix:".00",
    allowDecimal: true,
    includeThousandsSeparator: true,
});
export default {
    name: "EditRecord",
    components: { DeleteModal, vSelect },
    data() {
        return {
            numberMask,
            amountMask,
            activeTab: "info",
            itemId: null,
            showDocumentModal: false,
            currentPage: 1,
            fields: [
                {
                    key: "Description",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
            editDocumentFlag: false,
            documentIndex: null,
            documentData: {
                object_id: null,
                date: null,
                document: null,
                description: "",
            },
            items: [],
            emptyTable: false,
            nameOptions: [],
            recordPayload: {
                date: new Date(),
                unit_no: null,
                description: null,
                amount: null,
                odometer: null,
                status: "Closed"
            },
            schedulePayload: {
                unit_no: null,
                due_date: null,
                description: null,
            },
            fileName: null,
            errors: {
                date: null,
                document: null,
                description: null,
            }
        }
    },
    props: ['recordID'],
    methods: {
        ...mapActions(['retrieveMaintenanceDocument', 'deleteMaintenanceDocument', 'updateMaintenanceDocument', 'getMaintenanceRecord', 'setLoader', "getAllEquipments", "patchMaintenance", 'setNotification', 'sendMaintenanceDocument']),
        async openLink(id) {
            console.log(id)
            const doc = await this.retrieveMaintenanceDocument({ id: this.currentMaintenance.id, doc: id })
            window.open(doc.document, "_blank");
        },
        showPopup(id) {
            this.showDocumentModal = true;
            this.itemId = id;
        },
        getFileNameFromUrl(url) {
            let urlObj = new URL(url);
            let pathname = urlObj.pathname;
            let segments = pathname.split('/');
            let fileName = segments.pop();
            return fileName;
        },
        editDocument(ind) {
            this.editDocumentFlag = true;
            this.documentIndex = ind;
            const { id, date_created, document, description } = this.currentMaintenance.documents[ind];
            this.documentData = {
                date: date_created,
                document,
                description,
                id
            }
            if (this.documentData.document) {
                this.fileName = this.getFileNameFromUrl(this.documentData.document)
            } else {
                this.documentData.document = null;
                this.fileName = "";
                this.errors = {
                    date: null,
                    document: null,
                    description: null,
                };
            }
            this.$bvModal.show("modal-document");
        },
        deleteDocumentItem(item) {
            if (item) {
                this.deleteDocument(this.itemId);
            }
            this.showDocumentModal = false;
        },
        async deleteDocument(id) {
            try {
                this.setLoader(true)
                await this.deleteMaintenanceDocument({ id: this.currentMaintenance.id, doc: `${id}/?company_id=${this.userCompany}` });
                const index = this.items.findIndex((doc) => doc.pk === id)
                this.itemId = null
                this.items.splice(index, 1)
                await this.setNotification({
                    msg: "Document successfully deleted!!",
                    type: "success",
                    color: "green",
                });
                // await this.getMaintenanceRecord(this.recordID);
                this.setLoader(false)
                this.$bvModal.hide("modal-document");
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        resetField() {
            this.$refs.docFile.value = null;
        },
        handleFileUpload() {
            this.documentData.document = this.$refs.docFile.files[0];
            this.fileName = this.documentData.document.name;
        },
        updateDocs() {
            this.items = [];
            this.currentMaintenance.documents.forEach((doc, id) => {
                this.items.push({
                    id: id,
                    pk: doc.id,
                    Date: moment(doc.date).format("MMM DD, YYYY"),
                    Description: doc.description,
                });
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
            });
        },
        async sendDocumentData() {
            try {
                this.setLoader(true);
                let formData = new FormData();
                if (this.$refs.docFile.files.length === 0) {
                    this.documentData.document = null;
                }
                formData.append(
                    "date",
                    moment(this.documentData.date).format("YYYY-MM-DD")
                );
                formData.append("description", this.documentData.description);
                if (this.documentData.document)
                    formData.append(
                        "document",
                        new File(
                            [this.documentData.document],
                            this.documentData.document["name"].split(".")[0] +
                            "_" + this.userCompany + "_" +
                            new Date().getTime() +
                            "." +
                            this.documentData.document["type"].split("/").pop(),
                            { type: this.documentData.document["type"] }
                        )
                    );
                formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
                if (this.editDocumentFlag) {
                    const { id } = this.documentData
                    this.documentData = await this.updateMaintenanceDocument({
                        doc: id,
                        formData,
                        id: this.currentMaintenance.id
                    });
                    await this.setNotification({
                        msg: "Document Successfully updated!!",
                        type: "success",
                        color: "green",
                    });
                    this.currentMaintenance.documents[this.documentIndex] = this.documentData;
                    this.items[this.documentIndex].Date = moment(
                        this.documentData.date
                    ).format("MMM DD, YYYY");
                    this.items[this.documentIndex].Description =
                        this.documentData.description;
                    this.editDocumentFlag = false;
                    this.updateDocs();
                    this.documentData = {
                        object_id: null,
                        date: null,
                        document: null,
                        description: "",
                    };
                    this.fileName = null;
                    this.$bvModal.hide("modal-document");
                } else {
                    if (this.documentData.date) {
                        await this.sendMaintenanceDocument({ formData, id: this.currentMaintenance.id });
                        await this.getMaintenanceRecord(this.recordID);
                        this.updateDocs();
                        await this.setNotification({
                            msg: `Document Successfully ${this.editDocumentFlag ? "updated" : "added"
                                }!!`,
                            type: "success",
                            color: "green",
                        });
                        this.documentData = {
                            object_id: null,
                            date: null,
                            document: null,
                            description: "",
                        };
                        this.fileName = null;
                        this.$bvModal.hide("modal-document");
                    } else {
                        this.errors.date = this.documentData.date
                            ? null
                            : ["This field is required."];
                        // this.errors.description = this.documentData.description? null: ['This field is required.']

                        console.log(this.errors);
                    }
                }
                this.setLoader(false);
            } catch (error) {
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        resetForm() {
            this.recordPayload = {
                date: new Date(),
                unit_no: null,
                description: null,
                amount: null,
                odometer: null,
                status: "Closed"
            }
            this.$emit("resetMaintenance", true)
        },
        addDocument() {
            this.fileName = null;
            this.editDocumentFlag = false;
            this.$bvModal.show("modal-document");
            this.documentData = {
                object_id: null,
                date: new Date(),
                document: null,
                description: null,

            };
            this.errors = {
                date: null,
                document: null,
                description: null,
            };
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (
                    !value &&
                    key !== "document" &&
                    key.toLowerCase() !== "odometer" &&
                    key !== "amount"
                ) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async updateMaintenance() {
            try {
                const nullKeys = this.checkNullValues(this.recordPayload, "maintenance");
                let formData = new FormData();
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    formData.append("unit_no", this.recordPayload.unit_no);
                    formData.append(
                        "date",
                        moment(this.recordPayload.date).format("YYYY-MM-DD")
                    );
                    formData.append(
                        "amount",
                        this.recordPayload.amount
                            ? parseFloat(this.recordPayload.amount).toFixed(2)
                            : 0
                    );
                    formData.append("description", this.recordPayload.description);
                    formData.append('status', this.recordPayload.status)
                    formData.append(
                        "odometer",
                        this.recordPayload.odometer
                            ? this.recordPayload.odometer.toString().replace(/,/g, "")
                            : ""
                    );
                    await this.patchMaintenance({
                        id: this.currentMaintenance.id,
                        payload: formData,
                    });
                } else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
                this.fileName = null;
                await this.setNotification({
                    msg: `Record successfully updated`,
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
                this.$emit("recordUpdated", true)
                this.resetForm()
                this.nameOptions = []
            } catch (error) {
                this.setLoader(false)
                if (typeof error === "object") {
                    console.log(error);
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        async searchAssets(searchText) {
            let options = []
            let url = `unit_no=${searchText}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            const equipmentRecords = await this.getAllEquipments(url);
            equipmentRecords.records.map((item) => {
                options.push({
                    id: `${item.id}`,
                    label: item.unit_no,
                    type: "equipment",
                });
            });
            this.nameOptions = options
        }

    },
    computed: {
        ...mapGetters(['currentMaintenance', 'userCompany'])
    },
    watch: {
        async recordID() {
            if (this.recordID) {
                this.activeTab = 'info'
                try {
                    this.setLoader(true)
                    await this.getMaintenanceRecord(this.recordID);
                    this.recordPayload = {
                        date: this.currentMaintenance.date,
                        unit_no: this.currentMaintenance.unit_no.id,
                        description: this.currentMaintenance.description,
                        amount: this.currentMaintenance.amount,
                        odometer: this.currentMaintenance.odometer ? this.currentMaintenance.odometer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                        status: this.currentMaintenance.status
                    }
                    this.nameOptions.push({
                        id: this.currentMaintenance.unit_no.id,
                        label: this.currentMaintenance.unit_no.unit_no,
                        type: "equipment",
                    })
                    this.updateDocs();
                    this.setLoader(false)
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            }
        }
    },
}
</script>