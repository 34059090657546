<template>
  <b-container fluid class="mt-2 px-1 px-sm-3 pb-3 team-page">
    <b-row>
      <b-col cols="" class="">
        <b-row>
          <b-col cols="">
            <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
              <h2 class="text-left">Drivers</h2>
              <div class="d-flex justify-content-between justify-content-sm-center flex-wrap" v-if="$store.state.user.user.team_member.role === 'Admin' ||
                $store.state.user.user.team_member.role === 'Operations'">
                <b-button @click="allowAddTeamMember = true"
                  class="export equipment d-flex justify-content-center align-items-center mx-1 my-1 w-fit-content"
                  variant="primary">
                  <b-icon icon="plus" aria-hidden="true"></b-icon>
                  <p class="mb-0 ml-2 text-white">Add Driver</p>
                </b-button>

                <b-dropdown id="dropdown-divider" text="text" class="load-dropdown">
                  <template slot="button-content">
                    <b-icon class="dots" icon="three-dots"></b-icon>
                  </template>
                  <b-dropdown-item-button @click="downloadExcel" class="mb-2">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Export CSV</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button class="mb-2" @click="$bvModal.show('modal-bulk-upload')">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Import CSV</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button class="mb-2" v-if="$store.state.user.user.team_member.role === 'Admin'"
                    @click="$bvModal.show('modal-set-password')">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Set Password</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button :class="selectedRows.length ? 'mb-2' : 'mb-0'"  v-if="$store.state.user.user.team_member.role === 'Admin'"
                    @click="$bvModal.show('send-invite-modal')">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Send Invite</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button v-if="($store.state.user.user.team_member.role === 'Admin' && selectedRows.length)"
                    @click="$bvModal.show('modal-add-bulk-tags')">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Add Tags</p>
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="data-table team-data-table pt-3 pt-sm-4 bg-white rounded table-custom-border">
              <div class="table_row team_table_row">
                <div class="equipment-border filter-box">
                  <div class="d-flex mb-4 px-3 px-sm-4">
                    <b-form-group class="text-left label font-weight-normal search-field mb-0">
                      <b-input-group class="username-input-field">
                        <b-form-input type="text" placeholder="Search" v-model="filterFields.search_field"
                          class="unit f-12" @keyup.enter="getTeamMemberData()"></b-form-input>
                        <b-input-group-append>
                          <b-button variant="primary" @click="getTeamMemberData()">
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                    <div class="ml-auto"
                      v-if="$store.state.user.user && ($store.state.user.user.team_member.role !== 'Maintenance' && $store.state.user.user.team_member.role !== 'Maintenance Manager')">
                      <b-dropdown id="dropdown-divider" ref="filterDropdown"
                        class="filter-section table-columns-dropdown mt-0"
                        :class="checkFilterStatus ? 'active-dropdown' : ''">
                        <template slot="button-content">
                          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8.74045 19C8.55379 19 8.37477 18.9256 8.24278 18.7933C8.11079 18.6609 8.03663 18.4813 8.03663 18.2941V11.5071L1.51223 4.31059C1.2554 4.02674 1.08624 3.67422 1.02528 3.29583C0.964316 2.91743 1.01418 2.52942 1.16881 2.17889C1.32344 1.82836 1.57619 1.53038 1.89639 1.32112C2.21659 1.11185 2.59047 1.0003 2.97266 1H18.0273C18.4095 1.0003 18.7834 1.11185 19.1036 1.32112C19.4238 1.53038 19.6766 1.82836 19.8312 2.17889C19.9858 2.52942 20.0357 2.91743 19.9747 3.29583C19.9138 3.67422 19.7446 4.02674 19.4878 4.31059L12.9634 11.5071V15.4706C12.9633 15.5763 12.9395 15.6807 12.8939 15.776C12.8482 15.8713 12.7817 15.9551 12.6994 16.0212L9.18034 18.8447C9.05557 18.9451 8.90042 18.9999 8.74045 19ZM2.97266 2.41176C2.86314 2.41204 2.75604 2.44414 2.66434 2.50419C2.57263 2.56424 2.50024 2.64966 2.45594 2.75011C2.41163 2.85056 2.3973 2.96174 2.41468 3.07019C2.43207 3.17864 2.48042 3.27971 2.55389 3.36118L9.26128 10.7729C9.3762 10.8997 9.44123 11.064 9.44427 11.2353V16.8259L11.5557 15.1176V11.2353C11.5553 11.0591 11.6206 10.8892 11.7387 10.7588L18.4461 3.34706C18.5152 3.26523 18.5598 3.16547 18.5748 3.0593C18.5897 2.95312 18.5744 2.84488 18.5306 2.74706C18.4868 2.64924 18.4163 2.56585 18.3273 2.50652C18.2382 2.44719 18.1342 2.41434 18.0273 2.41176H2.97266Z"
                              stroke-width="0.2" />
                          </svg>

                        </template>
                        <div class="filter-section-items">
                          <p class="font-weight-bold mb-2 mt-2 heading">Filter</p>
                          <b-form-group label="Type" v-if="checkTableField('driver_type')"
                            class="text-left select_arrow label">
                            <b-form-select class="input f-12" :options="[
                              {
                                value: '',
                                text: '',
                              },
                              {
                                value: 'Company',
                                text: 'Company',
                              },
                              {
                                value: 'Owner Operator',
                                text: 'Owner Operator',
                              },

                            ]" v-model="filterFields.driver_type" @change="getTeamMemberData()"></b-form-select>
                          </b-form-group>
                          <b-form-group label="Docs" v-if="checkTableField('num_docs')"
                            class="text-left select_arrow label mb-3">
                            <b-form-select type="text" class="input f-12" :options="[
                              {
                                value: '',
                                text: '',
                              },
                              {
                                value: 'True',
                                text: 'Yes',
                              },
                              {
                                value: 'False',
                                text: 'No',
                              },
                            ]" v-model="filterFields.doc" @change="getTeamMemberData()"></b-form-select>
                          </b-form-group>
                          <b-form-group label="Tags" class="text-left w-equipment-box mb-3">
                            <v-select multiple :options="allTags" class="f-12" :reduce="(option) => option.id"
                              v-model="filterFields.tags" @input="getTeamMemberData()" />
                          </b-form-group>
                          <b-form-group label="App User" class="text-left select_arrow label mb-3">
                            <b-form-select type="text" class="input f-12" v-model="filterFields.app_user"
                              @change="getTeamMemberData()" :options="appUserOptions"></b-form-select>
                          </b-form-group>
                          <b-form-group label="Login" class="text-left select_arrow label mb-3">
                            <b-form-select type="text" class="input f-12" v-model="filterFields.last_login"
                              @change="getTeamMemberData()" :options="[
                                {
                                  value: '',
                                  text: '',
                                },
                                {
                                  value: 'True',
                                  text: 'Yes',
                                },
                                {
                                  value: 'False',
                                  text: 'No',
                                },
                              ]"></b-form-select>
                          </b-form-group>
                          <b-form-group label="Status" class="text-left select_arrow label w-equipment-box mb-3">
                            <b-form-select v-model="filterFields.status" class="input f-12" :options="statusOptions"
                              @change="getTeamMemberData()" />
                          </b-form-group>
                        </div>
                        <div class="d-flex align-items-center justify-content-between buttons-section">
                          <b-button variant="primary" @click="resetFilterFields()">Reset</b-button>
                          <b-button class="text-dark btn-secondary-2"
                            @click="$refs.filterDropdown.hide()">Close</b-button>
                        </div>
                      </b-dropdown>
                      <b-dropdown id="dropdown-divider" ref="tableDropdown" class="table-columns-dropdown ml-2 mt-0">
                        <template slot="button-content">
                          <svg width="18" height="20" class="dots" viewBox="0 0 18 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <mask id="path-1-inside-1_4223_1514" fill="white">
                              <rect width="4" height="20" rx="1" />
                            </mask>
                            <rect width="4" height="20" rx="1" stroke="#2D69F6" stroke-width="3"
                              mask="url(#path-1-inside-1_4223_1514)" />
                            <mask id="path-2-inside-2_4223_1514" fill="white">
                              <rect x="7" width="4" height="20" rx="1" />
                            </mask>
                            <rect x="7" width="4" height="20" rx="1" stroke="#2D69F6" stroke-width="3"
                              mask="url(#path-2-inside-2_4223_1514)" />
                            <mask id="path-3-inside-3_4223_1514" fill="white">
                              <rect x="14" width="4" height="20" rx="1" />
                            </mask>
                            <rect x="14" width="4" height="20" rx="1" stroke="#2D69F6" stroke-width="3"
                              mask="url(#path-3-inside-3_4223_1514)" />
                          </svg>

                        </template>
                        <div class="checkbox-section">
                          <p class="font-weight-bold mb-2 mt-2 heading">Columns</p>
                          <draggable v-model="fields" :element="'div'" ghost-class="ghost">
                            <div
                              :class="`align-items-center justify-content-between mb-3 cursor-pointer ${field.key === '#' ? 'd-none' : 'd-flex'}`"
                              v-for="(field, index) in fields" :key="index">
                              <b-form-checkbox :id="'checkbox-' + index" v-model="field.visible"
                                :disabled="field.disabled" @change="updateFields()" :name="'checkbox-' + index"
                                @click.native.capture.stop>
                                {{ field.label }}
                              </b-form-checkbox>
                              <svg width="19" height="7" viewBox="0 0 19 7" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="19" height="2" rx="1" fill="black"></rect>
                                <rect y="5" width="19" height="2" rx="1" fill="black"></rect>
                              </svg>

                            </div>
                          </draggable>
                        </div>
                        <div class="d-flex align-items-center justify-content-between buttons-section">
                          <b-button variant="primary" @click="resetTableFields()">Reset</b-button>
                          <b-button class="text-dark btn-secondary-2" @click="$refs.tableDropdown.hide()">Close</b-button>
                        </div>
                      </b-dropdown>
                    </div>
                  </div>
                  <!-- <div class="d-flex">
                                        <b-form-group label="Groups" class="text-left w-equipment-box"
                                            :style="{ width: `${widthBox}px` }">
                                            <v-select multiple class="mb-4" :options="teamDispatchGroups"
                                                v-model="searchGroups" :reduce="(option) => option.id" />
                                        </b-form-group>
                                       
                                    </div> -->
                </div>

                <b-table ref="tableRef" class="mt-2" responsive :fields="visibleFields" :items="items" hover :current-page="currentPage"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
                  selectable select-mode="multi" no-select-on-click
                      @row-selected="onRowSelected" :show-empty="emptyTable"
                  >
                  <template #head(selected)="" v-if="$store.state.user.user.team_member.role === 'Admin' ||
                        $store.state.user.user.team_member.role === 'Operations'">
                        <b-form-checkbox class="pl-0 w-fit-content" @change="selectRows($event)"
                          v-model="allRowsSelected">
                        </b-form-checkbox>
                      </template>
                      <template #cell(selected)="data" v-if="$store.state.user.user.team_member.role === 'Admin' ||
                        $store.state.user.user.team_member.role === 'Operations'">
                        <b-form-checkbox class="pl-0 w-fit-content" @change="checked(data.index, data.rowSelected)"
                          v-model="data.rowSelected">
                        </b-form-checkbox>
                      </template>
                  <template #cell(#)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <b-img class="rounded-circle small_img" style="width: 40px; height: 40px; margin: 3px" :src="data.item.Image
                      ? data.item.Image
                      : require('@/assets/user.png')
                      " />
                  </template>
                  <template #cell(tags)="data">
                    <div v-if="data.value.length" class="d-flex align-items-center">
                      <div class="table-tags mr-2">{{ data.value[0]}}</div>
                      <v-app>
                    <v-tooltip bottom v-if="data.value.length > 1">
                        <template #activator="{ on, attrs }">
                          <div class="table-tags"  v-on="on" v-bind="attrs">+{{ data.value.length - 1}}</div>
                        </template> <span v-html="data.value.slice(1).join('<br>')"></span>
                    </v-tooltip>
                </v-app>
                </div>
                <p class="mb-0" v-else>-</p>
                  </template>
                  <template #cell(name)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <div>
                      <p class="text_primary cursor-pointer mb-0"
                        v-if="$store.state.user.user && ($store.state.user.user.team_member.role !== 'Maintenance' && $store.state.user.user.team_member.role !== 'Maintenance Manager')"
                        @click="setDriverData(data.item.id)">{{ data.value }}
                      </p>
                      <p class="mb-0" v-else>{{ data.value }}</p>
                    </div>
                  </template>
                  <template #cell(num_docs)="data">
                    <span :style='data.item.color' class="docs-circle">{{ data.value }}</span>
                  </template>
                  <template #cell(status)="data">
                    <b-button class="export table_btn pe-none d-flex justify-content-center align-items-center" :class="data.item.status === 'Active'
                      ? 'equipment'
                      : 'inactive-equipment'
                      " :variant="data.item.status === 'Active' ? 'primary' : 'secondary'">
                      <p class="mb-0">{{ data.value }}</p>
                    </b-button>
                  </template>
                  <template #cell(app_user)="data">
                    <b-img :src="data.item.last_login !== '-' ? require('@/assets/Check.svg') :
                      require('@/assets/check-yellow.svg')
                      "></b-img>
                  </template>
                </b-table>
                <div class="entries-pagination px-3 px-sm-4">
                  <div class="d-flex align-items-center flex-wrap">
                    <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                      <b-form-select v-model="perPage" :options="pageOptions" />
                    </div>
                    <div class="pagination">
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </div>
                    <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Import CSV Modal  -->
    <b-modal id="modal-bulk-upload" centered hide-footer title="Import CSV" @shown="resetField" no-close-on-backdrop>
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Import CSV
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="d-block text-center">
        <b-row class="import-excel-modal mx-0">
          <b-col cols="12" class="px-0">
            <div class="upload-btn-wrapper mt-0">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button class="foo" v-if="!fileName">
                Drop your document here, or browse
              </button>
              <button v-else class="foo">{{ fileName }}</button>
              <input ref="docFile" type="file" name="docFile" @change="handleFileUpload" accept=".csv" />
              <p v-if="errors && errors.document" class="field-error">
                {{ errors.document }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" class="text-left cursor-pointer mt-2 pt-1 pl-1">
            <a class="download-sample-file" href="/files/DriverSample.csv" download="sample.csv">
              <b-img :src="require('@/assets/sample-file.svg')"></b-img>
              Download Sample File
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button class="mx-0 mr-3 h-40px w-100px" @click="$bvModal.hide('modal-bulk-upload')"
          variant="secondary">Cancel</b-button>
        <b-button class="mx-0 mb-0 h-40px w-100px" variant="primary" @click="bulkUploadTeamMembers">Save</b-button>
      </div>
    </b-modal>
    <!-- Set Password Modal  -->
    <b-modal id="modal-set-password" no-close-on-esc centered no-close-on-backdrop
      :footer-class="'d-block px-3 pt-0 pb-3 m-0'" title="Set Password" @shown="clearPasswordFields"
      v-if="$store.state.user.user.team_member.role === 'Admin'">
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Set Password For All Drivers
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>

      <div class="text-center">
        <b-form-group label="" class="text-left password show-password-section">
          <b-form-input :type="showPassword ? 'text' : 'password'" placeholder="Enter Password" required
            v-model="setPasswordField" />
          <b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'" @click="showPassword = !showPassword"></b-icon>
          <p v-if="errors && errors.setPasswordField" class="field-error">
            {{ errors.setPasswordField }}
          </p>
        </b-form-group>
      </div>
      <template #modal-footer>
        <div class="text-right modal-footer border-0 p-0 mx-2">
          <b-button class="mx-0 mr-3 h-40px w-100px m-0"
            @click="$bvModal.hide('modal-set-password'); showPassword = false" variant="secondary">Cancel</b-button>
          <div class="d-flex align-items m-0">
            <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary"
              @click="setPasswordforDrivers">Save</b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <EditTeamMember :driverID="driverID" :states="states" :options="options" @updateDriver="resetDriverList"
      @updateData="getTeamMemberData()" @resetDriver="driverID = ''" />
    <SendInvite v-if="!driverID" :title="'Send Invite For All Drivers'" @sendInvite="sendInviteInBulk($event)" />
    <AddTeamMember :allowAddTeamMember="allowAddTeamMember" :states="states" @close="allowAddTeamMember = false"
      @getNewData="getTeamMemberNewData($event)" />
    <AddTags :ids="selectedRows.map((data) => data.id)" v-if="selectedRows.length" @close="$refs.tableRef.clearSelected(); selectedRows=[]"
      @updateTags="getTeamMemberData()"
      :type="'driver'"
      />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { checkEmail } from "../../services/helper";
import draggable from 'vuedraggable'
import * as moment from "moment-timezone";
import EditTeamMember from "@/components/TeamMember/EditTeamMember.vue";
import SendInvite from "@/components/TeamMember/SendInvite.vue"
import AddTeamMember from "@/components/TeamMember/AddTeamMember.vue";
import AddTags from "@/components/Shared/AddTags.vue";
export default {
  name: "Team-list",
  components: {
    vSelect,
    draggable,
    EditTeamMember,
    SendInvite,
    AddTeamMember,
    AddTags
  },
  data() {
    return {
      states: [],
      countries: [],
      allowAddTeamMember: false,
      document: null,
      showAdditionalSetting: false,
      sortBy: undefined,
      emptyTable: false,
      sortDesc: undefined,
      isBusy: false,
      setPasswordField: "",
      showPassword: false,
      checkEmail,
      positionText: "",
      fileName: null,
      perPage: 10,
      currentPage: 1,
      allRowsSelected: false,
      driverID: null,
      widthBox: 100,
      items: [],
      allTags: [],
      errors: {
        document: null,
      },
      teamDispatchGroups: [],
      filterFields: {
        search_field: '',
        status: 'True',
        driver_type: '',
        last_login: '',
        app_user: '',
        doc: '',
        tags: [],
      },
      bindProps: {
        mode: "international",
        autoFormat: true,
        preferredCountries: ["US", "CA", "MX"],
        inputOptions: {
          placeholder: "Enter a phone no.",
          maxlength: 30,
        },
        dropdownOptions: {
          showSearchBox: true,
          showDialCodeInList: true,
          showFlags: true,
          showDialCodeInSelection: true,
          width: "300px",
        },
      },
      statusOptions: [
        {
          value: "",
          text: "",
        },
        {
          value: "True",
          text: "Active",
        },
        {
          value: "False",
          text: "Inactive",
        },
      ],
      appUserOptions: [
        {
          value: "",
          text: "",
        },
        {
          value: "True",
          text: "Active",
        },
        {
          value: "False",
          text: "Inactive",
        },
      ],
      defaultFields: [
      {
          key: "selected",
          label: "",
          sortable: false,
          heading: "Select",
          visible: true,
          disabled: true,
          thStyle: { minWidth: "20px", width: "20px" },
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
          tdClass: "align-middle",
          visible: true,
          disabled: true,
          field: "text",
          stickyColumn: true,
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Email",
          key: "email",
          sortable: true,
          tdClass: "align-middle",
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Username",
          key: "username",
          sortable: true,
          tdClass: "align-middle",
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Phone",
          key: "phone",
          sortable: true,
          tdClass: "align-middle",
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "License #",
          key: "license_number",
          sortable: true,
          tdClass: "align-middle",
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "State",
          key: "state",
          sortable: true,
          tdClass: "align-middle",
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Tags",
          key: "tags",
          sortable: true,
          tdClass: "align-middle",
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "default_vehicle",
          label: "Vehicle",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "default_trailer",
          label: "Trailer",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "CDL",
          key: "cdl",
          sortable: true,
          tdClass: "align-middle",
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "DOB",
          key: "dob",
          sortable: true,
          tdClass: "align-middle",
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Type",
          key: "driver_type",
          sortable: true,
          tdClass: "align-middle",
          visible: false,
          disabled: false,
          field: "dropdown",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "App",
          key: "app_user",
          sortable: true,
          tdClass: "align-middle",
          visible: true,
          disabled: true,
          field: "dropdown",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Login",
          key: "last_login",
          sortable: true,
          tdClass: "align-middle",
          visible: false,
          disabled: false,
          field: "dropdown",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "num_docs",
          label: "Docs",
          sortable: true,
          visible: true,
          disabled: false,
          field: "dropdown",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Status",
          key: "status",
          sortable: true,
          tdClass: "align-middle",
          visible: true,
          disabled: true,
          field: "dropdown",
          thClass: 'font-table-weight-bold'
        },
      ],
      maintenanceFields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          tdClass: "align-middle",
          visible: true,
          disabled: true,
          field: "text",
          stickyColumn: true,
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Phone",
          key: "phone",
          sortable: true,
          tdClass: "align-middle",
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "default_vehicle",
          label: "Vehicle",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "default_trailer",
          label: "Trailer",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Status",
          key: "status",
          sortable: true,
          tdClass: "align-middle",
          visible: true,
          disabled: true,
          field: "dropdown",
          thClass: 'font-table-weight-bold'
        },
      ],
      fields: [],
      selected: null,
      options: [
        {
          value: null,
          text: "",
        },
        // {
        //     value: "Maintenance",
        //     text: "Maintenance",
        // },
        {
          value: "Driver",
          text: "Driver",
        },
      ],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      isPhoneValid: false,
      selectedRows: [],
      rows: 1,
      newTeamMember: {
        first_name: "",
        last_name: "",
        role: "Driver",
        email: "",
        username: "",
        phone: "",
        password: "",
        inviteNewMember: false,
        license_number: "",
        license_state: {
          id: 54,
          country: 1,
        },
      },
    };
  },

  computed: {
    ...mapGetters([
      "allDrivers",
      "dispatchGroups",
      "licenseInventory",
      "company",
      "allCountries",
      "tags",
      "userCompany"
    ]),
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
    checkFilterStatus() {
      return !Object.values(this.filterFields).every(x => x === null || x === '' || (Array.isArray(x) && x.length === 0));
    }
  },
  methods: {
    ...mapActions([
      "getAllDrivers",
      "sendInvite",
      "setNotification",
      "postExcelData",
      "getDispatchGroups",
      "addBulkData",
      "getAllCountries",
      "setLoader",
      "getAllTags",
      "getDriversExportData",
      "setPasswordforAllDrivers",
      "sendBulkInvite"
    ]),
    checked(index, checked) {
      let tableRef = this.$refs.tableRef
      if (checked === true) tableRef.selectRow(index)
      else tableRef.unselectRow(index)
    },
    onRowSelected(items) {
      this.selectedRows = items
    },
    selectRows(checked) {
      if (checked) {
        this.$refs.tableRef.selectAllRows()
      }
      else {
        this.$refs.tableRef.clearSelected()
      }
    },
    async sendInviteInBulk(password) {
      try {
        this.setLoader(true)
        await this.sendBulkInvite({
          password: password ? password : null
        })
        this.setLoader(false)
        await this.setNotification({
          msg: `Invitation Sent Successfully`,
          type: "success",
          color: "green",
        });
        this.$bvModal.hide('send-invite-modal')
      }
      catch (e) {
        console.log(e)
        this.setLoader(false)
      }
    },
    async resetFilterFields() {
      this.filterFields = {
        search_field: '',
        status: 'True',
        driver_type: '',
        app_user: '',
        last_login: '',
        doc: '',
        tags: [],
      }
      await this.getTeamMemberData();
    },
    setDriverData(id) {
      this.driverID = id
    },
    resetDriverList() {
      this.driverID = ""
      this.getTeamMemberData()
    },
    async updateFields() {
      this.filterFields = {
        search_field: '',
        status: 'True',
        driver_type: '',
        app_user: '',
        last_login: '',
        doc: '',
        tags: []
      }
      localStorage.setItem("driverTableFields", JSON.stringify(this.fields))
      await this.getTeamMemberData();
    },
    resetTableFields() {
      localStorage.setItem("driverTableFields", JSON.stringify(this.defaultFields))
      this.fields = JSON.parse(JSON.stringify(this.defaultFields));
    },
    checkTableField(key) {
      return this.fields.filter((item) => item.key === key)[0]?.visible
    },
    clearPasswordFields() {
      this.setPasswordField = ''
      this.errors = {}
    },
    async setPasswordforDrivers() {
      if (this.setPasswordField) {
        this.setLoader(true)
        try {
          await this.setPasswordforAllDrivers({
            password: this.setPasswordField
          })
          this.setLoader(false)
          await this.setNotification({
            msg: `Successfully Set Password`,
            type: "success",
            color: "green",
          });
          this.showPassword = false
          this.$bvModal.hide('modal-set-password')
        }
        catch (e) {
          this.setLoader(false)
          console.log(e)
        }
      } else {
        this.errors = {
          setPasswordField: "THIS FIELD IS REQUIRED.",
        };
      }
    },
    async bulkUploadTeamMembers() {
      if (this.document) {
        this.setLoader(true)
        this.errors = {
          document: null,
        };
        try {
          let formData = new FormData();
          formData.append("file", this.document);
          formData.append("target_table", "TeamMember");
          formData.append("company_id", this.userCompany)
          const newMembers = await this.addBulkData(formData);
          if (newMembers) {
            await this.getTeamMemberData();
            await this.setNotification({
              msg: `Successfully Imported Team Members`,
              type: "success",
              color: "green",
            });
            this.$bvModal.hide("modal-bulk-upload");
            this.setLoader(false)
          }
        } catch (e) {
          this.setLoader(false)
          if ("equipment" in e) {
            await this.setNotification({
              msg: e["equipment"][0],
              type: "error",
              color: "red",
            });
          } else {
            await this.setNotification({
              msg: "The Uploaded CSV has Wrong/Missing Fields.",
              type: "error",
              color: "red",
            });
          }
        }
      } else {
        this.errors = {
          document: "This field is required.",
        };
      }
    },
    checkNum(data, phoneObject) {
      if (phoneObject.valid !== undefined) {
        this.isPhoneValid = phoneObject.valid;
      }
    },
    resetField() {
      this.$refs.docFile.value = null;
      this.fileName = null;
      this.document = null;
      this.errors = {
        document: null,
      };
    },
    handleFileUpload() {
      this.document = this.$refs.docFile.files[0];
      this.fileName = this.document.name;
    },
    async downloadExcel() {
      try {
        this.setLoader(true)
        let url = ""
        let tags = ""
        Object.keys(this.filterFields).map((key) => {
          if (this.filterFields[key] !== '' && key != 'tags') {
            if (key !== 'search_field') url += `${key}=${this.filterFields[key]}&`
            else {
              let searchList = ""
              let tableFields = localStorage.getItem("driverTableFields") ? JSON.parse(localStorage.getItem("driverTableFields")) : JSON.parse(JSON.stringify(this.defaultFields));
              tableFields.filter((item) => item.visible && item.field === 'text').map(({ key }, index) => index === 0 ? searchList = key : searchList += ',' + key)
              url += `${key}=${this.filterFields[key]}&search_list=${searchList}&`
            }
          }
          else if (key === "tags" && this.filterFields[key].length > 0) {
            this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
            url += `${key}=${tags}&`
          }
        })
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
        }
        else {
          url += `sort_field=name&sort_order=asc&`
        }
        url += "role=driver"
        const { excel_file } = await this.getDriversExportData(url)
        window.open(process.env.VUE_APP_API_URL + excel_file, "_blank");
        await this.setNotification({
          msg: `File Exported Successfully`,
          type: "success",
          color: "green",
        });
        this.setLoader(false)
      }
      catch (e) {
        this.setLoader(false)
        console.log(e)
      }
    },

    async getTeamMemberNewData(id = null) {
      this.allowAddTeamMember = false
      await this.getTeamMemberData()
      if (id) this.driverID = id
    },

    async getCountries(item) {
      await this.getAllCountries();
      this.countries = this.allCountries.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      this.getStates(this.countries[0], item);
    },
    getStates(selectedOption, item) {
      this.states = this.allCountries
        .filter((country) => country.id === selectedOption.id)[0]
        .state.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      if (this.states.length === 0) {
        this.newTeamMember.license_state.id = null;
      } else {
        if (this.newTeamMember.license_state && item) {
          this.newTeamMember.license_state.id = item;
        } else if (this.newTeamMember.license_state) {
          this.newTeamMember.license_state.id = this.states.filter(
            (state) => state.label === "-"
          )[0].id;
        }
      }
    },
    async getTeamMemberData() {
      try {
        this.isBusy = true
        let url = ""
        let tags = ""
        Object.keys(this.filterFields).map((key) => {
          if (this.filterFields[key] !== '' && key != 'tags') {
            if (key !== 'search_field') url += `${key}=${this.filterFields[key]}&`
            else {
              let searchList = ""
              let tableFields = localStorage.getItem("driverTableFields") ? JSON.parse(localStorage.getItem("driverTableFields")) : JSON.parse(JSON.stringify(this.defaultFields));
              tableFields.filter((item) => item.visible && item.field === 'text').map(({ key }, index) => index === 0 ? searchList = key : searchList += ',' + key)
              url += `${key}=${this.filterFields[key]}&search_list=${searchList}&`
            }
          }
          else if (key === "tags" && this.filterFields[key].length > 0) {
            this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
            url += `${key}=${tags}&`
          }
        })
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
        }
        else {
          url += `sort_field=name&sort_order=asc&`
        }
        url += `page=${this.currentPage}&page_size=${this.perPage}`
        const data = await this.getAllDrivers(url);
        if (data.total_entries) {
          this.rows = data.total_entries
          this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
        }
        else {
          this.rows = 0
          this.positionText = `0 to 0 of 0`
        }
        const teamMemberData = [];
        this.allDrivers.map((member) => {
          let color;
          if (member.docs.length === 0) {
            color = "background:#C4C4C4";
          }
          else if (member.docs.filter((item) => item.expiry_date && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) <= 0).length) {
            color = "background:#FF0000";
          }
          else if (member.docs.filter((item) => item.expiry_date && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) <= 31 && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) >= 1).length) {
            color = "background:#FFC800";
          }
          else {
            color = "background:#2CCE89";
          }
          const data = {
            "#": member.id || "#",
            name: `${member.user.first_name} ${member.user.last_name}`,
            username: member.user.username,
            role: member.role,
            email: member.user.email ? member.user.email : "",
            phone: member.phone ? member.phone : "-",
            num_docs: member.num_docs,
            license_number: member.license_number ? member.license_number : "-",
            cdl: member.CDL === true ? 'Yes' : member.CDL === false ? 'No' : '-',
            dob: member.dob ? moment(member.dob).format("MMM DD, YYYY") : '-',
            driver_type: member.driver_type ? member.driver_type : '-',
            state: member.license_state?.name,
            default_vehicle: member.default_vehicle ? member.default_vehicle.unit_no : "-",
            default_trailer: member.default_trailer ? member.default_trailer.unit_no : "-",
            app_user: member.user.is_active ? "Active" : "Inactive",
            status: member.status ? "Active" : "Inactive",
            id: member.id,
            Image: member.profile_picture,
            dispatch_group1: member.dispatch_group1,
            dispatch_group2: member.dispatch_group2,
            color,
            last_login: member.user.last_login ? moment(member.user.last_login).format("MMM DD, YYYY") : '-',
            tags: member.tags.map((tag)=>tag.add_tag)
          };
          teamMemberData.push(data);
        });
        this.items = teamMemberData;
        if (this.items.length === 0) this.emptyTable = true
        else this.emptyTable = false
        this.isBusy = false
      }
      catch (e) {
        this.isBusy = false
        console.log(e)
      }
    },
    getCountryName(id) {
      return this.allCountries.filter((item) => item.id === id)[0].name;
    },
  },
  watch: {
    perPage: {
      async handler() {
        await this.getTeamMemberData();
      },
      immediate: true,
    },
    currentPage: {
      async handler() {
        await this.getTeamMemberData();
      },
    },
    sortBy: {
      async handler() {
        await this.getTeamMemberData();
      },
    },
    sortDesc: {
      async handler() {
        await this.getTeamMemberData();
      },
    },
    filterFields: {
      handler() {
        this.currentPage = 1
      },
      deep: true
    },
    company: {
      async handler() {
        await this.getDispatchGroups(this.company[0].id);
        await this.getAllTags(this.company[0].id);
        this.dispatchGroups.map((item) => {
          this.teamDispatchGroups.push({
            id: item.id,
            label: item.name,
          });
        });
        this.tags.map((item) => {
          this.allTags.push({
            id: item.add_tag,
            label: item.add_tag,
          });
        });
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    try {
      this.fields = this.$store.state.user.user.team_member.role === 'Maintenance' || this.$store.state.user.user.team_member.role === 'Maintenance Manager' ? this.maintenanceFields : localStorage.getItem("driverTableFields") ? JSON.parse(localStorage.getItem("driverTableFields")) : JSON.parse(JSON.stringify(this.defaultFields));
      console.log(this.fields)
      await this.getCountries(this.newTeamMember.license_state.id);
    } catch (error) {
      console.log(error);
      if (error.status === 403) {
        await this.setNotification({
          msg: "You are not allowed to see team members.",
          type: "error",
          color: "red",
        });
      }
    }
  },
};
</script>

<style>
.modal .col-form-label {
  font-size: var(--small) !important;
}

.team-page .select_arrow {
  position: relative;
}

.team-page .select_arrow svg {
  position: absolute;
  right: 10px;
  top: 40px;
}

.font-grey {
  font-size: 12px;
  color: #000000 !important;
}

/* @media (min-width: 992px) and (max-width: 1166px){
  .team-data-table{
    overflow-x: auto;
  }
  .team_table_row{
    width: 1050px;
  }
} */

.load-dropdown,
.table-columns-dropdown {
  margin-top: 3.5px;
}

.load-dropdown .btn,
.table-columns-dropdown .btn.dropdown-toggle,
.btn-dashboard-export-table-btn {
  background: #ffffff !important;
  border: 1px solid #e3e3e3 !important;
  border-radius: 5px;
  width: 54px;
  height: 40px;
}

.load-dropdown .btn::after,
.table-columns-dropdown .btn::after {
  display: none;
}

.load-dropdown .dropdown-menu,
.table-columns-dropdown .dropdown-menu {
  border: 0;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.load-dropdown .dropdown-menu.show {
  transform: translate3d(-105px, 40px, 0px) !important;
}

.load-dropdown .dropdown-item .btn,
.table-columns-dropdown .dropdown-item .btn {
  width: 150px;
  height: 31px;
  background: #2cce89 !important;
  border: 0 !important;
  border-radius: 5px;
  font-size: 14px;
  color: #fff !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-columns-dropdown .btn .dots>rect {
  stroke: #e3e3e3 !important;
}

.load-dropdown .btn .dots {
  fill: #c4c4c4 !important;
}

.load-dropdown.show .btn,
.table-columns-dropdown.show .dropdown-toggle,
.table-columns-dropdown.active-dropdown .dropdown-toggle {
  border-color: #367BF6 !important;
}

.filter-section .btn>svg {
  stroke: #C4C4C4;
  fill: #C4C4C4;
}

.filter-section.show .btn>svg,
.load-dropdown.show .btn .dots,
.filter-section.active-dropdown .btn>svg {
  fill: #367BF6 !important;
}

.filter-section.active-dropdown .btn>svg,
.table-columns-dropdown.show .btn .dots,
.table-columns-dropdown.show .btn .dots>rect {
  stroke: #367BF6 !important;
}

.import-excel-modal {
  margin: 0 9px !important;
}

.username-input-field {
  width: 100% !important;
  height: 100% !important;
  border: none !important;
}

.input-group-text {
  background-color: white !important;
  font-size: 12px !important;
}

#modal-set-password .modal-md .modal-body {
  padding: 25px 25px 0px 25px !important;
}

@media (max-width: 575px) {
  .table-columns-dropdown .btn.dropdown-toggle {
    width: 100% !important;
  }

  .btn-dashboard-export-table-btn {
    width: 44px !important;
  }
}
</style>
