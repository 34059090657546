import Vuex from "vuex";
import Vue from "vue";

import user from "./modules/user/user";
import notification from "./modules/notification/notification";
import license from "./modules/license/license";
import team from "./modules/team/team";
import company from "./modules/company/company";
import equipment from "./modules/equipment/equipment";
import fileDrop from "./modules/fileDrop/fileDrop";
import loader from "./modules/loader/loader";
import customers from "./modules/customers/customers";
import locations from "./modules/locations/locations";
import reports from "./modules/reports/reports"
import safety from "./modules/safety/safety";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    notification,
    loader,
    license,
    team,
    company,
    equipment,
    fileDrop,
    customers,
    locations,
    reports,
    safety
  },
});
