import { getReq, postReq } from "../../../services/api";

const state = {
  licenseOptions: [],
  coupons: [],
  licenses: [],
  licenseInventory: {},
};

const getters = {
  licenseOptions: (state) => state.licenseOptions,
  coupons: (state) => state.coupons,
  licenses: (state) => state.licenses,
  licenseInventory: (state) => state.licenseInventory,
};

const actions = {
  async getLicenseInventory({ commit }) {
    //   get request
    try {
      const licenseInventory = await getReq(`app/license-inventory${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setLicenseInventory", licenseInventory);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error);
      throw error;
    }
  },
  async getLicenseOptions({ commit }) {
    //   get request
    try {
      const licenseOptions = await getReq("app/checkout/license");
      commit("setLicenseOptions", licenseOptions);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error);
      throw error;
    }
  },
  async getLicenses({ commit }) {
    //   get request
    try {
      const licenses = await getReq(`app/checkout${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);

      commit("setLicenses", licenses);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error);
      throw error;
    }
  },
  async getcoupons({ commit }) {
    //   get request
    try {
      const coupons = await getReq("app/checkout/coupon");

      commit("setCoupons", coupons);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error);
      throw error;
    }
  },
  async stripeCheckout(_, payload) {
    try {
      const response = await postReq("app/checkout", payload);
      console.log(response);
      return response;
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error;
    }
  },
};

const mutations = {
  setLicenseOptions: (state, payload) => (state.licenseOptions = payload),
  setCoupons: (state, payload) => (state.coupons = payload),
  setLicenses: (state, licenses) => (state.licenses = licenses),
  setLicenseInventory: (state, licenseInventory) =>
    (state.licenseInventory = licenseInventory),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
