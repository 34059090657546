<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" backdrop shadow :visible="!!claimID" :right="true"
            no-header-close no-close-on-backdrop no-close-on-esc>
            <template #header="{ }">
                <div class="d-flex align-items-center w-100 justify-content-between">
                    <div class="d-flex align-items-center w-100 mr-2">
                        <div class="text-nowrap" v-if="currentClaimData">
                            Claim // {{ currentClaimData.record_no }}
                        </div>
                        <b-button class="ml-5 pe-none d-flex justify-content-center align-items-center w-100px h-40px"
                            :class="claimPayload.status === 'New'
                                ? 'new-record'
                                : claimPayload.status === 'Urgent'
                                    ? 'urgent-record text-white'
                                    : claimPayload.status === 'Open' ? 'open-record' : 'inactive-equipment text-black'"
                            :variant="claimPayload.status === 'New' ? 'warning' : claimPayload.status === 'Urgent' ? 'danger' : claimPayload.status === 'Open' ? 'success' : 'light'">
                            <p class="mb-0">{{ claimPayload.status }}</p>
                        </b-button>
                    </div>
                    <b-dropdown id="dropdown-divider" text="text" class="load-dropdown mt-0">
                        <template slot="button-content">
                            <b-icon class="dots" icon="three-dots"></b-icon>
                        </template>
                        <b-dropdown-item-button @click="showDeleteClaimModal = true">
                            <p class="text_secondary cursor-pointer mb-0 f-14">Delete</p>
                        </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </template>
            <div class="body" v-if="currentClaimData">
                <div class="sidebar-tabs">
                    <div class="bg-white rounded">
                        <v-tabs class="team-tabs mb-0" v-model="activeTab">
                            <v-tab key='info'>Info</v-tab>
                            <v-tab key='contacts'>Contacts</v-tab>
                            <v-tab key='documents'>Documents</v-tab>
                        </v-tabs>
                    </div>
                </div>
                <v-tabs-items v-model="activeTab">
                    <v-tab-item key='info'>
                        <hr class="mt-0" />
                        <div class="d-block text-center mt-4rem">
                            <b-row class="align-items-center px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Record # :" class="text-left mb-0">
                                        <b-form-input v-model="claimPayload.record_no" disabled type="text"
                                            placeholder="Enter Record" class="unit" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left select_arrow">
                                        <label class="state">Status:</label>
                                        <b-form-select v-model="claimPayload.status" class="f-12" :options="[{
                                            value: 'New',
                                            text: 'New'
                                        },
                                        {
                                            value: 'Open',
                                            text: 'Open'
                                        },
                                        {
                                            value: 'Closed',
                                            text: 'Closed'
                                        },
                                        {
                                            value: 'Urgent',
                                            text: 'Urgent'
                                        }
                                        ]" />
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label for="claim-add-datepicker" class="state">Date</label>
                                    <b-form-datepicker id="claim-add-datepicker" v-model="claimPayload.date" reset-button
                                        class="f-12"></b-form-datepicker>
                                    <p v-if="errors && errors.date" class="field-error">
                                        {{ errors.date[0] }}
                                    </p>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">Time:</label>
                                    <b-form-input type="time" class="f-12" v-model="claimPayload.time"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <div class="text-left select_arrow">
                                        <label class="state">Driver:</label>
                                        <v-select class="bg_white w-100 f-12" v-model="claimPayload.driver"
                                            :options="driverOptions" item-value="id" item-text="label"
                                            :reduce="(option) => option.id" @search="searchDriver">
                                            <span slot="no-options">
                                                Search Driver ...
                                            </span>
                                        </v-select>
                                        <p v-if="errors && errors.driver" class="field-error">
                                            {{ errors.driver[0] }}
                                        </p>
                                    </div>
                                </b-col>
                                <b-col md="3" sm="12" class="mt-3 text-left">
                                    <div class="text-left select_arrow">
                                        <label class="state">Vehicle:</label>
                                        <v-select class="bg_white w-100 f-12" v-model="claimPayload.vehicle"
                                            :options="vehicleOptions" item-value="id" item-text="label"
                                            :reduce="(option) => option.id" @search="searchVehicle">
                                            <span slot="no-options">
                                                Search Vehicle ...
                                            </span>
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col md="3" sm="12" class="mt-3 text-left">
                                    <div class="text-left select_arrow">
                                        <label class="state">Trailer:</label>
                                        <v-select class="bg_white w-100 f-12" v-model="claimPayload.trailer"
                                            :options="trailerOptions" item-value="id" item-text="label"
                                            :reduce="(option) => option.id" @search="searchTrailer">
                                            <span slot="no-options">
                                                Search Trailer ...
                                            </span>
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <div class="text-left select_arrow">
                                        <label class="state">Type:</label>
                                        <b-form-select v-model="claimPayload.type" class="f-12" :options="[{
                                            value: '',
                                            text: ''
                                        },
                                        {
                                            value: 'Self',
                                            text: 'Self'
                                        },
                                        {
                                            value: 'Insurance',
                                            text: 'Insurance'
                                        },
                                        {
                                            value: 'Other',
                                            text: 'Other'
                                        },
                                        {
                                            value: 'AL',
                                            text: 'AL'
                                        },
                                        {
                                            value: 'Cargo',
                                            text: 'Cargo'
                                        },
                                        {
                                            value: 'GL',
                                            text: 'GL'
                                        },
                                        {
                                            value: 'PD',
                                            text: 'PD'
                                        },
                                        {
                                            value: 'OO PD',
                                            text: 'OO PD'
                                        },
                                        {
                                            value: 'OO NTL',
                                            text: 'OO NTL'
                                        },
                                        {
                                            value: 'OccAcc',
                                            text: 'OccAcc'
                                        },
                                        {
                                            value: 'W.C.',
                                            text: 'W.C.'
                                        }
                                        ]" />
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <div class="text-left select_arrow">
                                        <b-form-group label="Claim #:" class="text-left mb-0">
                                            <b-form-input type="text" class="f-12" placeholder="Enter Claim #"
                                                v-model="claimPayload.claim_number"></b-form-input>
                                        </b-form-group>
                                    </div>
                                </b-col>
                                <b-col md="12" class="text-left mt-3">
                                    <label class="state text-left">Notes:</label>
                                    <b-form-textarea id="textarea" class="f-12" placeholder="Enter Notes" rows="5"
                                        v-model="claimPayload.notes"></b-form-textarea>
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Accidents</h3>
                                <b-col md="12" sm="12" class="text-left mt-3">
                                    <label class="state">Accidents:</label>
                                    <v-select class="bg_white w-100 f-12" v-model="claimPayload.accident_number"
                                        :options="accidentOptions" item-value="id" item-text="label"
                                        :reduce="(option) => option.id" @search="searchAccidents">
                                        <span slot="no-options">
                                            Search Accident # ...
                                        </span>
                                    </v-select>
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4 mb-7rem">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Details</h3>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">Address:</label>
                                    <b-form-input v-model="claimPayload.address" class="f-12"
                                        placeholder="Enter Address"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">City:</label>
                                    <b-form-input v-model="claimPayload.city" class="f-12"
                                        placeholder="Enter City"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <div class="text-left select_arrow">
                                        <label class="state">State:</label>
                                        <v-select class="bg_white w-100 f-12" v-model="claimPayload.state" :options="states"
                                            item-value="id" item-text="label" :reduce="(option) => option.id">
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">Postal Code:</label>
                                    <b-form-input v-model="claimPayload.postal_code" class="f-12"
                                        placeholder="Enter Postal Code"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">Customer:</label>
                                    <b-form-input v-model="claimPayload.customer" class="f-12"
                                        placeholder="Enter Customer"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">Load #:</label>
                                    <b-form-input v-model="claimPayload.load_number" class="f-12"
                                        placeholder="Enter Load #"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Insurance:</label>
                                    <b-form-input v-model="claimPayload.insurance_name" class="f-12"
                                        placeholder="Enter Insurance Name"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Policy #’s:</label>
                                    <b-form-input v-model="claimPayload.policy_type" class="f-12"
                                        placeholder="Enter Policy #’s"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Damage Value:</label>
                                    <b-form-input v-model="claimPayload.damage_value" class="f-12"
                                        placeholder="Enter Damage Value"></b-form-input>
                                </b-col>
                            </b-row>
                        </div>
                    </v-tab-item>
                    <v-tab-item key='contacts'>
                        <div class="d-block body">
                            <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
                                <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Contacts</h3>
                                <div class="d-flex align-items-center justify-content-end cursor-pointer"
                                    @click='$bvModal.show("modal-contact")'>
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z"
                                            fill="#367BF6" />
                                        <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6"
                                            stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <p class="text_primary ml-2 mb-0 fw-600">Add Contact</p>
                                </div>
                            </div>
                        </div>
                        <b-table show-empty responsive :fields="contactFields" :items="contactItems" hover
                            :current-page="currentContactPage">
                            <template #cell(role)="data">
                                <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
                            </template>
                            <template #cell(phone)="data">
                                <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
                            </template>
                            <template #cell(email)="data">
                                <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
                            </template>
                            <template #cell(action)="data">
                                <b-icon style="fill: limegreen" class="mr-4" icon="pencil"
                                    @click="setContactData(data.item)" />
                                <b-icon style="fill:#FF0000" icon="trash-fill"
                                    @click="setDeleteContactData(data.item.id)" />
                            </template>
                        </b-table>
                    </v-tab-item>
                    <v-tab-item key='documents'>
                        <div class="d-block body">
                            <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
                                <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Documents</h3>
                                <div class="d-flex align-items-center justify-content-end cursor-pointer"
                                    @click="$bvModal.show('modal-document')">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z"
                                            fill="#367BF6" />
                                        <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6"
                                            stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <p class="text_primary ml-2 mb-0 fw-600">Add Document</p>
                                </div>
                            </div>
                            <b-table show-empty responsive :fields="documentFields" :items="documentItems" hover
                                :current-page="currentDocumentPage">
                                <template #cell(description)="data">
                                    <v-app v-if="data.value">
                                        <v-tooltip top>
                                            <template #activator="{ on, attrs }">
                                                <span class="w-fit-content item-description text-left" v-on="on"
                                                    v-bind="attrs">{{ data.value }}</span>
                                            </template>{{ formatTooltipText(data.value) }}
                                        </v-tooltip>
                                    </v-app>
                                    <p v-else>-</p>
                                </template>
                                <template #cell(action)="data">
                                    <b-icon v-if="data.item.document" style="fill: #2D69F6" class="mr-4" icon="eye-fill"
                                        @click="openDoc(data.item)" />
                                    <b-icon style="fill: limegreen" class="mr-4" icon="pencil"
                                        @click="setDocumentData(data.item)" />
                                    <b-icon style="fill:#FF0000" icon="trash-fill"
                                        @click="setDeleteDocumentData(data.item.id)" />
                                </template>
                            </b-table>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
                <div class="sidebar-footer position-fixed">
                    <div class="d-flex align-items-center justify-content-end">
                        <div class="text-right modal-footer border-0 p-0">
                            <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="resetPopup()"
                                variant="secondary">Close</b-button>
                            <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary"
                                @click="onUpdateClaim()">Save</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-sidebar>
        <Contacts @contactUpdated="getContactData" :id="claimID" :contactData="contactData" @popupClose="contactData = null"
            :addContact="addClaimContact" :updateContact="updateClaimContact" />
        <DeleteModal v-if="showDeleteContactPopup" @confirm="deleteContactItem($event)" />
        <DeleteModal v-if="showDeleteDocumentPopup" @confirm="deleteDocumentItem($event)" />
        <DeleteModal v-if="showDeleteClaimModal" @confirm="deleteClaimItem($event)" />
        <Document :id="claimID" @documentsUpdated="getDocumentData" :documentItem="documentData"
            @popupClose="documentData = null" :addDoc="addClaimDoc" :updateDoc="updateClaimDoc" :allowVideo="true" />
        <MediaPopup :media="media" :mediaID="mediaID" :mediaType="mediaType" :deleteDocItem="deleteDocumentItem" />
    </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
import DeleteModal from "@/components/DeleteModal.vue";
import Contacts from "./Contacts";
import Document from './Document.vue';
import MediaPopup from "@/components/Shared/MediaPopup.vue"

export default {
    components: { vSelect, DeleteModal, Document, Contacts, MediaPopup },
    name: "EditClaim",
    props: ['claimID'],
    computed: {
        ...mapGetters([
            "userCompany",
            "allEquipments",
            "allDrivers",
            "currentClaimData",
            "allCountries",
        ]),
    },
    data() {
        return {
            errors: {},
            contactData: null,
            documentData: null,
            states: [],
            media: null,
            mediaID: null,
            mediaType: null,
            currentDocumentPage: 1,
            showDeleteClaimModal: false,
            showDeleteContactPopup: false,
            showDeleteDocumentPopup: false,
            activeTab: "info",
            currentContactPage: 1,
            claimPayload: {
                address: "",
                record_no: "",
                city: "",
                claim_number: "",
                postal_code: "",
                state: null,
                driver: null,
                status: "Closed",
                vehicle: null,
                trailer: null,
                date: null,
                time: null,
                notes: "",
                type: "",
                policy_type: "",
                insurance_name: "",
                damage_value: "",
                accident_number: "",
                customer: "",
                load_number: ""
            },
            contactFields: [
                {
                    key: "name",
                    label: "Name",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "role",
                    label: "Role",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "phone",
                    label: "Phone",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "email",
                    label: "Email",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],

            driverOptions: [],
            vehicleOptions: [],
            trailerOptions: [],
            contactItems: [],
            documentItems: [],
            accidentOptions: [],
            documentFields: [
                {
                    label: "Description",
                    key: "description",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
        }
    },
    methods: {
        ...mapActions([
            "getAllEquipments",
            "getAllDrivers",
            "setLoader",
            "getClaimData",
            "setNotification",
            "getAllCountries",
            "updateClaim",
            "getClaimContacts",
            "getClaimDocs",
            "deleteClaimDocument",
            "addClaimDoc",
            "updateClaimDoc",
            "addClaimContact",
            "updateClaimContact",
            "deleteClaim",
            "deleteClaimContact",
            "getAllAccidentData"
        ]),
        async openDoc(data) {
            this.media = data.document
            this.mediaID = data.id
            if (data.document.includes('.pdf')) this.mediaType = 'pdf'
            else if (data.document.includes('.mp4') || data.document.includes('.mkv') || data.document.includes('.mov') || data.document.includes('.avi')) this.mediaType = 'video'
            else this.mediaType = 'image'
            this.$bvModal.show("doc-media-popup");
        },
        async deleteClaimItem(status) {
            if (status) {
                try {
                    await this.deleteClaim({
                        claimID: `${this.claimID}/?company_id=${this.userCompany}`
                    })
                    await this.setNotification({
                        msg: `Claim Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.showDeleteClaimModal = false
                    this.$emit("claimUpdated", true)
                    this.resetPopup()
                }
                catch (e) {
                    console.log(e)
                }
            } else {
                this.showDeleteClaimModal = false
            }
        },
        async openLink(link) {
            window.open(link, "_blank");
        },
        setDeleteDocumentData(id) {
            this.showDeleteDocumentPopup = true
            this.documentID = id
        },
        setDocumentData(data) {
            this.documentData = data
            this.$bvModal.show("modal-document");
        },
        formatTooltipText(text) {
            const maxLineLength = window.screen.width <= 767 ? 50 : 80;
            let formattedText = "";
            let lineLength = 0;

            text.split(' ').forEach(word => {
                if (lineLength + word.length + 1 > maxLineLength) {
                    formattedText += "\n";
                    lineLength = 0;
                }
                formattedText += (lineLength === 0 ? "" : " ") + word;
                lineLength += word.length + 1;
            });

            return formattedText;
        },
        async getDocumentData() {
            this.documentItems = []
            const data = await this.getClaimDocs({ id: this.claimID })
            data.map((item) => {
                this.documentItems.push({
                    id: item.id,
                    date: item.date_created,
                    description: item.description,
                    document: item.document
                })
            })
        },
        async deleteDocumentItem(status, id = null) {
            if (status) {
                try {
                    this.setLoader(true)
                    await this.deleteClaimDocument({
                        claimID: this.claimID,
                        documentID: `${id ? id : this.documentID}/?company_id=${this.userCompany}`
                    })
                    await this.setNotification({
                        msg: `Document Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.getDocumentData()
                    this.documentID = null
                    this.showDeleteDocumentPopup = false
                    this.setLoader(false)
                }
                catch (e) {
                    console.log(e)
                    this.setLoader(false)
                }
            } else {
                this.documentID = null
                this.showDeleteDocumentPopup = false
            }
        },

        setDeleteContactData(id) {
            this.showDeleteContactPopup = true
            this.contactID = id
        },
        async deleteContactItem(status) {
            if (status) {
                try {
                    this.setLoader(true)
                    await this.deleteClaimContact({
                        claimID: this.claimID,
                        contactID: `${this.contactID}/?company_id=${this.userCompany}`
                    })
                    await this.setNotification({
                        msg: `Contact Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.getContactData()
                    this.contactID = null
                    this.showDeleteContactPopup = false
                    this.setLoader(false)
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            } else {
                this.contactID = null
                this.showDeleteContactPopup = false
            }
        },
        setContactData(data) {
            this.contactData = data
            this.$bvModal.show("modal-contact");
        },
        async onUpdateClaim() {
            try {
                const nullKeys = this.checkNullValues({
                    driver: this.claimPayload.driver,
                    date: this.claimPayload.date,
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    await this.updateClaim({ id: this.claimID, payload: { ...this.claimPayload, accident_number: this.claimPayload.accident_number ? this.claimPayload.accident_number : '' } })
                    await this.setNotification({
                        msg: `Claim Successfully Updated`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.$emit("claimUpdated", true)
                    this.resetPopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
                console.log(error)
                this.setLoader(false);
            }
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async getCountries() {
            await this.getAllCountries();
            const countries = this.allCountries.map((item) => ({
                id: item.id,
                label: item.name,
            }));
            this.getStates(countries[0]);
        },
        getStates(selectedOption) {
            this.states = this.allCountries
                .filter((country) => country.id === selectedOption.id)[0]
                .state.map((item) => ({
                    id: item.id,
                    label: item.name,
                }));
            this.claimPayload.state = this.states.filter(
                (state) => state.label === "-"
            )[0].id;
        },
        async getContactData() {
            this.contactItems = []
            const data = await this.getClaimContacts({ id: this.claimID })
            console.log(data)
            data.map((item) => {
                this.contactItems.push({
                    id: item.id,
                    name: item.name,
                    role: item.role,
                    phone: item.phone,
                    email: item.email
                })
            })
        },
        resetPopup() {
            this.claimPayload = {
                address: "",
                city: "",
                claim_number: "",
                postal_code: "",
                record_no: "",
                state: null,
                driver: null,
                status: "Closed",
                vehicle: null,
                trailer: null,
                date: null,
                time: null,
                notes: "",
                type: "",
                policy_type: "",
                insurance_name: "",
                damage_value: "",
                accident_number: "",
                customer: "",
                load_number: ""
            },
                this.errors = {}
            this.driverOptions = [],
                this.vehicleOptions = [],
                this.trailerOptions = []
            this.accidentOptions = []
            this.$emit("resetEditClaim", true)
        },
        async searchAccidents(searchText) {
            let options = []
            const url = `sort_field=record_no&search_field=${searchText}&sort_order=desc&page=1&page_size=10`
            const data = await this.getAllAccidentData(url);
            data.records.forEach((x) => {
                options.push({
                    id: x.record_no.toString(),
                    label: x.record_no.toString(),
                });
            });
            this.accidentOptions = this.accidentOptions.concat(options)
            this.accidentOptions = [...new Map(this.accidentOptions.map(item => [item['id'], item])).values()]
        },
        async searchDriver(searchText) {
            let options = []
            const url = `name=${searchText}&page=1&page_size=10`
            await this.getAllDrivers(url);
            this.allDrivers.forEach((x) => {
                options.push({
                    id: x.id,
                    label: `${x.user.first_name} ${x.user.last_name}`,
                });
            });
            this.driverOptions = this.driverOptions.concat(options)
            this.driverOptions = [...new Map(this.driverOptions.map(item => [item['id'], item])).values()]
        },
        async searchVehicle(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Vehicle&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.vehicleOptions = this.vehicleOptions.concat(options)
            this.vehicleOptions = [...new Map(this.vehicleOptions.map(item => [item['id'], item])).values()]
        },
        async searchTrailer(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Trailer&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.trailerOptions = this.trailerOptions.concat(options)
            this.trailerOptions = [...new Map(this.trailerOptions.map(item => [item['id'], item])).values()]
        },
    },
    watch: {
        async claimID() {
            if (this.claimID) {
                this.activeTab = "info"
                try {
                    this.setLoader(true)
                    this.$bvModal.show("modal-edit-claim");
                    await this.getCountries();
                    await this.getClaimData(this.claimID)
                    this.claimPayload = {
                        address: this.currentClaimData.address,
                        record_no: this.currentClaimData.record_no,
                        city: this.currentClaimData.city,
                        claim_number: this.currentClaimData.claim_number,
                        postal_code: this.currentClaimData.postal_code,
                        state: this.currentClaimData.state,
                        driver: this.currentClaimData.driver,
                        status: this.currentClaimData.status,
                        vehicle: this.currentClaimData.vehicle,
                        trailer: this.currentClaimData.trailer,
                        date: this.currentClaimData.date,
                        time: this.currentClaimData.time,
                        notes: this.currentClaimData.notes,
                        type: this.currentClaimData.type,
                        policy_type: this.currentClaimData.policy_type,
                        insurance_name: this.currentClaimData.insurance_name,
                        damage_value: this.currentClaimData.damage_value,
                        accident_number: this.currentClaimData.accident_number,
                        customer: this.currentClaimData.customer,
                        load_number: this.currentClaimData.load_number
                    }
                    if (this.currentClaimData.driver) {
                        this.driverOptions.push({
                            id: this.currentClaimData.driver,
                            label: this.currentClaimData.driver_name,
                        })
                    }
                    if (this.currentClaimData.vehicle) {
                        this.vehicleOptions.push({
                            id: this.currentClaimData.vehicle,
                            label: this.currentClaimData.vehicle_name,
                        })
                    }
                    if (this.currentClaimData.trailer) {
                        this.trailerOptions.push({
                            id: this.currentClaimData.trailer,
                            label: this.currentClaimData.trailer_name,
                        })
                    }
                    if (this.currentClaimData.accident_number) {
                        this.accidentOptions.push({
                            id: this.currentClaimData.accident_number,
                            label: this.currentClaimData.accident_number,
                        })
                    }
                    this.getContactData()
                    this.getDocumentData()
                    this.setLoader(false)
                }
                catch (e) {
                    console.log(e)
                    this.setLoader(false)
                }
            }
        }
    }
}
</script>