<template>
  <b-modal
    id="deleteModal"
    :header-bg-variant="'white'"
    header-class="border-none"
    footer-class="border-none justify-content-center pt-0 pb-4"
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-header="{ close }">
      <b-button
        size="sm"
        class="d-none"
        variant="outline-danger"
        @click="close()"
      >
        Close Modal
      </b-button>
    </template>
    <p class="font-weight-bold f-18 text-center">
      {{ this.message }}
    </p>
    <template #modal-footer="">
      <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="cancel()"
                            variant="secondary">Cancel</b-button>
      <b-button class="h-40px px-2 m-0" :class="removeMessage === 'Deactivate' ? 'w-125px':'w-100px'" variant="danger"  @click="confirm()" v-if="!!removeMessage"> {{removeMessage}} </b-button>
      <b-button class="h-40px w-100px m-0 px-2" :class="successMessage === 'Activate' ? 'w-125px':'w-100px'" variant="success"  @click="confirm()" v-if="!!successMessage"> {{successMessage}} </b-button>

    </template>
  </b-modal>
</template>

<script>
export default {
  name: "DeleteModal",
  props: {
    message: {
      type: String,
      default: "Do you want to delete this Item?",
    },
    removeMessage:{
      type: String,
      default:"Delete"
    },
    successMessage:{
      type: String,
      default:""
    }
  },
  methods: {
    confirm() {
      this.$bvModal.hide("deleteModal");
      this.$emit("confirm", true);
    },
    cancel() {
      this.$bvModal.hide("deleteModal");
      this.$emit("confirm", false);
    },
  },
  mounted() {
    this.$bvModal.show("deleteModal");
    console.log(this.removeMessage)
  },
};
</script>

<style>
.border-none {
  border: none !important;
}

.close-btn {
  width: 100px;
  color: var(--black) !important;
  height: 36px;
}

.save-btn {
  color: #fff;
  font-size: 14px;
  width: 95px;
  height: 36px;
  background-color: var(--btn-bg-color) !important;
}
</style>
