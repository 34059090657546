<template>
  <div class="app_user_detail bg-white rounded p-4 mt-4">
    <h5 class="text-left">Pay Settings</h5>
    <b-row>
      <b-col lg="3" md="4" sm="12">
        <b-form-group label="Email" class="text-left">
          <b-form-input
            type="text"
            placeholder="Enter Email"
            v-model="driverPayInfo.setting_email1"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="4" sm="12">
        <b-form-group label="Email" class="text-left">
          <b-form-input
            type="text"
            placeholder="Enter Email"
            v-model="driverPayInfo.setting_email2"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "DriverPaySettings",
  props: ["currentTeamMember", "driverPayInfo"],
};
</script>

<style></style>
