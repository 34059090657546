var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"addTagsModal","header-bg-variant":'white',"header-class":"border-none","footer-class":"border-none justify-content-center","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('b-button',{staticClass:"d-none",attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return close()}}},[_vm._v(" Close Modal ")])]}},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"close-btn",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Close ")]),_c('b-button',{staticClass:"save-btn",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.confirmTags()}}},[_vm._v(" Add ")])]},proxy:true}])},[_c('h4',{staticClass:"text-center font-weight-bold"},[_vm._v("Add Tags")]),_c('v-app',{staticClass:"add-tags"},[_c('v-combobox',{staticClass:"tags-select",attrs:{"items":_vm.tagsData,"label":"Add Tags","menu-props":{ value: false },"multiple":"","chips":"","append-icon":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")])])]}}]),model:{value:(_vm.tagsData),callback:function ($$v) {_vm.tagsData=$$v},expression:"tagsData"}}),_c('div',{staticClass:"d-flex align-items-center flex-wrap"},_vm._l((_vm.tags),function(tag){return _c('v-chip',{key:tag.id,staticClass:"equipment-chips ml-2 width-fit-content cursor-pointer mb-2",attrs:{"append-icon":""},on:{"click":function($event){return _vm.addTag(tag.add_tag)}}},[_vm._v(" "+_vm._s(tag.add_tag)+" ")])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }