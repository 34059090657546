<template>
  <div class="data-table bg-white m-0">
    <b-row>
      <b-col cols="12">
        <div class="object_detail">
          <div class="num d-flex align-items-center justify-content-start mb-3">
            <p class="mb-0 font-weight-bold">{{ currentCustomer.name }}</p>
            <p
              class="mb-0 ml-2"
              :class="currentCustomer.status ? 'active' : 'inactive'"
            >
              {{ currentCustomer.status ? "Active" : "Inactive" }}
            </p>
          </div>
          <b-row>
            <b-col lg="6" md="8" sm="12">
              <b-form class="mb-2">
                <b-form-group label="Name" class="text-left">
                  <b-form-input
                    type="text"
                    placeholder="Enter Name"
                    v-model="currentCustomer.name"
                    required
                  ></b-form-input>
                  <p v-if="errors.name" class="field-error">
                    This field is required.
                  </p>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col lg="6" md="4" sm="12" class="text-left mb-2">
              <label>Active</label>
              <b-form-checkbox
                name="check-button"
                v-model="currentCustomer.status"
                switch
                size="lg"
              ></b-form-checkbox>
            </b-col>

            <b-col lg="3" md="6" sm="12">
              <b-form class="mb-2">
                <b-form-group label="Address" class="text-left select_arrow">
                  <b-form-input
                    type="text"
                    placeholder="Enter Address"
                    v-model="currentCustomer.address"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col lg="2" md="6" sm="12" class="text-left mb-2">
              <b-form class="mb-2">
                <b-form-group label="City" class="text-left">
                  <b-form-input
                    type="text"
                    placeholder="Enter City"
                    v-model="currentCustomer.city"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col lg="3" md="6" sm="12" class="text-left mb-2">
              <b-form class="mb-2">
                <b-form-group label="State" class="text-left">
                  <v-select
                    class="bg_white mb-3"
                    :options="states"
                    v-model="currentCustomer.state.id"
                    :reduce="(option) => option.id"
                  />
                </b-form-group>
              </b-form>
            </b-col>
            <b-col lg="2" md="6" sm="12" class="text-left mb-2">
              <b-form class="mb-2">
                <b-form-group label="Postal Code" class="text-left">
                  <b-form-input
                    type="text"
                    placeholder="Enter Postal Code"
                    v-model="currentCustomer.postal_code"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </b-col>

            <b-col lg="2" md="6" sm="12" class="text-left mb-2">
              <b-form class="mb-2">
                <b-form-group label="Country" class="text-left">
                  <v-select
                    class="bg_white mb-3"
                    v-model="currentCustomer.state.country"
                    @option:selected="getStates"
                    :options="countries"
                    :reduce="(option) => option.id"
                  />
                </b-form-group>
              </b-form>
            </b-col>
            <b-col lg="3" md="6" sm="12" class="text-left mb-2">
              <b-form class="mb-2">
                <b-form-group label="Phone" class="text-left">
                  <vue-tel-input
                    v-bind="bindProps"
                    class="w-100 f-14"
                    v-model="currentCustomer.phone"
                    @validate="checkNum"
                  />
                </b-form-group>
                <p v-if="errors.phone" class="field-error">
                  The Format is Incorrect!
                </p>
              </b-form>
            </b-col>
            <b-col lg="3" md="6" sm="12" class="text-left mb-2">
              <b-form class="mb-2">
                <b-form-group label="Billing Email" class="text-left">
                  <b-form-input
                    type="email"
                    placeholder="Enter Billing Email"
                    v-model="currentCustomer.billing_email"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col lg="3" md="6" sm="12" class="text-left mb-2">
              <label>Quick Pay</label>
              <b-form-checkbox
                name="check-button"
                v-model="currentCustomer.quick_pay"
                switch
                size="lg"
              ></b-form-checkbox>
            </b-col>
            <b-col lg="3" md="6" sm="12" class="text-left mb-2">
              <label>Factoring Loads</label>
              <b-form-checkbox
                name="check-button"
                v-model="currentCustomer.factoring_loads"
                switch
                size="lg"
              ></b-form-checkbox>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "CustomerInfoFields",
  props: ["currentCustomer", "errors"],
  components: {
    vSelect,
  },
  data() {
    return {
      countries: [],
      states: [],
      isPhoneValid: false,
      bindProps: {
        mode: "international",
        autoFormat: true,
        preferredCountries: ["US", "CA", "MX"],
        inputOptions: {
          placeholder: "Enter a phone no.",
          maxlength: 30,
        },
        dropdownOptions: {
          showSearchBox: true,
          showDialCodeInList: true,
          showFlags: true,
          showDialCodeInSelection: true,
          width: "300px",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["allCountries"]),
  },
  methods: {
    ...mapActions(["getAllCountries", "setNotification"]),
    checkNum(phoneObject) {
      if (phoneObject.valid !== undefined) {
        this.$emit("updateNumState", phoneObject.valid);
      }
    },
    async getCountries(item) {
      await this.getAllCountries();
      this.countries = this.allCountries.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      this.getStates(this.countries[0], item);
    },
    getStates(selectedOption, item) {
      this.states = this.allCountries
        .filter((country) => country.id === selectedOption.id)[0]
        .state.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      if (this.states.length === 0) {
        this.currentCustomer.state.id = null;
      } else {
        if (this.currentCustomer.state && item) {
          this.currentCustomer.state.id = item;
        } else if (this.currentCustomer.state) {
          this.currentCustomer.state.id = this.states.filter(
            (state) => state.label === "-"
          )[0].id;
        }
      }
    },
  },
  async mounted() {
    try {
      await Promise.all([
        await this.getCountries(this.currentCustomer.state.id),
      ]);
      console.log(this.errors);
    } catch (error) {
      console.log(error);
      await this.setNotification({
        msg: error,
        type: "error",
        color: "red",
      });
      this.$router.push("/");
    }
  },
};
</script>

<style></style>
