<template>
  <b-container v-if="user" fluid class="mt-2 px-1 px-sm-3 pb-3">
    <b-row>
      <b-col cols="" class="">
        <b-row>
          <b-col cols="">
            <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
              <h2 class="text-left">Dashboard</h2>
            </div>
          </b-col>
        </b-row>
        <b-row class="dashboard_total">
          <b-col lg="3" md="4" sm="6" class="px-3 pr-lg-2 mt-2 mt-lg-0">
            <div class="total_team">
              <b-img :src="require('@/assets/total_team.svg')"></b-img>
              <div>
                <h5 class="number">
                  {{ teamMembers.totalTeamMembers.role_no_drivers || 0 }}
                </h5>
                <p>Active Users</p>
              </div>
            </div>
          </b-col>
          <b-col lg="3" md="4" sm="6" class="px-3 px-lg-2 mt-2 mt-lg-0">
            <div class="total_team total_driver">
              <b-img :src="require('@/assets/total_drivers.svg')"></b-img>
              <div>
                <h5 class="number">
                  {{ teamMembers.totalDrivers.role_driver || 0 }}
                </h5>
                <p>Active Drivers</p>
              </div>
            </div>
          </b-col>
          <b-col lg="3" md="4" sm="6" class="px-3 px-lg-2 mt-2 mt-lg-0">
            <div class="total_team total_vehicle">
              <b-img :src="require('@/assets/total_vehicles.svg')"></b-img>
              <div>
                <h5 class="number">
                  {{ equipments.vehicles.vehicle_count || 0 }}
                </h5>
                <p>Active Vehicles</p>
              </div>
            </div>
          </b-col>
          <b-col lg="3" md="4" sm="6" class="px-3 pl-lg-2 mt-2 mt-lg-0">
            <div class="total_team total_trailer">
              <b-img :src="require('@/assets/total_trailers.svg')"></b-img>
              <div>
                <h5 class="number">
                  {{ equipments.trailers.trailer_count || 0 }}
                </h5>
                <p>Active Trailers</p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col lg="12" md="12" sm="12" cols="12" class="my-1">
            <div class="data-table bg-white pt-3 pt-sm-4 table-custom-border rounded-top">
              <div class="equipment-border filter-box table_row">
                <div class="d-flex px-3 px-sm-4 mb-4">
                  <b-form-group class="text-left label font-weight-normal search-field mb-0">
                    <b-input-group class="username-input-field">
                      <b-form-input type="text" placeholder="Search" v-model="filterFields.search" class="unit f-12"
                        @keyup.enter="getDocuments()"></b-form-input>
                      <b-input-group-append>
                        <b-button variant="primary" @click="getDocuments()">
                          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <div class="ml-auto">
                    <b-dropdown id="dropdown-divider" ref="filterDropdown"
                      class="filter-section table-columns-dropdown mt-0"
                      :class="checkFilterStatus ? 'active-dropdown' : ''">
                      <template slot="button-content">
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.74045 19C8.55379 19 8.37477 18.9256 8.24278 18.7933C8.11079 18.6609 8.03663 18.4813 8.03663 18.2941V11.5071L1.51223 4.31059C1.2554 4.02674 1.08624 3.67422 1.02528 3.29583C0.964316 2.91743 1.01418 2.52942 1.16881 2.17889C1.32344 1.82836 1.57619 1.53038 1.89639 1.32112C2.21659 1.11185 2.59047 1.0003 2.97266 1H18.0273C18.4095 1.0003 18.7834 1.11185 19.1036 1.32112C19.4238 1.53038 19.6766 1.82836 19.8312 2.17889C19.9858 2.52942 20.0357 2.91743 19.9747 3.29583C19.9138 3.67422 19.7446 4.02674 19.4878 4.31059L12.9634 11.5071V15.4706C12.9633 15.5763 12.9395 15.6807 12.8939 15.776C12.8482 15.8713 12.7817 15.9551 12.6994 16.0212L9.18034 18.8447C9.05557 18.9451 8.90042 18.9999 8.74045 19ZM2.97266 2.41176C2.86314 2.41204 2.75604 2.44414 2.66434 2.50419C2.57263 2.56424 2.50024 2.64966 2.45594 2.75011C2.41163 2.85056 2.3973 2.96174 2.41468 3.07019C2.43207 3.17864 2.48042 3.27971 2.55389 3.36118L9.26128 10.7729C9.3762 10.8997 9.44123 11.064 9.44427 11.2353V16.8259L11.5557 15.1176V11.2353C11.5553 11.0591 11.6206 10.8892 11.7387 10.7588L18.4461 3.34706C18.5152 3.26523 18.5598 3.16547 18.5748 3.0593C18.5897 2.95312 18.5744 2.84488 18.5306 2.74706C18.4868 2.64924 18.4163 2.56585 18.3273 2.50652C18.2382 2.44719 18.1342 2.41434 18.0273 2.41176H2.97266Z"
                            stroke-width="0.2" />
                        </svg>

                      </template>
                      <div class="filter-section-items">
                        <p class="font-weight-bold mb-2 mt-2 heading">Filter</p>

                        <b-form-group label="Type" class="text-left select_arrow label mb-0">
                          <b-form-select type="text" class="mb-4 input f-12" required v-model="filterFields.type"
                            :options="typeOptions" @change="getDocuments()"></b-form-select>
                        </b-form-group>

                        <b-form-group label="Days:" class="text-left label mb-0">
                          <b-form-select class="mb-4 input f-12" @change="getDocuments()" :options="[
                            {
                              value: null,
                              text: '',
                            },
                            {
                              value: '0-15',
                              text: '0-15',
                            },
                            {
                              value: '0-31',
                              text: '0-31',
                            },
                          ]" v-model="filterFields.days">
                          </b-form-select>
                        </b-form-group>
                      </div>
                      <div class="d-flex align-items-center justify-content-between buttons-section">
                        <b-button variant="primary" @click="resetFilterFields()">Reset</b-button>
                        <b-button class="text-dark btn-secondary-2" @click="$refs.filterDropdown.hide()">Close</b-button>
                      </div>
                    </b-dropdown>
                    <b-button v-if="$store.state.user.user.team_member.role !== 'Dispatcher'" @click="downloadExcel"
                      class="ml-2" variant="dashboard-export-table-btn"><svg width="16" height="18" viewBox="0 0 16 18"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M13.0206 6.20996H10.364V7.80592H13.0206C13.8106 7.80592 14.4235 8.21448 14.4235 8.56613V15.6431C14.4235 15.9948 13.8106 16.4033 13.0206 16.4033H2.97916C2.18919 16.4033 1.57628 15.9948 1.57628 15.6431V8.56639C1.57628 8.21475 2.18919 7.80618 2.97916 7.80618H5.63519V6.21023H2.97916C1.30857 6.21023 0 7.24521 0 8.56639V15.6434C0 16.9648 1.30857 17.9996 2.97916 17.9996H13.0208C14.6912 17.9996 16 16.9646 16 15.6434V8.56639C15.9997 7.24494 14.6912 6.20996 13.0206 6.20996Z"
                          fill="#C4C4C4" />
                        <path
                          d="M5.66755 3.97792C5.86932 3.97792 6.07082 3.89999 6.22477 3.74411L7.21151 2.74505V6.2104V7.80636V10.8637C7.21151 11.3044 7.56434 11.6617 7.99965 11.6617C8.43497 11.6617 8.78779 11.3044 8.78779 10.8637V7.80636V6.2104V2.69105L9.82787 3.74411C9.98182 3.89999 10.1836 3.97792 10.3851 3.97792C10.5866 3.97792 10.7883 3.89999 10.9423 3.74411C11.2502 3.43264 11.2502 2.92725 10.9423 2.61577L8.58918 0.233276C8.43523 0.0774039 8.23373 0 8.03223 0C8.03013 0 8.02829 0 8.02619 0C8.02409 0 8.02225 0 8.02014 0C7.81864 0 7.61714 0.0774039 7.46319 0.233276L5.11007 2.61577C4.80218 2.92725 4.80218 3.43264 5.11007 3.74411C5.26429 3.89999 5.46579 3.97792 5.66755 3.97792Z"
                          fill="#C4C4C4" />
                      </svg>
                    </b-button>
                  </div>
                </div>

                <b-table class="mt-2" responsive :fields="tableFields" :items="items" hover :current-page="currentPage"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
                  :show-empty="emptyTable">
                  <template #cell(name)="data">
                    <p class="mb-0">{{ data.value }}</p>
                  </template>
                  <template #cell(date)="data">
                    <span v-html="data.value"></span>
                  </template>
                  <template #cell(description)="data">
                    <v-app>
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <span class="w-fit-content item-description text-left" v-on="on" v-bind="attrs">{{ data.value
                          }}</span>
                        </template>{{ data.value }}
                      </v-tooltip>
                    </v-app>
                  </template>
                  <template #cell(days)="data">
                    <span :style="data.item.color">{{ data.value }} Days</span>
                  </template>
                  <template #cell(action)="data">
                    <b-icon style="fill: #2D69F6" class="mr-4" icon="eye-fill" @click="openDoc(data.item)" />
                    <b-icon v-if="$store.state.user.user.team_member.role === 'Admin' ||
                      $store.state.user.user.team_member.role === 'Operations'
                      " style="fill: limegreen" class="mr-4" icon="pencil" @click="editDocument(data.item.id)" />
                  </template>
                </b-table>
                <div class="entries-pagination px-3 px-sm-4">
                  <div class="d-flex align-items-center justify-content-between flex-wrap">
                    <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                      <b-form-select v-model="perPage" :options="pageOptions" />
                    </div>
                    <div class="pagination">
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                        aria-controls="my-table"></b-pagination>
                    </div>
                    <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-3 mt-sm-0">{{ positionText }}</p>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <!-- <b-col lg="4" md="8" sm="12" class="mx-auto my-1">
            <b-card img-top class="profile-card mb-2 bg-white" style="background: white !important">
              <b-card-text class="card-detail">
                <div class="user_photo text-center mb-3">
                  <b-img class="userImage rounded-circle" :src="user.team_member && user.team_member.profile_picture
                    ? user.team_member.profile_picture
                    : require('@/assets/user.png')
                    "></b-img>
                </div>
                <div class="card-title font-weight-bold mb-0">
                  {{ user.first_name }} {{ user.last_name }}
                </div>

                <div v-if="user && user.team_member" class="designation mb-3 f-14">
                  <div>
                    {{ user.team_member.role }}
                  </div>
                </div>
                <div class="w-100 text-center mt-5">
                  <p class="f-14">{{ user.email }}</p>
                  <p class="f-14">{{ user.team_member.phone }}</p>
                </div>
              </b-card-text>
              <b-button variant="primary" class="my-5 save text-white text-center" to="/profile-page">View
                Profile</b-button>
            </b-card>
          </b-col> -->
        </b-row>
      </b-col>
    </b-row>
    <b-modal id="modal-edit-document" centered hide-footer no-close-on-backdrop :body-class="'p-4'" no-close-on-esc>
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Edit Document
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="d-block text-center">
        <b-row>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label class="state">Name</label>
              <v-select class="bg_white w-100" v-model="documentData.name" :options="nameOptions" item-value="id"
                item-text="label" :reduce="(option) => option.id" @option:selected="getNameItem($event)"
                @search="searchAssets">
                <span slot="no-options">
                  Search Unit No or Name...
                </span>
              </v-select>
              <p v-if="errors && errors.name" class="field-error">
                {{ errors.name[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Date</label>
              <b-form-datepicker id="modal-datepicker" class="mb-2" v-model="documentData.date"
                reset-button></b-form-datepicker>
              <p v-if="errors && errors.date" class="field-error">
                {{ errors.date[0] }}
              </p>
            </div>

            <div class="text-left mb-3">
              <label for="modal-expiry-date" class="state">Expiry Date</label>
              <b-form-datepicker id="modal-expiry-date" class="mb-2" v-model="documentData.expiry_date"
                reset-button></b-form-datepicker>
            </div>
            <div class="text-left mb-3">
              <label class="state">Description</label>
              <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                v-model="documentData.description"></b-form-textarea>
              <p v-if="errors && errors.description" class="field-error">
                {{ errors.description[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label class="state">Driver</label>
              <b-form-checkbox class="ml-2" v-model="documentData.driver" name="driver-button" switch size="lg" />
            </div>
            <div class="text-left" v-if="showPublicQR">
              <label class="state">Public (QR)</label>
              <b-form-checkbox class="ml-2" v-model="documentData.public" name="public-button" switch size="lg" />
            </div>
          </b-col>
          <b-col lg="6" md="8" sm="12">
            <div class="upload-btn-wrapper">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button v-if="!fileName" class="foo">
                Drop your document here, or browse
              </button>
              <button v-else class="foo">{{ fileName }}</button>
              <p v-if="fileName" class="mb-0 field-error cursor-pointer font-12 position-relative error-button"
                @click="removeFile()">
                Delete
              </p>
              <input ref="file" type="file" name="myfile" v-on:change="handleFileUpload"
                accept=".pdf, .png, .jpg, .jpeg" />
              <p v-if="errors && errors.document" class="field-error">
                {{ errors.document[0] }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button class="mt-3 mr-3 btn cancel" block @click="$bvModal.hide('modal-edit-document')">Cancel</b-button>
        <b-button class="mt-3 btn save mx-0" variant="primary" @click="sendDocumentData">Save</b-button>
      </div>
    </b-modal>
    <b-modal id="media-popup" no-close-on-backdrop size="lg" scrollable centered
      :footer-class="'d-block border-0 px-20px pt-0 pb-3 m-0'">
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Preview
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <vue-pdf-embed v-if="mediaType === 'pdf'" :source="media" />
      <img :src="media" v-else class="w-100" />
      <template #modal-footer>
        <div class="d-flex modal-footer border-0 p-0 mt-3"
          :class="($store.state.user.user.team_member.role !== 'Admin' ||
            $store.state.user.user.team_member.role !== 'Operations') ? 'justify-content-between' : 'justify-flex-end'">
          <div class="d-flex m-0">
            <b-button class="cancel text-white m-0" v-if="$store.state.user.user.team_member.role === 'Admin' ||
              $store.state.user.user.team_member.role === 'Operations'" :class="mediaType === 'pdf' ? 'mr-3' : ''"
              variant="danger" @click="showPopup(mediaID)">Delete</b-button>
            <b-button class="btn w-125px mx-0 m-0 px-2" v-if="mediaType === 'pdf'" @click="downloadFile(media)"
              variant="success">Download</b-button>
          </div>
          <b-button class="btn cancel m-0" block @click="$bvModal.hide('media-popup')">Close</b-button>
        </div>
      </template>
    </b-modal>
    <DeleteModal v-if="showDocumentModal" @confirm="deleteDocumentItem($event)" />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueMask from "v-mask";
import Vue from "vue";
import * as moment from "moment-timezone";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import axios from "axios";
import DeleteModal from "@/components/DeleteModal.vue";
Vue.use(VueMask);

export default {
  name: "Dashboard",
  components: { vSelect, VuePdfEmbed, DeleteModal },
  computed: {
    ...mapGetters(["user", "documents", "allCountries", "singleDoc", "userCompany"]),
    checkFilterStatus() {
      return !Object.values(this.filterFields).every(x => x === null || x === '');
    },
    typeOptions() {
      const options = [
        {
          value: "",
          text: "",
        },
        {
          value: "Vehicle",
          text: "Vehicle",
        },
        {
          value: "Trailer",
          text: "Trailer",
        },
      ];

      const userRole = this.$store.state.user.user.team_member.role;

      if (userRole !== 'Maintenance' && userRole !== 'Maintenance Manager') {
        options.push({
          value: "Driver",
          text: "Driver",
        });
        options.push({
          value: "Company",
          text: "Company",
        });
      }

      return options;
    }
  },
  methods: {
    ...mapActions([
      "getAllDocuments",
      "setNotification",
      "getEquipmentCount",
      "getTeamMembersCount",
      "getAllDrivers",
      "postExcelData",
      "getAllEquipments",
      "getSingleDoc",
      "setLoader",
      "updateTeamMemberDocument",
      "retrieveDocument",
      "deleteCompanyDocument",
      "exportExpiryData"
    ]),
    deleteDocumentItem(item) {
      if (item) {
        this.deleteDocument(this.itemId);
      }
      this.showDocumentModal = false;
    },
    async deleteDocument(id) {
      try {
        await this.deleteCompanyDocument(`${id}/?company_id=${this.userCompany}`);
        await this.setNotification({
          msg: "Document successfully deleted!!",
          type: "success",
          color: "green",
        });
        await this.getDocuments()
        this.$bvModal.hide("media-popup");
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    showPopup(id) {
      this.showDocumentModal = true;
      this.itemId = id;
    },
    downloadFile(uri) {
      axios({
        method: 'get',
        url: uri,
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const parts = uri.split('/');
        const lastPart = parts[parts.length - 1];
        const fileName = lastPart.split('?')[0];
        a.download = fileName
        document.body.appendChild(a);
        a.click();
        a.remove()
        window.URL.revokeObjectURL(url);
      });
    },
    async openDoc(data) {
      const doc = await this.retrieveDocument(data.id);
      this.media = doc.document
      this.mediaID = data.id
      if (doc.document.includes('.pdf')) this.mediaType = 'pdf'
      else this.mediaType = 'image'
      this.$bvModal.show("media-popup");
    },

    downloadURI(uri) {
      let link = document.createElement("a");
      link.setAttribute("href", uri);
      link.setAttribute("download", "export.csv");
      link.click();
      link.remove()
    },
    resetFilterFields() {
      this.filterFields = {
        search: "",
        type: "",
        days: ""
      }
    },
    async sendDocumentData() {
      if (this.documentData.date && this.documentData.name) {
        try {
          this.setLoader(true);
          let formData = new FormData();
          if (this.$refs.file.files.length === 0) {
            this.documentData.document = null;
          }
          formData.append("object_id", this.documentData.name.split("-")[1]);
          formData.append(
            "date",
            moment(this.documentData.date).format("YYYY-MM-DD")
          );
          formData.append("description", this.documentData.description);
          formData.append("type", this.documentData.type);
            formData.append("expiry_date", this.documentData.expiry_date ? this.documentData.expiry_date : '');
          if (this.documentData.document)
            formData.append(
              "document",
              new File(
                [this.documentData.document],
                this.documentData.document["name"].split(".")[0] +
                "_" + this.userCompany + "_" +
                new Date().getTime() +
                "." +
                this.documentData.document["type"].split("/").pop(),
                { type: this.documentData.document["type"] }
              )
            );
          formData.append("driver", this.documentData.driver);
          formData.append("public", this.documentData.public);
          formData.append(
            "model_type",
            this.nameOptions.filter(
              (item) => item.id === this.documentData.name
            )[0].type
          );
          this.documentData = await this.updateTeamMemberDocument({
            id: this.documentData.id,
            payload: formData,
          });
          await this.setNotification({
            msg: "Document Successfully updated!!",
            type: "success",
            color: "green",
          });
          this.$bvModal.hide("modal-edit-document");
          await this.getDocuments();
          this.documentData = {
            object_id: null,
            date: null,
            name: null,
            type: null,
            document: null,
            description: null,
            expiry_date: "",
            driver: true,
            public: false,
          };
          this.fileName = null;
          this.setLoader(false);

        }
        catch (e) {
          console.log(e)
        }
      }
      else {
        this.errors.date = this.documentData.date
          ? null
          : ["This field is required."];
        this.errors.name = this.documentData.name
          ? null
          : ["This field is required."];
      }
    },
    async editDocument(id) {
      await this.getSingleDoc(id)
      this.nameOptions.push({
        id: `${this.singleDoc.type}-${this.singleDoc.type === "Company" ? this.userCompany : this.singleDoc.document_of.id}`,
        label: this.singleDoc.document_of.unit_no
          ? this.singleDoc.document_of.unit_no
          : this.singleDoc.document_of.name,
        type: `${this.singleDoc.type === "Company" ? 'company' : this.singleDoc.type === "Vehicle" || this.singleDoc.type === "Trailer" ? 'equipment' : 'teammember'}`,
      })
      this.nameOptions = [...new Map(this.nameOptions.map(item => [item['id'], item])).values()]
      this.showPublicQR = this.singleDoc.type === "Vehicle" || this.singleDoc.type === "Trailer";
      this.documentIndex = id;
      this.documentData = this.singleDoc
      this.documentData.name = `${this.singleDoc.type}-${this.singleDoc.type === "Company" ? this.userCompany : this.singleDoc.document_of.id}`;
      if (this.documentData.document) {
        let file_name = "";
        if (typeof this.documentData.document === "string") {
          file_name = this.documentData.document;
        } else {
          file_name = this.documentData.document.name;
        }
        this.fileName = file_name.split("/").pop().split("#")[0].split("?")[0];
      } else {
        this.documentData.document = null;
        this.fileName = "";
        this.errors = {
          date: null,
          type: null,
          document: null,
          description: null,
        };
      }
      console.log(this.documentData)
      this.$bvModal.show("modal-edit-document");
    },
    async searchAssets(searchText) {
      let options = []
      let url = `unit_no=${searchText}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
      const equipmentRecords = await this.getAllEquipments(url);
      if (equipmentRecords.records.length === 0) {
        url = `name=${searchText}&sort_field=name&sort_order=asc&&page=1&page_size=10`
        const driverRecords = await this.getAllDrivers(url);
        if (driverRecords.records?.length) {
          driverRecords.records?.map((item) => {
            options.push({
              id: `Driver-${item.id}`,
              label: item.name,
              type: "teammember",
            });
          });
        }
      }
      else {
        equipmentRecords.records.map((item) => {
          options.push({
            id: `${item.type}-${item.id}`,
            label: item.unit_no,
            type: "equipment",
          });
        });
      }
      this.nameOptions = this.nameOptions.concat(options).concat([{
        id: `Company-${this.company[0].id}`,
        label: "Company",
        type: "company",
      }])
      this.nameOptions = [...new Map(this.nameOptions.map(item => [item['id'], item])).values()]
    },
    getNameItem(item) {
      this.showPublicQR = item.type === "equipment";
    },
    removeFile() {
      this.fileName = null;
      this.$refs.file.value = "";
    },
    async downloadExcel() {
      try {
        this.setLoader(true)
        let url = ""
        let tags = ""
        Object.keys(this.filterFields).map((key) => {
          if (key === "tags" && this.filterFields[key].length > 0) {
            this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
            url += `${key}=${tags}&`
          }
          else if (this.filterFields[key] !== '' && key !== 'tags') {
            url += `${key}=${this.filterFields[key]}&`
          }
        })
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
        }
        else {
          url += `sort_field=Days&sort_order=asc&`
        }
        const data = await this.exportExpiryData(url)
        const blob = new Blob([data], { type: 'text/csv' });
        const csvUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = csvUrl;
        a.download = 'records.csv';
        a.target = "_blank"
        document.body.appendChild(a);
        a.click();
        a.remove()
        window.URL.revokeObjectURL(url);
        await this.setNotification({
          msg: `File Exported Successfully`,
          type: "success",
          color: "green",
        });
        this.setLoader(false)
        this.setLoader(false)
      }
      catch (e) {
        this.setLoader(false)
        console.log(e)
      }
    },
    async getDocuments() {
      try {
        this.isBusy = true
        let url = ""
        let tags = ""
        Object.keys(this.filterFields).map((key) => {
          if (key === "tags" && this.filterFields[key].length > 0) {
            this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
            url += `${key}=${tags}&`
          }
          else if (this.filterFields[key] !== '' && key !== 'tags') {
            url += `${key}=${this.filterFields[key]}&`
          }
        })
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
        }
        else {
          url += `sort_field=Days&sort_order=asc&`
        }
        url += `page=${this.currentPage}&page_size=${this.perPage}`
        const data = await this.getAllDocuments(url);
        if (data.count) {
          this.rows = data.count
          this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1) + (this.perPage > data.count ? data.count : this.perPage)} of ${data.count}`
        }
        else {
          this.rows = 0
          this.positionText = `0 to 0 of 0`
        }
        this.items = []
        this.documents.forEach((doc) => {
          if (doc.Days <= 60) {
            if (doc.Days <= 0) {
              var color = "color:red";
            } else if (doc.Days <= 31 && doc.Days >= 1) {
              color = "color:orange";
            } else {
              color = "color:green";
            }
            this.items.push({
              name: doc.name,
              id: doc.id,
              type: doc.type,
              description: doc.description,
              Date: `<span style=${color}>${moment(doc.Date).format("MMM DD, YYYY")}</span>`,
              "Expiry Date": moment(doc.Date).format("MMM DD, YYYY"),
              color: color,
              Days: doc.Days,
              route: doc.team_id ? "driver" : doc.company_id ? "company" : "equipment",
            });
          }
        })
        if (this.items.length === 0) this.emptyTable = true
        else this.emptyTable = false
        this.isBusy = false
      }
      catch (e) {
        this.isBusy = false
        console.log(e)
      }

    },
    phoneRule: (value) => {
      const pattern = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
      return pattern.test(value);
    },
    handleFileUpload() {
      this.documentData.document = this.$refs.file.files[0];
      this.fileName = this.documentData.document.name;
    },

  },
  watch: {
    perPage: {
      async handler() {
        await this.getDocuments();
      },
      immediate: true,
    },
    currentPage: {
      async handler() {
        await this.getDocuments();
      },
    },
    sortBy: {
      async handler() {
        await this.getDocuments();
      },
    },
    sortDesc: {
      async handler() {
        await this.getDocuments();
      },
    },
    filterFields: {
      handler() {
        this.currentPage = 1
      },
      deep: true
    },
  },
  async mounted() {
    try {
      this.teamMembers.totalTeamMembers = await this.getTeamMembersCount(
        "no_drivers"
      );
      this.teamMembers.totalDrivers = await this.getTeamMembersCount("driver");
      this.equipments.vehicles = await this.getEquipmentCount("Vehicle");
      this.equipments.trailers = await this.getEquipmentCount("Trailer");
      await this.getDocuments()
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      showPublicQR: false,
      fileName: "",
      showDocumentModal: false,
      emptyTable: false,
      positionText: "",
      rows: 0,
      media: null,
      mediaType: null,
      mediaID: null,
      sortBy: undefined,
      sortDesc: undefined,
      filterFields: {
        search: "",
        type: "",
        days: ""
      },
      searchGroups: null,
      teamMembers: {
        totalTeamMembers: 0,
        totalDrivers: 0,
      },
      equipments: {
        vehicles: 0,
        trailers: 0,
      },
      documentData: {
        name: null,
        object_id: null,
        date: new Date(),
        type: null,
        document: null,
        description: null,
        expiry_date: "",
        driver: true,
        public: false,
      },
      errors: {
        date: null,
        name: null,
        document: null,
        description: null,
      },
      perPage: 10,
      currentPage: 1,
      nameOptions: [],
      items: [],
      passwords: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      roleOptions: [
        {
          value: "Admin",
          text: "Admin",
        },
        {
          value: "Operations",
          text: "Operations",
        },
        {
          value: "Maintenance",
          text: "Maintenance",
        },
        {
          value: "Driver",
          text: "Driver",
        },
      ],
      tableFields: [
        {
          label: "Name",
          key: "name",
          sortable: true,
          stickyColumn: true,
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Type",
          key: "type",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Description",
          key: "description",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Expiry Date",
          key: "Date",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
        {
          key: "Days",
          sortable: true,
          thClass: 'font-table-weight-bold'
          // Variant applies to the whole column, including the header and footer
        },
        {
          key: "Action",
          label: "Action",
          sortable: true,
          visible: true,
          disabled: true,
          field: "text",
          thClass: 'font-table-weight-bold'
        }
      ],
      fields: [
        {
          key: "Name",
        },
        {
          key: "Type",
        },
        {
          key: "Inspection",
        },
        {
          key: "Days",
        },
      ],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.userImage {
  /* max-width: 150px; */
  width: 150px;
  height: 150px;
  /* max-height: 140px; */
  object-fit: cover;
  margin: 3px;
  border: 3px solid lightgrey;
}

a {
  text-decoration: none !important;
  color: var(--black) !important;
}

.userFields {
  padding: 0 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.userFields label {
  margin-right: auto;
}

.dashboard_total .total_team {
  display: flex;
  align-items: center;
  padding: 25px;
  background-color: white;
  border-radius: 0.25rem;
  height: 104px;
  box-shadow: 0 4px 50px rgb(0 0 0/10%);
  border: 1px solid #dee2e6;
}

.dashboard_total .total_team img {
  margin-right: 20px;
}

.dashboard_total .total_team .number {
  font-size: 23px !important;
  font-weight: 700 !important;
  color: black;
  text-align: left;
  margin-bottom: 0px !important;
}

.dashboard_total .total_team p {
  font-size: 14px;
  color: black;
  margin-bottom: 0;
  text-align: left;
}

.table_heading {
  background-color: #2D69F6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.table_heading .heading {
  color: #fff;
}

.main-container .table.bg-white {
  box-shadow: 0px 4px 50px rgb(0 0 0 / 10%);
}

.table {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}

.table> :not(caption)>*>* {
  padding: 10px 15px;
}

.table_heading .view {
  background-color: var(--btn-bg-color);
  font-size: 14px;
  color: #fff !important;
  border: none;
}

.table_heading .view,
.table_heading .view:focus,
.table_heading .view:active,
.table_heading .view:hover {
  background-color: var(--btn-bg-color) !important;
  box-shadow: none;
}

.card {
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
}

.profile-card {
  box-shadow: 0px 4px 50px rgb(0 0 0 / 10%) !important;
}

.save {
  background-color: var(--btn-bg-color) !important;
}

.card-detail .email {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 30px;
  position: relative;
  flex-direction: row;
  margin-bottom: 5px;
}

.card-detail .email.update-change {
  display: block;
  padding: 0;
  margin-bottom: 13px;
  position: relative;
}

.card-detail .email.update-change svg {
  position: absolute;
  right: 10px;
  top: 43px;
}

/* BEFORE CLICK THE UPDATE CSS */
.card-detail .email .heading {
  font-weight: 600;
  width: 85px;
  text-align: left;
}

.card-detail .email p {
  line-break: anywhere;
  line-height: 16px;
}

/* AFTER CLICK THE UPDATE CSS */
.card-detail .email.update-change .heading {
  width: auto;
}

.card-detail .email p {
  margin-bottom: 0;
}

.card-detail .change {
  background-color: #2D69F6;
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 12px;
  position: absolute;
  right: -10px;
  bottom: -45px;
}

.card-detail .change:hover,
.card-detail .change:focus,
.card-detail .change:active {
  background-color: #2D69F6;
}

.card-detail .w-100 {
  width: 100% !important;
  margin: auto;
  flex-direction: column;
}

.table-dashboard .table {
  margin-bottom: 0rem !important;
}

.designation {
  color: var(--black);
  opacity: 0.6;
}

@media (max-width: 1200px) {
  .card-detail .change {
    right: -25px;
    padding: 2px 5px;
  }
}

@media (max-width: 1140px) {
  .dashboard_total .total_team {
    padding: 9px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .card-detail .change {
    right: -10px;
  }
}

@media (max-width: 991px) {
  .table {
    overflow-x: scroll;
  }

  .card-detail .change {
    right: 0px;
  }
}

@media (max-width: 575px) {
  .card-detail .change {
    right: 0;
  }

  .card-detail .email p {
    font-size: 11px;
  }
}

@media (max-width: 395px) {
  .table_heading {
    padding: 12px !important;
  }

  .table_heading .view {
    font-size: 12px;
  }

  .card-detail .email p {
    overflow: hidden;
    line-height: 18px;
  }
}
</style>
