<template>
    <div>
        <b-modal id="assign-equipment" size="sm" centered no-close-on-backdrop header-class="d-none"
            footer-class="border-0 d-block assign-equipment-btn-section" no-close-on-esc>
            <template #modal-header="{ close }">
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="text-center assign-modal">
                <svg v-if="alreadyAssigned" class="mb-3" width="46" height="48" viewBox="0 0 46 48" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4914_3406)">
                        <path
                            d="M39.4739 45.0668H6.52503C4.26377 45.0668 2.1633 43.8718 0.974569 41.9081C-0.214516 39.9448 -0.322646 37.493 0.688795 35.4283L17.1632 6.63908C18.2688 4.38299 20.5279 2.95752 22.9995 2.95752C25.471 2.95752 27.7302 4.38299 28.8357 6.63908L45.3102 35.4283C46.3216 37.493 46.2135 39.9448 45.0244 41.9081C43.8357 43.8718 41.7355 45.0668 39.4739 45.0668Z"
                            fill="#F0C419" />
                        <path
                            d="M39.4778 45.067H22.9995V2.95765C25.477 2.94188 27.7453 4.37308 28.8389 6.64243L45.3091 35.4305C46.322 37.4939 46.2153 39.9456 45.0272 41.9089C43.8389 43.8726 41.7391 45.0677 39.4778 45.067Z"
                            fill="#F29C1F" />
                        <path
                            d="M26.1732 37.7786C26.1732 39.5677 24.7521 41.0179 22.9995 41.0179C21.247 41.0179 19.8262 39.5677 19.8262 37.7786C19.8262 35.9897 21.247 34.5396 22.9995 34.5396C24.7521 34.5396 26.1732 35.9897 26.1732 37.7786Z"
                            fill="#35495E" />
                        <path
                            d="M26.1732 37.7786C26.1732 38.6379 25.839 39.4617 25.2439 40.0694C24.6485 40.6768 23.8414 41.0179 22.9995 41.0179V34.5396C23.8414 34.5392 24.6485 34.8803 25.2439 35.4881C25.839 36.0955 26.1732 36.9196 26.1732 37.7786Z"
                            fill="#2C3E50" />
                        <path
                            d="M22.9995 9.43604C24.7521 9.43604 26.1732 10.8862 26.1732 12.6754V28.0613C26.1732 29.8502 24.7521 31.3004 22.9995 31.3004C21.247 31.3004 19.8262 29.8502 19.8262 28.0613V12.6754C19.8262 10.8862 21.247 9.43604 22.9995 9.43604Z"
                            fill="#35495E" />
                        <path
                            d="M26.1732 12.6754V28.0613C26.1732 28.9206 25.839 29.7445 25.2439 30.3518C24.6485 30.9596 23.8414 31.3007 22.9995 31.3004V9.43604C23.8414 9.43496 24.6496 9.77574 25.245 10.3835C25.84 10.9912 26.1743 11.8158 26.1732 12.6754Z"
                            fill="#2C3E50" />
                    </g>
                    <defs>
                        <clipPath id="clip0_4914_3406">
                            <rect width="46" height="46.9518" fill="white" transform="translate(0 0.496582)" />
                        </clipPath>
                    </defs>
                </svg>

                <svg v-else class="mb-3" width="52" height="52" viewBox="0 0 52 52" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4914_3393)">
                        <path
                            d="M50.8889 10.4739C49.9109 6.05797 45.63 2.01274 41.2019 1.15352C38.5034 0.614221 30.9766 -0.0520293 26 -0.000232386C21.0234 -0.0550761 13.4977 0.609143 10.7991 1.15047C6.37102 2.01274 2.09016 6.05797 1.11719 10.4739C0.555547 13.1552 0.008125 16.8033 0 21.7829C0.008125 26.7595 0.555547 30.4086 1.11719 33.0909C2.09523 37.5068 6.37609 41.552 10.8042 42.4113C12.2606 42.7017 15.1257 43.0308 18.2355 43.2623C18.5876 43.8155 18.9702 44.4046 19.3832 45.0295C21.0336 47.53 22.296 49.2718 23.2537 50.4855C24.8463 52.5045 27.1639 52.5045 28.7564 50.4855C29.7141 49.2718 30.9766 47.53 32.627 45.0295C33.04 44.4053 33.4225 43.8162 33.7746 43.2623C36.8855 43.0308 39.7495 42.7017 41.207 42.4113C45.6351 41.552 49.9159 37.5068 50.894 33.0909C51.4505 30.4086 51.997 26.7615 52.0061 21.7829C51.9919 16.8023 51.4455 13.1562 50.8889 10.4739Z"
                            fill="#F9973E" />
                        <path
                            d="M26 -0.000232386C21.0234 -0.0550761 13.4977 0.609143 10.7991 1.15047C6.37102 2.01274 2.09016 6.05797 1.11719 10.4739C0.555547 13.1552 0.008125 16.8033 0 21.7829C0.008125 26.7595 0.555547 30.4086 1.11719 33.0909C1.69305 35.6898 3.41352 38.1609 5.63469 39.9291C7.80133 40.4478 10.0216 40.7093 12.2495 40.708C27.8525 40.708 40.5011 28.0595 40.5011 12.4574C40.5047 8.39421 39.6281 4.37843 37.9316 0.68633C34.4612 0.29633 29.574 -0.0367949 26 -0.000232386Z"
                            fill="#FCA84C" />
                        <path
                            d="M28.8722 36.8814C28.8048 37.1977 28.6472 37.4878 28.4185 37.7165C28.1897 37.9452 27.8997 38.1028 27.5834 38.1702C26.5343 38.3314 25.4667 38.3314 24.4177 38.1702C24.1013 38.1029 23.8112 37.9454 23.5824 37.7166C23.3537 37.4879 23.1961 37.1978 23.1288 36.8814C22.9663 35.8321 22.9663 34.764 23.1288 33.7147C23.1961 33.3983 23.3537 33.1082 23.5824 32.8794C23.8112 32.6507 24.1013 32.4931 24.4177 32.4258C25.4667 32.2647 26.5343 32.2647 27.5834 32.4258C27.9002 32.4936 28.1907 32.6518 28.4194 32.8812C28.6482 33.1107 28.8055 33.4016 28.8722 33.7187C29.0347 34.768 29.0347 35.8361 28.8722 36.8854V36.8814ZM35.0553 20.2465C34.2712 21.3555 33.0962 22.3681 31.3574 23.4315C29.1566 24.7883 28.8041 25.3459 28.8041 26.4377V26.8694C28.8112 27.2426 28.7439 27.6134 28.606 27.9603C28.468 28.3072 28.2624 28.623 28.0009 28.8895C27.7395 29.1559 27.4276 29.3676 27.0834 29.5121C26.7392 29.6565 26.3697 29.731 25.9964 29.731C25.6232 29.731 25.2536 29.6565 24.9095 29.5121C24.5653 29.3676 24.2534 29.1559 23.992 28.8895C23.7305 28.623 23.5249 28.3072 23.3869 27.9603C23.249 27.6134 23.1817 27.2426 23.1887 26.8694V25.869C23.1887 24.5812 23.5107 23.4883 24.1729 22.5286C24.8148 21.5983 25.7776 20.7715 27.2035 19.9235C29.833 18.3767 30.3144 17.5083 30.3144 16.3556C30.3144 14.9073 29.0804 13.9709 27.1659 13.9709C25.0748 13.9709 23.7128 14.9215 23.1187 16.7974C22.9262 17.4009 22.5458 17.927 22.0331 18.299C21.5204 18.671 20.9022 18.8694 20.2688 18.8652C20.1957 18.8652 20.1216 18.8652 20.0495 18.8571C19.5944 18.825 19.1527 18.6894 18.7581 18.4606C18.3634 18.2317 18.0263 17.9158 17.7724 17.5368C17.5188 17.1645 17.3536 16.7392 17.2895 16.2933C17.2254 15.8474 17.2642 15.3928 17.4027 14.9642C18.0121 13.011 19.2619 11.32 20.9503 10.1644C22.6525 9.00451 24.8026 8.39209 27.1659 8.39209C29.6207 8.39209 31.8988 9.17514 33.5806 10.597C35.3123 12.0595 36.2649 14.0776 36.2649 16.2784C36.27 17.7917 35.8729 19.0937 35.0553 20.2465Z"
                            fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_4914_3393">
                            <rect width="52" height="52" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <h2 class="font-weight-bold f-20 mb-3">{{title}}</h2>
                <p class="f-14 mb-0">{{ message }}</p>
            </div>
            <template #modal-footer="" class="">
                <b-button class="save-btn w-100px m-0" variant="primary" @click="$emit('assignEquipment')"> Confirm </b-button>
                <b-button class="close-btn w-100px m-0" variant="cancel-btn" @click="$bvModal.hide('assign-equipment')"> Cancel </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    name: "AssignVehicle",
    props: {
        title: {
            type: String,
            default: "",
        },
        message: {
            type: String,
            default: "",
        },
        alreadyAssigned: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style>
.assign-modal>h2,
.assign-modal>p {
    color: #11004D;
}

.assign-modal>p {
    opacity: 0.5;
}

.assign-equipment-btn-section>button {
    height: 48px;
    font-weight: 500;
}

.assign-equipment-btn-section>.btn-cancel-btn {
    border-radius: 5px;
    border: 1px solid rgba(17, 0, 77, 0.06) !important;
    background: #FFF !important;
    color: #11004D !important;
}</style>