<template>
    <div @mouseover="isHovered = true" @mouseleave="isHovered = false">
        <router-link :to="routeTo"
            :class="(activeRoute.includes(routeTo) && routeTo !== '/') || (activeRoute === routeTo) ? 'active' : ''">
            <b-img class="" :src="((activeRoute.includes(routeTo) && routeTo !== '/') || (activeRoute === routeTo) || isHovered)
                ? hoverImg
                : img
                "></b-img>
            <span class="links_name">{{ name }}</span>
        </router-link>
    </div>
</template>
<script>
export default {
    name: "SidebarItem",
    props: ["routeTo", "activeRoute", 'img', 'hoverImg', "name"],
    data() {
        return {
            isHovered: false
        }
    },
}
</script>