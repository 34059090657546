<template>
    <div>
        <div class="">
            <header class="text-left search_equipment d-flex user-detail row">
                <div class="col-md-8 d-flex justify-content-between mx-3 mx-md-auto align-items-center">
                    <router-link to="/driver-details">
                        <b-img :src="require('@/assets/form-logo.png')" width="143" height="37"></b-img>
                    </router-link>
                    <div>
                        <b-dropdown id="dropdown-right" right class="mx-2 dropdown dropdown-dark-section header-section-dropdown">
                            <template slot="button-content">
                                <b-img v-if="user.team_member && user.team_member.profile_picture" class="userSmallImage rounded-circle" :src="user.team_member.profile_picture"></b-img>
            <div v-else class="user-default-img">
              {{ user.first_name.charAt(0).toUpperCase() }}{{ user.last_name.charAt(0).toUpperCase() }}
            </div>
                            </template>
                            <b-dropdown-item><span class="font-weight-bold f-14">{{ user.first_name }}
                                    {{ user.last_name }}</span>
                                <br /> <span class="f-12">{{ user.email }}</span>
                            </b-dropdown-item>
                            <div v-if="userRole !== 'Driver'">
                            <hr class="my-2" />
                            <b-dropdown-item to="/profile-page"><span class="f-14">View Profile</span></b-dropdown-item>
                            <b-dropdown-item to="/all-companies"><span class="f-14">View Companies</span></b-dropdown-item>
                            </div>
                            <hr class="my-2" />
                            <b-dropdown-item @click="OnlogoutUser"><span class="f-14">Logout</span></b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </header>
            <div class="row mt-5">
                <div class="col-md-8 mx-3 mx-md-auto pt-64">
                    <div class="font-weight-bold f-16 mb-3 detail-section bg-white"> {{ currentEquipment.unit_no }} </div>
                    <div class="bg-white p-4 rounded equipment-details-section mb-3">
                        <b-row>
                            <b-col>
                                <div class="desc text-left f-14 mb-2">{{ currentEquipment.unit_no }}</div>
                                <div class="desc text-left f-14 mb-2">{{ currentEquipment.type ? currentEquipment.type :
                                    '-' }}</div>
                                <div class="desc text-left f-14 mb-2">{{ currentEquipment.subtype ? currentEquipment.subtype
                                    : '-' }}</div>
                                <div class="desc text-left f-14 mb-2">{{ currentEquipment.vin_no ? currentEquipment.vin_no :
                                    '-' }}</div>
                                <div class="desc text-left f-14 mb-2">{{ currentEquipment.plate_no ?
                                    currentEquipment.plate_no : '-' }}</div>
                                <div class="desc text-left f-14 mb-0">{{ currentEquipment.state.name }}</div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="d-flex align-items-center mb-3 flex-wrap flex-sm-nowrap driver-equipment-btn-section"
                        v-if="currentEquipment.driver && currentEquipment.driver.id === user.team_member.id">
                        <b-button variant="primary" class="w-100 f-14 font-weight-bold"
                            @click="$bvModal.show('assign-equipment')">Unassign</b-button>
                        <b-button variant="view-all" class="w-100 mt-2 mt-sm-0 ml-0 ml-sm-3 f-14 font-weight-bold"
                            @click="showAllEquipment">View All</b-button>
                    </div>
                    <div class="detail-section bg-white mb-3 cursor-pointer" @click="openLink(docs.id)"
                        v-for="(docs, index) in currentEquipment.docs" :key="index">
                        <div>
                            <p class="f-14 mb-0 text-left font-weight-bold mb-1">
                                {{ docs.description ? docs.description : "-" }}
                            </p>
                            <p class="f-12 mb-0 text-left" :class="docs.expired_status">
                                {{
                                    docs.expiry_date
                                    ? "Expiring " +
                                    moment(docs.expiry_date).format("MMM DD, YYYY")
                                    : "-"
                                }}
                            </p>
                        </div>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_4849_341)">
                                <path
                                    d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                                    fill="#2D69F6" />
                            </g>
                            <defs>
                                <clipPath id="clip0_4849_341">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <AssignEquipment :title="modalTitle" :message="modalMessage" @assignEquipment="unAssignEquipment" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import * as moment from "moment-timezone";
import AssignEquipment from "@/components/driver/AssignEquipment.vue";
export default {
    data() {
        return {
            moment,
            modalTitle: "Confirmation",
            modalMessage: "Do you want to unassign this vehicle?",
        }
    },
    components: { AssignEquipment },
    computed: {
        ...mapGetters(["user", "userRole", "currentEquipment"])
    },
    methods: {
        ...mapActions(["logoutUser", "getUserByID", "getAllEquipments", "getEquipment", "retrieveDocument", "updateTeamMember", "setNotification"]),
        async openLink(id) {
            const doc = await this.retrieveDocument(id);
            let link = document.createElement("a");
            link.setAttribute("href", doc.document);
            link.setAttribute("target", "_blank")
            link.click();
            link.remove()
        },
        async unAssignEquipment() {
            try {
                await this.updateTeamMember({
                    id: this.user.team_member.id,
                    payload: { user: this.user.team_member, ...this.currentEquipment.type === 'Vehicle' ? { default_vehicle: null } : { default_trailer: null } },
                })
                await this.setNotification({
                    msg: "Vehicle Unassigned Successfully",
                    type: "success",
                    color: "green",
                });
                this.$bvModal.hide('assign-equipment')
                this.$router.push("/driver-details")
            }
            catch (e) {
                await this.setNotification({
                    msg: e,
                    type: "error",
                    color: "red",
                });
            }
        },
        showAllEquipment() {
            if (this.currentEquipment.type === 'Vehicle') this.$router.push("/driver-vehicles-search")
            else this.$router.push("/driver-trailers-search")
        },
        OnlogoutUser() {
            this.logoutUser();
            this.$router.push("/login");
        },
    },
    async mounted() {
        if (!this.user) {
            await this.getUserByID()
        }
        await this.getEquipment(this.$route.params.id)
    }
}
</script>
<style>
.driver-equipment-btn-section .btn {
    height: 56px !important;
    border-radius: 5px;
}

.driver-equipment-btn-section .btn-view-all {
    border-radius: 5px;
    background: #E8E9ED !important;
    color: #2D69F6 !important
}
</style>