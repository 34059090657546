var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left p-3 bg-white rounded"},[_c('h3',{staticClass:"font-weight-bold f-20 mb-4 mt-2 text-left text-black"},[_vm._v("Integrations")]),(!_vm.showInnerSamsaraPage && !_vm.showInnerMotivePage && !_vm.showInnerGeoTabPage && !_vm.showInnerIsaacPage)?_c('div',[_c('b-row',{staticClass:"mb-5 mt-3"},[_c('b-col',{staticClass:"mb-3 mb-md-0",attrs:{"md":"6"}},[_c('b-card',{staticClass:"table-custom-border h-100"},[_c('b-card-text',{staticClass:"position-relative"},[_c('img',{attrs:{"src":require('@/assets/samsara.svg'),"height":"50px"}}),_c('img',{staticClass:"integration-status",attrs:{"src":_vm.company && _vm.company.samsara_refreshtoken
                ? require('@/assets/active-state.svg')
                : require('@/assets/inactive-state.svg')}}),_c('p',{staticClass:"mt-4 text-tertiary"},[_vm._v(" Samsara integration will integrate your drivers, vehicles and trailers to our platform. ")]),_c('hr'),_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"text-main cursor-pointer mb-0",on:{"click":function($event){return _vm.$emit('samsaraStatus', true)}}},[_vm._v(" View Integration ")])])])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-card',{staticClass:"table-custom-border h-100"},[_c('b-card-text',{staticClass:"position-relative"},[_c('img',{attrs:{"src":require('@/assets/motive.svg'),"height":"50px"}}),_c('img',{staticClass:"integration-status",attrs:{"src":_vm.company && _vm.company.motive_refreshtoken
                ? require('@/assets/active-state.svg')
                : require('@/assets/inactive-state.svg')}}),_c('p',{staticClass:"mt-4 text-tertiary"},[_vm._v(" Motive integration will integrate your drivers, vehicles and trailers to our platform. ")]),_c('hr'),_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"text-main cursor-pointer mb-0",on:{"click":function($event){return _vm.$emit('motiveStatus', true)}}},[_vm._v(" View Integration ")])])])],1)],1),_c('b-col',{staticClass:"mb-3 mb-md-0 mt-30-px",attrs:{"md":"6"}},[_c('b-card',{staticClass:"table-custom-border h-100"},[_c('b-card-text',{staticClass:"position-relative"},[_c('img',{attrs:{"src":require('@/assets/geotab.svg'),"width":"208","height":"50px"}}),_c('img',{staticClass:"integration-status",attrs:{"src":_vm.company && _vm.company.geotab_database
                ? require('@/assets/active-state.svg')
                : require('@/assets/inactive-state.svg')}}),_c('p',{staticClass:"mt-4 text-tertiary"},[_vm._v(" Geotab integration will integrate your drivers, vehicles and trailers to our platform. ")]),_c('hr'),_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"text-main cursor-pointer mb-0",on:{"click":function($event){return _vm.$emit('geoTabStatus', true)}}},[_vm._v(" View Integration ")])])])],1)],1),_c('b-col',{staticClass:"mb-3 mb-md-0 mt-30-px",attrs:{"md":"6"}},[_c('b-card',{staticClass:"table-custom-border h-100"},[_c('b-card-text',{staticClass:"position-relative"},[_c('img',{attrs:{"src":require('@/assets/isaac.svg'),"width":"208","height":"50px"}}),_c('img',{staticClass:"integration-status",attrs:{"src":_vm.company && _vm.company.isaac_auth_code
                ? require('@/assets/active-state.svg')
                : require('@/assets/inactive-state.svg')}}),_c('p',{staticClass:"mt-4 text-tertiary"},[_vm._v(" Isaac integration will integrate your drivers, vehicles and trailers to our platform. ")]),_c('hr'),_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"text-main cursor-pointer mb-0",on:{"click":function($event){return _vm.$emit('isaacStatus', true)}}},[_vm._v(" View Integration ")])])])],1)],1)],1)],1):_vm._e(),(_vm.showInnerSamsaraPage)?_c('SamsaraIntegration',{attrs:{"status":!!_vm.company.samsara_refreshtoken},on:{"close":function($event){return _vm.$emit('samsaraStatus', false)}}}):_vm._e(),(_vm.showInnerMotivePage)?_c('MotiveIntegration',{attrs:{"status":!!_vm.company.motive_refreshtoken},on:{"close":function($event){return _vm.$emit('motiveStatus', false)}}}):_vm._e(),(_vm.showInnerGeoTabPage)?_c('GeoTabIntegration',{attrs:{"status":!!_vm.company.geotab_database},on:{"close":function($event){return _vm.$emit('geoTabStatus', false)}}}):_vm._e(),(_vm.showInnerIsaacPage)?_c('IsaacIntegration',{attrs:{"status":!!_vm.company.isaac_auth_code},on:{"close":function($event){return _vm.$emit('isaacStatus', false)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }