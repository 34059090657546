<template>
    <div>
        <b-modal id="modal-contact" class="maintenance_modal" centered :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
            scrollable>
            <template #modal-header="{ close }">
                <h5 class="modal-title"> {{ contactData ? 'Edit' : 'Add' }} Contact
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="d-block text-center">
                <b-row>
                    <b-col sm="12" class="text-left">
                        <b-form-group>
                            <label class="state">Name:</label>
                            <b-form-input type="text" v-model="contactPayload.name" class="f-12"
                                placeholder="Enter Name"></b-form-input>
                            <p v-if="errors && errors.name" class="field-error">
                                {{ errors.name[0] }}
                            </p>
                        </b-form-group>
                        <b-form-group>
                            <label class="state">Role:</label>
                            <b-form-input type="text" v-model="contactPayload.role" class="f-12"
                                placeholder="Enter Role"></b-form-input>
                        </b-form-group>
                        <b-form-group>
                            <label class="state">Phone</label>
                            <vue-tel-input v-bind="bindProps" class="w-100 f-12" @validate="checkNum"
                                v-model="contactPayload.phone"></vue-tel-input>
                            <p v-if="errors && errors.phone" class="field-error">
                                {{ errors.phone[0] }}
                            </p>
                        </b-form-group>
                        <b-form-group>
                            <label class="state">Email</label>
                            <b-form-input type="text" v-model="contactPayload.email" class="f-12"
                                placeholder="Enter Email"></b-form-input>
                            <p v-if="errors && errors.email" class="field-error">
                                {{ errors.email[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <div class="text-right modal-footer border-0 p-0">
                    <b-button class="mt-3 mr-3 btn cancel m-0" block @click="resetPopup()">Cancel</b-button>
                    <div class="d-flex align-items mt-3 m-0">
                        <b-button class="btn save mx-0 w-100" variant="primary" @click="onAddContact">Save</b-button>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { checkEmail } from '../../services/helper';
export default {
    name: "Contacts",
    props: ["id", "contactData", "addContact", 'updateContact'],
    data() {
        return {
            errors: {},
            checkEmail,
            contactPayload: {
                name: "",
                role: "",
                phone: "",
                email: ""
            },
            isPhoneValid: false,
            bindProps: {
                mode: "international",
                autoFormat: true,
                preferredCountries: ["US", "CA", "MX"],
                inputOptions: {
                    placeholder: "Enter a phone no.",
                    maxlength: 30,
                },
                dropdownOptions: {
                    showSearchBox: true,
                    showDialCodeInList: true,
                    showFlags: true,
                    showDialCodeInSelection: true,
                    width: "300px",
                },
            },
        }
    },
    methods: {
        ...mapActions(['setNotification', 'setLoader']),
        checkNum(phoneObject) {
            if (phoneObject.valid !== undefined) {
                this.isPhoneValid = phoneObject.valid;
            }
        },
        resetPopup() {
            this.errors = {}
            this.contactPayload = {
                name: "",
                role: "",
                phone: "",
                email: ""
            }
            this.$bvModal.hide("modal-contact");
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async onAddContact() {
            try {
                if (this.contactPayload.email) {
                    if (!this.checkEmail(this.contactPayload.email)) {
                        throw {
                            email: ["Wrong format"],
                        };
                    }
                }
                const nullKeys = this.checkNullValues({
                    name: this.contactPayload.name
                });
                if (this.contactPayload.phone) {
                    if (!this.isPhoneValid) {
                        throw {
                            phone: ["Wrong Format"],
                        };
                    }
                }
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    let method = 'Added'
                    if (this.contactData) {
                        await this.updateContact({ id: this.id, payload: { ...this.contactPayload, id: this.contactData.id } })
                        method = 'Updated'
                    }
                    else await this.addContact({ id: this.id, payload: this.contactPayload })
                    await this.setNotification({
                        msg: `Contact Successfully ${method}`,
                        type: "success",
                        color: "green",
                    });
                    this.$emit("contactUpdated", true)
                    this.resetPopup()
                    this.setLoader(false)
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
                this.setLoader(false);
                console.log(error)
            }
        }
    },
    watch: {
        contactData() {
            if (this.contactData) this.contactPayload = this.contactData
        }
    }
}
</script>