<template>
  <div>
    <div class="data-table bg-white rounded padding-customer-documents">
      <div class="table_row">
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <h4>Documents</h4>
          <b-button
            @click="addDocumentPopup"
            class="document equipment d-flex justify-content-center align-items-center mx-1 my-1"
          >
            <b-icon icon="plus" class="p-0"></b-icon>
            <p class="mb-0 ml-2 text-white">Document</p>
          </b-button>
        </div>

        <b-table
          responsive
          :fields="fields"
          :items="items"
          striped
          hover
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(action)="data">
            <b-icon
              v-if="data.item.document"
              style="fill: #2D69F6"
              class="mr-4"
              icon="eye-fill"
              @click="openLink(data.item.document)"
            />
            <b-icon
              style="fill: limegreen"
              class="mr-4"
              icon="pencil"
              @click="editDocument(data)"
            />
            <b-icon
              style="fill:#FF0000"
              icon="trash-fill"
              @click="showPopup(data.item.pk)"
            />
          </template>
        </b-table>
        <div class="pagination justify-content-end mt-4">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
    <b-modal
      id="customer-document"
      centered
      hide-footer
      :title="editDocumentFlag ? 'Edit Document' : 'Add Document'"
      :body-class="'p-4'"
    >
      <div class="d-block text-center">
        <b-row>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Date</label>
              <b-form-datepicker
                id="modal-datepicker"
                class=""
                v-model="doc.date"
                reset-button
              ></b-form-datepicker>
            </div>
            <div class="text-left">
              <label class="state">Description</label>
              <b-form-textarea
                id="textarea"
                placeholder="Enter Description"
                v-model="doc.description"
                rows="3"
              ></b-form-textarea>
            </div>
          </b-col>
          <b-col lg="6" md="8" sm="12">
            <div class="upload-btn-wrapper">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button v-if="!doc.fileName" class="foo">
                Drop your document here, or browse
              </button>
              <button v-else class="foo">{{ doc.fileName }}</button>
              <input
                ref="file"
                type="file"
                name="myfile"
                v-on:change="handleFileUpload"
                accept=".pdf, .png, .jpg, .jpeg"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button
          class="mt-3 mr-3 btn cancel"
          block
          @click="$bvModal.hide('customer-document')"
        >
          Cancel
        </b-button>
        <b-button class="mt-3 btn save" variant="primary" @click="createDocument">Save</b-button>
      </div>
    </b-modal>
    <DeleteModal
      v-if="showCustomerDocModal"
      @confirm="removeDocument($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DeleteModal from "@/components/DeleteModal.vue";

export default {
  name: "CustomerDocuments",
  components: {
    DeleteModal,
  },
  data() {
    return {
      showCustomerDocModal: false,
      documentID: null,
      editDocumentFlag: false,
      customerId: this.$route.params.id,

      doc: {
        date: null,
        description: null,
        fileName: null,
        document: null,
      },
      fields: [
        {
          label: "Date",
          key: "date",
          sortable: true,
        },

        {
          label: "Description",
          key: "description",
          sortable: true,
        },
        {
          key: "Action",
          sortable: true,
        },
      ],
      items: [],
      perPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters(["customerDocs"]),
    rows() {
      return this.items.length;
    },
  },
  watch: {
    customerDocs() {
      this.items = [];
      this.customerDocs.map((doc) => {
        this.items.push({
          date: doc.date,
          description: doc.description,
          document: doc.document,
          pk: doc.id,
        });
      });
    },
  },
  methods: {
    ...mapActions([
      "getAllCustomerDocuments",
      "createCustomerDocument",
      "setNotification",
      "deleteCustomerDocument",
      "editCustomerDocument",
    ]),
    addDocumentPopup() {
      this.editDocumentFlag = false;
      this.$bvModal.show("customer-document");
      this.doc = {
        date: null,
        description: null,
        fileName: null,
        document: null,
      };
    },

    async openLink(document) {
      console.log(document);
      if (document.startsWith("https")) {
        window.open(document, "_blank");
      } else {
        window.open(`https://api.digitalpermitbook.com${document}`, "_blank");
      }
    },

    editDocument(data) {
      this.editDocumentFlag = true;
      this.doc = {
        ...this.customerDocs[data.index],
      };

      if (this.doc.document) {
        let fileName = "";
        if (typeof this.doc.document === "string") {
          fileName = this.doc.document;
        } else {
          fileName = this.doc.document.name;
        }
        this.doc.fileName = fileName.substring(fileName.lastIndexOf("/") + 1);
      }

      this.$bvModal.show("customer-document");
    },

    handleFileUpload() {
      this.doc.document = this.$refs.file.files[0];
      this.doc = {
        ...this.doc,
        fileName: this.doc.document.name,
      };
    },

    async createDocument() {
      let formData = new FormData();
      if (this.$refs.file.files.length === 0) {
        this.doc.document = null;
      }

      formData.append("date", this.doc.date);
      formData.append("description", this.doc.description);

      this.doc.document ? formData.append("document", this.doc.document) : null;
      formData.append("customer", this.customerId);

      if (!this.editDocumentFlag) {
        if (this.doc.date && this.doc.document) {
          try {
            await this.createCustomerDocument(formData);

            await this.setNotification({
              msg: "Document Successfully added!!",
              type: "success",
              color: "green",
            });

            this.$bvModal.hide("customer-document");
            this.doc = {
              date: null,
              document: null,
              description: null,
              fileName: null,
            };
          } catch (e) {
            console.log(e.message);
          }
        } else {
          this.errors.date = this.doc.date ? null : ["This field is required."];
          this.errors.document = this.doc.document
            ? null
            : ["Please upload document."];
          console.log(this.errors);
        }
      } else {
        try {
          formData.append("pk", this.doc.id);

          this.editDocumentFlag = false;
          await this.editCustomerDocument(formData);
          await this.setNotification({
            msg: "Document Successfully Updated!!",
            type: "success",
            color: "green",
          });
          this.$bvModal.hide("customer-document");
          this.doc = {
            date: null,
            document: null,
            description: null,
            fileName: null,
          };
        } catch (e) {
          console.log(e.message);
        }
      }
    },

    showPopup(id) {
      this.showCustomerDocModal = true;
      this.documentID = id;
    },

    async removeDocument(item) {
      this.showCustomerDocModal = false;
      if (item) {
        try {
          await this.deleteCustomerDocument({
            id: this.documentID,
          });
          await this.setNotification({
            msg: `Successfully deleted Document`,
            type: "success",
            color: "green",
          });
        } catch (error) {
          await this.setNotification({
            msg: error,
            type: "error",
            color: "red",
          });
        }
      }
    },
  },
  async mounted() {
    try {
      await this.getAllCustomerDocuments();
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style>
.padding-customer-documents {
  padding: 25px 25px 0px !important;
}
</style>
