<template>
  <div class="">
    <!-- <b-img class="w-100 " :src="require('@/assets/img.svg')"></b-img> -->
  </div>
</template>

<script>
export default {
  name: "Auth",
};
</script>
<style>
.auth-bg-img {
  background-image: url("./../../assets/img.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
