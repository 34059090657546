<template>
    <div>
        <b-modal id="modal-schedule" class="maintenance_modal" centered
            :footer-class="'d-block px-3 pt-0 pb-3 m-0'">
            <template #modal-header="{ close }">
        <h5 class="modal-title">
          {{ editFlag ? 'Edit Schedule' : 'Add Schedule' }}
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
            <b-row>
                <b-col sm="12">
                    <b-row>
                        <b-col sm="12">
                            <div class="text-left mb-3">
                                <label for="modal-due-date-datepicker-" class="state">Date</label>
                                <b-form-datepicker id="modal-due-date-datepicker" class="" v-model="schedulePayload.due_date"
                                    reset-button></b-form-datepicker>
                                <p v-if="errors && errors.due_date" class="field-error">
                                    {{ errors.due_date[0] }}
                                </p>
                            </div>
                        </b-col>
                        <b-col md="12" sm="12">
                            <div class="text-left select_arrow mb-3">
                                <label class="state">Unit #</label>
                                <v-select class="bg_white w-100" v-model="schedulePayload.unit_no" :options="nameOptions"
                                    item-value="id" item-text="label" :reduce="(option) => option.id"
                                    
                                     @search="searchAssets">
                                    <span slot="no-options">
                                        Search Unit # ...
                                    </span>
                                </v-select>
                                <p v-if="errors && errors.unit_no" class="field-error">
                                    {{ errors.unit_no[0] }}
                                </p>
                            </div>
                        </b-col>
                        <b-col md="12">
                            <div class="text-left">
                                <label class="state">Description</label>
                                <b-form-textarea id="textarea" placeholder="Enter Description" rows="5"
                                    v-model="schedulePayload.description"></b-form-textarea>
                                <p v-if="errors && errors.description" class="field-error">
                                    {{ errors.description[0] }}
                                </p>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <template #modal-footer>
                <div class="text-right mt-3 modal-footer border-0 p-0">
                    <b-button class="mr-3 btn cancel m-0" block
                        @click="resetForm()">Cancel</b-button>
                    <b-button class="btn save mx-0" variant="primary" @click="checkMaintenanceForm">Save</b-button>
                </div>
            </template>
        </b-modal>
       
    </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
export default {
    name: "addSchedule",
    components: { vSelect },
    data() {
        return {
            editFlag: false,
            nameOptions: [],
            schedulePayload: {
                due_date: null,
                unit_no: null,
                description: null
            },
            errors: {}
        }
    },
    computed:{
        ...mapGetters(['currentMaintenanceSchedule', 'userCompany'])
    },
    watch:{
        currentMaintenanceSchedule(){
            if(this.currentMaintenanceSchedule){
                this.nameOptions = [
                {
                    id: this.currentMaintenanceSchedule.unit.id,
                    label: this.currentMaintenanceSchedule.unit.unit_no,
                    type: "equipment",
                }
                ]
                this.schedulePayload = {
                    due_date: this.currentMaintenanceSchedule.date,
                    description: this.currentMaintenanceSchedule.description,
                    unit_no:this.currentMaintenanceSchedule.unit.id
                }
                this.editFlag = true
            }
        }
    },
    methods: {
        ...mapActions(["getAllEquipments","postMaintenanceSchedule", "setNotification", "setLoader", 'patchMaintenanceSchedule','setMaintenanceSchedule']),
        resetForm(){
            this.$bvModal.hide('modal-schedule')
            this.editFlag = false
            this.schedulePayload = {
                due_date: null,
                description: null,
                unit_no: null
            }
            this.errors = {}
            this.setMaintenanceSchedule(null)
        },
        async searchAssets(searchText) {
            let options = []
            let url = `unit_no=${searchText}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            const equipmentRecords = await this.getAllEquipments(url);
            equipmentRecords.records.map((item) => {
                options.push({
                    id: item.id,
                    label: item.unit_no,
                    type: "equipment",
                });
            });
            this.nameOptions = options
        },
        async checkMaintenanceForm() {
            try {
                const nullKeys = this.checkNullValues(this.schedulePayload);
                if (nullKeys.length) {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
                else await this.addMaintenanceSchedule()
            }
            catch (error) {
                if (typeof error === "object") {
                    console.log(error);
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        async addMaintenanceSchedule(unitNo = null) {
            let method = "";
            try {
                this.setLoader(true)
                if (
                    !this.schedulePayload.unit_no &&
                    unitNo &&
                    typeof unitNo !== "object"
                ) {
                    this.schedulePayload.unit_no = unitNo;
                }
                if (this.editFlag) {
                    await this.patchMaintenanceSchedule({
                      id: this.currentMaintenanceSchedule.pk,
                      payload: this.schedulePayload,
                    });
                    method = "updated";
                    this.$emit("scheduleUpdated", true)
                } else {
                    await this.postMaintenanceSchedule(this.schedulePayload);
                    method = "added";
                    this.$emit("scheduleUpdated", true)
                }
                this.schedulePayload = {
                    unit_no: null,
                    due_date: null,
                    description: null,
                };
                this.$bvModal.hide("modal-schedule");
                this.editFlag = false;
                    await this.setNotification({
                        msg: `Maintenance Schedule successfully ${method}`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)

            } catch (error) {
                this.setLoader(false)
                if (typeof error === "object") {
                    console.log(error);
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        checkNullValues(payload, type) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                console.log(key, value);
                if (
                    !value &&
                    key !== "document" &&
                    key.toLowerCase() !== "odometer" &&
                    key !== "amount"
                ) {
                    nullKeys.push(key);
                }
            }
            if (
                type === "maintenance" &&
                this.addSchedule &&
                !this.schedulePayload.description
            ) {
                nullKeys.push("schedule_description");
            }
            return nullKeys;
        },

    },
}
</script>
<style>
.schedule-btn{
    width: 230px !important;
    font-size: 14px !important;
}
</style>