<template>
  <b-container fluid class="mt-2 px-1 px-sm-3 pb-3 maintenance-page">
    <b-row>
      <b-col cols="" class="">
        <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
          <h2 class="text-left">Maintenance</h2>
          <div class="d-flex justify-content-between justify-content-sm-center flex-wrap"
            v-if="(activeTab === 'records' || activeTab === 'schedule') && $store.state.user.user.team_member.role !== 'Dispatcher'">
            <b-button @click="showMaintenaceModal()"
              class="export equipment d-flex justify-content-center align-items-center mx-1 my-1 w-fit-content" variant="primary">
              <b-icon icon="plus" aria-hidden="true"></b-icon>
              <p class="mb-0 ml-2 text-white">Add {{ activeTab === 'records' ? 'Record' : 'Schedule' }}</p>
            </b-button>
            <b-dropdown id="dropdown-divider" text="text" class="load-dropdown">
              <template slot="button-content">
                <b-icon class="dots" icon="three-dots"></b-icon>
              </template>
              <b-dropdown-item-button class="mb-2" @click="downloadExcel">
                <p class="text_secondary cursor-pointer mb-0 f-14">Export CSV</p>
              </b-dropdown-item-button>
              <b-dropdown-item-button @click="$bvModal.show('modal-bulk-upload')">
                <p class="text_secondary cursor-pointer mb-0 f-14">Import CSV</p>
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
        </div>
        <div class="data-table team-data-table bg-white rounded table-custom-border">
          <div class="bg-white rounded">
            <v-tabs class="team-tabs mb-0">
              <v-tab @click="activeTab = 'records'">Records</v-tab>
              <v-tab @click="activeTab = 'totals'">Totals</v-tab>
              <v-tab @click="activeTab = 'schedule'">Schedule</v-tab>
            </v-tabs>
          </div>
          <div class="bg-white pt-3 pt-sm-4">
            <div class="table_row">
              <Records v-if="activeTab === 'records'" ref="recordsSection" :allowAddRecord="allowAddRecord" @close="allowAddRecord = false"/>
              <Schedule v-if="activeTab === 'schedule'" ref="scheduleSection"/>
              <Totals v-if="activeTab === 'totals'" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <DeleteModal v-if="showModal" @confirm="deleteItem($event)" />
    <!-- Import CSV Modal  -->
    <b-modal id="modal-bulk-upload" centered hide-footer title="Import CSV" @shown="resetField" no-close-on-backdrop>
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Import CSV
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="d-block text-center">
        <b-row class="import-excel-modal mx-0">
          <b-col cols="12" class="px-0">
            <div class="upload-btn-wrapper mt-0">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button class="foo" v-if="!fileName">
                Drop your document here, or browse
              </button>
              <button v-else class="foo">{{ fileName }}</button>
              <input ref="docFile" type="file" name="docFile" @change="handleFileUpload" accept=".csv" />
              <p v-if="errors && errors.document" class="field-error">
                {{ errors.document }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" class="text-left cursor-pointer mt-2 pt-1 pl-1">
            <a class="download-sample-file"
              :href="activeTab === 'records' ? '/files/RecordsSample.csv' : '/files/ScheduleSample.csv'"
              download="sample.csv">
              <b-img :src="require('@/assets/sample-file.svg')"></b-img>
              Download Sample File
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button class="mx-0 mr-3 h-40px w-100px" @click="$bvModal.hide('modal-bulk-upload')"
                            variant="secondary">Cancel</b-button>
        <b-button class="mx-0 mb-0 h-40px w-100px" variant="primary"
                                @click="bulkUploadData">Save</b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import DeleteModal from "@/components/DeleteModal.vue";
import Records from "@/components/Maintenance/Records.vue";
import Schedule from "@/components/Maintenance/Schedule.vue";
import Totals from "@/components/Maintenance/Totals";

export default {
  name: "maintenance",
  components: {
    DeleteModal,
    Records,
    Schedule,
    Totals
  },
  data() {
    return {
      activeTab: "records",
      maintenanceSchedulePopup: false,
      allowAddRecord:false,
      perPage: 10,
      perPageSchedule: 10,
      currentPage: 1,
      currentPageSchedule: 1,
      items: [],
      itemId: 0,
      showModal: false,
      deleteScheduleItem: false,
      deleteMaintenanceItem: false,
      scheduleItems: [],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      searchDescriptionS: null,
      searchDays: null,
      fields: [
        {
          key: "Unit #",
          sortable: true,
        },
        {
          key: "Type",
          sortable: true,
        },
        {
          key: "Date",
          sortable: true,
        },
        {
          key: "Description",
          sortable: true,
        },
        {
          key: "Odometer",
          sortable: true,
        },
        {
          key: "Amount",
          sortable: true,
        },
        {
          key: "Action",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
        },
      ],
      scheduleFields: [
        {
          key: "Unit #",
          sortable: true,
        },
        {
          key: "Type",
          sortable: true,
        },
        {
          key: "Due Date",
          sortable: true,
        },
        {
          key: "Description",
          sortable: true,
        },
        {
          key: "Days",
          sortable: true,
        },
        {
          key: "Completed",
          sortable: true,
        },
        {
          key: "Action",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
        },
      ],
      selected: null,
      addSchedule: false,
      fileName: null,
      document: null,
      errors: {
        document: null,
      },
      mainPayload: {
        date: null,
        unit_no: null,
        description: null,
        amount: null,
        odometer: null,
        document: null,
      },
      scheduleLoad: {
        unit_no: null,
        due_date: null,
        description: null,
      },
    };
  },
  computed: {
    ...mapGetters(["maintenance", "allEquipments", "maintenanceSchedule"]),
  },
  methods: {
    ...mapActions([
      "getMaintenance",
      "getAllEquipments",
      "postMaintenance",
      "setNotification",
      "patchMaintenance",
      "deleteMaintenance",
      "getMaintenanceSchedule",
      "postMaintenanceSchedule",
      "patchMaintenanceSchedule",
      "deleteMaintenance",
      "deleteMaintenanceSchedule",
      "retrieveDocument",
      "postExcelData",
      "addBulkMaintenanceRecords",
      "addBulkMaintenanceSchedule",
      "setLoader"
    ]),
    async downloadExcel(){
      if(this.activeTab === 'records') await this.$refs.recordsSection.downloadExcel()
      else await this.$refs.scheduleSection.downloadExcel()
    },
    resetField() {
      this.$refs.docFile.value = null;
      this.fileName = null;
      this.document = null;
      this.errors = {
        document: null,
      };
    },
    handleFileUpload() {
      this.document = this.$refs.docFile.files[0];
      this.fileName = this.document.name;
    },
    showMaintenaceModal() {
      if (this.activeTab === 'records') this.allowAddRecord = true
      else this.$bvModal.show("modal-schedule");
    },
    showModalSchedulePopup() {
      this.$bvModal.show("modal-schedule");
      this.errors = {};
      this.scheduleLoad = {
        unit_no: null,
        due_date: null,
        description: null,
      };
    },
    deleteItem(item) {
      if (item) {
        if (this.deleteMaintenanceItem) {
          this.deleteRecord(this.itemId);
        } else {
          this.deleteSchedule(this.itemId);
        }
      }
      this.showModal = false;
      this.deleteMaintenanceItem = false;
      this.deleteScheduleItem = false;
    },
    async bulkUploadData() {
      if (this.document) {
        this.setLoader(true)
        this.errors = {
          document: null,
        };
        try {
          let formData = new FormData();
          formData.append("file", this.document);
          const newRecords = this.activeTab === 'records' ? await this.addBulkMaintenanceRecords(formData) : this.addBulkMaintenanceSchedule(formData);
          if (newRecords) {
            // await this.getTeamMemberData();
            await this.setNotification({
              msg: `Successfully Imported ${this.activeTab === 'records' ? 'Records Data' : 'Schedule Data'}`,
              type: "success",
              color: "green",
            });
            if(this.activeTab === 'records') await this.$refs.recordsSection.getMaintenanceData()
            else await this.$refs.scheduleSection.getMaintenanceScheduleData()
            this.$bvModal.hide("modal-bulk-upload");
            this.setLoader(false)
          }
        } catch (e) {
          this.setLoader(false)
          if ("equipment" in e) {
            await this.setNotification({
              msg: e["equipment"][0],
              type: "error",
              color: "red",
            });
          } else {
            await this.setNotification({
              msg: "The Uploaded CSV has Wrong/Missing Fields.",
              type: "error",
              color: "red",
            });
          }
        }
      } else {
        this.errors = {
          document: "This field is required.",
        };
      }
    },
    showPopup(id, item) {
      if (item === "Maintenance") {
        this.deleteMaintenanceItem = true;
      } else {
        this.deleteScheduleItem = true;
      }
      this.showModal = true;
      this.itemId = id;
    },
  },
  async mounted() {
  },
};
</script>

<style>
.bi-x.cross_icon {
  position: absolute;
  right: 10px !important;
  top: 42px !important;
  width: 8px;
  cursor: pointer;
  fill: #000;
}

.maintenance h4 {
  font-size: 18px !important;
  color: var(--black);
  font-weight: 600;
  margin-top: 20px;
}

.maintenance #__BVID__45___BV_tab_button__ {
  width: 292px;
  margin-right: 0;
}

.maintenance #__BVID__18___BV_tab_button__ {
  width: 212px !important;
}

.maintenance #maintenance-modalexample-datepicker {
  display: none;
}

.maintenance fieldset {
  width: 100%;
  font-size: var(--extrasmall) !important;
}

.record {
  font-size: var(--small) !important;
}

.due_date .date {
  width: 227px !important;
  margin-right: 25px !important;
}

.due_date .desc {
  width: -webkit-fill-available !important;
}

.desc textarea {
  background-color: #f5f5f5 !important;
}

.modal-content .vs__dropdown-toggle {
  height: 40px;
}

.upload-btn-wrapper {
  width: 100%;
}

.upload-btn-wrapper .foo {
  width: 100% !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 631px !important;
    width: 631px !important;
  }
}

.maintenance-page .vs__dropdown-menu {
  min-width: 150px !important;
}

.modal .form-control {
  font-size: var(--extrasmall) !important;
  padding-right: 0;
  /* display: flex; */
  align-items: center;
}

.b-form-btn-label-control.form-control>.btn {
  padding-right: 5px;
  padding-left: 5px;
}

.b-form-btn-label-control.form-control>.form-control {
  padding-left: 0 !important;
  padding-right: 20px;
  font-size: 11px !important;
  padding-top: 8px;
  text-transform: capitalize;
  line-height: 14px;
}

.form-control:focus {
  box-shadow: unset;
}

@media (max-width: 1560px) {
  .b-form-btn-label-control.form-control>.form-control {
    font-size: 12px !important;
    font-weight: 500;
  }
}

@media (max-width: 991px) {
  .maintenance .card-text {
    overflow-x: scroll;
  }

  .maintenance #__BVID__45___BV_tab_button__,
  .maintenance #__BVID__18___BV_tab_button__ {
    width: auto !important;
  }
}

@media (max-width: 575px) {
  .due_date.d-flex {
    display: block !important;
  }
}</style>
