<template>
    <div class="bg-white rounded">
        <div class="table pt-3 mb-0">
            <div class="add_license d-flex justify-content-between align-items-center flex-wrap mb-3 px-3">
                <h3 class="font-weight-bold f-20 text-left mb-0 text-black">Tags</h3>
                <b-button @click="$bvModal.show('tag-modal')"
                    class="export equipment d-flex justify-content-center align-items-center w-fit-content" v-if="$store.state.user.user &&
                        ($store.state.user.user.team_member.role === 'Admin' ||
                            $store.state.user.user.team_member.role === 'Operations')
                        " variant="primary">
                    <b-icon icon="plus" class="p-0" aria-hidden="true"></b-icon>
                    <p class="mb-0 ml-2 text-white p-0">Add Tag</p>
                </b-button>
            </div>
            <b-table responsive :fields="fields" :items="items" hover :per-page="perPage" :current-page="currentPage"
                show-empty>
                <template #cell(action)="data">
                    <b-icon style="fill: limegreen" class="mr-4" icon="pencil" @click="editTagItem(data.item)" />
                    <b-icon style="fill:#FF0000" icon="trash-fill" @click="showTagDeletePopup(data.item.id)" />
                </template>
            </b-table>
            <div class="entries-pagination px-3 px-sm-4">
                <div class="d-flex align-items-center flex-wrap">
                    <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                        <b-form-select v-model="perPage" :options="pageOptions" />
                    </div>
                    <div class="pagination">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="my-table"></b-pagination>
                    </div>
                    <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">
                        {{ positionText }}
                    </p>
                </div>
            </div>
        </div>
        <b-modal id="tag-modal" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
            :body-class="'equipment-edit-modal'" no-close-on-esc>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    {{ editTag ? 'Edit Tag' : 'Add Tag' }}
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="body">
                <b-row>
                    <b-col cols="12" class="mt-3">
                        <b-form-group label="Enter Tag" class="text-left mb-0">
                            <b-form-input v-model="tag" placeholder="Enter Tag" class="unit" required></b-form-input>
                            <p v-if="tagError" class="field-error mb-lg-3">
                                {{ tagError }}
                            </p>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <div class="text-right modal-footer border-0 p-0 mx-2">
                    <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="resetForm()"
                        variant="secondary">Cancel</b-button>
                    <div class="d-flex align-items m-0">
                        <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="updateTag()">{{ editTag ?
                            "Save" : "Add Tag" }}</b-button>
                    </div>
                </div>
            </template>
        </b-modal>
        <DeleteModal v-if="showDeletePopup" @confirm="deleteItem($event)" />
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DeleteModal from "@/components/DeleteModal.vue";
export default {
    name: "TagsSection",
    components: { DeleteModal },
    data() {
        return {
            items: [],
            editTag: false,
            perPage: 50,
            currentPage: 1,
            tagID: null,
            emptyTable: false,
            showDeletePopup: false,
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
            tagError: "",
            tag: null,
            fields: [
                {
                    key: "add_tag",
                    label: "Tag",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                    stickyColumn: true,
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
        }
    },
    computed: {
        ...mapGetters(["tags", "userCompany"]),
        rows() {
            return this.items.length;
        },
        positionText() {
            let endIndex = this.currentPage * this.perPage,
                startIndex =
                    this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

            return (
                startIndex +
                " to " +
                (endIndex > this.rows ? this.rows : endIndex) +
                " of " +
                this.rows
            );
        }
    },
    methods: {
        ...mapActions(['getAllTags', "deleteCompanyTag", "setNotification", "updateCompanyTag", "addCompanyTag"]),
        async deleteItem(status) {
            if (status) {
                try {
                    await this.deleteCompanyTag(`${this.tagID}/?company_id=${this.userCompany}`)
                    await this.setNotification({
                        msg: "Tag successfully deleted!!",
                        type: "success",
                        color: "green",
                    });
                    this.tagID = null
                    this.showDeletePopup = false;
                    await this.getTags()
                }
                catch (e) {
                    console.log(e)
                }
            }
        },
        showTagDeletePopup(id) {
            this.showDeletePopup = true;
            this.tagID = id;
        },
        async getTags() {
            await this.getAllTags(this.userCompany);
            this.items = [];
            this.tags.map((tag) => {
                this.items.push({
                    add_tag: tag.add_tag,
                    id: tag.id
                })
            })
        },
        resetForm() {
            this.tagError = ""
            this.$bvModal.hide('tag-modal')
            this.tag = null
            this.tagID = null
            this.editTag = false

        },
        async updateTag() {
            if (this.tag) {
                let method = 'added'
                if (this.editTag) {
                    method = 'updated'
                    try {
                        await this.updateCompanyTag({
                            id: this.tagID,
                            payload: {
                                add_tag: this.tag
                            }
                        })
                    }
                    catch (e) {
                        await this.setNotification({
                            msg: e,
                            type: "error",
                            color: "red",
                        });
                        return
                    }
                }
                else {
                    try {
                        await this.addCompanyTag({ add_tag: this.tag, company: this.userCompany })
                    }
                    catch (e) {
                        await this.setNotification({
                            msg: e,
                            type: "error",
                            color: "red",
                        });
                        return
                    }
                }
                await this.setNotification({
                    msg: `Tag successfully ${method}!!`,
                    type: "success",
                    color: "green",
                });
                this.resetForm()
                await this.getTags()
            }
            else {
                this.tagError = "This field is required."
            }
        },
        editTagItem(data) {
            this.editTag = true
            this.tag = data.add_tag
            this.tagID = data.id
            this.$bvModal.show('tag-modal')
        },
    },
    async mounted() {
        this.getTags();
    }
}
</script>