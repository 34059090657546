<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" :title="'Add Claim'" backdrop shadow
            :visible="allowAddClaim" :right="true" no-header-close no-close-on-backdrop no-close-on-esc>
            <div class="d-block text-center mt-3">
                <b-row class="align-items-center px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Record # :" class="text-left mb-0" v-if="showRecordField">
                            <b-form-input v-model="claimPayload.record_no" type="text" placeholder="Enter Record"
                                class="unit" required></b-form-input>
                            <p v-if="errors && errors.record_no" class="field-error">
                                {{ errors.record_no[0] }}
                            </p>
                        </b-form-group>
                        <b-form-group label="Record # :" class="text-left mb-0" v-else>
                            <b-form-input v-model="lastRecord" disabled type="text" placeholder="Enter Record" class="unit"
                                required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <div class="text-left select_arrow">
                            <label class="state">Status:</label>
                            <b-form-select v-model="claimPayload.status" class="f-12" :options="[{
                                value: 'New',
                                text: 'New'
                            },
                            {
                                value: 'Open',
                                text: 'Open'
                            },
                            {
                                value: 'Closed',
                                text: 'Closed'
                            },
                            {
                                value: 'Urgent',
                                text: 'Urgent'
                            }
                            ]" />
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <label for="claim-add-datepicker" class="state">Date</label>
                        <b-form-datepicker id="claim-add-datepicker" v-model="claimPayload.date" reset-button
                            class="f-12"></b-form-datepicker>
                        <p v-if="errors && errors.date" class="field-error">
                            {{ errors.date[0] }}
                        </p>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <label class="state">Time:</label>
                        <b-form-input type="time" class="f-12" v-model="claimPayload.time"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <div class="text-left select_arrow">
                            <label class="state">Driver:</label>
                            <v-select class="bg_white w-100 f-12" v-model="claimPayload.driver" :options="driverOptions"
                                item-value="id" item-text="label" :reduce="(option) => option.id" @search="searchDriver">
                                <span slot="no-options">
                                    Search Driver ...
                                </span>
                            </v-select>
                            <p v-if="errors && errors.driver" class="field-error">
                                {{ errors.driver[0] }}
                            </p>
                        </div>
                    </b-col>
                    <b-col md="3" sm="12" class="mt-3 text-left">
                        <div class="text-left select_arrow">
                            <label class="state">Vehicle:</label>
                            <v-select class="bg_white w-100 f-12" v-model="claimPayload.vehicle" :options="vehicleOptions"
                                item-value="id" item-text="label" :reduce="(option) => option.id" @search="searchVehicle">
                                <span slot="no-options">
                                    Search Vehicle ...
                                </span>
                            </v-select>
                        </div>
                    </b-col>
                    <b-col md="3" sm="12" class="mt-3 text-left">
                        <div class="text-left select_arrow">
                            <label class="state">Trailer:</label>
                            <v-select class="bg_white w-100 f-12" v-model="claimPayload.trailer" :options="trailerOptions"
                                item-value="id" item-text="label" :reduce="(option) => option.id" @search="searchTrailer">
                                <span slot="no-options">
                                    Search Trailer ...
                                </span>
                            </v-select>
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <div class="text-left select_arrow">
                            <label class="state">Type:</label>
                            <b-form-select v-model="claimPayload.type" class="f-12" :options="[{
                                value: '',
                                text: ''
                            },
                            {
                                value: 'Self',
                                text: 'Self'
                            },
                            {
                                value: 'Insurance',
                                text: 'Insurance'
                            },
                            {
                                value: 'Other',
                                text: 'Other'
                            },
                            {
                                value: 'AL',
                                text: 'AL'
                            },
                            {
                                value: 'Cargo',
                                text: 'Cargo'
                            },
                            {
                                value: 'GL',
                                text: 'GL'
                            },
                            {
                                value: 'PD',
                                text: 'PD'
                            },
                            {
                                value: 'OO PD',
                                text: 'OO PD'
                            },
                            {
                                value: 'OO NTL',
                                text: 'OO NTL'
                            },
                            {
                                value: 'OccAcc',
                                text: 'OccAcc'
                            },
                            {
                                value: 'W.C.',
                                text: 'W.C.'
                            }
                            ]" />
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <div class="text-left select_arrow">
                            <b-form-group label="Claim #:" class="text-left mb-0">
                                <b-form-input type="text" class="f-12" placeholder="Enter Claim #"
                                    v-model="claimPayload.claim_number"></b-form-input>
                            </b-form-group>
                        </div>
                    </b-col>
                    <b-col md="12" class="text-left mt-3">
                        <label class="state text-left">Notes:</label>
                        <b-form-textarea id="textarea" class="f-12" placeholder="Enter Notes" rows="5"
                            v-model="claimPayload.notes"></b-form-textarea>
                    </b-col>
                </b-row>
                <hr class="m-4" />
                <b-row class="px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Accidents</h3>
                    <b-col md="12" sm="12" class="text-left mt-3">
                        <label class="state">Accidents:</label>
                        <v-select class="bg_white w-100 f-12" v-model="claimPayload.accident_number"
                            :options="accidentOptions" item-value="id" item-text="label" :reduce="(option) => option.id"
                            @search="searchAccidents">
                            <span slot="no-options">
                                Search Accident # ...
                            </span>
                        </v-select>
                    </b-col>
                </b-row>
                <hr class="m-4" />
                <b-row class="px-4 mb-7rem">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Details</h3>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <label class="state">Address:</label>
                        <b-form-input v-model="claimPayload.address" class="f-12"
                            placeholder="Enter Address"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <label class="state">City:</label>
                        <b-form-input v-model="claimPayload.city" class="f-12" placeholder="Enter City"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <div class="text-left select_arrow">
                            <label class="state">State:</label>
                            <v-select class="bg_white w-100 f-12" v-model="claimPayload.state" :options="states"
                                item-value="id" item-text="label" :reduce="(option) => option.id">
                            </v-select>
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <label class="state">Postal Code:</label>
                        <b-form-input v-model="claimPayload.postal_code" class="f-12"
                            placeholder="Enter Postal Code"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <label class="state">Customer:</label>
                        <b-form-input v-model="claimPayload.customer" class="f-12"
                            placeholder="Enter Customer"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3 text-left">
                        <label class="state">Load #:</label>
                        <b-form-input v-model="claimPayload.load_number" class="f-12"
                            placeholder="Enter Load #"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Insurance:</label>
                        <b-form-input v-model="claimPayload.insurance_name" class="f-12"
                            placeholder="Enter Insurance Name"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Policy #’s:</label>
                        <b-form-input v-model="claimPayload.policy_type" class="f-12"
                            placeholder="Enter Policy #’s"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Damage Value:</label>
                        <b-form-input v-model="claimPayload.damage_value" class="f-12"
                            placeholder="Enter Damage Value"></b-form-input>
                    </b-col>
                </b-row>
                <div class="sidebar-footer position-fixed">
                    <div class="text-right modal-footer border-0 p-0">
                        <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetPopup()"
                            variant="secondary">Cancel</b-button>
                        <b-button class="mx-0 h-40px w-125px px-2 mr-3" @click="onAddNewClaim(true)"
                            variant="outline-primary">Save & Open</b-button>
                        <b-button class="mx-0 h-40px w-100px" @click="onAddNewClaim()" variant="primary">Save</b-button>
                    </div>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
export default {
    components: { vSelect },
    name: "AddClaim",
    props: {
        allowAddClaim: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapGetters([
            "userCompany",
            "allEquipments",
            "allDrivers",
            "allCountries"
        ]),
    },
    data() {
        return {
            errors: {},
            states: [],
            claimID: null,
            showRecordField:false,
            claimPayload: {
                address: "",
                city: "",
                claim_number: "",
                postal_code: "",
                state: null,
                driver: null,
                status: "New",
                record_no: "",
                vehicle: null,
                trailer: null,
                date: null,
                time: null,
                notes: "",
                type: "",
                policy_type: "",
                insurance_name: "",
                damage_value: "",
                accident_number: "",
                customer: "",
                load_number: ""
            },
            driverOptions: [],
            vehicleOptions: [],
            trailerOptions: [],
            accidentOptions: [],
            lastRecord:null
        }
    },
    methods: {
        ...mapActions([
            "getAllEquipments",
            "getAllDrivers",
            "setLoader",
            "addClaim",
            "setNotification",
            "getAllCountries",
            "getAllAccidentData",
            "getLastRecord"
        ]),
        async getCountries() {
            await this.getAllCountries();
            const countries = this.allCountries.map((item) => ({
                id: item.id,
                label: item.name,
            }));
            this.getStates(countries[0]);
        },
        getStates(selectedOption) {
            this.states = this.allCountries
                .filter((country) => country.id === selectedOption.id)[0]
                .state.map((item) => ({
                    id: item.id,
                    label: item.name,
                }));
            this.claimPayload.state = this.states.filter(
                (state) => state.label === "-"
            )[0].id;
        },
        async searchAccidents(searchText) {
            let options = []
            const url = `sort_field=record_no&search_field=${searchText}&sort_order=desc&page=1&page_size=10`
            const data = await this.getAllAccidentData(url);
            data.records.forEach((x) => {
                options.push({
                    id: x.record_no.toString(),
                    label: x.record_no.toString(),
                });
            });
            this.accidentOptions = this.accidentOptions.concat(options)
            this.accidentOptions = [...new Map(this.accidentOptions.map(item => [item['id'], item])).values()]
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async onAddNewClaim(openClaim = false) {
            try {
                const nullKeys = this.checkNullValues({
                    driver: this.claimPayload.driver,
                    date: this.claimPayload.date,
                    ...this.showRecordField ? { record_no: this.claimPayload.record_no } : {}
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true);
                    if (!this.showRecordField) delete this.claimPayload.record_no
                    const record = await this.addClaim({ ...this.claimPayload, company: this.userCompany, accident_number: this.claimPayload.accident_number ? this.claimPayload.accident_number : '' })
                    await this.setNotification({
                        msg: `Claim Successfully Added`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false);
                    if (openClaim) this.$emit("claimAdded", record)
                    else this.$emit("claimAdded", null)
                    this.resetPopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
                this.setLoader(false);
            }

        },
        resetPopup() {
            this.claimPayload = {
                address: "",
                city: "",
                claim_number: "",
                postal_code: "",
                state: null,
                driver: null,
                status: "New",
                record_no: "",
                vehicle: null,
                trailer: null,
                date: null,
                time: null,
                notes: "",
                type: "",
                policy_type: "",
                insurance_name: "",
                damage_value: "",
                accident_number: "",
                customer: "",
                load_number: ""
            },
                this.errors = {}
            this.driverOptions = [],
                this.vehicleOptions = [],
                this.trailerOptions = []
            this.accidentOptions = []
            this.$emit("close")
        },
        async searchDriver(searchText) {
            let options = []
            const url = `name=${searchText}&page=1&page_size=10`
            await this.getAllDrivers(url);
            this.allDrivers.forEach((x) => {
                options.push({
                    id: x.id,
                    label: `${x.user.first_name} ${x.user.last_name}`,
                });
            });
            this.driverOptions = this.driverOptions.concat(options)
            this.driverOptions = [...new Map(this.driverOptions.map(item => [item['id'], item])).values()]
        },
        async searchVehicle(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Vehicle&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.vehicleOptions = this.vehicleOptions.concat(options)
            this.vehicleOptions = [...new Map(this.vehicleOptions.map(item => [item['id'], item])).values()]
        },
        async searchTrailer(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Trailer&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.trailerOptions = this.trailerOptions.concat(options)
            this.trailerOptions = [...new Map(this.trailerOptions.map(item => [item['id'], item])).values()]
        },
    },
    watch: {
        async allowAddClaim() {
            if(this.allowAddClaim){
                this.setLoader(true)
            const lastItemRecord = await this.getLastRecord({
                company_id: this.userCompany,
                query: `model_name=safetyclaim`
            })
            if (lastItemRecord) this.lastRecord = lastItemRecord + 1;
            else this.showRecordField = true
            this.setLoader(false)
        }
        }
    },
    async mounted(){
        await this.getCountries();
    }
}
</script>