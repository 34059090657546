<template>
  <div class="driver-pay app_user_detail bg-white rounded p-4 mt-4">
    <h5 class="text-left">Driver Pay</h5>
    <b-row>
      <b-col cols="12" class="text-left">
        <div class="driver-switch">
          <label>Pay Driver</label>
          <b-form-checkbox
            name="check-button"
            switch
            size="lg"
            v-model="singleDriverPayInfo.driver_pay"
          ></b-form-checkbox>
        </div>
      </b-col>
    </b-row>
    <hr />

    <b-row>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch text-left">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left">
              <label>Total Revenue</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.total_revenue"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="
                  handleCheck($event, singleDriverPayInfo, 'total_revenue')
                "
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.total_revenue"
              type="number"
              :readonly="
                !singleDriverPayInfo.total_revenue ||
                !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>%</span>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left">
              <label>Freight Charge</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.freight_charge"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="
                  handleCheck($event, singleDriverPayInfo, 'freight_charge')
                "
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.freight_charge"
              type="number"
              :readonly="
                !singleDriverPayInfo.freight_charge ||
                !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>%</span>
          </div>
        </div>
      </b-col>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch text-left">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left ml-md-3">
              <label>Fuel Surcharge</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.fuel_surcharge"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="
                  handleCheck($event, singleDriverPayInfo, 'fuel_surcharge')
                "
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.fuel_surcharge"
              type="number"
              :readonly="
                !singleDriverPayInfo.fuel_surcharge ||
                !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>%</span>
          </div>
        </div>
      </b-col>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch text-left">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left ml-md-3">
              <label>Detention</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.detention"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="handleCheck($event, singleDriverPayInfo, 'detention')"
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.detention"
              type="number"
              :readonly="
                !singleDriverPayInfo.detention ||
                !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>%</span>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left">
              <label>Driver Assist</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.driver_assist"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="
                  handleCheck($event, singleDriverPayInfo, 'driver_assist')
                "
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.driver_assist"
              type="number"
              :readonly="
                !singleDriverPayInfo.driver_assist ||
                !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>%</span>
          </div>
        </div>
      </b-col>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch text-left">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left ml-md-3">
              <label>Layover</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.layover"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="handleCheck($event, singleDriverPayInfo, 'layover')"
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.layover"
              type="number"
              :readonly="
                !singleDriverPayInfo.layover || !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>%</span>
          </div>
        </div>
      </b-col>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch text-left">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left ml-md-3">
              <label>TONU</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.tonu"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="handleCheck($event, singleDriverPayInfo, 'tonu')"
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.tonu"
              type="number"
              :readonly="
                !singleDriverPayInfo.tonu || !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>%</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left">
              <label>Stop Charge</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.stop_charge"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="
                  handleCheck($event, singleDriverPayInfo, 'stop_charge')
                "
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.stop_charge"
              type="number"
              :readonly="
                !singleDriverPayInfo.stop_charge ||
                !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>%</span>
          </div>
        </div>
      </b-col>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch text-left">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left ml-md-3">
              <label>Lumper</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.lumper"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="handleCheck($event, singleDriverPayInfo, 'lumper')"
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.lumper"
              type="number"
              :readonly="
                !singleDriverPayInfo.lumper || !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>%</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left">
              <label>Total Miles</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.total_miles"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="
                  handleCheck($event, singleDriverPayInfo, 'total_miles')
                "
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.total_miles"
              type="number"
              :readonly="
                !singleDriverPayInfo.total_miles ||
                !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>CPM</span>
          </div>
        </div>
      </b-col>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch text-left">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left ml-md-3">
              <label>Empty Miles</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.empty_miles"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="
                  handleCheck($event, singleDriverPayInfo, 'empty_miles')
                "
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.empty_miles"
              type="number"
              :readonly="
                !singleDriverPayInfo.empty_miles ||
                !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>CPM</span>
          </div>
        </div>
      </b-col>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch text-left">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left ml-md-3">
              <label>Loaded Miles</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.loaded_miles"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="
                  handleCheck($event, singleDriverPayInfo, 'loaded_miles')
                "
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.loaded_miles"
              type="number"
              :readonly="
                !singleDriverPayInfo.loaded_miles ||
                !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>CPM</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left">
              <label>Per Load</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.per_load"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="handleCheck($event, singleDriverPayInfo, 'per_load')"
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.per_load"
              type="number"
              :readonly="
                !singleDriverPayInfo.per_load || !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>$</span>
          </div>
        </div>
      </b-col>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch text-left">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left ml-md-3">
              <label>Stops 1-2</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.stops_1_2"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="handleCheck($event, singleDriverPayInfo, 'stops_1_2')"
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.stops_1_2"
              type="number"
              :readonly="
                !singleDriverPayInfo.stops_1_2 ||
                !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>$</span>
          </div>
        </div>
      </b-col>
      <b-col lg="4" md="6" sm="12">
        <div class="driver-switch text-left">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="text-left ml-md-3">
              <label>Stops 3+</label>
              <b-form-checkbox
                :checked="!!singleDriverPayInfo.stops_3_plus"
                name="check-button"
                switch
                size="lg"
                :disabled="!singleDriverPayInfo.driver_pay"
                @change="
                  handleCheck($event, singleDriverPayInfo, 'stops_3_plus')
                "
              ></b-form-checkbox>
            </div>
            <b-form-input
              v-model="singleDriverPayInfo.stops_3_plus"
              type="number"
              :readonly="
                !singleDriverPayInfo.stops_3_plus ||
                !singleDriverPayInfo.driver_pay
              "
            ></b-form-input>
            <span>$</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>

<script>
export default {
  name: "DriverPay",
  props: ["currentTeamMember", "singleDriverPayInfo"],

  data() {
    return {
      // payDriver:false,
    };
  },
  methods: {
    handleCheck(e, value, key) {
      if (!e) {
        value[key] = 0;
      } else {
        value[key] = 1;
      }
      // console.log(this.singleDriverPayInfo)
    },
  },
  watch: {
    "singleDriverPayInfo.driver_pay"(val) {
      if (!val) {
        this.singleDriverPayInfo.total_revenue = 0;
        this.singleDriverPayInfo.freight_charge = 0;
        this.singleDriverPayInfo.fuel_surcharge = 0;
        this.singleDriverPayInfo.detention = 0;
        this.singleDriverPayInfo.driver_assist = 0;
        this.singleDriverPayInfo.layover = 0;
        this.singleDriverPayInfo.tonu = 0;
        this.singleDriverPayInfo.stop_charge = 0;
        this.singleDriverPayInfo.lumper = 0;
        this.singleDriverPayInfo.total_miles = 0;
        this.singleDriverPayInfo.empty_miles = 0;
        this.singleDriverPayInfo.loaded_miles = 0;
        this.singleDriverPayInfo.per_load = 0;
        this.singleDriverPayInfo.stops_1_2 = 0;
        this.singleDriverPayInfo.stops_3_plus = 0;
      }
    },
  },
};
</script>

<style>
.driver-pay .driver-switch .form-control {
  width: 150px;
  margin-left: auto;
  margin-top: 7px;
}

.driver-pay .driver-switch {
  margin-bottom: 25px;
  position: relative;
}

.driver-pay .driver-switch label,
.driver-pay .driver-switch span {
  text-transform: capitalize;
  font-size: 14px;
}

.driver-pay .driver-switch span {
  color: #b4b4b4;
  position: absolute;
  right: 5px;
  top: 22px;
  text-transform: uppercase;
}

@media (max-width: 360px) {
  .driver-pay .driver-switch .form-control {
    width: 85px;
  }
}
</style>
