<template>
  <div class="data-table table_row bg-white rounded p-4 mt-4">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <h4>Schedule Credits & Deductions</h4>
      <b-button
        class="document equipment w-auto d-flex justify-content-center align-items-center m-1 px-3"
        @click="addDriverItemPopup"
      >
        <b-icon icon="plus" class="p-0"></b-icon>
        <p class="mb-0 ml-2 text-white">Item</p>
      </b-button>
    </div>

    <b-table :fields="fields" :items="items" striped hover>
      <template #cell(credit_type)="data">
        <p>{{ data.item.credit_type ? data.item.credit_type.name : "" }}</p>
      </template>
      <template #cell(amount)="data">
        <p>{{ data.item.amount ? `${addZeroes(data.item.amount)}` : "" }}</p>
      </template>
      <template #cell(max_amount)="data">
        <p>
          {{ data.item.max_amount ? `${addZeroes(data.item.max_amount)}` : "" }}
        </p>
      </template>
      <template #cell(is_active)="data">
        <b-form-checkbox
          v-model="data.item.is_active"
          name="check-button"
          switch
          size="lg"
          @change="updateDriveDeductionActive($event, data.item)"
        />
      </template>
      <template #cell(action)="data">
        <b-icon
          style="fill: limegreen"
          class="mr-4"
          icon="pencil"
          @click="editDriverDeduction(data.item)"
        />
        <b-icon
          style="fill:#FF0000"
          icon="trash-fill"
          @click="showPopup(data.item.id)"
        />
      </template>
    </b-table>
    <div class="pagination justify-content-end mt-4">
      <b-pagination
        v-model="currentPage"
        aria-controls="my-table"
      ></b-pagination>
    </div>
    <b-modal
      id="add-items-modal"
      centered
      hide-footer
      :title="edit ? 'Edit Item' : 'Add Item'"
    >
      <div class="d-block text-center">
        <b-row>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Start Date</label>
              <b-form-datepicker
                v-model="form.start_date"
                id="modal-datepicker"
                class="mb-2"
              ></b-form-datepicker>
              <p v-if="errors.start_date" class="field-error">
                This field is required.
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Period</label>
              <b-form-select
                v-model="form.period"
                :options="[
                  { value: '', text: '' },
                  { value: 'Once', text: 'Once' },
                  { value: 'Daily', text: 'Daily' },
                  { value: 'Weekly', text: 'Weekly' },
                  { value: 'Monthly', text: 'Monthly' },
                ]"
              ></b-form-select>
            </div>
          </b-col>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Type</label>
              <b-form-select
                :options="expenseTypes"
                v-model="form.credit_type"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Description</label>
              <b-form-input
                type="text"
                v-model="form.description"
                placeholder="Enter Description"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Amount</label>
              <b-form-input
                type="number"
                v-model="form.amount"
                placeholder="Enter Amount"
              ></b-form-input>
            </div>
          </b-col>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Max Amount</label>
              <b-form-input
                type="number"
                v-model="form.max_amount"
                placeholder="Enter Max Amount"
              ></b-form-input>
              <p v-if="errors.max_amount" class="field-error">
                This field is required.
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button
          class="mt-3 mr-3 btn cancel"
          block
          @click="$bvModal.hide('add-items-modal')"
          >Cancel</b-button
        >
        <b-button class="mt-3 save" variant="primary" v-if="edit" @click="setDriverDeduction"
          >Save</b-button
        >
        <b-button class="mt-3 save" variant="primary" v-else @click="createDriverDeduction"
          >Save</b-button
        >
      </div>
    </b-modal>
    <DeleteModal v-if="showModal" @confirm="deleteItem($event)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeleteModal from "@/components/DeleteModal.vue";

export default {
  name: "DriverPayTable",
  props: ["currentTeamMember"],
  components: {
    DeleteModal,
  },
  data() {
    return {
      errors: {
        start_date: false,
        max_amount: false,
      },
      form: {
        start_date: "",
        period: "Once",
        credit_type: null,
        description: "",
        amount: "",
        max_amount: "",
      },
      expenseTypes: [],
      edit: false,
      showModal: false,
      fields: [
        {
          label: "Period",
          key: "period",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Type",
          key: "credit_type",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Description",
          key: "description",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "StartDate",
          key: "start_date",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Amt.",
          key: "amount",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Max Amt.",
          key: "max_amount",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "Cur Amt.",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "Bal. Amt.",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Active",
          key: "is_active",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "Action",
          sortable: true,
          tdClass: "align-middle",
        },
      ],
      items: [],
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters(["allDriverDeductions", "expenseAccounts"]),
  },
  methods: {
    ...mapActions([
      "addDriverDeduction",
      "setNotification",
      "getExpenseAccounts",
      "getDriverDeductions",
      "deleteDriverDeduction",
      "updateDriverDeduction",
    ]),
    addDriverItemPopup() {
      this.$bvModal.show("add-items-modal");
      this.form = {
        ...this.form,
        start_date: "",
        period: "",
        credit_type: null,
        description: "",
        amount: "",
        max_amount: "",
      };
      this.errors = {
        start_date: false,
        max_amount: false,
      };
      this.edit = false;
    },
    async setDriverDeduction() {
      try {
        await this.updateDriverDeduction({
          ...this.form,
          id: this.itemId,
        });
        await this.setNotification({
          msg: `Successfully updated Driver Deduction`,
          type: "success",
          color: "green",
        });
        this.$bvModal.hide("add-items-modal");
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    showPopup(id) {
      this.showModal = true;
      this.itemId = id;
    },
    async updateDriveDeductionActive(e, data) {
      let errors = this.checkErrors();
      if (errors) {
        return;
      }
      this.form = {
        ...data,
        credit_type: data.credit_type ? data.credit_type.id : null,
      };
      try {
        await this.updateDriverDeduction({
          ...this.form,
          id: data.id,
        });
        await this.setNotification({
          msg: `Successfully updated Driver Deduction`,
          type: "success",
          color: "green",
        });
        this.$bvModal.hide("add-items-modal");
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    async deleteItem() {
      this.showModal = false;
      try {
        await this.deleteDriverDeduction(this.itemId);
        await this.setNotification({
          msg: `Successfully deleted Driver Deduction`,
          type: "success",
          color: "green",
        });
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    checkErrors() {
      if (!this.form.start_date || !this.form.max_amount) {
        this.errors.start_date = !this.form.start_date;
        this.errors.max_amount = !this.form.max_amount;
        return true;
      } else {
        this.errors.start_date = false;
        this.errors.max_amount = false;
        return false;
      }
    },
    addZeroes(num) {
      return parseFloat(num).toLocaleString("en", {
        useGrouping: false,
        minimumFractionDigits: 2,
      });
    },
    async createDriverDeduction() {
      let errors = this.checkErrors();
      if (errors) {
        return;
      }
      try {
        await this.addDriverDeduction(this.form);
        await this.setNotification({
          msg: `Successfully added Driver Deduction`,
          type: "success",
          color: "green",
        });
        this.$bvModal.hide("add-items-modal");
        this.form.name = "";
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    editDriverDeduction(item) {
      this.$bvModal.show("add-items-modal");
      this.errors = {
        start_date: false,
        max_amount: false,
      };
      this.edit = true;
      console.log(item.credit_type);
      this.form = {
        ...this.form,
        start_date: item.start_date,
        period: item.period,
        credit_type: item.credit_type ? item.credit_type.id : null,
        description: item.description,
        amount: item.amount,
        max_amount: item.max_amount,
      };
      this.itemId = item.id;
    },
  },
  watch: {
    allDriverDeductions() {
      this.items = [];
      this.allDriverDeductions.map((item) => {
        this.items.push({
          id: item.id,
          period: item.period,
          credit_type: item.credit_type,
          description: item.description,
          start_date: item.start_date,
          amount: item.amount,
          max_amount: item.max_amount,
          is_active: item.is_active,
        });
      });
    },
  },
  async mounted() {
    await this.getExpenseAccounts();

    this.expenseTypes.push({
      value: null,
      text: "",
    });
    this.expenseAccounts.map((item) => {
      this.expenseTypes.push({
        value: item.id,
        text: item.name,
      });
    });
    await this.getDriverDeductions(this.currentTeamMember.id);
    this.form.driver = this.currentTeamMember.id;
  },
};
</script>

<style></style>
