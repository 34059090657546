<template>
  <div class="integration-box">
    <div class="d-flex align-items-center flex-wrap">
      <img :src="require('@/assets/motive.svg')" />
      <button v-if="status" type="button"
        class="h-100 btn btn-primary export equipment ml-0 ml-sm-4 mt-2 mt-sm-0 h-40px w-100px">
        Active
      </button>
      <button v-else type="button" class="h-100 btn btn-secondary close-btn ml-4 mt-2 mt-sm-0 h-40px">
        Inactive
      </button>
    </div>
    <p class="mt-3 text-tertiary">
      Motive integration will integrate your drivers, vehicles and trailers to
      our platform.
    </p>
    <div v-if="status">
      <div class="d-flex align-items-center flex-wrap mb-3" v-for="(item, index) in buttonsData" :key="index">
        <div>
          <p class="mb-0">{{ item.title }}</p>
          <button @click="setSyncData(item.link)" type="button" :disabled="item.status === true ? false : true"
            class="btn btn-primary export equipment h-40px w-100px text-white">
            Sync Data
          </button>
        </div>
        <!-- <div class="ml-5">
                    <p class="mb-0">Update</p>
                    <b-form-checkbox name="check-button" switch size="lg"></b-form-checkbox>
                </div> -->
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between buttons-section flex-wrap">
      <div>
        <b-button type="button" v-if="status" class="h-40px w-125px" variant="danger" @click="removeMotiveToken()">
          Deactivate
        </b-button>
        <a class="h-40px btn btn-success assigned-equipment w-100px border-0 d-flex align-items-center justify-content-center"
          href="https://api.gomotive.com/oauth/authorize?client_id=8lnqmXjkGSu9ZXRa3A2Dslo-SXruM2kFboi94ThBwVo&redirect_uri=https://app.digitalpermitbook.com/company/motive&response_type=code&scope=users.manage+vehicles.manage+assets.manage+companies.read+locations.vehicle_locations_list+locations.vehicle_locations_single+locations.driver_locations"
          v-else>Activate</a>
      </div>
      <button type="button" class="btn btn-secondary close-btn w-100px h-40px" @click="$emit('close')">
        Close
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Motive-Integration",
  props: ["status"],
  data() {
    return {
      buttonsData: [
        {
          title: "Vehicles",
          link: "motive_vehicle_sync",
          status: true,
          data: "Vehicle",
        },
        {
          title: "Trailers",
          link: "motive_trailer_sync",
          status: true,
          data: "Trailer",
        },
        {
          title: "Drivers",
          link: "motive_driver_sync",
          status: true,
          data: "Driver",
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "setLoader",
      "setNotification",
      "setAssetsData",
      "getAssetsData",
      "updateCompany"
    ]),
    async setSyncData(link) {
      try {
        this.setLoader(true);
        await this.setAssetsData(link);
        await this.setNotification({
          msg: "Sync done successfully !!",
          type: "success",
          color: "green",
        });
        this.setLoader(false);
      } catch (error) {
        this.setLoader(false);
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    setButtonsData() {
      if (
        this.assetsData.filter((item) => item.partner_name === "Motive")
          .length === 0
      )
        this.buttonsData[2].status = false;
    },
    async removeMotiveToken() {
      try {
        delete this.company[0].logo
        await this.updateCompany({
          id: this.userCompany,
          payload: { ...this.company[0], motive_refreshtoken: null, state: this.company[0].state.id },
        });
        await this.setNotification({
          msg: "Motive Integration removed successfully !!",
          type: "success",
          color: "green",
        });
      }
      catch (e) {
        console.log(e)
      }
    }
  },
  computed: {
    ...mapGetters(["assetsData", "company", "userCompany"]),
  },
  mounted() { },
};
</script>
