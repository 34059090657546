<template>
  <div>
    <div class="data-table bg-white rounded m-0">
      <div class="table_row">
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <h4>Contacts</h4>
          <b-button
            @click="openModal"
            class="document equipment d-flex justify-content-center align-items-center mx-1 my-1"
          >
            <b-icon icon="plus" class="p-0"></b-icon>
            <p class="mb-0 ml-2 text-white">Contact</p>
          </b-button>
        </div>
        <b-table
          responsive
          :fields="fields"
          :items="items"
          striped
          hover
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(action)="data">
            <b-icon
              style="fill: limegreen"
              class="mr-4"
              icon="pencil"
              @click="editContact(data.item)"
            />
            <b-icon
              style="fill:#FF0000"
              icon="trash-fill"
              @click="showCustomerContactDeletePopup(data.item.id)"
            />
          </template>
        </b-table>

        <div class="entries-pagination mt-0">
          <div
            class="d-flex align-items-center justify-content-between flex-wrap"
          >
            <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
              <label for="" class="ml-0">Show</label>
              <b-form-select v-model="perPage" :options="pageOptions" />
              <label for="" class="me-0">entries</label>
            </div>
            <div class="pagination">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
          <p class="mt-2">{{ positionText }}</p>
        </div>
      </div>
    </div>
    <b-modal
      id="customer-contact"
      centered
      hide-footer
      :title="editDocumentFlag ? 'Edit Contact' : 'Add Contact'"
    >
      <div class="d-block text-center">
        <b-row>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Name</label>
              <b-form-input
                type="text"
                placeholder="Enter Name"
                required
                v-model="customer.name"
              ></b-form-input>
              <p v-if="errors.name" class="field-error">
                This field is required.
              </p>
            </div>
          </b-col>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Role</label>
              <b-form-input
                type="text"
                placeholder="Enter Role"
                required
                v-model="customer.role"
              ></b-form-input>
            </div>
          </b-col>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Phone</label>
              <vue-tel-input
                v-bind="bindProps"
                class="w-100 f-14"
                @validate="checkNum"
                v-model="customer.phone"
              />
              <p v-if="errors.phone" class="field-error">
                The Format is Incorrect!
              </p>
            </div>
          </b-col>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Email</label>
              <b-form-input
                type="text"
                placeholder="Enter Email"
                required
                v-model="customer.email"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button
          class="mt-3 mr-3 btn cancel"
          block
          @click="$bvModal.hide('customer-contact')"
        >
          Cancel
        </b-button>
        <b-button class="mt-3 btn save mx-0" @click="createContact">Save</b-button>
      </div>
    </b-modal>
    <DeleteModal
      v-if="showCustomerContactModal"
      @confirm="deleteContactContact($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DeleteModal from "@/components/DeleteModal.vue";

export default {
  name: "CustomerDocuments",
  components: {
    DeleteModal,
  },
  data() {
    return {
      customerContactID: null,
      showCustomerContactModal: false,
      editDocumentFlag: false,
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          label: "Role",
          key: "role",
          sortable: true,
        },
        {
          label: "Phone",
          key: "phone",
          sortable: true,
        },
        {
          label: "Email",
          key: "email",
          sortable: true,
        },

        {
          key: "Action",
          sortable: true,
        },
      ],
      customer: {
        name: null,
        role: null,
        phone: null,
        email: null,
      },
      items: [],
      perPage: 10,
      currentPage: 1,
      customerId: this.$route.params.id,
      isPhoneValid: false,
      errors: {
        name: false,
        phone: false,
      },
      bindProps: {
        mode: "international",
        autoFormat: true,
        preferredCountries: ["US", "CA", "MX"],
        inputOptions: {
          placeholder: "Enter a phone no.",
          maxlength: 30,
        },
        dropdownOptions: {
          showSearchBox: true,
          showDialCodeInList: true,
          showFlags: true,
          showDialCodeInSelection: true,
          width: "300px",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["allCustomerContacts"]),
    rows() {
      return this.items.length;
    },
    positionText() {
      let endIndex = this.currentPage * this.perPage,
        startIndex =
          this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.rows ? this.rows : endIndex) +
        " of " +
        this.rows +
        " entries"
      );
    },
  },
  watch: {
    allCustomerContacts() {
      this.items = [];
      this.allCustomerContacts.map((customerContact) => {
        this.items.push({
          ...customerContact,
        });
      });
    },
  },
  methods: {
    ...mapActions([
      "getAllCustomerContacts",
      "createCustomerContact",
      "editCustomerContact",
      "deleteCustomerContact",
      "setNotification",
    ]),
    openModal() {
      this.errors = {
        name: false,
        phone: false,
      };
      this.editDocumentFlag = false;
      (this.customer = {
        name: null,
        role: null,
        phone: null,
        email: null,
      }),
        this.$bvModal.show("customer-contact");
    },
    checkNum(phoneObject) {
      if (phoneObject.valid !== undefined) {
        this.isPhoneValid = phoneObject.valid;
      }
    },
    checkErrors() {
      if (!this.customer.name) {
        this.errors = {
          ...this.errors,
          name: !this.customer.name,
          phone: this.customer.phone ? !this.isPhoneValid : null,
        };
        return true;
      } else {
        this.errors.name = false;
        if (!this.isPhoneValid) {
          this.errors = {
            ...this.errors,
            phone: this.customer.phone ? !this.isPhoneValid : null,
          };
          return true;
        }
        return false;
      }
    },

    showCustomerContactDeletePopup(id) {
      this.showCustomerContactModal = true;
      this.customerContactID = id;
    },

    async createContact() {
      let errors = this.checkErrors();
      if (errors) {
        return;
      }
      if (!this.editDocumentFlag) {
        await this.createCustomerContact({
          ...this.customer,
          customer: this.customerId,
        });
        await this.setNotification({
          msg: "Customer Contact Created Successfully",
          type: "success",
          color: "green",
        });
      } else {
        await this.editCustomerContact({
          ...this.customer,
        });
        await this.setNotification({
          msg: "Customer Contact Updated Successfully",
          type: "success",
          color: "green",
        });
        this.editDocumentFlag = false;
      }
      console.log(this.allCustomerContacts);
      this.$bvModal.hide("customer-contact");
    },

    editContact(item) {
      this.editDocumentFlag = true;
      let data = {
        id: item.id,
        email: item.email,
        phone: item.phone,
        role: item.role,
        name: item.name,
      };
      console.log(data);
      this.customer = data;
      this.errors = {
        name: false,
      };
      this.$bvModal.show("customer-contact");
    },

    async deleteContactContact(item) {
      this.showCustomerContactModal = false;
      if (item) {
        try {
          await this.deleteCustomerContact({
            id: this.customerContactID,
            customer: this.customerId,
          });
          await this.setNotification({
            msg: `Successfully deleted Contact`,
            type: "success",
            color: "green",
          });
        } catch (error) {
          await this.setNotification({
            msg: error,
            type: "error",
            color: "red",
          });
        }
      }
    },
  },

  async mounted() {
    try {
      await this.getAllCustomerContacts(this.customerId);
    } catch (error) {
      console.log(error);
      if (error.status === 403) {
        await this.setNotification({
          msg: "You are not allowed to see Customer Contacts.",
          type: "error",
          color: "red",
        });
      }
    }
  },
};
</script>

<style></style>
