<template>
  <b-container fluid class="mt-2 px-1 px-sm-3 pb-3">
    <b-row>
      <b-col cols="" class="">
        <b-row>
          <b-col cols="">
            <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
              <h2 class="text-left">FileDrop</h2>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <div class="data-table bg-white pt-3 pt-sm-4 table-custom-border rounded-top">
              <div class="table_row">
                <div class="equipment-border filter-box">
                  <div class="d-flex mb-4 px-3 px-sm-3">
                    <b-form-group class="text-left label font-weight-normal search-field mb-0">
                      <b-input-group class="username-input-field">
                        <b-form-input type="text" placeholder="Search" v-model="filterFields.search"
                          class="unit f-12" @keyup.enter="getFileData()"></b-form-input>
                        <b-input-group-append>
                          <b-button variant="primary" @click="getFileData()">
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>

                    <div class="ml-auto">
                      <b-dropdown id="dropdown-divider" ref="filterDropdown"
                        class="filter-section table-columns-dropdown mt-0"
                        :class="checkFilterStatus ? 'active-dropdown' : ''">
                        <template slot="button-content">
                          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8.74045 19C8.55379 19 8.37477 18.9256 8.24278 18.7933C8.11079 18.6609 8.03663 18.4813 8.03663 18.2941V11.5071L1.51223 4.31059C1.2554 4.02674 1.08624 3.67422 1.02528 3.29583C0.964316 2.91743 1.01418 2.52942 1.16881 2.17889C1.32344 1.82836 1.57619 1.53038 1.89639 1.32112C2.21659 1.11185 2.59047 1.0003 2.97266 1H18.0273C18.4095 1.0003 18.7834 1.11185 19.1036 1.32112C19.4238 1.53038 19.6766 1.82836 19.8312 2.17889C19.9858 2.52942 20.0357 2.91743 19.9747 3.29583C19.9138 3.67422 19.7446 4.02674 19.4878 4.31059L12.9634 11.5071V15.4706C12.9633 15.5763 12.9395 15.6807 12.8939 15.776C12.8482 15.8713 12.7817 15.9551 12.6994 16.0212L9.18034 18.8447C9.05557 18.9451 8.90042 18.9999 8.74045 19ZM2.97266 2.41176C2.86314 2.41204 2.75604 2.44414 2.66434 2.50419C2.57263 2.56424 2.50024 2.64966 2.45594 2.75011C2.41163 2.85056 2.3973 2.96174 2.41468 3.07019C2.43207 3.17864 2.48042 3.27971 2.55389 3.36118L9.26128 10.7729C9.3762 10.8997 9.44123 11.064 9.44427 11.2353V16.8259L11.5557 15.1176V11.2353C11.5553 11.0591 11.6206 10.8892 11.7387 10.7588L18.4461 3.34706C18.5152 3.26523 18.5598 3.16547 18.5748 3.0593C18.5897 2.95312 18.5744 2.84488 18.5306 2.74706C18.4868 2.64924 18.4163 2.56585 18.3273 2.50652C18.2382 2.44719 18.1342 2.41434 18.0273 2.41176H2.97266Z"
                              stroke-width="0.2" />
                          </svg>

                        </template>
                        <div class="filter-section-items">
                          <p class="font-weight-bold mb-2 mt-2 heading">Filter</p>
                          <b-form-group label="Type" class="text-left select_arrow label mb-0">
                            <b-form-select class="input f-12 mb-4" :options="[
                              {
                                value: '',
                                text: '',
                              },
                              {
                                value: 'Driver',
                                text: 'Driver',
                              },
                              {
                                value: 'Vehicle',
                                text: 'Vehicle',
                              },
                              {
                                value: 'Trailer',
                                text: 'Trailer',
                              },
                              {
                                value: 'Maintenance',
                                text: 'Maintenance',
                              },
                              {
                                value: 'Insurance',
                                text: 'Insurance',
                              },
                              {
                                value: 'Inspection',
                                text: 'Inspection',
                              },
                              {
                                value: 'Accident',
                                text: 'Accident',
                              },
                              {
                                value: 'Load',
                                text: 'Load',
                              },
                              {
                                value: 'Other',
                                text: 'Other',
                              }
                            ]" v-model="filterFields.type" @change="getFileData()"></b-form-select>
                          </b-form-group>
                        </div>
                        <div class="d-flex align-items-center justify-content-between buttons-section">
                          <b-button variant="primary" @click="resetFilterFields()">Reset</b-button>
                          <b-button class="text-dark btn-secondary-2"
                            @click="$refs.filterDropdown.hide()">Close</b-button>
                        </div>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
                <b-table class="mt-2" responsive :fields="fields" :items="items" hover :current-page="currentPage"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
                  :show-empty="emptyTable">
                  <template #cell(note)="data">
                      <v-app v-if="data.value && data.value !== 'null'">
                        <v-tooltip top>
                          <template #activator="{ on, attrs }">
                            <span class="w-fit-content item-description text-left" v-on="on" v-bind="attrs">{{ data.value }}</span>
                          </template>{{ formatTooltipText(data.value) }}
                        </v-tooltip>
                      </v-app>
                      <p v-else>-</p>
                    </template>
                  <template #cell(action)="data">
                    <b-icon :style="data.item.link ? 'fill: #2D69F6' : 'fill: grey'" class="mr-4" icon="eye-fill"
                      @click="openDoc(data.item)" />
                    <b-icon style="fill:#FF0000" icon="trash-fill" @click="showPopup(data.item.pk)" />
                  </template>
                </b-table>
                <div class="entries-pagination px-3 px-sm-4">
                  <div class="d-flex align-items-center flex-wrap">
                    <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                      <b-form-select v-model="perPage" :options="pageOptions" />
                    </div>
                    <div class="pagination">
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                        aria-controls="my-table"></b-pagination>
                    </div>
                    <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <DeleteModal v-if="showModal" @confirm="deleteItem($event)" />
    <b-modal id="file-drop-popup" no-close-on-backdrop size="lg" scrollable centered
    no-close-on-esc
      :footer-class="'d-block border-0 px-20px pt-0 pb-3 m-0'">
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Preview
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <vue-pdf-embed v-if="mediaType === 'pdf'" :source="media" />
      <img :src="media" v-else class="w-100" />
      <template #modal-footer>
        <div class="d-flex modal-footer border-0 p-0 justify-content-between mt-3">
          <div class="d-flex m-0">
            <b-button class="cancel text-white mr-3 m-0" variant="danger" @click="showPopup(mediaID)">Delete</b-button>
            <b-button class="btn w-125px mx-0 m-0 px-2" @click="downloadFile(media)"
            variant="success">Download</b-button>
          </div>
          <b-button class="btn cancel m-0" block @click="$bvModal.hide('file-drop-popup')">Close</b-button>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "../../router";
import DeleteModal from "@/components/DeleteModal.vue";
import * as moment from "moment-timezone";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: "file-drop",
  components: { DeleteModal, VuePdfEmbed },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      emptyTable: false,
      itemId: 0,
      showModal: false,
      sortDesc: undefined,
      sortBy: undefined,
      mediaID: null,
      media: null,
      isBusy: false,
      positionText: "",
      filterFields: {
        search: '',
        type: ''
      },
      mediaType: null,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      items: [],
      fields: [
        {
          label:"Driver",
          key: "driver",
          sortable: true,
          thClass: 'font-table-weight-bold',
          stickyColumn: true,
          field: "text"
        },
        {
          label: "Type",
          key:"type",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
        {
          label:"Note",
          key: "note",
          sortable: true,
          thClass: 'font-table-weight-bold',
          field: "text"
        },
        {
          label: "Date",
          key:'date',
          sortable: true,
          thClass: 'font-table-weight-bold',
          field: "text"
        },

        {
          key: "Action",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getAllFiles", "deleteFile", "setNotification", "setLoader"]),
    formatTooltipText(text){
      const maxLineLength = window.screen.width <= 767 ? 50 : 80;
      let formattedText = "";
      let lineLength = 0;

      text.split(' ').forEach(word => {
        if (lineLength + word.length + 1 > maxLineLength) {
          formattedText += "\n";
          lineLength = 0;
        }
        formattedText += (lineLength === 0 ? "" : " ") + word;
        lineLength += word.length + 1;
      });

      return formattedText;
    },
    async getFileData() {
      try {
        this.isBusy = true
        let url = ""
        Object.keys(this.filterFields).map((key) => {
          if (this.filterFields[key] !== '') {
             url += `${key}=${this.filterFields[key]}&`
          }
        })
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
        }
        else {
          url += `sort_field=date&sort_order=desc&`
        }
        url += `page=${this.currentPage}&page_size=${this.perPage}`
        const data = await this.getAllFiles(url);
        if (data.count) {
          this.rows = data.count
          this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1) + (this.perPage > data.count ? data.count : this.perPage) } of ${data.count}`
        }
        else {
          this.rows = 0
          this.positionText = `0 to 0 of 0`
        }
        this.items = []
        this.allFiles.forEach((_file, id) => {
          this.items.push({
            ID: id + 1,
            pk: _file.id,
            date: moment(_file.date).format("MMM DD, YYYY"),
            driver: _file.driver.name,
            type: _file.type,
            note: _file.note ? _file.note : "-",
            link: _file.document,
          });
        });
        if (this.items.length === 0) this.emptyTable = true
        else this.emptyTable = false
        this.isBusy = false
      }
      catch (e) {
        this.isBusy = false
        console.log(e)
      }
    },
    async resetFilterFields() {
      this.filterFields = {
        search: '',
        type: ''
      }
      await this.getFileData();
    },
    async openDoc(data) {
      this.media = data.link
      this.mediaID = data.pk
      if (data.link.includes('.pdf')) this.mediaType = 'pdf'
      else this.mediaType = 'image'
      this.$bvModal.show("file-drop-popup");
    },
    downloadFile(uri) {
      let link = document.createElement("a");
      link.setAttribute("href", uri);
      link.setAttribute("download", uri.substring(uri.lastIndexOf('/') + 1));
      link.click();
      link.remove()
    },
    deleteItem(item) {
      if (item) {
        this.deleteRecord(this.itemId);
      }
      this.showModal = false;
    },
    showPopup(id) {
      this.showModal = true;
      this.itemId = id;
    },
    async deleteRecord(id) {
      try {
        this.setLoader(true)
        await this.deleteFile(`${id}/?company_id=${this.userCompany}`);
        const item = this.items.filter((record) => record.pk === id)[0];
        const index = this.items.indexOf(item);
        this.items.splice(index, 1);
        await this.setNotification({
          msg: "File successfully deleted!!",
          type: "success",
          color: "green",
        });
        this.$bvModal.hide("file-drop-popup");
        this.setLoader(false)
        await this.getFileData();
      } catch (error) {
        this.setLoader(false)
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },

  },
  watch: {
    perPage: {
      async handler() {
        await this.getFileData();
      },
      immediate: true,
    },
    currentPage: {
      async handler() {
        await this.getFileData();
      },
    },
    sortBy: {
      async handler() {
        await this.getFileData();
      },
    },
    sortDesc: {
      async handler() {
        await this.getFileData();
      },
    },
    filterFields: {
      handler() {
        this.currentPage = 1
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["allFiles", "userCompany"]),
    checkFilterStatus() {
      return !Object.values(this.filterFields).every(x => x === null || x === '');
    },
  },
  async mounted() {
    try {
      await this.getFileData();
    } catch (error) {
      console.log(error);
      await this.setNotification({
        msg: error,
        type: "error",
        color: "red",
      });
      await router.push("/");
    }
  },
};
</script>

<style>
.filedrop fieldset {
  margin-right: 20px;
}

.filedrop .b-form-btn-label-control.form-control>.form-control {
  font-size: 12px !important;
}

.vue-pdf-embed__page canvas {
  width: 100% !important;
  height: 100% !important;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px !important;
    width: 800px !important;
  }
}
</style>

