import {
  getReq,
  getById,
  postReq,
  patchReq,
  deleteReq,
} from "../../../services/api";

const state = {
  allCustomers: [],
  customerDocs: [],
  allCustomerContacts: [],
  currentCustomer: null,
};

const getters = {
  currentCustomer: (state) => state.currentCustomer,
  allCustomers: (state) => state.allCustomers,
  customerDocs: (state) => state.customerDocs,
  allCustomerContacts: (state) => state.allCustomerContacts,
};

const actions = {
  async getCurrentCustomer({ commit }, id) {
    try {
      const customer = await getById(`tms_customer/customers/`, id);
      commit("setCurrentCustomer", customer);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updateCurrentCustomer({ commit }, payload) {
    try {
      const customer = await patchReq(
        `tms_customer/customers`,
        payload.id,
        payload
      );
      commit("setCurrentCustomer", customer);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getAllCustomers({ commit }) {
    try {
      const allCustomers = await getReq(`tms_customer/customers/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setAllCustomers", allCustomers);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async addCustomer({ commit }, payload) {
    try {
      const customer = await postReq(`tms_customer/customers/`, payload);
      commit("addCustomer", customer);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async getAllCustomerContacts({ commit }, id) {
    try {
      //   console.log(customerID);
      const allCustomerContacts = await getReq(
        `tms_customer/contacts/?customer_id=${id}`
      );
      commit("setAllCustomerContacts", allCustomerContacts);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async createCustomerContact({ commit }, payload) {
    try {
      const customerContact = await postReq(`tms_customer/contacts/`, payload);
      commit("addCustomerContact", customerContact);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async editCustomerContact({ commit }, payload) {
    try {
      const customerContact = await patchReq(
        `tms_customer/contacts`,
        payload.id,
        payload
      );
      commit("editCustomerContact", customerContact);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async deleteCustomerContact({ commit }, payload) {
    try {
      await deleteReq(`tms_customer/contacts`, payload.id);
      commit("deleteCustomerContact", payload.id);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async getAllCustomerDocuments({ commit }) {
    try {
      const docs = await getReq(`tms_customer/documents/`);
      commit("setCustomerDocs", docs);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async createCustomerDocument({ commit }, payload) {
    try {
      const customerDocument = await postReq(
        `tms_customer/documents/`,
        payload
      );
      console.log(customerDocument);
      commit("addCustomerDocument", customerDocument);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  async deleteCustomerDocument({ commit }, payload) {
    try {
      await deleteReq(`tms_customer/documents`, payload.id);
      commit("deleteCustomerDocument", payload.id);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  async editCustomerDocument({ commit }, payload) {
    try {
      const customerDocument = await patchReq(
        `tms_customer/documents`,
        payload.getAll("pk")[0],
        payload
      );
      commit("editCustomerDocument", customerDocument);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
};

const mutations = {
  setCurrentCustomer: (state, customer) => (state.currentCustomer = customer),
  setAllCustomers: (state, allCustomers) => (state.allCustomers = allCustomers),
  addCustomer: (state, customer) => state.allCustomers.push(customer),
  setAllCustomerContacts: (state, allCustomerContacts) =>
    (state.allCustomerContacts = allCustomerContacts),
  addCustomerContact: (state, customerContact) =>
    state.allCustomerContacts.push(customerContact),

  deleteCustomerContact: (state, id) => {
    const index = state.allCustomerContacts.findIndex((x) => x.id === id);
    state.allCustomerContacts.splice(index, 1);
  },
  editCustomerContact: (state, customerContact) => {
    state.allCustomerContacts = state.allCustomerContacts.map((item) =>
      item.id === customerContact.id ? customerContact : item
    );
  },
  setCustomerDocs: (state, docs) => (state.customerDocs = docs),
  addCustomerDocument: (state, customerDocument) => {
    state.customerDocs.push(customerDocument);
  },
  deleteCustomerDocument: (state, id) => {
    const index = state.customerDocs.findIndex((x) => x.id === id);
    state.customerDocs.splice(index, 1);
  },

  editCustomerDocument: (state, customerDocument) => {
    state.customerDocs = state.customerDocs.map((item) =>
      item.id === customerDocument.id ? customerDocument : item
    );
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
