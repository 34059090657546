<template>
    <div>
        <div class="filter-box">
            <div class="d-flex mb-4 px-3 px-sm-4">
                <b-form-group class="text-left label font-weight-normal search-field mb-0">
                    <b-input-group class="username-input-field">
                        <b-form-input type="text" placeholder="Search" v-model="filterFields.search" class="unit f-12"
                            @keyup.enter="getMaintenanceTotalData()"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="primary" @click="getMaintenanceTotalData()">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group class="d-none d-md-block">
                    <date-picker v-model="filterFields.date" :formatter="momentFormat" @change="getMaintenanceTotalData()"
                    :range-separator="' - '"
                                placeholder="Select Date"    
                                @clear="filterFields.date=''"
                    range></date-picker>
                </b-form-group>
                <div class="ml-auto">
                    <b-dropdown id="dropdown-divider" ref="filterDropdown"
                        class="filter-section table-columns-dropdown mt-0"
                        :class="checkFilterStatus ? 'active-dropdown' : ''">
                        <template slot="button-content">
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.74045 19C8.55379 19 8.37477 18.9256 8.24278 18.7933C8.11079 18.6609 8.03663 18.4813 8.03663 18.2941V11.5071L1.51223 4.31059C1.2554 4.02674 1.08624 3.67422 1.02528 3.29583C0.964316 2.91743 1.01418 2.52942 1.16881 2.17889C1.32344 1.82836 1.57619 1.53038 1.89639 1.32112C2.21659 1.11185 2.59047 1.0003 2.97266 1H18.0273C18.4095 1.0003 18.7834 1.11185 19.1036 1.32112C19.4238 1.53038 19.6766 1.82836 19.8312 2.17889C19.9858 2.52942 20.0357 2.91743 19.9747 3.29583C19.9138 3.67422 19.7446 4.02674 19.4878 4.31059L12.9634 11.5071V15.4706C12.9633 15.5763 12.9395 15.6807 12.8939 15.776C12.8482 15.8713 12.7817 15.9551 12.6994 16.0212L9.18034 18.8447C9.05557 18.9451 8.90042 18.9999 8.74045 19ZM2.97266 2.41176C2.86314 2.41204 2.75604 2.44414 2.66434 2.50419C2.57263 2.56424 2.50024 2.64966 2.45594 2.75011C2.41163 2.85056 2.3973 2.96174 2.41468 3.07019C2.43207 3.17864 2.48042 3.27971 2.55389 3.36118L9.26128 10.7729C9.3762 10.8997 9.44123 11.064 9.44427 11.2353V16.8259L11.5557 15.1176V11.2353C11.5553 11.0591 11.6206 10.8892 11.7387 10.7588L18.4461 3.34706C18.5152 3.26523 18.5598 3.16547 18.5748 3.0593C18.5897 2.95312 18.5744 2.84488 18.5306 2.74706C18.4868 2.64924 18.4163 2.56585 18.3273 2.50652C18.2382 2.44719 18.1342 2.41434 18.0273 2.41176H2.97266Z"
                                    stroke-width="0.2" />
                            </svg>

                        </template>
                        <div class="filter-section-items">
                            <p class="font-weight-bold mb-2 mt-2 heading">Filter</p>
                            <b-form-group label="Type" class="text-left select_arrow label mb-3">
                                <b-form-select v-model="filterFields.type" class="input f-12" :options="[
                                    {
                                        value: '',
                                        text: '',
                                    },
                                    {
                                        value: 'Vehicle',
                                        text: 'Vehicle',
                                    },
                                    {
                                        value: 'Trailer',
                                        text: 'Trailer',
                                    }
                                ]" @change="getMaintenanceTotalData()"></b-form-select>
                            </b-form-group>
                            <b-form-group label="Tags" class="text-left w-equipment-box mb-3">
                                <v-select multiple class="f-12 bg-grey" :options="allTags" :reduce="(option) => option.id"
                                    v-model="filterFields.tags" @input="getMaintenanceTotalData()" />
                            </b-form-group>
                        </div>
                        <div class="d-flex align-items-center justify-content-between buttons-section">
                            <b-button variant="primary" @click="resetFilterFields()">Reset</b-button>
                            <b-button class="text-dark btn-secondary-2"
                                @click="$refs.filterDropdown.hide()">Close</b-button>
                        </div>
                    </b-dropdown>
                </div>
            </div>
        </div>
        <b-table class="mt-2" responsive :fields="visibleFields" :items="items" hover :current-page="currentPage"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
            :show-empty="emptyTable">

            <template #cell(amount)="data">
                {{
                    data.value !== "-" ? `$${getAmount(data.value)}` : "-"
                }}
            </template>
        </b-table>
        <div class="entries-pagination px-3 px-sm-4">
            <div class="d-flex align-items-center flex-wrap">
                <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                    <b-form-select v-model="perPage" :options="pageOptions" />
                </div>
                <div class="pagination">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                        aria-controls="my-table"></b-pagination>
                </div>
                <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css"
import * as moment from "moment-timezone";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name: "Totals",
    components: { vSelect, DatePicker },
    data() {
        return {
            filterFields: {
                search: '',
                type: '',
                date: '',
                tags: [],
                status: ''
            },
            momentFormat: {
                //[optional] Date to String
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                //[optional]  String to Date
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            fields: [
                {
                    key: "unit_no",
                    label: "Unit #",
                    sortable: true,
                    visible: true,
                    disabled: true,
                    field: "text",
                    stickyColumn: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "type",
                    label: "Type",
                    sortable: true,
                    visible: true,
                    disabled: true,
                    field: "text",
                    stickyColumn: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "amount",
                    label: "Amount",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
            ],
            items: [],
            rows: 0,
            allTags: [],
            positionText: "",
            sortBy: undefined,
            sortDesc: undefined,
            isBusy: false,
            perPage: 10,
            emptyTable: false,
            currentPage: 1,
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
        }
    },
    methods: {
        ...mapActions(["getMaintenanceTotal", "getAllTags"]),
        getAmount(val) {
            return parseFloat(val).toLocaleString("en", {
                useGrouping: false,
                minimumFractionDigits: 2,
            });
        },
        async resetFilterFields(){
            this.filterFields= {
                search: '',
                type: '',
                date: '',
                tags: [],
                status: ''
            }
         await this.getMaintenanceTotalData()
        },
        async getMaintenanceTotalData() {
            try {
                this.isBusy = true
                let url = ""
                let tags = ""
                Object.keys(this.filterFields).map((key) => {
                    if (key === "tags" && this.filterFields[key].length > 0) {
                        this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
                        url += `${key}=${tags}&`
                    }
                    else if (key === 'date' && this.filterFields['date'][0]) {
                        url += `start_date=${moment(this.filterFields['date'][0]).format("YYYY-MM-DD")}&end_date=${moment(this.filterFields['date'][1]).format("YYYY-MM-DD")}&`
                    }
                    else if (this.filterFields[key] !== '' && key !== 'tags' && key !== 'date') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=unit_no&sort_order=asc&`
                }
                url += `page=${this.currentPage}&page_size=${this.perPage}`
                console.log(url)
                const data = await this.getMaintenanceTotal(url);
                if (data.total_entries) {
                    this.rows = data.total_entries
                    this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
                }
                else {
                    this.rows = 0
                    this.positionText = `0 to 0 of 0`
                }
                this.items = [];
                data.records.forEach(async (m, id) => {
                    this.items.push({
                        id: id,
                        pk: m.id,
                        unit_no: m.unit_no,
                        type: m.type,
                        amount: m.total_maintenance_amount
                    });
                });
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
                this.isBusy = false
                console.log(this.items)
            }
            catch (e) {
                this.isBusy = false
                console.log(e)
            }
        },
    },
    computed: {
        ...mapGetters(['tags', 'company', 'userCompany']),
        checkFilterStatus() {
            return !Object.values(this.filterFields).every(x => x === null || x === '' || (Array.isArray(x) && x.length === 0));
        },
        visibleFields() {
            return this.fields.filter(field => field.visible)
        }
    },
    watch: {
        perPage: {
            async handler() {
                await this.getMaintenanceTotalData();
            },
            immediate: true,
        },
        currentPage: {
            async handler() {
                await this.getMaintenanceTotalData();
            },
        },
        sortBy: {
            async handler() {
                await this.getMaintenanceTotalData();
            },
        },
        sortDesc: {
            async handler() {
                await this.getMaintenanceTotalData();
            },
        },
        filterFields: {
            handler() {
                this.currentPage = 1
            },
            deep: true
        },
        company: {
            async handler() {
                await this.getAllTags(this.company[0].id);
                this.tags.map((item) => {
                    this.allTags.push({
                        id: item.add_tag,
                        label: item.add_tag,
                    });
                });
            },
            deep: true,
            immediate: true
        }
    }
}
</script>