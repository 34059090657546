import { getReq, deleteReq } from "../../../services/api";

const state = {
  allFiles: [],
};

const getters = {
  allFiles: (state) => state.allFiles,
};

const actions = {
  async getAllFiles({ commit }, url) {
    try {
      const data = await getReq(`app/file-drop/${url ? "?"+url:""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&':'?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setAllFiles", data.results);
      return {records:data.results, count:data.count}
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async deleteFile(_, id) {
    try {
      return await deleteReq("app/file-drop", id);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
};

const mutations = {
  setAllFiles: (state, allFiles) => (state.allFiles = allFiles),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
