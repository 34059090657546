var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mt-2 license-management-page",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"px-1 px-sm-3",attrs:{"cols":""}},[_c('div',{staticClass:"expiry-data d-flex justify-content-between align-items-center mb-4"},[_c('h2',{staticClass:"text-left"},[_vm._v("Company")])]),_c('div',{staticClass:"pr-0 mb-3 d-block d-lg-flex w-100"},[_c('div',{},[_c('div',{staticClass:"pt-3 px-3 bg-white h-100 d-none d-lg-block table-custom-border company-menu-section rounded"},[_c('div',{staticClass:"company-tabs text-left"},[_c('div',{staticClass:"company-tab",class:_vm.activeTab === 'general' ? 'active' : '',on:{"click":function($event){_vm.activeTab = 'general'}}},[_vm._v(" General ")]),(_vm.$store.state.user.user.team_member.role !== 'Maintenance' && _vm.$store.state.user.user.team_member.role !== 'Maintenance Manager')?_c('div',{staticClass:"company-tab",class:_vm.activeTab === 'documents' ? 'active' : '',on:{"click":function($event){_vm.activeTab = 'documents'}}},[_vm._v(" Documents ")]):_vm._e(),(_vm.$store.state.user.user && ( _vm.$store.state.user.user.team_member.role === 'Admin' || _vm.$store.state.user.user.team_member.role === 'Operations'))?_c('div',{staticClass:"company-tab",class:_vm.activeTab === 'settings' ? 'active' : '',on:{"click":function($event){_vm.activeTab = 'settings'}}},[_vm._v(" Settings ")]):_vm._e(),(_vm.$store.state.user.user && ( _vm.$store.state.user.user.team_member.role === 'Admin' || _vm.$store.state.user.user.team_member.role === 'Operations' || _vm.$store.state.user.user.team_member.role === 'Maintenance Manager'))?_c('div',{staticClass:"company-tab",class:_vm.activeTab === 'tags' ? 'active' : '',on:{"click":function($event){_vm.activeTab = 'tags'}}},[_vm._v(" Tags ")]):_vm._e(),(_vm.$store.state.user.user &&
                _vm.$store.state.user.user.team_member.role === 'Admin'
                )?_c('div',{staticClass:"company-tab",class:_vm.activeTab === 'users' ? 'active' : '',on:{"click":function($event){_vm.activeTab = 'users'}}},[_vm._v(" Users ")]):_vm._e(),(_vm.$store.state.user.user &&
                  _vm.$store.state.user.user.team_member.role === 'Admin'
                  )?_c('div',{staticClass:"company-tab",class:_vm.activeTab === 'integrations' ? 'active' : '',on:{"click":function($event){_vm.activeTab = 'integrations'}}},[_vm._v(" Integrations ")]):_vm._e()])]),_c('div',{staticClass:"d-block d-lg-none mb-3"},[_c('b-form-select',{staticClass:"bg-white",attrs:{"options":[
              {
                value: 'general',
                text: 'General',
              },
              {
                value: 'documents',
                text: 'Documents',
              },
              {
                value: 'settings',
                text: 'Settings',
              },
              {
                value: 'tags',
                text: 'Tags',
              },
              {
                value: 'users',
                text: 'Users',
              },
              {
                value: 'integrations',
                text: 'Integrations',
              } ]},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}})],1)]),_c('div',{staticClass:"w-100 ml-lg-3 ml-0 table-custom-border rounded"},[_c('div',{},[(_vm.activeTab === 'general')?_c('General'):_vm._e(),(_vm.activeTab === 'documents')?_c('Documents'):_vm._e(),(_vm.activeTab === 'settings')?_c('Settings'):_vm._e(),(_vm.activeTab === 'tags')?_c('TagsSection'):_vm._e(),(_vm.activeTab === 'users')?_c('Users'):_vm._e(),(_vm.activeTab === 'integrations')?_c('Integrations',{attrs:{"company":_vm.company[0],"showInnerSamsaraPage":_vm.showInnerSamsaraPage,"showInnerMotivePage":_vm.showInnerMotivePage,"showInnerGeoTabPage":_vm.showInnerGeoTabPage,"showInnerIsaacPage":_vm.showInnerIsaacPage},on:{"samsaraStatus":function($event){return _vm.setSamsaraStatus($event)},"motiveStatus":function($event){return _vm.setMotiveStatus($event)},"geoTabStatus":function($event){return _vm.setGeoTabStatus($event)},"isaacStatus":_vm.setIsaacStatus}}):_vm._e()],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }