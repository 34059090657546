<template>
    <div class="bg-white rounded">
        <div class="table pt-3 mb-0">
            <div class="add_license d-flex justify-content-between align-items-center flex-wrap mb-3 px-3">
                <h3 class="font-weight-bold f-20 text-left mb-0 text-black">Documents</h3>
                <b-button @click="addDocument" class="export equipment d-flex justify-content-center align-items-center w-fit-content"
                    v-if="$store.state.user.user &&
                        ($store.state.user.user.team_member.role === 'Admin' ||
                            $store.state.user.user.team_member.role === 'Operations')
                        " variant="primary">
                    <b-icon icon="plus" class="p-0" aria-hidden="true"></b-icon>
                    <p class="mb-0 ml-2 text-white p-0">Add Document</p>
                </b-button>
            </div>
            <b-table responsive :fields="fields" :items="items" hover :per-page="perPage" :current-page="currentPage"
                show-empty>
                <template #cell(name)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <a :href="`#${data.value
                        .replace(/[^a-z]+/i, '-')
                        .toLowerCase()}`">{{ data.value }}</a>
                </template>
                <template #cell(description)="data">
                    <p class="item-description" v-if="data.value && data.value !== 'null'">
                        {{ data.value }}
                    </p>
                </template>
                <template #cell(expiry_date)="data">
                    <span v-html="data.value"></span>
                </template>
                <!--                                        <template #cell(public)="data">-->
                <!--                                          <b-form-checkbox v-model="data.value" name="check-button" switch/>-->
                <!--                                        </template>-->
                <template #cell(driver)="data">
                    <b-form-checkbox v-model="data.value" name="check-button" switch @change="
                        updateDocumentOnToggle(
                            data.item.id,
                            'driver',
                            data.value
                        )
                        " :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
        $store.state.user.user.team_member.role !== 'Operations'" />
                </template>
                <template #cell(action)="data">
                    <b-icon v-if="data.item.link" style="fill: #2D69F6" class="mr-4" icon="eye-fill"
                        @click="openDoc(data.item)" />
                    <b-icon v-if="$store.state.user.user.team_member.role === 'Admin' ||
                        $store.state.user.user.team_member.role === 'Operations'" style="fill: limegreen" class="mr-4"
                        icon="pencil" @click="editDocument(data.item.id)" />
                    <b-icon v-if="$store.state.user.user.team_member.role === 'Admin' ||
                        $store.state.user.user.team_member.role === 'Operations'" style="fill:#FF0000"
                        icon="trash-fill" @click="showDocumentDeletePopup(data.item.pk)" />
                </template>
            </b-table>
            <div class="entries-pagination px-3 px-sm-4">
                <div class="d-flex align-items-center flex-wrap">
                    <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                        <b-form-select v-model="perPage" :options="pageOptions" />
                    </div>
                    <div class="pagination">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="my-table"></b-pagination>
                    </div>
                    <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">
                        {{ positionText }}
                    </p>
                </div>
            </div>
        </div>
        <b-modal id="modal-company-docs" centered hide-footer no-close-on-backdrop no-close-on-esc>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    {{ editDocumentFlag ? "Edit Document" : "Add Document" }}
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="d-block text-center">
                <b-row>
                    <b-col lg="6" md="8" sm="12">
                        <div class="text-left mb-3">
                            <label for="modal-datepicker" class="state">Date:</label>
                            <b-form-datepicker id="modal-datepicker" class="" v-model="documentData.date"
                                reset-button></b-form-datepicker>
                            <p v-if="errors && errors.date" class="field-error">
                                {{ errors.date[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label for="modal-expiry-date" class="state">Expiry Date:</label>
                            <b-form-datepicker id="modal-expiry-date" class="" v-model="documentData.expiry_date"
                                reset-button></b-form-datepicker>
                            <p v-if="errors && errors.expiry_date" class="field-error">
                                {{ errors.expiry_date[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Description:</label>
                            <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                                v-model="documentData.description"></b-form-textarea>
                            <p v-if="errors && errors.description" class="field-error">
                                {{ errors.description[0] }}
                            </p>
                        </div>
                        <div class="text-left">
                            <label class="state">Driver:</label>
                            <b-form-checkbox v-model="documentData.driver" name="check-button" switch size="lg"
                                class="ml-2" />
                        </div>
                    </b-col>
                    <b-col lg="6" md="8" sm="12">
                        <div class="upload-btn-wrapper">
                            <b-img :src="require('@/assets/drop.svg')"></b-img>
                            <button v-if="!fileName" class="foo">
                                Drop your document here, or browse
                            </button>
                            <button v-else class="foo">{{ fileName }}</button>
                            <p v-if="fileName"
                                class="mb-0 field-error cursor-pointer font-12 position-relative error-button"
                                @click="removeFile()">
                                Delete
                            </p>
                            <input ref="file" type="file" name="myfile" v-on:change="handleFileUpload"
                                accept=".pdf, .png, .jpg, .jpeg" />
                            <p v-if="errors && errors.document" class="field-error">
                                {{ errors.document[0] }}
                            </p>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="text-right modal-footer border-0 p-0">
                <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="$bvModal.hide('modal-company-docs')"
                            variant="secondary">Cancel</b-button>
                <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary"
                                @click="sendDocumentData">Save</b-button>
            </div>
        </b-modal>
        <DeleteModal v-if="showDeletePopup" @confirm="deleteItem($event)" />
        <b-modal id="media-popup" no-close-on-backdrop size="lg" scrollable centered
            :footer-class="'d-block border-0 px-20px pt-0 pb-3 m-0'" no-close-on-esc>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Preview
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <vue-pdf-embed v-if="mediaType === 'pdf'" :source="media" />
            <img :src="media" v-else class="w-100" />
            <template #modal-footer>
                <div class="d-flex modal-footer border-0 p-0 mt-3"
                    :class="($store.state.user.user.team_member.role !== 'Admin' ||
                        $store.state.user.user.team_member.role !== 'Operations') ? 'justify-content-between' : 'justify-flex-end'">
                    <div class="d-flex m-0">
                        <b-button v-if="$store.state.user.user.team_member.role === 'Admin' ||
                            $store.state.user.user.team_member.role === 'Operations'" class="cancel text-white m-0"
                            :class="mediaType === 'pdf' ? 'mr-3' : ''" variant="danger"
                            @click="showDocumentDeletePopup(mediaID)">Delete</b-button>
                        <b-button class="btn w-125px mx-0 m-0 px-2" v-if="mediaType === 'pdf'" @click="downloadFile(media)"
                            variant="success">Download</b-button>
                    </div>
                    <b-button class="btn cancel m-0" block @click="$bvModal.hide('media-popup')">Close</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import * as moment from "moment-timezone";
import generatePdf from "../../services/generatePDF";
import DeleteModal from "@/components/DeleteModal.vue";
import axios from "axios";
export default {
    name: "Documents",
    components: { VuePdfEmbed, DeleteModal },
    data() {
        return {
            editDocumentFlag: false,
            items: [],
            perPage: 50,
            currentPage: 1,
            documentId: 0,
            emptyTable: false,
            documentIndex: null,
            fileName: null,
            showDeletePopup: false,
            media: null,
            mediaType: null,
            mediaID: null,
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
            documentOptions: [
                {
                    value: null,
                    text: "",
                },
                {
                    value: "Permit",
                    text: "Permit",
                },
                {
                    value: "Insurance",
                    text: "Insurance",
                },
                {
                    value: "IFTA",
                    text: "IFTA",
                },
                {
                    value: "ELD Documents",
                    text: "ELD Documents",
                },
                {
                    value: "Other",
                    text: "Other",
                },
            ],
            documentData: {
                object_id: null,
                date: null,
                type: null,
                expiry_date: "",
                document: null,
                description: "",
                driver: true,
            },
            errors: {
                date: null,
                type: null,
                document: null,
                expiry_date: "",
                description: null,
            },
            options: [
                {
                    value: "Illinois",
                    text: "Illinois",
                },
                {
                    value: "Chicago",
                    text: "Chicago",
                },
                {
                    value: "Mexico",
                    text: "Mexico",
                },
            ],
            fields: [
                {
                    key: "Description",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                    stickyColumn: true,
                    thStyle: { width: "200px" },
                },
                {
                    key: "expiry_date",
                    label: "Expiry Date",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Driver",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                // {
                //     key: 'Public',
                //     sortable: true,
                // },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                    // Variant applies to the whole column, including the header and footer
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['company', "allCountries", "userCompany"]),
        rows() {
            return this.items.length;
        },
        positionText() {
            let endIndex = this.currentPage * this.perPage,
                startIndex =
                    this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

            return (
                startIndex +
                " to " +
                (endIndex > this.rows ? this.rows : endIndex) +
                " of " +
                this.rows
            );
        }
    },
    methods: {
        ...mapActions(['sendCompanyDocuments', 'updateCompanyDocuments', 'deleteCompanyDocuments', 'retrieveDocument', "getAllCountries", "setNotification", "setLoader"]),
        removeFile() {
            this.fileName = null;
            this.$refs.file.value = "";
        },
        deleteItem(item) {
            if (item) {
                this.deleteDocument(this.documentId);
            }
            this.showDeletePopup = false;
        },
        showDocumentDeletePopup(id) {
            this.showDeletePopup = true;
            this.documentId = id;
        },
        getPdf(data) {
            let country = "-";
            if (this.company[0].state.country) {
                country = this.allCountries.filter(
                    (country) => country.id === this.company[0].state.country
                )[0].name;
            }
            generatePdf(data, this.company, country);
        },
        handleFileUpload() {
            this.documentData.document = this.$refs.file.files[0];
            this.fileName = this.documentData.document.name;
        },
        addDocument() {
            this.fileName = null;
            this.editDocumentFlag = false;
            this.$bvModal.show("modal-company-docs");
            this.documentData = {
                object_id: null,
                date: new Date(),
                type: null,
                document: null,
                expiry_date: "",
                description: null,
                driver: true,
            };
            this.errors = {
                date: null,
                type: null,
                document: null,
                expiry_date: "",
                description: null,
            };
        },
        async sendDocumentData() {
            try {
                this.setLoader(true);
                let formData = new FormData();
                console.log(this.$refs.file.files.length);
                if (this.$refs.file.files.length === 0) {
                    this.documentData.document = null;
                }
                formData.append("object_id", this.userCompany);
                formData.append(
                    "date",
                    moment(this.documentData.date).format("YYYY-MM-DD")
                );
                formData.append("type", this.documentData.type);
                formData.append("description", this.documentData.description);
                    formData.append("expiry_date", this.documentData.expiry_date ? this.documentData.expiry_date : '');
                if (this.documentData.document)
                    formData.append(
                        "document",
                        new File(
                            [this.documentData.document],
                            this.documentData.document["name"].split(".")[0] +
                            "_" + this.userCompany + "_" +
                            new Date().getTime() +
                            "." +
                            this.documentData.document["type"].split("/").pop(),
                            { type: this.documentData.document["type"] }
                        )
                    );
                formData.append("driver", this.documentData.driver);
                formData.append("model_type", "company");
                formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
                if (this.editDocumentFlag) {
                    this.documentData = await this.updateCompanyDocuments({
                        id: this.documentData.id,
                        payload: formData,
                    });
                    await this.setNotification({
                        msg: "Document Successfully updated!!",
                        type: "success",
                        color: "green",
                    });
                    this.$bvModal.hide("modal-company-docs");
                    let color;
                    if (this.documentData.expiry_date) {
                        const currentDate = new Date();
                        const expiryDate = new Date(this.documentData.expiry_date);
                        const diffDays = parseInt(
                            (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                            10
                        );
                        if (diffDays > 31) {
                            color = "color:black";
                        } else if (diffDays <= 31 && diffDays >= 1) {
                            color = "color:#FFC800";
                        } else color = "color:#FF0000";
                    }
                    this.companyDocuments[this.documentIndex] = this.documentData;
                    this.items[this.documentIndex].Date = this.documentData.date;
                    this.items[this.documentIndex].Type = this.documentData.type;
                    this.items[this.documentIndex].Description =
                        this.documentData.description;
                    this.items[this.documentIndex].expiry_date = this.documentData
                        .expiry_date
                        ? `<span style=${color}>${moment(
                            this.documentData.expiry_date
                        ).format("MMM DD, YYYY")}</span`
                        : "-";
                    this.items[this.documentIndex].Driver = this.documentData.driver;
                    this.editDocumentFlag = false;
                    this.$bvModal.hide("modal-company-docs");
                    this.documentData = {
                        object_id: null,
                        date: null,
                        type: null,
                        expiry_date: "",
                        document: null,
                        description: null,
                        driver: true,
                    };
                    this.updateItems();
                    this.fileName = null;
                } else {
                    if (this.documentData.date) {
                        this.companyDocuments = await this.sendCompanyDocuments(formData);
                        this.company[0].docs = this.companyDocuments;
                        this.updateItems();
                        this.$bvModal.hide("modal-company-docs");
                        await this.setNotification({
                            msg: "Document Successfully added!!",
                            type: "success",
                            color: "green",
                        });
                        this.documentData = {
                            object_id: null,
                            date: null,
                            type: null,
                            document: null,
                            description: null,
                            expiry_date: "",
                            driver: true,
                        };
                        this.fileName = null;
                    } else {
                        this.errors.date = this.documentData.date
                            ? null
                            : ["This field is required."];
                        // this.errors.description = this.documentData.description? null: ['This field is required.']
                        console.log(this.errors);
                    }
                }
                this.setLoader(false);
            } catch (error) {
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        async openLink(id) {
            const doc = await this.retrieveDocument(id);
            window.open(doc.document, "_blank");
        },
        editDocument(id) {
            this.editDocumentFlag = true;
            this.documentIndex = id;
            this.documentData = this.company[0].docs[id];
            if (this.documentData.document) {
                let file_name = "";
                if (typeof this.documentData.document === "string") {
                    file_name = this.documentData.document;
                } else {
                    file_name = this.documentData.document.name;
                }
                this.fileName = file_name.substring(file_name.lastIndexOf("/") + 1);
            } else {
                this.documentData.document = null;
                this.fileName = "";
                this.errors = {
                    date: null,
                    type: null,
                    document: null,
                    description: null,
                };
            }
            this.$bvModal.show("modal-company-docs");
        },
        async updateDocumentOnToggle(id, key, value) {
            try {
                this.company[0].docs[id][key] = value;
                if (!this.company[0].docs[id].expiry_date)
                    delete this.company[0].docs[id].expiry_date;
                delete this.company[0].docs[id].document;
                await this.updateCompanyDocuments({
                    id: this.company[0].docs[id].id,
                    payload: this.company[0].docs[id],
                });
                await this.setNotification({
                    msg: "Document successfully updated!!",
                    type: "success",
                    color: "green",
                });
            } catch (error) {
                console.log(error);
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        async deleteDocument(id) {
            try {
                const docs = await this.deleteCompanyDocuments(`${id}/?company_id=${this.userCompany}`);
                if (docs) {
                    this.companyDocuments = docs;
                    this.updateItems();
                }
                await this.setNotification({
                    msg: "Document successfully deleted!!",
                    type: "success",
                    color: "green",
                });
                this.$bvModal.hide("media-popup");
            } catch (error) {
                console.log(error);
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        updateItems() {
            this.items = [];
            this.companyDocuments.forEach((doc, id) => {
                let color;
                if (doc.expiry_date) {
                    const currentDate = new Date();
                    const expiryDate = new Date(doc.expiry_date);
                    const diffDays = parseInt(
                        (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                        10
                    );
                    if (diffDays > 31) {
                        color = "color:black";
                    } else if (diffDays <= 31 && diffDays >= 1) {
                        color = "color:#FFC800";
                    } else color = "color:#FF0000";
                }
                this.items.push({
                    id: id,
                    pk: doc.id,
                    Date: moment(doc.date).format("MMM DD, YYYY"),
                    Description: doc.description,
                    expiry_date: doc.expiry_date
                        ? `<span style=${color}>${moment(doc.expiry_date).format(
                            "MMM DD, YYYY"
                        )}</span>`
                        : "-",
                    Driver: doc.driver,
                    Public: doc.public,
                    link: doc.document,
                });
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
            });
        },
        async openDoc(data) {
            const doc = await this.retrieveDocument(data.pk);
            this.media = doc.document
            this.mediaID = data.pk
            if (doc.document.includes('.pdf')) this.mediaType = 'pdf'
            else this.mediaType = 'image'
            this.$bvModal.show("media-popup");
        },
        downloadFile(uri) {
            axios({
                method: 'get',
                url: uri,
                responseType: 'blob',
            }).then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                const parts = uri.split('/');
                const lastPart = parts[parts.length - 1];
                const fileName = lastPart.split('?')[0];
                a.download = fileName
                document.body.appendChild(a);
                a.click();
                a.remove()
                window.URL.revokeObjectURL(url);
            });
        },
    },
    mounted() {
        this.companyDocuments = this.company[0].docs;
        this.updateItems();
        this.getAllCountries()
    }
}
</script>