<template>
  <b-container fluid class="form login-form reset-form">
    <b-row>
      <b-col lg="5" md="5" sm="12" class="left-side">
        <b-row>
          <b-col lg="9" md="12" sm="12" class="m-md-0 my-md-5 my-5">
            <div class="fix-width fix-width-login">
              <div class="logo mb-3 pb-3 text-left">
                <b-img
                  class=""
                  :src="require('@/assets/new-logo.svg')"
                  width="245"
                  height="70"
                ></b-img>
              </div>
              <b-form @submit.prevent="handleSubmit">
                <b-form-group
                  id="input-group-1"
                  label="New Password:"
                  label-for="input-1"
                  class="text-left label password"
                >
                  <b-form-input
                    id="input-1"
                    :type="showPassword1 ? 'text' : 'password'"
                    class="mb-3 input"
                    required
                    v-model="user.password1"
                  ></b-form-input>
                  <b-icon
                    :icon="showPassword1 ? 'eye-fill' : 'eye-slash-fill'"
                    @click="showPassword1 = !showPassword1"
                  ></b-icon>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="Confirm Password:"
                  label-for="input-1"
                  class="text-left label password"
                >
                  <b-form-input
                    id="input-1"
                    :type="showPassword2 ? 'text' : 'password'"
                    class="mb-3 input"
                    required
                    v-model="user.password2"
                  ></b-form-input>
                  <b-icon
                    :icon="showPassword2 ? 'eye-fill' : 'eye-slash-fill'"
                    @click="showPassword2 = !showPassword2"
                  ></b-icon>
                </b-form-group>

                <b-button type="submit" class="w-100 mt-2 login"
                variant="primary"
                  >Submit</b-button
                >
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="7" md="7" class="p-0 auth-bg-img">
        <Auth />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Auth from "../../components/auth/Auth.vue";
import { mapActions } from "vuex";
export default {
  components: { Auth },
  name: "ResetPassword",
  data() {
    return {
      showPassword1: false,
      showPassword2: false,
      user: {
        token: this.$route.params.token,
        uid: this.$route.params.uid,
        password1: "",
        password2: "",
      },
    };
  },
  methods: {
    ...mapActions(["resetPasswordConfirm", "setNotification"]),
    async handleSubmit() {
      if (!this.user.password1 || !this.user.password1) {
        this.setNotification({
          msg: "Please enter the above fields",
          type: "error",
          color: "red",
        });
      } else {
        try {
          const { detail } = await this.resetPasswordConfirm(this.user);
          if (detail) {
            this.setNotification({
              msg: "Password changed successfully.",
              type: "success",
              color: "green",
            });
            this.$router.push("/");
          }
        } catch (error) {
          this.setNotification({
            msg: error,
            type: "error",
            color: "red",
          });
        }
      }
    },
  },
};
</script>

<style>
/* .login-form.reset-form .right-img{
    height: calc(100vh);
} */
.password {
  position: relative;
}
.password .b-icon {
  position: absolute;
  top: 42px;
  right: 13px;
}
@media (max-width: 1200px) {
  .reset-form .auth-bg-img {
    height: 100vh !important;
  }
}
@media (max-width: 991px) {
  .login-form.reset-form .right-img {
    height: 100%;
  }
}
</style>
