<template>
    <b-modal id="modal-document" centered hide-footer no-close-on-backdrop :body-class="'p-4'" no-close-on-esc>
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                {{ documentItem ? "Edit Document" : "Add Document" }}
            </h5>
            <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                Close Modal
            </b-button>
        </template>
        <div class="d-block text-center">
            <b-row>
                <b-col lg="6" md="8" sm="12">
                    <div class="text-left mb-3">
                        <label for="modal-datepicker" class="state">Date:</label>
                        <b-form-datepicker id="modal-datepicker" class="" v-model="documentData.date"
                            reset-button></b-form-datepicker>
                        <p v-if="errors && errors.date" class="field-error">
                            {{ errors.date[0] }}
                        </p>
                    </div>
                    <div class="text-left mb-3">
                        <label class="state">Description:</label>
                        <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                            v-model="documentData.description"></b-form-textarea>
                        <p v-if="errors && errors.description" class="field-error">
                            {{ errors.description[0] }}
                        </p>
                    </div>
                </b-col>
                <b-col lg="6" md="8" sm="12">
                    <div class="upload-btn-wrapper">
                        <b-img :src="require('@/assets/drop.svg')"></b-img>
                        <button v-if="!fileName" class="foo">
                            Drop your document here, or browse
                        </button>
                        <button v-else class="foo">{{ fileName }}</button>
                        <p v-if="fileName" class="mb-0 field-error cursor-pointer font-12 position-relative error-button"
                            @click="removeFile()">
                            Delete
                        </p>
                        <input ref="docFile" type="file" name="docFile" v-on:change="handleFileUpload"
                            :accept="allowVideo ? '.pdf, .png, .jpg, .jpeg, .mp4, .mov, .avi, .mkv':'.pdf, .png, .jpg, .jpeg'" />
                        <p v-if="errors && errors.document" class="field-error">
                            {{ errors.document[0] }}
                        </p>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="text-right modal-footer border-0 p-0">
            <b-button class="mt-3 mr-3 btn cancel m-0" block @click="$bvModal.hide('modal-document')">Cancel</b-button>
            <b-button class="mt-3 btn save mx-0 m-0" variant="primary" @click="sendDocumentData">Save</b-button>
        </div>
    </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "DotInspectionDocument",
    props: ['id', "documentItem",'addDoc','updateDoc','allowVideo'],
    data() {
        return {
            fileName: null,
            documentData: {
                date: new Date(),
                document: null,
                description: null,
            },
            errors: {
                date: null,
                document: null,
                description: null,
            }
        }
    },
    computed: {
        ...mapGetters(['userCompany'])
    },
    watch: {
        documentItem() {
            if (this.documentItem) {
                console.log(this.documentItem)
                this.documentData.description = this.documentItem.description
                this.documentData.date = this.documentItem.date
                if (this.documentItem.document) {
                    this.fileName = this.documentItem.document.substring(this.documentItem.document.lastIndexOf('/') + 1, this.documentItem.document.indexOf('?'));
                } else {
                    this.documentData.document = null;
                }
            }
        }
    },
    methods: {
        ...mapActions([ 'setLoader', 'setNotification']),
        resetPopup() {
            this.documentData = {
                date: new Date(),
                document: null,
                description: null,
            };
            this.errors = {
                date: null,
                document: null,
                description: null,
            };
            this.fileName = null
            this.$bvModal.hide("modal-document");

        },
        handleFileUpload() {
            this.documentData.document = this.$refs.docFile.files[0];
            this.fileName = this.documentData.document.name;
        },
        removeFile() {
            this.fileName = null;
            this.$refs.docFile.value = "";
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async sendDocumentData() {
            try {
                const nullKeys = this.checkNullValues({
                    date: this.documentData.date,
                    description: this.documentData.description,
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    let method = 'Added'
                    let formData = new FormData();
                    formData.append("date", this.documentData.date);
                    formData.append("description", this.documentData.description);
                    if (this.documentData.document)
                        formData.append(
                            "document",
                            new File(
                                [this.documentData.document],
                                this.documentData.document["name"].split(".")[0] +
                                "_" + this.userCompany + "_" +
                                new Date().getTime() +
                                "." +
                                this.documentData.document["type"].split("/").pop(),
                                { type: this.documentData.document["type"] }
                            )
                        )
                    if (this.documentItem) {
                        await this.updateDoc({ id: this.id, docID: this.documentItem.id, payload: formData })
                        method = 'Updated'
                    }
                    else await this.addDoc({ id: this.id, payload: formData })
                    await this.setNotification({
                        msg: `Document Successfully ${method}`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.$emit("documentsUpdated", true)
                    this.resetPopup()
                }
            }
            catch (e) {
                console.log(e)
            }
        }
    },
}
</script>