<template>
    <b-container fluid class="mt-2 license-management-page">
        <b-row>
            <b-col cols="" class="px-1 px-sm-3">
                <div class="expiry-data d-flex justify-content-between align-items-center mb-4">
                    <h2 class="text-left">Reports</h2>
                </div>
                <div class="bg-white rounded table-custom-border">
                    <div class="p-3">
                        <b-row class="mb-0 my-3">
                            <b-col md="6" class="mb-3 mb-md-0">
                                <b-card class="table-custom-border">
                                    <b-card-text>
                                        <div class="d-flex align-items-center">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.7616 7.68913H7.52965V18H4.7616V7.68913Z" fill="black" />
                                                <path d="M9.52173 9.87511H12.2898V17.9999H9.52173V9.87511Z" fill="black" />
                                                <path d="M14.2833 6.01311H17.0514V18H14.2833V6.01311Z" fill="black" />
                                                <path d="M0 11.5876H2.76805V18H0V11.5876Z" fill="black" />
                                                <path
                                                    d="M15.494 0C14.7199 0 14.0925 0.61898 14.0925 1.38253C14.0925 1.56124 14.1273 1.73186 14.1898 1.88864L11.4889 4.16006C11.2907 4.05414 11.0636 3.99399 10.8224 3.99399C10.4219 3.99399 10.0608 4.16002 9.80544 4.42581L7.47378 3.48311C7.47396 3.47418 7.47448 3.46533 7.47448 3.45633C7.47448 2.69278 6.84702 2.0738 6.07299 2.0738C5.29897 2.0738 4.67155 2.69278 4.67155 3.45633C4.67155 3.53361 4.67827 3.60928 4.69063 3.6831L2.26822 5.097C2.02968 4.91147 1.72881 4.80046 1.40148 4.80046C0.627462 4.80046 0 5.41944 0 6.18299C0 6.94654 0.627462 7.56552 1.40148 7.56552C2.1755 7.56552 2.80293 6.94654 2.80293 6.18299C2.80293 6.10571 2.79621 6.03004 2.78385 5.95622L5.20626 4.54232C5.44479 4.72785 5.74566 4.83886 6.07303 4.83886C6.47359 4.83886 6.83461 4.67283 7.09001 4.40704L9.42167 5.34974C9.42149 5.35867 9.42097 5.36752 9.42097 5.37648C9.42097 6.14004 10.0484 6.75902 10.8225 6.75902C11.5965 6.75902 12.2239 6.14004 12.2239 5.37648C12.2239 5.2165 12.196 5.063 12.1454 4.92007L14.8762 2.62355C15.0626 2.71402 15.2722 2.76506 15.494 2.76506C16.268 2.76506 16.8955 2.14608 16.8955 1.38253C16.8955 0.61898 16.268 0 15.494 0Z"
                                                    fill="black" />
                                            </svg>
                                            <h5 class="f-18 text-black ml-2 mb-0">Active Driver</h5>
                                        </div>
                                        <p class="mt-5 text-left text_secondary f-14">Active driver report based on any date
                                            range.</p>
                                        <hr />
                                        <div class="text-right">
                                            <router-link to="/reports/active-driver" class="text_primary">View Report</router-link>
                                        </div>
                                    </b-card-text>
                                </b-card>
                            </b-col>
                            <b-col md="6" class="mb-3 mb-md-0">
                                <b-card class="table-custom-border">
                                    <b-card-text>
                                        <div class="d-flex align-items-center">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.7616 7.68913H7.52965V18H4.7616V7.68913Z" fill="black" />
                                                <path d="M9.52173 9.87511H12.2898V17.9999H9.52173V9.87511Z" fill="black" />
                                                <path d="M14.2833 6.01311H17.0514V18H14.2833V6.01311Z" fill="black" />
                                                <path d="M0 11.5876H2.76805V18H0V11.5876Z" fill="black" />
                                                <path
                                                    d="M15.494 0C14.7199 0 14.0925 0.61898 14.0925 1.38253C14.0925 1.56124 14.1273 1.73186 14.1898 1.88864L11.4889 4.16006C11.2907 4.05414 11.0636 3.99399 10.8224 3.99399C10.4219 3.99399 10.0608 4.16002 9.80544 4.42581L7.47378 3.48311C7.47396 3.47418 7.47448 3.46533 7.47448 3.45633C7.47448 2.69278 6.84702 2.0738 6.07299 2.0738C5.29897 2.0738 4.67155 2.69278 4.67155 3.45633C4.67155 3.53361 4.67827 3.60928 4.69063 3.6831L2.26822 5.097C2.02968 4.91147 1.72881 4.80046 1.40148 4.80046C0.627462 4.80046 0 5.41944 0 6.18299C0 6.94654 0.627462 7.56552 1.40148 7.56552C2.1755 7.56552 2.80293 6.94654 2.80293 6.18299C2.80293 6.10571 2.79621 6.03004 2.78385 5.95622L5.20626 4.54232C5.44479 4.72785 5.74566 4.83886 6.07303 4.83886C6.47359 4.83886 6.83461 4.67283 7.09001 4.40704L9.42167 5.34974C9.42149 5.35867 9.42097 5.36752 9.42097 5.37648C9.42097 6.14004 10.0484 6.75902 10.8225 6.75902C11.5965 6.75902 12.2239 6.14004 12.2239 5.37648C12.2239 5.2165 12.196 5.063 12.1454 4.92007L14.8762 2.62355C15.0626 2.71402 15.2722 2.76506 15.494 2.76506C16.268 2.76506 16.8955 2.14608 16.8955 1.38253C16.8955 0.61898 16.268 0 15.494 0Z"
                                                    fill="black" />
                                            </svg>
                                            <h5 class="f-18 text-black ml-2 mb-0">Active Equipment</h5>
                                        </div>
                                        <p class="mt-5 text-left text_secondary f-14">Active equipment report based on any
                                            date range.</p>
                                        <hr />
                                        <div class="text-right">
                                            <router-link to="/reports/active-equipment" class="text_primary">View Report</router-link>

                                        </div>
                                    </b-card-text>
                                </b-card>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: "Reports"
}
</script>