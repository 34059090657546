<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" :title="'Add Record'" backdrop shadow
            :visible="allowAddRecord" :right="true" no-header-close no-close-on-backdrop no-close-on-esc>
            <div class="d-block text-center mt-3">
                <b-row class="align-items-center px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Record # :" class="text-left mb-0" v-if="showRecordField">
                            <b-form-input v-model="recordPayload.record_no" type="text" placeholder="Enter Record"
                                class="unit" required></b-form-input>
                            <p v-if="errors && errors.record_no" class="field-error">
                                {{ errors.record_no[0] }}
                            </p>
                        </b-form-group>
                        <b-form-group label="Record # :" class="text-left mb-0" v-else>
                            <b-form-input v-model="lastRecord" disabled type="text" placeholder="Enter Record" class="unit"
                                required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <div class="text-left select_arrow ">
                            <label class="state">Status</label>
                            <b-form-select v-model="recordPayload.status" :options="[{
                                value: 'New',
                                text: 'New'
                            },
                            {
                                value: 'Open',
                                text: 'Open'
                            },
                            {
                                value: 'Closed',
                                text: 'Closed'
                            },
                            {
                                value: 'Urgent',
                                text: 'Urgent'
                            }
                            ]" />
                        </div>
                    </b-col>
                    <b-col md="6" sm="12">
                        <div class="text-left mt-3">
                            <label for="modal-add-record-date-datepicker" class="state">Date *</label>
                            <b-form-datepicker id="modal-add-record-date-datepicker" class="" v-model="recordPayload.date"
                                reset-button></b-form-datepicker>
                            <p v-if="errors && errors.date" class="field-error">
                                {{ errors.date[0] }}
                            </p>
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <div class="text-left select_arrow ">
                            <label class="state">Unit #</label>
                            <v-select class="bg_white w-100" v-model="recordPayload.unit_no" :options="nameOptions"
                                item-value="id" item-text="label" :reduce="(option) => option.id" @search="searchAssets">
                                <span slot="no-options">
                                    Search Unit # ...
                                </span>
                            </v-select>
                            <p v-if="errors && errors.unit_no" class="field-error">
                                {{ errors.unit_no[0] }}
                            </p>
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <div class="text-left ">
                            <b-form-group label="Odometer" class="text-left">
                                <b-form-input type="text" v-mask="numberMask" placeholder="Enter Odometer" required
                                    v-model="recordPayload.odometer"></b-form-input>
                                <p v-if="errors && errors.odometer" class="field-error">
                                    {{ errors.odometer[0] }}
                                </p>
                            </b-form-group>
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <div class="text-left ">
                            <b-form-group label="Amount" class="text-left">
                                <b-form-input type="text" v-mask="amountMask" placeholder="Enter Amount" required
                                    v-model="recordPayload.amount"></b-form-input>
                                <p v-if="errors && errors.amount" class="field-error">
                                    {{ errors.amount[0] }}
                                </p>
                            </b-form-group>
                        </div>
                    </b-col>
                    <b-col md="12">
                        <div class="text-left">
                            <label class="state">Description</label>
                            <b-form-textarea id="textarea" class="f-14" placeholder="Enter Description" rows="5"
                                v-model="recordPayload.description"></b-form-textarea>
                            <p v-if="errors && errors.description" class="field-error">
                                {{ errors.description[0] }}
                            </p>
                        </div>
                    </b-col>
                </b-row>
                <hr class="m-4" />
                <b-row class="px-4 mb-7rem">
                    <h3 class="f-18 font-weight-bold col-12 text-left mb-0">Schedule</h3>
                    <b-col cols="12">
                        <div class="text-left">
                            <label class="state mt-4">Add Schedule</label>
                            <b-form-checkbox class="pl-3-5" name="check-button" switch v-model="addSchedule"
                                size="lg"></b-form-checkbox>
                        </div>
                    </b-col>
                    <b-col cols="12" v-if="addSchedule">
                        <b-row>
                            <b-col md="6" sm="12">
                                <div class="text-left my-3">
                                    <label for="due-date-picker" class="state">Date</label>
                                    <b-form-datepicker id="due-date-picker" class="" v-model="schedulePayload.due_date"
                                        reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.schedule_due_date" class="field-error">
                                        {{ errors.schedule_due_date[0] }}
                                    </p>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="12">
                                <div class="text-left">
                                    <label class="state">Description</label>
                                    <b-form-textarea id="textarea" class="f-14" placeholder="Enter Description" rows="5"
                                        v-model="schedulePayload.description"></b-form-textarea>
                                    <p v-if="errors && errors.schedule_description" class="field-error">
                                        {{ errors.schedule_description[0] }}
                                    </p>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <div class="sidebar-footer position-fixed">
                <div class="text-right modal-footer border-0 p-0">
                    <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetPopup()" variant="secondary">Cancel</b-button>
                    <b-button class="mx-0 h-40px w-125px px-2 mr-3" @click="addMaintenance(true)"
                        variant="outline-primary">Save & Open</b-button>
                    <b-button class="mx-0 h-40px w-100px" @click="addMaintenance()" variant="primary">Save</b-button>
                </div>
            </div>
        </b-sidebar>
        <!-- <EditRecord v-if="recordID" :recordID="recordID" @resetMaintenance="recordID = ''"
            @recordUpdated="$emit('recordAdded', true)" /> -->
    </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { mapActions, mapGetters } from "vuex"
import * as moment from "moment-timezone";
const numberMask = createNumberMask({
    prefix: "",
    allowDecimal: true,
    includeThousandsSeparator: true,
});
const amountMask = createNumberMask({
    prefix: "",
    suffix:".00",
    allowDecimal: true,
    includeThousandsSeparator: true,
});
export default {
    name: "addRecord",
    components: { vSelect },
    props: {
        unitNo: {
            type: Object,
            default: null
        },
        allowAddRecord: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            numberMask,
            amountMask,
            lastRecord:null,
            showRecordField:false,
            recordID: null,
            addSchedule: false,
            nameOptions: [],
            recordPayload: {
                record_no: "",
                date: new Date(),
                unit_no: null,
                description: null,
                amount: null,
                odometer: null,
                status: "New"
            },
            schedulePayload: {
                unit_no: null,
                due_date: null,
                description: null,
            },
            editFlag: false,
            errors: {}
        }
    },
    computed: {
        ...mapGetters(["currentMaintenance", 'userCompany'])
    },
    watch: {
        unitNo() {
            if (this.unitNo) {
                this.recordPayload.unit_no = this.unitNo.id
                this.nameOptions = [this.unitNo]
                this.recordPayload.description = this.description
            }
        },
        async allowAddRecord(){
            if(this.allowAddRecord){
                this.setLoader(true)
                const lastItemRecord = await this.getLastRecord({
                    company_id:this.userCompany,
                    query:`model_name=maintenance`
                })
                if(lastItemRecord) this.lastRecord = lastItemRecord + 1;
                else this.showRecordField = true
                this.setLoader(false)
            }
        }
    },
    methods: {
        ...mapActions(["getAllEquipments", "postMaintenance", "patchMaintenance", "patchMaintenanceSchedule", "setNotification", "postMaintenanceSchedule", "setLoader","getLastRecord"]),
        resetPopup() {
            this.recordPayload = {
                record_no: "",
                date: new Date(),
                unit_no: null,
                description: null,
                amount: null,
                odometer: null,
                status: "New"
            }
            this.errors = {}
            this.$emit('close');
        },
        checkNullValues(payload, type) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (
                    !value &&
                    key !== "document" &&
                    key.toLowerCase() !== "odometer" &&
                    key !== "amount" && key !== 'record_no'
                ) {
                    nullKeys.push(key);
                }
            }
            if (
                type === "maintenance" &&
                this.addSchedule &&
                !this.schedulePayload.description
            ) {
                nullKeys.push("schedule_description");
            }
            if (
                type === "maintenance" &&
                this.addSchedule &&
                !this.schedulePayload.due_date
            ) {
                nullKeys.push("schedule_due_date");
            }
            if (
                type === "maintenance" &&
                this.showRecordField &&
                !this.recordPayload.record_no
            ) {
                nullKeys.push("record_no");
            }
            return nullKeys;
        },
        async addMaintenance(openRecord = false) {
            let record
            try {
                this.setLoader(true)
                const nullKeys = this.checkNullValues(this.recordPayload, "maintenance");
                let formData = new FormData();
                const unit_no = this.recordPayload.unit_no;
                if (nullKeys.length === 0) {
                    formData.append("unit_no", this.recordPayload.unit_no);
                    formData.append(
                        "date",
                        moment(this.recordPayload.date).format("YYYY-MM-DD")
                    );
                    formData.append(
                        "amount",
                        this.recordPayload.amount
                            ? parseFloat(this.recordPayload.amount).toFixed(2)
                            : 0
                    );
                    formData.append("description", this.recordPayload.description);
                    formData.append('status', this.recordPayload.status)
                    formData.append(
                        "odometer",
                        this.recordPayload.odometer
                            ? this.recordPayload.odometer.toString().replace(/,/g, "")
                            : ""
                    );
                    if (this.showRecordField) {
                        formData.append("record_no", this.recordPayload.record_no)
                    }
                    record = await this.postMaintenance(formData);

                } else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
                if (this.addSchedule) {
                    try {
                        await this.addMaintenanceSchedule(unit_no);
                    }
                    catch (e) {
                        console.log(e)
                        return
                    }
                }
                this.editFlag = false;
                this.addSchedule = false;
                this.fileName = null;
                await this.setNotification({
                    msg: `Maintenance successfully added`,
                    type: "success",
                    color: "green",
                });
                this.recordPayload = {
                    date: new Date(),
                    unit_no: null,
                    description: null,
                    amount: null,
                    odometer: null,
                    status: "New"
                };
                this.nameOptions = []
                if (openRecord) this.$emit("recordAdded", record.id)
                else this.$emit("recordAdded", null)
                this.setLoader(false)
                return record
            } catch (error) {
                if (typeof error === "object") {
                    console.log(error);
                    this.errors = error;
                    this.setLoader(false)
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        async addMaintenanceSchedule(unitNo = null) {
            const _id = this.schedulePayload.id;
            let method = "";
            try {
                if (
                    !this.schedulePayload.unit_no &&
                    unitNo &&
                    typeof unitNo !== "object"
                ) {
                    this.schedulePayload.unit_no = unitNo;
                }
                delete this.schedulePayload.days;
                if (!this.schedulePayload.complete_date) {
                    delete this.schedulePayload.complete_date;
                }
                const nullKeys = this.checkNullValues(this.schedulePayload);
                if (nullKeys.length === 0) {
                    if (this.editScheduleFlag) {
                        await this.patchMaintenanceSchedule({
                            id: _id,
                            payload: this.schedulePayload,
                        });
                        method = "updated";
                    } else {
                        await this.postMaintenanceSchedule(this.schedulePayload);
                        method = "added";
                    }
                    this.schedulePayload = {
                        unit_no: null,
                        due_date: null,
                        description: null,
                    };
                    // this.$bvModal.hide("modal-schedule");
                    this.editScheduleFlag = false;
                    if (this.maintenanceSchedulePopup) {
                        await this.setNotification({
                            msg: `Maintenance Schedule successfully ${method}`,
                            type: "success",
                            color: "green",
                        });
                    }
                } else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        x = "schedule_" + x;
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            } catch (error) {
                if (typeof error === "object") {
                    console.log(error);
                    this.errors = error;
                    this.setLoader(false)
                    return
                }
            }
        },
        async searchAssets(searchText) {
            let options = []
            let url = `unit_no=${searchText}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            const equipmentRecords = await this.getAllEquipments(url);
            equipmentRecords.records.map((item) => {
                options.push({
                    id: `${item.id}`,
                    label: item.unit_no,
                    type: "equipment",
                });
            });
            this.nameOptions = options
        }
    },
}
</script>